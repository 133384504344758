import * as routeHelpers from "../../lib/routeHelpers";
import cn from "classnames";
import CustomizationList from "../CustomizationList";
import Drawer from "../Drawer";
import getInitialModSelections from "../../lib/getInitialModSelections";
import ItemEditor from "../ItemEditor";
import logger from "../../lib/logger";
import OutlineButton from "../OutlineButton";
import QuantityControl from "../QuantityControl";
import React, {useState, useEffect} from "react";
import RetinaImage from "../RetinaImage";
import SplitDrawerContent from "../SplitDrawerContent";
import styles from "./GroupingCustomization.module.css";
import ToggleButton from "../ToggleButton";
import {Actions, API, Lib} from "habit-core";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../lib/hooks";
import {v4 as uuid4} from "uuid";

const strings = {
    addToOrder: "Add To Order",
    updateOrder: "Update Order",
    cancel: "Cancel",
    addOnsTitle: "Suggested Add-Ons",
};

type Params = {
    categoryId: string;
    groupingId: string;
};

function getUnitPriceCents(
    menuItemPriceCents: API.models.USDCents,
    modifierSelections: API.models.ModifierSelectionDict,
    menuItemModifiers: {[modifierId: string]: API.models.MenuItemModifier},
    allPreps: {byId: {[modifierId: string]: Lib.selectors.ItemModifierData}},
): API.models.USDCents {
    let perUnitPriceCents = menuItemPriceCents;
    Object.keys(modifierSelections).forEach((modId) => {
        const mod = menuItemModifiers[modId];
        if (mod) {
            const [sel] = mod.selections.filter(
                (x) => x.id === modifierSelections[modId]?.selectionId,
            );

            if (sel && sel.priceCents) {
                perUnitPriceCents +=
                    sel.priceCents * modifierSelections[modId]?.quantity;
            }
        } else {
            const allPrepsMod = allPreps.byId[modId];
            const allPrepModSelection = allPrepsMod?.selections.find(
                (selection) =>
                    selection.id === modifierSelections[modId]?.selectionId,
            );

            if (allPrepModSelection && allPrepModSelection.priceCents) {
                perUnitPriceCents +=
                    allPrepModSelection.priceCents *
                    modifierSelections[modId]?.quantity;
            }
        }
    });

    return perUnitPriceCents;
}

export default function GroupingCustomization() {
    const navigate = useNavigate();
    const {categoryId, groupingId} = useParams<Params>();
    const menuId = useAppSelector((state) => state.currentOrder.menuId);
    useEffect(() => {
        if (!menuId || !categoryId || !groupingId) {
            logger.warn(
                `invalid menu id (${menuId}), category id (${categoryId}), and/or grouping id (${groupingId})`,
            );
            navigate(routeHelpers.menu(), {replace: true});
        }
    }, []);

    const grouping = useAppSelector((state) =>
        groupingId ? state.groupings.byId[groupingId] : null,
    );
    const itemsById = useAppSelector((state) => state.items.byId);
    const menuItems = useAppSelector((state) => state.menuItems.byMenuId);
    const menuItemModifiers = useAppSelector(
        (state) => state.menuItemModifiers.byMenuId,
    );
    const menuCombos = useAppSelector((state) => state.menuCombos.byMenuId);

    const availableOptions =
        grouping?.options.filter((option) => {
            if (option.itemId) {
                return !!menuItems[menuId ?? ""][option.itemId];
            } else if (option.comboId) {
                return !!menuCombos[menuId ?? ""][option.comboId];
            }
            return false;
        }) ?? [];

    useEffect(() => {
        if (!grouping) {
            logger.warn(`No grouping found with groupingId ${groupingId}`);
            navigate(routeHelpers.menu(), {replace: true});
        } else if (availableOptions.length === 0) {
            logger.warn(
                `No available option for grouping with groupingId ${groupingId}`,
            );
            navigate(routeHelpers.menu(), {replace: true});
        }
    }, []);

    const dispatch = useAppDispatch();
    const [quantity, setQuantity] = useState(1);
    const onClose = () =>
        navigate(
            categoryId
                ? routeHelpers.category(categoryId)
                : routeHelpers.menu(),
        );

    const [activeOptionId, setActiveOptionId] = useState(
        availableOptions[0].itemId ?? "",
    );
    const [modSelections, setModSelections] =
        useState<API.models.ModifierSelectionDict>(
            getInitialModSelections(
                itemsById,
                activeOptionId,
                menuItems,
                menuId ?? "",
                menuItemModifiers,
                null,
                true,
            ),
        );

    const addOns = useAppSelector(
        (state) => state.pos.addOns.byId[activeOptionId],
    );
    const unfilteredAddOnsInfo = addOns?.map((addOnId) => {
        const addOnMenuItem = menuItems[menuId ?? ""][addOnId];
        const addOnItem = itemsById[addOnId];
        if (addOnMenuItem) {
            return {
                itemId: addOnMenuItem.itemId,
                name: addOnItem.name,
                priceCents: addOnMenuItem.priceCents,
            };
        } else {
            return null;
        }
    });
    const addOnsInfo: {
        itemId: string;
        name: string;
        priceCents: API.models.USDCents;
    }[] = unfilteredAddOnsInfo?.filter(
        (addOnInfo): addOnInfo is Exclude<typeof addOnInfo, null> =>
            addOnInfo !== null,
    );

    const [selectedAddOnIds, setSelectedAddOnIds] = useState<string[]>([]);

    const onSave = () => {
        if (selectedAddOnIds.length !== 0) {
            const itemCustomizationsToAdd = selectedAddOnIds.map((addOnId) => {
                return {
                    customizationId: uuid4(),
                    itemId: addOnId,
                    modifierSelections: {},
                    quantity: quantity,
                };
            });
            dispatch(
                Actions.currentOrderActions.addItems(
                    itemCustomizationsToAdd,
                    [],
                ),
            );
        }

        if (!grouping) {
            logger.error("No combo when calling on save");
            return;
        }

        dispatch(
            Actions.currentOrderActions.addItem(
                activeOptionId,
                quantity,
                modSelections,
            ),
        );

        onClose();
    };

    const allPreps = useAppSelector((state) => state.pos.allPreps);

    const baseUnitPriceCents = getUnitPriceCents(
        menuItems[menuId ?? ""]?.[activeOptionId]?.priceCents,
        {},
        menuItemModifiers[menuId ?? ""][activeOptionId],
        allPreps,
    );
    const totalPriceCents =
        getUnitPriceCents(
            menuItems[menuId ?? ""]?.[activeOptionId]?.priceCents,
            modSelections,
            menuItemModifiers[menuId ?? ""][activeOptionId],
            allPreps,
        ) * quantity;

    const totalPriceFormatted =
        Lib.currency.centsToDollarString(totalPriceCents);

    if (!categoryId || !groupingId || !menuId || !grouping) {
        return null;
    }

    const updateModSelection = (
        modifierId: string,
        selectionId: string,
        quantity: number,
        isDefault: boolean,
        isRequired: boolean,
    ) => {
        setModSelections((prevState) => {
            if (
                prevState[modifierId]?.selectionId === selectionId &&
                prevState[modifierId]?.quantity === quantity
            ) {
                return prevState;
            }

            const nextModSelections = {...prevState};
            if (isDefault && !isRequired) {
                delete nextModSelections[modifierId];
            } else {
                nextModSelections[modifierId] = {selectionId, quantity};
            }

            // TODO: #56 - Do we need dirty tracking?
            return nextModSelections;
        });
    };

    const getExistingAllPrepsMods = (
        currentItemModSelections: API.models.ModifierSelectionDict,
    ) => {
        const appliedAllPrepsMods: API.models.ModifierSelectionDict = {};
        Object.keys(currentItemModSelections).forEach((modId) => {
            const allPrepsMod = allPreps.byId[modId];
            const currentItemModSelection = currentItemModSelections[modId];
            const allPrepsModSelection = allPrepsMod?.selections.find(
                (sel) => sel.id === currentItemModSelection.selectionId,
            );
            if (allPrepsMod && allPrepsModSelection) {
                appliedAllPrepsMods[modId] = currentItemModSelection;
            }
        });
        return appliedAllPrepsMods;
    };

    const onChangeOption = (optionId: string) => {
        setModSelections((prevModSelections) => {
            // this will get us the required mods for the option selected
            const initialModSelections = getInitialModSelections(
                itemsById,
                optionId,
                menuItems,
                menuId,
                menuItemModifiers,
                null,
                true,
            );
            const existingValidMods =
                Lib.selectors.getExistingValidModsForSubstitute(
                    menuItemModifiers[menuId ?? ""],
                    optionId,
                    prevModSelections,
                );
            const existingAllPrepsMods =
                getExistingAllPrepsMods(prevModSelections);

            return {
                ...initialModSelections,
                ...existingValidMods,
                ...existingAllPrepsMods,
            };
        });
        setActiveOptionId(optionId);
    };

    function renderAddOns() {
        if ((addOnsInfo?.length ?? 0) === 0) {
            return;
        }

        return (
            <div className={styles.addOnsContainer}>
                {strings.addOnsTitle}
                <div className={styles.addOnsOptionsContainer}>
                    {addOnsInfo.map((addOnInfo, i) => (
                        <ToggleButton
                            key={"add-on-" + i}
                            className={styles.addOnsOption}
                            checked={selectedAddOnIds.includes(
                                addOnInfo.itemId,
                            )}
                            label={addOnInfo.name}
                            label2={`+ ${Lib.currency.centsToDollarString(
                                addOnInfo.priceCents,
                            )}`}
                            onChange={(checked) => {
                                if (checked) {
                                    setSelectedAddOnIds([
                                        ...selectedAddOnIds,
                                        addOnInfo.itemId,
                                    ]);
                                } else {
                                    setSelectedAddOnIds([
                                        ...selectedAddOnIds.filter(
                                            (addOnId) =>
                                                addOnId !== addOnInfo.itemId,
                                        ),
                                    ]);
                                }
                            }}
                        />
                    ))}
                </div>
            </div>
        );
    }

    const activeItem = itemsById[activeOptionId];

    return (
        <Drawer
            onClose={() => navigate(routeHelpers.category(categoryId))}
            closeButtonLabel={strings.cancel}>
            <SplitDrawerContent
                className={styles.content}
                left={
                    <ItemEditor
                        itemId={activeOptionId}
                        itemKey={activeOptionId}
                        menuId={menuId}
                        modifierSelections={modSelections}
                        onChangeModifier={updateModSelection}
                        options={availableOptions}
                        onChangeOption={onChangeOption}
                    />
                }
                right={
                    <>
                        <div className={styles.titleAndPrice}>
                            {activeItem.isolatedImages328.length > 0 && (
                                <div className={styles.imageContainer}>
                                    <RetinaImage
                                        retinaImages={
                                            activeItem.isolatedImages328
                                        }
                                        className={styles.image}
                                    />
                                </div>
                            )}
                            <div
                                className={cn(
                                    styles.title,
                                    activeItem.isolatedImages328.length > 0
                                        ? styles.titleMargin
                                        : null,
                                )}>
                                {activeItem.name}
                            </div>
                            <div className={styles.price}>
                                {Lib.currency.centsToDollarString(
                                    baseUnitPriceCents,
                                )}
                            </div>
                        </div>

                        <div className={styles.divider} />
                        <div className={styles.rightCustomizations}>
                            <CustomizationList
                                modifierSelections={modSelections}
                                itemId={activeItem.id}
                                onChangeModifier={updateModSelection}
                                editable={true}
                            />

                            <div className={styles.spacer} />
                        </div>
                        <div className={styles.divider} />
                        {renderAddOns()}

                        <div className={styles.divider} />

                        <div className={styles.qtyAddToOrder}>
                            <QuantityControl
                                quantity={quantity}
                                setQuantity={setQuantity}
                            />
                            <OutlineButton
                                label={totalPriceFormatted}
                                label2={strings.addToOrder}
                                onClick={onSave}
                                className={styles.fullWidth}
                            />
                        </div>
                    </>
                }
            />
        </Drawer>
    );
}
