import cn from "classnames";
import React from "react";
import styles from "./SplitDrawerContent.module.css";

type Props = {
    className?: string;
    left?: React.ReactNode;
    right?: React.ReactNode;
};

export default function SplitDrawerContent(props: Props) {
    return (
        <div className={cn(props.className, styles.container)}>
            <div className={styles.left}>{props.left}</div>

            <div className={styles.right}>{props.right}</div>
        </div>
    );
}
