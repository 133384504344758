import {API, Lib} from "habit-core";

export const getDisplayedCashAmount = (cashAmount: string) => {
    if (!cashAmount) {
        return null;
    }
    const inputCashAmountValue = revertDisplayedCashAmount(cashAmount);
    if (inputCashAmountValue.length > 2) {
        return (
            inputCashAmountValue.slice(0, -2) +
            "." +
            inputCashAmountValue.slice(inputCashAmountValue.length - 2)
        );
    } else if (inputCashAmountValue.length > 1) {
        return `0.${inputCashAmountValue}`;
    } else {
        return `0.0${inputCashAmountValue}`;
    }
};

const revertDisplayedCashAmount = (displayedTip: string) => {
    const unformattedCashAmountInput = parseInt(
        displayedTip.replace(".", ""),
    ).toString();
    return unformattedCashAmountInput;
};

export default function useOnChangeCashAmount(
    setDisplayedCashAmount: (input: string) => void,
    setCashAmount: (input: API.models.USDCents | null) => void,
) {
    const onChange = (input: string) => {
        const formattedCashAmount = getDisplayedCashAmount(input);
        if (formattedCashAmount) {
            const parsed = parseFloat(formattedCashAmount);
            setDisplayedCashAmount(formattedCashAmount);
            setCashAmount(
                isNaN(parsed) ? null : Lib.currency.dollarsFloatToCents(parsed),
            );
        } else {
            setDisplayedCashAmount("");
            setCashAmount(null);
        }
    };

    return onChange;
}
