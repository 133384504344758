import * as posModels from "../../lib/api/posModels";
import * as reports from "../../lib/api/reports";
import cn from "classnames";
import ManagerReportsDrawer from "../ManagerReportsDrawer";
import ManagerReportsSectionDropdown from "../ManagerReportsSectionDropdown";
import React from "react";
import styles from "./ManagerSalesReport.module.css";
import {Lib} from "habit-core";

const strings = {
    title: "Sales Detail",
    totals: "Totals",
    salesQuantity: "Sales Quantity :",
    itemDiscounts: "Item Discounts :",
    voidsRefunds: "Voids / Refunds :",
    subtotal: "Subtotal :",
    otherDiscounts: "Other Discounts :",
    netSales: "Net Sales",
    familyTotals: "Family Group Totals",
    family: "Family",
    itemCount: "Item Count",
    percentageOfSales: "% of Sales",
    totalSales: "Total Sales",
    item: "Item",
    salesQty: "Sales Qty",
    voids: "Voids",
    noData: "No Data to Show",
};

export default function ManagerSalesReport() {
    function renderEntityDropdown(cat: posModels.CategorySales) {
        return (
            <ManagerReportsSectionDropdown key={cat.name} title={cat.name}>
                <div
                    className={cn(styles.sectionContainer, styles.catEntities)}>
                    <div className={styles.row}>
                        <div
                            className={cn(
                                styles.catEntityRowItem,
                                styles.top,
                                styles.name,
                            )}>
                            {strings.item}
                        </div>
                        <div
                            className={cn(styles.catEntityRowItem, styles.top)}>
                            {strings.salesQty}
                        </div>
                        <div
                            className={cn(styles.catEntityRowItem, styles.top)}>
                            {strings.voids}
                        </div>
                        <div
                            className={cn(styles.catEntityRowItem, styles.top)}>
                            {strings.netSales}
                        </div>
                    </div>
                    {cat.entities.map((e) => (
                        <div key={e.plu} className={styles.row}>
                            <div
                                className={cn(
                                    styles.catEntityRowItem,
                                    styles.name,
                                )}>
                                {e.plu} - {e.name}
                            </div>
                            <div className={styles.catEntityRowItem}>
                                <div className={styles.catEntityRowChild}>
                                    {e.quantity}
                                </div>
                                <div className={styles.catEntityRowChild}>
                                    {e.percentage.toFixed(0)}%
                                </div>
                            </div>
                            <div className={styles.catEntityRowItem}>
                                <div className={styles.catEntityRowChild}>
                                    {e.voidQuantity}
                                </div>
                                <div className={styles.catEntityRowChild}>
                                    {e.voidPercentage.toFixed(0)}%
                                </div>
                            </div>
                            <div className={styles.catEntityRowItem}>
                                <div className={styles.catEntityRowChild}>
                                    {Lib.currency.centsToDollarString(
                                        e.netSalesCents,
                                    )}
                                </div>
                                <div className={styles.catEntityRowChild}>
                                    {e.netSalesPercentage.toFixed(1)}%
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </ManagerReportsSectionDropdown>
        );
    }

    function renderCategories(categories: posModels.CategorySales[]) {
        if (!categories.length) {
            return (
                <ManagerReportsSectionDropdown title={strings.familyTotals}>
                    <div className={cn(styles.sectionContainer, styles.noData)}>
                        {strings.noData}
                    </div>
                </ManagerReportsSectionDropdown>
            );
        }

        return (
            <>
                <ManagerReportsSectionDropdown title={strings.familyTotals}>
                    <div className={styles.sectionContainer}>
                        <div className={styles.row}>
                            <div
                                className={cn(
                                    styles.catTotalRowItem,
                                    styles.top,
                                    styles.name,
                                )}>
                                {strings.family}
                            </div>
                            <div
                                className={cn(
                                    styles.catTotalRowItem,
                                    styles.top,
                                )}>
                                {strings.itemCount}
                            </div>
                            <div
                                className={cn(
                                    styles.catTotalRowItem,
                                    styles.top,
                                )}>
                                {strings.percentageOfSales}
                            </div>
                            <div
                                className={cn(
                                    styles.catTotalRowItem,
                                    styles.top,
                                )}>
                                {strings.totalSales}
                            </div>
                        </div>
                        {categories.map((c) => (
                            <div key={c.name} className={styles.row}>
                                <div
                                    className={cn(
                                        styles.catTotalRowItem,
                                        styles.name,
                                    )}>
                                    {c.name} :
                                </div>
                                <div className={styles.catTotalRowItem}>
                                    {c.itemsSold}
                                </div>
                                <div className={styles.catTotalRowItem}>
                                    {c.salesPercentage.toFixed(0)}%
                                </div>
                                <div className={styles.catTotalRowItem}>
                                    {Lib.currency.centsToDollarString(
                                        c.totalCents,
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </ManagerReportsSectionDropdown>
                {categories.map((c) => renderEntityDropdown(c))}
            </>
        );
    }

    function renderTotals(data: posModels.SalesDetailSummary) {
        return (
            <div className={cn(styles.sectionContainer, styles.flex)}>
                <div className={styles.innerSectionContainer}>
                    <div className={styles.row}>
                        <div className={styles.totalsName}>
                            {strings.salesQuantity}
                        </div>
                        <div className={styles.totalsCount}>
                            {data.salesQuantity}
                        </div>
                        <div className={styles.totalsSubtotal}>
                            {Lib.currency.centsToDollarString(
                                data.salesSubtotalCents,
                            )}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.totalsName}>
                            {strings.itemDiscounts}
                        </div>
                        <div className={styles.totalsCount}>
                            {data.discountQuantity}
                        </div>
                        <div className={styles.totalsSubtotal}>
                            {Lib.currency.centsToDollarString(
                                data.discountSubtotalCents,
                            )}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.totalsName}>
                            {strings.voidsRefunds}
                        </div>
                        <div className={styles.totalsCount}>
                            {data.voidQuantity}
                        </div>
                        <div className={styles.totalsSubtotal}>
                            {Lib.currency.centsToDollarString(
                                data.voidSubtotalCents,
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.innerSectionContainer}>
                    <div className={styles.row}>
                        <div className={styles.totalsName}>
                            {strings.subtotal}
                        </div>
                        <div className={styles.totalsCount}>
                            {Lib.currency.centsToDollarString(
                                data.subtotalSumCents,
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <ManagerReportsDrawer
            title={strings.title}
            getData={reports.getSalesDetail}>
            {(data) => (
                <>
                    {renderCategories(data.categories)}
                    <ManagerReportsSectionDropdown title={strings.totals}>
                        {renderTotals(data.totals)}
                    </ManagerReportsSectionDropdown>
                </>
            )}
        </ManagerReportsDrawer>
    );
}
