import * as paymentMethods from "../../lib/api/paymentMethods";
import * as posActions from "../../lib/posActions";
import * as posModels from "../../lib/api/posModels";
import * as posSelectors from "../../lib/posSelectors";
import * as routeHelpers from "../../lib/routeHelpers";
import arrowIcon from "../../images/arrow-in-circle.svg";
import cn from "classnames";
import describeNetworkError from "../../lib/describeNetworkError";
import ErrorModal from "../ErrorModal";
import logger from "../../lib/logger";
import Modal from "../Modal";
import OutlineButton from "../OutlineButton";
import PaymentHeader from "../PaymentHeader";
import React, {useState, useEffect, useRef, useContext} from "react";
import StyledKeyboard from "../StyledKeyboard";
import styles from "./CheckoutGiftCard.module.css";
import TextInputWithResetButton from "../TextInputWithResetButton";
import {useExternalInput} from "../../lib/api/useExternalInput";
import {useLocation, useNavigate} from "react-router-dom";
import {CashierModeContext} from "../CashierModeContext";
import useOnChangeCashAmount from "../../lib/useOnChangeCashAmount";
import CurrencyInput from "../CurrencyInput";
import TextButton from "../TextButton";
import {ParallelModeContext} from "../ParallelModeContext";
import {API, Lib} from "habit-core";
import {useAppDispatch, useAppSelector} from "../../lib/hooks";
import {usePaidContext} from "../OrderSubtotalLayout";

const strings = {
    giftCard: "Gift or Comp Card",
    instructions: "Ask guest for their card and swipe their card.",
    trainingInstructions: "Enter the balance on the gift card.",
    giftCardBalancePrompt: "Gift Card Balance:",
    giftCardBalancePlaceholder: "Enter Amount",
    manual: "Enter Card Number Manually",
    manualPlaceholder: "0123 4567 8901 2345",
    reset: "Reset",
    apply: "Apply",
    giftCardProcessed: "Card Successfully Processed.",
    giftCardBalance: "Ask If They’d Like To Know Their Balance",
    completeInstructions:
        "Your pager will light up and vibrate when your order is ready. Thank you!",
    emptyGiftCardTitle: "Zero Balance",
    emptyGiftCardBody:
        "This card has no funds left on it and can't be used as payment.",
    ok: "OK",
    cardNotRecognized: "Card Not Recognized",
};

const TRAINING_GIFT_CARD_NUM = "6000111111111111";

type NameState = {
    name?: string;
};

const GIFT_CARD_LENGTH = 16;
const SHOW_SWIPE_BUTTON = import.meta.env.REACT_APP_MOCK_API === "true";

export default function CheckoutGiftCard() {
    const dispatch = useAppDispatch();
    const {balanceDueCents} = usePaidContext();
    const subtotalCents = useAppSelector((state) =>
        posSelectors.getCurrentOrderSubtotalSansVoidedCents(state),
    );
    const discountAmountCents = Math.min(
        useAppSelector((state) =>
            posSelectors.getCurrentOrderDiscountAmountCents(state),
        ),
        subtotalCents,
    );
    const {isTraining} = useContext(CashierModeContext);
    const {isParallel} = useContext(ParallelModeContext); // for training mode
    const [trainingGiftCardBalanceCents, setTrainingGiftCardBalanceCents] =
        useState<API.models.USDCents | null>(null);
    const [
        trainingDisplayedGiftCardBalance,
        setTrainingDisplayedGiftCardBalance,
    ] = useState("");
    const onChangeGiftCardBalance = useOnChangeCashAmount(
        setTrainingDisplayedGiftCardBalance,
        setTrainingGiftCardBalanceCents,
    );

    const location = useLocation();
    const navigate = useNavigate();
    const locationState = location.state as NameState;
    const customerName = locationState?.name ?? null;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<{
        type: "warning" | "info";
        title?: string;
        message: string;
    } | null>(null);
    const [manualCardNumber, setManualCardNumber] = useState("");
    const [showEmptyGiftCardModal, setShowEmptyGiftCardModal] = useState(false);

    /* keyboard */
    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);
    const giftCardBalanceRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (
            (inputRef.current || giftCardBalanceRef.current) &&
            !keyboardCollapsed
        ) {
            if (document.activeElement === inputRef.current) {
                inputRef?.current?.scrollIntoView();
            }
        }
    }, [keyboardCollapsed]);

    const {
        externalInput: cardNumber,
        clearExternalInput: clearCardNumber,
        cardReadError,
    } = useExternalInput(!manualCardNumber.length);
    useEffect(() => {
        if (cardNumber.length === GIFT_CARD_LENGTH && !cardReadError) {
            onApply(cardNumber);
        }
    }, [cardNumber, cardReadError]);
    useEffect(() => {
        if (cardReadError) {
            setError(cardReadError);
        }
    }, [cardReadError]);

    useEffect(() => {
        const floatVal = parseFloat(trainingDisplayedGiftCardBalance);
        if (
            isNaN(floatVal) ||
            trainingGiftCardBalanceCents !==
                Lib.currency.dollarsFloatToCents(floatVal)
        ) {
            setTrainingDisplayedGiftCardBalance(
                trainingGiftCardBalanceCents !== null
                    ? Lib.currency
                          .centsToDollarString(trainingGiftCardBalanceCents)
                          .replace("$", "")
                    : "",
            );
        }
    }, [trainingGiftCardBalanceCents]);

    const state = useAppSelector((state) => state);
    const compCardsApplied = useAppSelector(
        (state) => state.pos.currentOrder.payment.compCardsApplied,
    );
    const compCardPaidCents = compCardsApplied.reduce(
        (sum, cc) => (cc.appliedCents ?? 0) + sum,
        0,
    );

    function onApply(gcNum: string) {
        if (loading) {
            return;
        }

        setLoading(true);
        paymentMethods
            .getGiftCardBalance(gcNum)
            .then((data) => {
                const gcBalanceCents = data.balanceCents;
                const cardType = data.cardType;
                if (isNaN(gcBalanceCents)) {
                    throw new Error("Invalid gift card balance");
                }

                if (gcBalanceCents === 0) {
                    setShowEmptyGiftCardModal(true);
                    setLoading(false);
                    return;
                }

                // handle comp cards
                if (cardType === "comp") {
                    const newCard: posModels.AppliedCompCard = {
                        id: gcNum,
                        balance: gcBalanceCents,
                        appliedCents: undefined,
                    };

                    const tempCompCardsApplied = [...compCardsApplied, newCard];
                    const orderTotalWithNewCompCard =
                        posSelectors.getCurrentOrderTotalCents(
                            state,
                            tempCompCardsApplied,
                        );
                    const remainingBalanceDue =
                        orderTotalWithNewCompCard -
                        discountAmountCents -
                        compCardPaidCents; // exclude current comp card amount, discount(s) amount
                    if (gcBalanceCents >= remainingBalanceDue) {
                        newCard.appliedCents = remainingBalanceDue;
                        dispatch(posActions.applyPaymentCompCard(newCard, 0));
                    } else {
                        newCard.appliedCents = gcBalanceCents;
                        const balanceDueAfterCompCard =
                            remainingBalanceDue - gcBalanceCents;
                        dispatch(
                            posActions.applyPaymentCompCard(
                                newCard,
                                balanceDueAfterCompCard,
                            ),
                        );
                        navigate(routeHelpers.payment(), {
                            state: {
                                name: customerName,
                            },
                        });
                    }

                    return;
                }

                // card is giftcard
                if (gcBalanceCents >= balanceDueCents) {
                    const newCard = {
                        id: gcNum,
                        appliedCents: balanceDueCents,
                        balanceRemainingCents: gcBalanceCents - balanceDueCents,
                    };

                    dispatch(posActions.applyPaymentGiftCard(newCard));
                } else {
                    const newCard = {
                        id: gcNum,
                        appliedCents: gcBalanceCents,
                        balanceRemainingCents: 0,
                    };
                    dispatch(posActions.applyPaymentGiftCard(newCard));
                    navigate(routeHelpers.payment(), {
                        state: {
                            name: customerName,
                        },
                    });
                }
            })
            .catch((err) => {
                logger.warn(err);
                const description = describeNetworkError(err);
                setError({
                    type: err.response.status === 400 ? "info" : "warning",
                    title:
                        err.response.status === 400
                            ? strings.cardNotRecognized
                            : undefined,
                    message: description.join("\n"),
                });
                setLoading(false);
                clearCardNumber();
                setManualCardNumber("");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function trainingOnApply(giftCardBalanceCents: API.models.USDCents | null) {
        if (loading || !giftCardBalanceCents) {
            return;
        }

        setLoading(true);
        if (giftCardBalanceCents >= balanceDueCents) {
            const newCard = {
                id: TRAINING_GIFT_CARD_NUM,
                appliedCents: balanceDueCents,
                balanceRemainingCents: giftCardBalanceCents - balanceDueCents,
            };

            dispatch(posActions.applyPaymentGiftCard(newCard));
        } else {
            const newCard = {
                id: TRAINING_GIFT_CARD_NUM,
                appliedCents: giftCardBalanceCents,
                balanceRemainingCents: 0,
            };

            dispatch(posActions.applyPaymentGiftCard(newCard));

            navigate(routeHelpers.payment(), {
                state: {
                    name: customerName,
                },
            });
        }
        setLoading(false);
    }

    function onCloseEmptyModal() {
        setShowEmptyGiftCardModal(false);
        clearCardNumber();
        setManualCardNumber("");
    }

    function onChangeManualCardNumber(input: string) {
        setManualCardNumber(input);
    }

    function renderTrainingContent() {
        return (
            <>
                <div className={styles.instructions}>
                    {strings.trainingInstructions}
                </div>
                <form
                    className={styles.manualContainer}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (
                            trainingGiftCardBalanceCents !== null &&
                            trainingGiftCardBalanceCents !== 0
                        ) {
                            trainingOnApply(trainingGiftCardBalanceCents);
                        }
                    }}>
                    <div className={styles.manualPrompt}>
                        {strings.giftCardBalancePrompt}
                    </div>
                    <div className={styles.manualInnerContainer}>
                        <div className={styles.trainingAmountInputContainer}>
                            <CurrencyInput
                                className={styles.textInputWithoutApply}
                                placeholder={strings.giftCardBalancePlaceholder}
                                value={trainingDisplayedGiftCardBalance}
                                onChange={(num) =>
                                    setTrainingGiftCardBalanceCents(num)
                                }
                                onFocus={() => setKeyboardCollapsed(false)}
                                ref={giftCardBalanceRef}
                            />

                            <TextButton
                                className={styles.resetButton}
                                label={strings.reset}
                                onClick={() =>
                                    setTrainingGiftCardBalanceCents(null)
                                }
                            />
                        </div>
                        <OutlineButton
                            type="submit"
                            loading={loading}
                            className={styles.applyButton}
                            label={strings.apply}
                            disabled={
                                trainingGiftCardBalanceCents === null ||
                                trainingGiftCardBalanceCents === 0 ||
                                loading
                            }
                        />
                    </div>
                </form>
            </>
        );
    }

    function renderNonTrainingContent() {
        return (
            <>
                <div className={styles.instructions}>
                    {strings.instructions}
                </div>
                {SHOW_SWIPE_BUTTON ? (
                    <OutlineButton
                        className={styles.swipeButton}
                        label="mock swipe card"
                        onClick={() => onApply("1234567890")}
                        disabled={loading}
                    />
                ) : null}
                <form
                    className={styles.manualContainer}
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (manualCardNumber.length === GIFT_CARD_LENGTH) {
                            onApply(manualCardNumber);
                        }
                    }}>
                    <div className={styles.manualPrompt}>{strings.manual}</div>
                    <div className={styles.manualInnerContainer}>
                        <div>
                            <TextInputWithResetButton
                                inputClassName={styles.manualInput}
                                placeholder={strings.manualPlaceholder}
                                value={manualCardNumber}
                                setValue={setManualCardNumber}
                                disabled={loading}
                                onFocus={() => setKeyboardCollapsed(false)}
                                maxLength={GIFT_CARD_LENGTH}
                                ref={inputRef}
                            />
                        </div>
                        <OutlineButton
                            type="submit"
                            loading={loading}
                            className={styles.applyButton}
                            label={strings.apply}
                            disabled={
                                manualCardNumber.length !== GIFT_CARD_LENGTH ||
                                loading
                            }
                        />
                    </div>
                </form>
            </>
        );
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.headerContainer}>
                    <PaymentHeader
                        showCheckoutReturnPrompt={false}
                        customerName={customerName}
                    />
                    <div className={styles.backPrompt}>
                        <button
                            className={styles.backPromptButton}
                            onClick={() =>
                                navigate(routeHelpers.payment(), {
                                    state: {
                                        name: customerName,
                                    },
                                })
                            }>
                            <img src={arrowIcon} alt="" />
                            <div className={styles.backPromptText}>
                                {strings.giftCard}
                            </div>
                        </button>
                    </div>
                </div>

                <div className={styles.contentContainer}>
                    {isTraining || isParallel
                        ? renderTrainingContent()
                        : renderNonTrainingContent()}
                </div>
                <div
                    className={cn(
                        styles.keyboardContainer,
                        keyboardCollapsed && styles.keyboardContainerCollapsed,
                    )}>
                    <StyledKeyboard
                        currentInput={
                            isTraining || isParallel
                                ? trainingDisplayedGiftCardBalance
                                : manualCardNumber
                        }
                        visible={!keyboardCollapsed}
                        setVisible={(val: boolean) =>
                            setKeyboardCollapsed(!val)
                        }
                        onChange={
                            isTraining || isParallel
                                ? onChangeGiftCardBalance
                                : onChangeManualCardNumber
                        }
                        onPressEnter={
                            isTraining || isParallel
                                ? () =>
                                      trainingOnApply(
                                          trainingGiftCardBalanceCents,
                                      )
                                : () => onApply(manualCardNumber)
                        }
                        inputRefs={[inputRef, giftCardBalanceRef]}
                        layout="numeric"
                        maxLength={GIFT_CARD_LENGTH}
                    />
                </div>
            </div>
            {error ? (
                <ErrorModal
                    errorMessage={error.message}
                    onClose={() => {
                        setError(null);
                        if (cardReadError) {
                            clearCardNumber();
                        }
                    }}
                    title={error.title}
                    backgroundColor={error.type === "info" ? "grey" : "red"}
                    showITInfo={error.type === "warning"}
                />
            ) : null}
            {showEmptyGiftCardModal ? (
                <Modal
                    className={styles.emptyModal}
                    onClose={onCloseEmptyModal}>
                    <div className={styles.emptyModalContainer}>
                        <div className={styles.emptyModalTitle}>
                            {strings.emptyGiftCardTitle}
                        </div>
                        <div className={styles.emptyModalBody}>
                            {strings.emptyGiftCardBody}
                        </div>
                        <OutlineButton
                            label={strings.ok}
                            onClick={onCloseEmptyModal}
                        />
                    </div>
                </Modal>
            ) : null}
        </>
    );
}
