import * as orderDisplayUtil from "../../lib/orderDisplayUtil";
import * as posModels from "../../lib/api/posModels";
import * as printers from "../../lib/api/printers";
import cn from "classnames";
import describeNetworkError from "../../lib/describeNetworkError";
import ErrorModal from "../ErrorModal";
import logger from "../../lib/logger";
import ManagerHeader from "../ManagerHeader";
import OrderDetailsDrawer from "../OrderDetailsDrawer";
import OutlineButton from "../OutlineButton";
import React, {useMemo, useState, useContext} from "react";
import sortBy from "lodash.sortby";
import Spinner from "../Spinner";
import styles from "./ManagerPreviousOrders.module.css";
import TextModal from "../TextModal";
import useRefreshOrders from "../../lib/useRefreshOrders";
import {Lib} from "habit-core";
import {useAppSelector} from "../../lib/hooks";
import {CashierModeContext} from "../CashierModeContext";
import OrderFilterDrawer from "../OrderFilterDrawer";
import {
    DEFAULT_FILTERS,
    Filters,
    getFilteredOrders,
} from "../../lib/filterOrders";

const strings = {
    checkSearch: "Check Search",
    filter: "Filter Orders",
    orderNumber: "Order #",
    viewDetails: "View Details",
    rePrint: "Re-Print",
    noOrders: "No Orders",
    filterOrders: "Filter Orders",
    byDate: "By Date",
    bySource: "By Source",
    updateResults: "Update Results",
    searchBy: "Search by Name, Check or Amount",
    today: "Today",
    clearFilters: "Clear Filters",
    filtersApplied: (num: number) =>
        `${num} ${num === 1 ? "Filter" : "Filters"} Applied`,
    requestSent: "Request Sent",
    confirmPrinted:
        "Your request to print this order's receipt has been submitted",
    ok: "OK",
    pagerNumberLabel: "pgr. ",
    cancelledStub: " - CANCELLED",
    erroredStub: " - ERROR",
};

export default function ManagerPreviousOrders() {
    const {isTraining} = useContext(CashierModeContext);
    const currentStation = useAppSelector((state) => state.pos.station);
    const [errorMessage, setErrorMessage] = useState("");
    const [orderDetails, setOrderDetails] =
        useState<posModels.OrderParsed | null>(null);
    const [printingId, setPrintingId] = useState<string | null>(null);
    const [confirmPrinted, setConfirmPrinted] = useState(false);
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
    const [activeFilters, setActiveFilters] =
        useState<Filters>(DEFAULT_FILTERS);

    const ordersById = useAppSelector((state) => state.pos.orders.byId);

    const {initialLoading, initialErrorMessage, closeInitialErrorMessage} =
        useRefreshOrders(
            ["paid", "cancelled", "error", "pending"],
            [],
            isTraining,
        );

    function onPrint(orderId: string) {
        if (printingId !== null || currentStation.mode === null) {
            return;
        }

        setPrintingId(orderId);
        printers
            .printReceipt(orderId, currentStation.mode)
            .then(() => {
                setPrintingId(null);
                setConfirmPrinted(true);
            })
            .catch((err) => {
                logger.warn(err);
                const description = describeNetworkError(err);
                setErrorMessage(description.join("\n"));
                setPrintingId(null);
            });
    }

    const numActiveFilters =
        activeFilters.dates.length +
        (activeFilters.stationModes.length
            ? Math.ceil(activeFilters.stationModes.length / 2) - 1
            : 0) +
        activeFilters.sources.length +
        (activeFilters.text ? 1 : 0);

    const nonPendingOrders = useMemo(() => {
        return Object.keys(ordersById)
            .map((id) => ordersById[id])
            .filter((o) => o.status !== "pending");
    }, [ordersById]);

    const sortedOrders = useMemo(() => {
        return sortBy(
            nonPendingOrders,
            (o) => o.orderDate.getDate(),
            (o) => o.orderNumber,
        ).reverse();
    }, [nonPendingOrders]);

    const filteredOrders = useMemo(() => {
        return getFilteredOrders(sortedOrders, activeFilters);
    }, [sortedOrders, activeFilters]);

    const header = (
        <ManagerHeader
            label={strings.checkSearch}
            extraText={
                numActiveFilters !== 0
                    ? strings.filtersApplied(numActiveFilters)
                    : undefined
            }
            buttonLabel={strings.filter}
            buttonDisabled={!sortedOrders.length}
            buttonOnClick={() => setIsFilterDrawerOpen(true)}
        />
    );

    if (initialLoading) {
        return (
            <>
                {header}
                <div className={styles.spinnerContainer}>
                    <Spinner />
                </div>
            </>
        );
    }

    function getGuestOrPagerNumber(
        guestName?: string | null,
        pagerNumber?: number | null,
    ) {
        let content: string | undefined = undefined;
        if (guestName) {
            content = guestName;
        } else if (pagerNumber) {
            content = strings.pagerNumberLabel + pagerNumber;
        }
        return content;
    }

    return (
        <>
            {header}
            <div className={styles.ordersContainer}>
                {filteredOrders.length === 0 ? (
                    <div className={styles.noOrders}>{strings.noOrders}</div>
                ) : null}
                {filteredOrders.map((o) => {
                    const compCardPaidCents = o.transactions.reduce(
                        (sum, t) => {
                            return t.tender === "comp_card"
                                ? sum + t.amountCents
                                : sum;
                        },
                        0,
                    );

                    return (
                        <div key={o.id} className={styles.orderRow}>
                            <div className={cn(styles.rowSection, styles.info)}>
                                <div>
                                    <div className={styles.orderNumber}>
                                        <span>
                                            {strings.orderNumber}
                                            {o.orderNumber}
                                            {o.status === "cancelled"
                                                ? strings.cancelledStub
                                                : ""}
                                            {o.status === "error" ? (
                                                <span
                                                    className={
                                                        styles.erroredStub
                                                    }>
                                                    {strings.erroredStub}
                                                </span>
                                            ) : null}
                                        </span>
                                    </div>
                                    <div className={styles.stationName}>
                                        {orderDisplayUtil.getStationNameText(
                                            o.stationMode,
                                        ) ??
                                            orderDisplayUtil.getOrderSourceText(
                                                o.source,
                                                o.type,
                                            )}
                                    </div>
                                    <div className={styles.stationName}>
                                        {Lib.dates.format(
                                            o.orderDate,
                                            "MM/DD/YY",
                                        )}
                                        {" : "}
                                        {Lib.dates.format(
                                            o.orderDate,
                                            "hh:mm:ssa",
                                        )}
                                    </div>
                                </div>
                                <div className={styles.orderInfoRight}>
                                    <div className={styles.orderTotal}>
                                        {Lib.currency.centsToDollarString(
                                            Math.max(
                                                0,
                                                o.totalCents -
                                                    compCardPaidCents,
                                            ),
                                        )}
                                    </div>
                                    {getGuestOrPagerNumber(
                                        o.guestName,
                                        o.pagerNumber,
                                    ) ? (
                                        <div className={styles.orderName}>
                                            {getGuestOrPagerNumber(
                                                o.guestName,
                                                o.pagerNumber,
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className={styles.rowSection}>
                                <OutlineButton
                                    className={styles.orderButton}
                                    label={strings.viewDetails}
                                    onClick={() => setOrderDetails(o)}
                                />
                            </div>
                            <div className={styles.rowSection}>
                                <OutlineButton
                                    className={styles.orderButton}
                                    label={strings.rePrint}
                                    onClick={() => onPrint(o.id)}
                                    loading={printingId === o.id}
                                    disabled={
                                        printingId !== null &&
                                        printingId !== o.id
                                    }
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
            {orderDetails !== null ? (
                <OrderDetailsDrawer
                    order={orderDetails}
                    onClose={() => {
                        if (printingId !== orderDetails.id) {
                            setOrderDetails(null);
                        }
                    }}
                    showReprintButton={true}
                />
            ) : null}
            {isFilterDrawerOpen ? (
                <OrderFilterDrawer
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    onClose={() => setIsFilterDrawerOpen(false)}
                />
            ) : null}
            {errorMessage || initialErrorMessage ? (
                <ErrorModal
                    errorMessage={errorMessage || initialErrorMessage}
                    onClose={() =>
                        errorMessage
                            ? setErrorMessage("")
                            : closeInitialErrorMessage()
                    }
                    showITInfo
                />
            ) : null}
            {confirmPrinted ? (
                <TextModal
                    onClose={() => setConfirmPrinted(false)}
                    title={strings.requestSent}
                    content={strings.confirmPrinted}
                    cancelLabel={strings.ok}
                />
            ) : null}
        </>
    );
}
