import checkIcon from "../../images/check.svg";
import cn from "classnames";
import React, {InputHTMLAttributes} from "react";
import styles from "./Checkbox.module.css";

type Props = InputHTMLAttributes<HTMLInputElement> & {
    label: string;
};

export default function Checkbox({
    className,
    label,
    checked,
    disabled,
    ...rest
}: Props) {
    return (
        <label
            className={cn(
                styles.container,
                disabled ? styles.disabled : null,
                className,
            )}>
            <input
                {...rest}
                disabled={disabled}
                className={styles.input}
                type="checkbox"
            />
            <div
                className={cn({
                    [styles.check]: true,
                    [styles.filled]: checked,
                })}>
                <img
                    className={cn({
                        [styles.checkIcon]: true,
                        [styles.visible]: checked,
                    })}
                    src={checkIcon}
                    alt=""
                />
            </div>
            <div className={styles.label}>{label}</div>
        </label>
    );
}
