import * as routeHelpers from "../../lib/routeHelpers";
import ManagerLinks from "../ManagerLinks";
import React, {useContext, useEffect} from "react";
import styles from "./ManagerMenu.module.css";
import {CashierModeContext} from "../CashierModeContext";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

export default function ManagerMenu() {
    const location = useLocation();
    const navigate = useNavigate();
    const {cashierMode} = useContext(CashierModeContext);

    useEffect(() => {
        if (
            cashierMode.type === "user" &&
            location.pathname !== routeHelpers.managerMenu()
        ) {
            navigate(routeHelpers.managerMenu(), {replace: true});
        }
    }, [cashierMode.type]);

    return (
        <>
            <ManagerLinks className={styles.links} />
            <div className={styles.right}>
                <Outlet />
            </div>
        </>
    );
}
