import cn from "classnames";
import CurrencyInput from "../CurrencyInput";
import OutlineButton from "../OutlineButton";
import React from "react";
import styles from "./AddCashAmount.module.css";
import TextButton from "../TextButton";
import {API, Lib} from "habit-core";

const strings = {
    nearest: "Nearest",
    exactCash: "Exact Cash",
    enterAmount: "Enter Amount",
    reset: "Reset",
    apply: "Apply",
};

type Props = {
    className?: string;
    label: string;
    optionsCents: API.models.USDCents[];
    exactCashAmountCents?: API.models.USDCents;
    onApply?: () => void;
    cashAmountCents: API.models.USDCents | null;
    displayedCashAmount: string;
    setCashAmountCents: React.Dispatch<
        React.SetStateAction<API.models.USDCents | null>
    >;
    onFocus?: () => void;
    resetAmountOnSelectOption?: boolean;
};

const AddCashAmount = React.forwardRef<HTMLInputElement, Props>(
    (props, ref) => {
        let nearestCents = props.exactCashAmountCents ?? 0;
        if (
            props.exactCashAmountCents &&
            props.exactCashAmountCents % 100 > 0
        ) {
            nearestCents =
                props.exactCashAmountCents +
                (100 - (props.exactCashAmountCents % 100));
        }

        return (
            <div className={props.className}>
                <div className={styles.label}>{props.label}</div>
                <div className={cn(styles.buttonRow, styles.flexWrap)}>
                    {props.optionsCents.map((cents) => (
                        <OutlineButton
                            key={cents}
                            className={cn(styles.button, styles.buttonCentered)}
                            mode="blue"
                            onClick={() =>
                                props.setCashAmountCents(
                                    (val) =>
                                        (!val || props.resetAmountOnSelectOption
                                            ? 0
                                            : val) + cents,
                                )
                            }
                            label={
                                Lib.currency
                                    .centsToDollarString(cents)
                                    .split(".")[0]
                            }
                        />
                    ))}
                </div>

                {props.exactCashAmountCents ? (
                    <div className={styles.buttonRow}>
                        <OutlineButton
                            className={styles.nearestButton}
                            label={`${strings.nearest} ${
                                Lib.currency
                                    .centsToDollarString(nearestCents)
                                    .split(".")[0]
                            }`}
                            mode="blue"
                            onClick={() => {
                                if (props.exactCashAmountCents) {
                                    props.setCashAmountCents(
                                        Math.ceil(nearestCents),
                                    );
                                }
                            }}
                        />
                        <OutlineButton
                            className={styles.exactCashButton}
                            label={strings.exactCash}
                            mode="blue"
                            onClick={() => {
                                if (props.exactCashAmountCents) {
                                    props.setCashAmountCents(
                                        props.exactCashAmountCents,
                                    );
                                }
                            }}
                        />
                    </div>
                ) : null}

                <div className={styles.buttonRow}>
                    <CurrencyInput
                        className={cn(
                            props.onApply
                                ? styles.textInputWithApply
                                : styles.textInputWithoutApply,
                        )}
                        placeholder={strings.enterAmount}
                        value={props.displayedCashAmount}
                        onChange={(num) => props.setCashAmountCents(num)}
                        onFocus={props.onFocus}
                        ref={ref}
                    />

                    <TextButton
                        className={styles.resetButton}
                        label={strings.reset}
                        onClick={() => props.setCashAmountCents(null)}
                    />
                </div>
                {props.onApply &&
                props.cashAmountCents !== null &&
                props.cashAmountCents !== 0 ? (
                    <div className={styles.applyButtonContainer}>
                        <OutlineButton
                            label={
                                strings.apply + ` $${props.displayedCashAmount}`
                            }
                            mode="white"
                            onClick={props.onApply}
                            className={styles.applyButton}
                            labelClassName={styles.applyButtonLabel}
                        />
                    </div>
                ) : null}
            </div>
        );
    },
);

AddCashAmount.displayName = "AddCashAmount";
export default AddCashAmount;
