import * as posModels from "./posModels";
import instance from "./instance";
import {API} from "habit-core";
import {creditCardScanner as urls} from "./urls";

export type DeviceResponse = {
    isSuccess: boolean;
    result: string;
    resultCode: string;
    responseText: string;
    counter: string;
};

export function registerDevice(
    stationMode: posModels.StationMode,
    deviceCode: string,
): Promise<boolean> {
    return instance
        .request({
            url: urls.register,
            method: "GET",
            params: {
                deviceName: stationMode,
                deviceCode,
            },
        })
        .then((response) => response.data)
        .then((data: DeviceResponse) => {
            return data.isSuccess;
        });
}

export function testConnection(
    stationMode: posModels.StationMode,
): Promise<boolean> {
    return instance
        .request({
            url: urls.testConnection,
            method: "GET",
            params: {
                deviceName: stationMode,
            },
        })
        .then((response) => response.data)
        .then((data: DeviceResponse) => {
            return data.isSuccess;
        });
}

export function processPayment(
    stationMode: posModels.StationMode,
    refNum: number,
    orderNum: number,
    checkId: string,
    txnAmtCents: API.models.USDCents,
    taxAmtCents: API.models.USDCents,
    hideTipsPrompt: boolean,
): Promise<posModels.ProcessPaymentResponse> {
    return instance
        .request({
            url: urls.processPayment,
            method: "POST",
            data: {
                MachineName: stationMode, // card readers will be configured to correspond to station modes
                RefNum: refNum,
                OrderNum: orderNum,
                CheckId: checkId,
                TxnAmt: txnAmtCents,
                TaxAmt: taxAmtCents,
                Source: "pos",
                HideTipsPrompt: hideTipsPrompt ? 1 : 0,
            },
        })
        .then((response) => response.data);
}
