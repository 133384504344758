import cn from "classnames";
import React, {useEffect, useState} from "react";
import OutlineButton from "../OutlineButton";
import styles from "./ToggleButton.module.css";

type Props = {
    className?: string;
    checkedClassName?: string;
    checked?: boolean;
    label: string;
    label2?: string;
    leftContent?: React.ReactNode;
    onChange?: (checked: boolean) => void;
    disabled?: boolean;
};

export default function ToggleButton(props: Props) {
    const [checked, setChecked] = useState(props.checked || false);
    useEffect(() => {
        if (props.checked !== undefined && props.checked !== checked) {
            setChecked(props.checked);
        }
    }, [props.checked]);

    const onClick = () => {
        const next = !checked;
        if (props.onChange) {
            props.onChange(next);
        } else {
            setChecked((prevChecked) => !prevChecked);
        }
    };

    return (
        <OutlineButton
            className={cn(
                styles.toggleButton,
                props.leftContent ? styles.toggleWithLeftContent : null,
                checked ? styles.checked : null,
                props.disabled ? styles.disabled : null,
                props.className,
                checked ? props.checkedClassName : null,
            )}
            mode={checked ? "solid_blue" : "blue"}
            onClick={onClick}
            disabled={props.disabled}>
            {props.leftContent}
            <div>
                <div
                    className={cn({
                        [styles.line1]: true,
                        [styles.line1Checked]: checked,
                    })}>
                    {props.label}
                </div>
                {props.label2 ? (
                    <div
                        className={cn({
                            [styles.line2]: true,
                            [styles.line2Checked]: checked,
                        })}>
                        {props.label2}
                    </div>
                ) : null}
            </div>
        </OutlineButton>
    );
}
