import logger from "../lib/logger";
import {Preferences} from "@capacitor/preferences";

const DEVICE_ID_KEY = "deviceId";

export async function getStoredDeviceId() {
    try {
        const {value} = await Preferences.get({key: DEVICE_ID_KEY});
        return value;
    } catch (err) {
        logger.warn(err);
        return null; // this shouldn't happen
    }
}

export async function setStoredDeviceId(deviceId: string) {
    await Preferences.set({key: DEVICE_ID_KEY, value: deviceId});
}
