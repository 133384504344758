import axios from "axios";

const instance = axios.create({
    baseURL: import.meta.env.REACT_APP_API_ROOT,
    headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-POS-Version": import.meta.env.DEV
            ? "dev"
            : import.meta.env.REACT_APP_GIT_REVISION,
    },
});

if (import.meta.env.DEV) {
    import("axios-logger").then((module) => {
        const axiosLogger = module.createInterceptor();
        instance.interceptors.request.use(axiosLogger.request);
        instance.interceptors.response.use(
            axiosLogger.response,
            axiosLogger.failure,
        );
    });
}

export default instance;
