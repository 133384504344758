import * as posModels from "./posModels";
import instance from "./instance";
import {tills as urls} from "./urls";

export function logTillAssignment(
    requestBody: posModels.TillAssignmentRequest,
): Promise<void> {
    return instance
        .request({
            url: urls.logAssignment,
            method: "POST",
            data: requestBody,
        })
        .then((response) => response.data);
}

export function getCurrentAssignments(): Promise<posModels.TillAssignments> {
    return instance
        .request({
            url: urls.getAssignments,
            method: "GET",
        })
        .then((response) => response.data);
}
