import cn from "classnames";
import React from "react";
import styles from "./TextInputWithResetButton.module.css";
import TextButton from "../TextButton";
import TextInput from "../TextInput";

const strings = {
    reset: "Reset",
};

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    className?: string;
    inputClassName?: string;
    placeholder: string;
    value: string;
    setValue: (value: string) => void;
    disabled?: boolean;
    resetLabel?: string;
};

function TextInputWithResetButton(
    {
        className,
        inputClassName,
        value,
        setValue,
        placeholder,
        disabled,
        resetLabel,
        ...rest
    }: Props,
    ref: React.ForwardedRef<HTMLInputElement>,
) {
    return (
        <div className={cn(styles.inputContainer, className)}>
            <TextInput
                {...rest}
                className={cn(styles.input, inputClassName)}
                placeholder={placeholder}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={disabled}
                ref={ref}
                inputMode="none"
            />
            <TextButton
                label={resetLabel ?? strings.reset}
                onClick={() => setValue("")}
                disabled={!value || disabled}
            />
        </div>
    );
}

export default React.forwardRef<HTMLInputElement, Props>(
    TextInputWithResetButton,
);
