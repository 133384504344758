import React from "react";
import ToggleButton from "../ToggleButton";
import {Lib} from "habit-core";

const strings = {
    defaultSub: "(Default)",
};

type Props = {
    className?: string;
    item: Lib.selectors.SubstitutionItem;
    checked: boolean;
    onChange: (value: boolean) => void;
};

export default function SubstitutionToggle(props: Props) {
    function getLabel2() {
        if (props.checked && props.item.isDefault) {
            return strings.defaultSub;
        }
        if (props.item.priceAdjustmentCents === 0) {
            return props.item.isDefault ? strings.defaultSub : "";
        }
        return `${
            props.item.priceAdjustmentCents > 0 ? "+" : "-"
        }${Lib.currency.centsToDollarString(props.item.priceAdjustmentCents)}`;
    }

    return (
        <ToggleButton
            className={props.className}
            key={props.item.id}
            label={props.item.name}
            label2={getLabel2()}
            checked={props.checked}
            onChange={() => props.onChange(!props.checked)}
        />
    );
}
