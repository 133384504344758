import React from "react";
import registerIcon from "../../images/register.svg";
import styles from "./RegisterDrawerIsOpen.module.css";

const strings = {
    open1: "Register Drawer Is Open.",
    open2: "Close Drawer To Proceed.",
};

export default function RegisterDrawerIsOpen() {
    return (
        <div className={styles.openContainer}>
            <img className={styles.icon} src={registerIcon} alt="" />
            <div className={styles.openText}>{strings.open1}</div>
            <div className={styles.openText}>{strings.open2}</div>
        </div>
    );
}
