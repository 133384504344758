import cn from "classnames";
import Keyboard, {KeyboardReactInterface} from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import React, {
    MutableRefObject,
    RefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import styles from "./StyledKeyboard.module.css";

const CLOSE_BUTTON_LAYOUT = ["{close}"];
const CLOSE_BUTTON_THEME = {class: "hg-button-close", buttons: "close"};

const DEFAULT_LAYOUT = [
    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
    "{tab} q w e r t y u i o p [ ] \\",
    "{lock} a s d f g h j k l ; ' {enter}",
    "{shift} z x c v b n m , . / {shift}",
    ".com @ {space}",
];

const SHIFT_LAYOUT = [
    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
    "{tab} Q W E R T Y U I O P { } |",
    '{lock} A S D F G H J K L : " {enter}',
    "{shift} Z X C V B N M < > ? {shift}",
    ".com @ {space}",
];

const NUMERIC_LAYOUT = ["1 2 3 {bksp}", "4 5 6 {enter}", "7 8 9 ", " 0  "];

const NUMERIC_WITH_DECIMAL_LAYOUT = [
    "1 2 3 {bksp}",
    "4 5 6 {enter}",
    "7 8 9 ",
    " 0 . ",
];

const NUMERIC_NO_ENTER_LAYOUT = ["1 2 3 {bksp}", "4 5 6 ", "7 8 9 ", " 0  "];

type Props = {
    layout?:
        | "numeric"
        | "default"
        | "shift"
        | "numeric-decimal"
        | "numeric-no-enter";
    currentInput: string;
    onChange: (input: string) => void;
    onPressEnter?: () => void;
    inputRefs: RefObject<HTMLInputElement>[];
    ignoreKeyboardDismissRefs?: RefObject<HTMLDivElement>[]; // non input refs that when clicked on, shouldn't dismiss the keyboard
    supportsMultiple?: boolean;
    maxLength?: number;
    className?: string;
} & (
    | {
          alwaysVisible: true;
          visible?: never;
          setVisible?: never;
          showCloseButton?: never;
      }
    | {
          alwaysVisible?: never;
          visible: boolean;
          setVisible: (newVal: boolean) => void;
          showCloseButton?: boolean;
      }
);

export default function StyledKeyboard(props: Props) {
    const [layout, setLayout] = useState(props.layout ?? "default");
    const keyboardContainer = useRef<HTMLDivElement>(null);
    const keyboard: MutableRefObject<null | KeyboardReactInterface> =
        useRef(null);

    const handleClickOutsideInput = useCallback(
        (event: Event) => {
            if (
                !keyboardContainer.current ||
                (!props.inputRefs.length &&
                    !props.ignoreKeyboardDismissRefs?.length)
            ) {
                return;
            }
            const path = event.composedPath && event.composedPath();

            let clickedOnInput = false;
            props.inputRefs.forEach((inputRef) => {
                clickedOnInput =
                    clickedOnInput ||
                    (!!inputRef.current && path.includes(inputRef.current));
            });

            let clickedOnIgnoreDismissalDiv = false;
            props.ignoreKeyboardDismissRefs?.forEach((ref) => {
                clickedOnIgnoreDismissalDiv =
                    clickedOnIgnoreDismissalDiv ||
                    (!!ref.current && path.includes(ref.current));
            });

            if (
                !path.includes(keyboardContainer.current) &&
                !clickedOnInput &&
                !clickedOnIgnoreDismissalDiv
            ) {
                keyboardContainer.current.className = styles.keyboardCollapsed;
                setTimeout(() => props.setVisible?.(false), 270);
            }
        },
        [props.inputRefs],
    );

    useEffect(() => {
        if (props.supportsMultiple) {
            if (props.currentInput !== keyboard.current?.getInput()) {
                keyboard.current?.setInput(props.currentInput);
            }
        }
    }, [props.currentInput, props.supportsMultiple]);

    useEffect(() => {
        if (props.layout) {
            setLayout(props.layout);
        }
    }, [props.layout]);

    useEffect(() => {
        if (!props.alwaysVisible && props.visible) {
            document.addEventListener("click", handleClickOutsideInput);
        }
        return () => {
            document.removeEventListener("click", handleClickOutsideInput);
        };
    }, [handleClickOutsideInput, props.visible, props.alwaysVisible]);

    const onKeyPress = (button: string) => {
        if (button === "{shift}" || button === "{lock}") {
            handleShift();
        }

        if (button === "{enter}") {
            props.onPressEnter?.();
        }

        if (button === "{close}" && keyboardContainer.current) {
            keyboardContainer.current.className = styles.keyboardCollapsed;
            setTimeout(() => props.setVisible?.(false), 270);
        }
    };

    const handleShift = () => {
        setLayout((prevLayout) =>
            prevLayout === "default" ? "shift" : "default",
        );
    };

    if (!props.alwaysVisible && !props.visible) {
        return null;
    }

    return (
        <div
            ref={keyboardContainer}
            className={cn(styles.keyboard, props.className)}>
            <Keyboard
                keyboardRef={(r: KeyboardReactInterface) => {
                    r.setInput(props.currentInput);
                    keyboard.current = r;
                }}
                layoutName={layout}
                layout={{
                    default: props.showCloseButton
                        ? [...CLOSE_BUTTON_LAYOUT, ...DEFAULT_LAYOUT]
                        : DEFAULT_LAYOUT,
                    shift: props.showCloseButton
                        ? [...CLOSE_BUTTON_LAYOUT, ...SHIFT_LAYOUT]
                        : SHIFT_LAYOUT,
                    numeric: props.showCloseButton
                        ? [...CLOSE_BUTTON_LAYOUT, ...NUMERIC_LAYOUT]
                        : NUMERIC_LAYOUT,
                    "numeric-decimal": props.showCloseButton
                        ? [
                              ...CLOSE_BUTTON_LAYOUT,
                              ...NUMERIC_WITH_DECIMAL_LAYOUT,
                          ]
                        : NUMERIC_WITH_DECIMAL_LAYOUT,
                    "numeric-no-enter": props.showCloseButton
                        ? [...CLOSE_BUTTON_LAYOUT, ...NUMERIC_NO_ENTER_LAYOUT]
                        : NUMERIC_NO_ENTER_LAYOUT,
                }}
                buttonTheme={
                    layout === "numeric" ||
                    layout === "numeric-decimal" ||
                    layout === "numeric-no-enter"
                        ? [
                              CLOSE_BUTTON_THEME,
                              {
                                  class: "hg-numeric",
                                  buttons:
                                      "1 2 3 4 5 6 7 8 9 0 . {enter} {bksp} ", // extra space at the end to also apply this style for the empty button
                              },
                          ]
                        : [CLOSE_BUTTON_THEME]
                }
                display={{
                    "{bksp}": "\u232b",
                    "{enter}": "\u23ce",
                    "@": "\u0040",
                    "{tab}": "\u21e5",
                    "{lock}": "\u21ea",
                    "{space}": "\u2423",
                    "{shift}": "shift",
                    "{close}": "Close",
                }}
                onChange={props.onChange}
                onKeyPress={onKeyPress}
                maxLength={props.maxLength}
            />
        </div>
    );
}
