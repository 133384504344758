import cn from "classnames";
import React from "react";
import styles from "./Spinner.module.css";

type Props = {
    isSmall?: boolean;
};

export default function Spinner(props: Props) {
    return (
        <div
            className={cn(styles.spinner, props.isSmall ? styles.small : null)}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}
