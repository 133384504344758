import * as routeHelpers from "../../lib/routeHelpers";
import MenuProductLink from "../MenuProductLink";
import React from "react";
import styles from "./ManagerReports.module.css";
import {Outlet} from "react-router-dom";

const strings = {
    timeReport: "Time Period Report",
    balanceReport: "Balance Report",
    salesReport: "Sales Detail Report",
};

const LINKS = [
    {to: routeHelpers.timeReport(), label: strings.timeReport},
    {to: routeHelpers.balanceReport(), label: strings.balanceReport},
    {to: routeHelpers.salesReport(), label: strings.salesReport},
];

export default function ManagerReports() {
    return (
        <div className={styles.container}>
            {LINKS.map((l) => (
                <MenuProductLink
                    key={l.label}
                    url={l.to}
                    name={l.label}
                    hideImage={true}
                />
            ))}
            <Outlet />
            <div className={styles.spacer} />
        </div>
    );
}
