import cn from "classnames";
import Modal from "../Modal";
import OutlineButton from "../OutlineButton";
import React from "react";
import styles from "./ErrorModal.module.css";
import {useAppSelector} from "../../lib/hooks";

const strings = {
    title: "An Error Has Occurred",
    close: "Return To Last Screen",
    additionalInfo: "Additional Info:",
    storeNumber: (storeNumber: number) => `Store Number: ${storeNumber}`,
    version: `Version: ${import.meta.env.REACT_APP_GIT_REVISION}`,
    date: (date: Date) => `Date: ${date.toLocaleString()}`,
};

type Props = {
    onClose: () => void;
    errorMessage: string;
    closeButtonLabel?: string;
    title?: string;
    backgroundColor?: "red" | "grey";
    showITInfo?: boolean;
};

export default function ErrorModal({
    onClose,
    errorMessage,
    closeButtonLabel = strings.close,
    title = strings.title,
    backgroundColor = "red",
    showITInfo = false,
}: Props) {
    const storeNumber = useAppSelector(
        (state) =>
            state.stores.byId[state.currentOrder.storeId ?? ""]?.storeNumber,
    );
    const currentDate = new Date();

    const getITInfo = () => {
        return (
            <div className={styles.additionalInfoContainer}>
                <span>{strings.additionalInfo}</span>
                <ul>
                    {storeNumber !== undefined ? (
                        <li>{strings.storeNumber(storeNumber)}</li>
                    ) : null}
                    <li>{strings.version}</li>
                    <li>{strings.date(currentDate)}</li>
                </ul>
            </div>
        );
    };

    return (
        <Modal
            onClose={onClose}
            className={styles.modal}
            overlayClassName={cn(
                backgroundColor === "red" && styles.overlayRed,
            )}>
            <div className={styles.container}>
                <div className={styles.title}>{title}</div>
                <div className={styles.content}>{errorMessage}</div>
                {showITInfo ? getITInfo() : null}
                <OutlineButton label={closeButtonLabel} onClick={onClose} />
            </div>
        </Modal>
    );
}
