import * as posModels from "../../lib/api/posModels";
import cn from "classnames";
import OutlineButton from "../OutlineButton";
import React from "react";
import styles from "./OrderRowItem.module.css";
import TextButton from "../TextButton";
import TimeSince from "../TimeSince";
import {Lib} from "habit-core";

const strings = {
    orderNum: "Order #",
    open: "Open",
    cancel: "Cancel",
    view: "View",
};

type Props = {
    order: posModels.OrderParsed;
    onOpen?: () => void;
    onClickDetails: () => void;
    onCancel?: () => void;
};

export default function OrderRowItem({
    order,
    onOpen,
    onClickDetails,
    onCancel,
}: Props) {
    const isPending = order.status === "pending";

    return (
        <div className={styles.orderRow}>
            <div className={styles.orderRowSection}>
                <div>
                    {isPending ? (
                        <TextButton
                            className={cn(
                                styles.orderNum,
                                styles.orderNumButton,
                            )}
                            onClick={onClickDetails}
                            label={`${strings.orderNum}${order.orderNumber}`}
                        />
                    ) : (
                        <div
                            className={cn(
                                styles.orderNum,
                                styles.closedOrderNum,
                            )}>
                            {strings.orderNum}
                            {order.orderNumber}
                        </div>
                    )}
                    {order.driveThruDetails ? (
                        <div className={styles.carDetails}>
                            {order.driveThruDetails.vehicleColor}{" "}
                            {order.driveThruDetails.vehicleType}
                        </div>
                    ) : null}
                </div>
                <div>
                    <div className={styles.total}>
                        {Lib.currency.centsToDollarString(order.totalCents)}
                    </div>
                    <TimeSince
                        className={styles.time}
                        startTime={order.startTime}
                        endTime={isPending ? undefined : order.orderDate}
                    />
                </div>
            </div>
            {isPending ? (
                <div className={styles.orderRowSection}>
                    <OutlineButton
                        className={styles.pendingButton}
                        label={strings.open}
                        onClick={onOpen}
                    />
                    <OutlineButton
                        className={styles.pendingButton}
                        mode="red"
                        label={strings.cancel}
                        onClick={onCancel}
                    />
                </div>
            ) : (
                <OutlineButton
                    className={styles.viewButton}
                    label={strings.view}
                    onClick={onClickDetails}
                />
            )}
        </div>
    );
}
