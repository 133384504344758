import {API} from "habit-core";
import {useAppSelector} from "../lib/hooks";
import * as routeHelpers from "../lib/routeHelpers";
import MenuProductLink from "./MenuProductLink";
import React from "react";

type Props = {
    className?: string;
    categoryId: string;
    groupingId: string;
    menuId: string;
    numColumns: number;
    displaySize: "small" | "regular";
};

// NOTE: in practice, the menu doesn't seem to use groupings for combos. this will reroute to the combo customization if options happen to be combos.
export default function MenuGroupingLink({
    displaySize = "regular",
    ...props
}: Props) {
    const grouping = useAppSelector(
        (state) => state.groupings.byId[props.groupingId],
    );
    const combosById = useAppSelector((state) => state.combos.byId);
    const itemsById = useAppSelector((state) => state.items.byId);

    const imagesOption = grouping.options.find((o) => o.useImages);
    let images: API.models.Image[] = [];
    let url: string | undefined = undefined;
    if (imagesOption) {
        if (imagesOption.comboId) {
            const combo = combosById[imagesOption.comboId];
            images = combo.isolatedImages328;
            url = routeHelpers.combo(props.categoryId, imagesOption.comboId);
        } else if (imagesOption.itemId) {
            const item = itemsById[imagesOption.itemId];
            images = item.isolatedImages328;
            url = routeHelpers.grouping(props.categoryId, grouping.id);
        }
    }

    return (
        <MenuProductLink
            className={props.className}
            url={url ?? ""}
            name={grouping.name}
            status={"available"}
            numColumns={props.numColumns}
            retinaImages={images}
            displaySize={displaySize}
            disabled={!url}
        />
    );
}
