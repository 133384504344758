import * as posModels from "../../lib/api/posModels";
import * as printers from "../../lib/api/printers";
import * as routeHelpers from "../../lib/routeHelpers";
import cn from "classnames";
import describeNetworkError from "../../lib/describeNetworkError";
import Drawer from "../Drawer";
import ErrorModal from "../ErrorModal";
import logger from "../../lib/logger";
import ManagerHeader from "../ManagerHeader";
import OutlineButton from "../OutlineButton";
import PreferenceButtons from "./PreferenceButtons";
import React, {useState, useEffect, useContext, useRef} from "react";
import Spinner from "../Spinner";
import styles from "./ManagerPrinters.module.css";
import TextButton from "../TextButton";
import TextInput from "../TextInput";
import {CashierModeContext} from "../CashierModeContext";
import StyledKeyboard from "../StyledKeyboard";
import {useNavigate} from "react-router-dom";

const strings = {
    printerList: "Printer List",
    configure: "Configure",
    assign: "Assign Printer",
    markOffline: "Mark Offline",
    markOnline: "Mark Online",
    offline: "Offline",
    notAssigned: "No Printer Assigned",
    allOffline: "Printer and Backups are Offline",
    noBackups: "No Backups Configured",
    printerConfig: "Printer Configuration",
    assignBackups: "Assign Backups",
    printerName: "Printer Name",
    deletePrinter: "Delete Printer",
    printerBackup: "Printer Backup (Select in order of preference)",
    stationName: "Station Name",
    assignInstructions:
        "When you first plug in a printer and connect it to the internet, it should automatically print out a sheet of paper. That will include the IP address you need to enter here.",
    printerIpAddress: "Printer IP Address",
    enterHere: "Enter Here",
    submit: "Submit",
};

export default function ManagerPrinters() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [updatingStatusMode, setUpdatingStatusMode] =
        useState<posModels.StationMode | null>(null);
    const [stations, setStations] = useState<posModels.Station[]>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [configureStation, setConfigureStation] =
        useState<posModels.Station | null>(null);
    const [assignPrinterStation, setAssignPrinterStation] =
        useState<posModels.Station | null>(null);
    const [printerIpAddress, setPrinterIpAddress] = useState("");
    const [assigning, setAssigning] = useState(false);
    const [backupPreferences, setBackupPreferences] = useState<string[]>([]);

    const {cashierMode, setCashierMode} = useContext(CashierModeContext);

    /* keyboard */
    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current && !keyboardCollapsed) {
            if (document.activeElement === inputRef.current) {
                inputRef?.current?.scrollIntoView();
            }
        }
    }, [keyboardCollapsed]);

    useEffect(() => {
        setLoading(true);
        printers
            .get()
            .then((data) => {
                setStations(data.stations);
                setLoading(false);
            })
            .catch((err) => {
                logger.warn(err);
                const description = describeNetworkError(err);
                setErrorMessage(description.join("\n"));
                setLoading(false);
            });
    }, []);

    function updatePrinterStatus(
        s: posModels.Station,
        newStatus: posModels.PrinterStatus,
    ) {
        if (cashierMode.type !== "manager") {
            logger.warn(
                "attempting to update printer status when not in manager mode",
            );
            return;
        }

        setUpdatingStatusMode(s.mode);
        printers
            .setStatus(cashierMode.managerId, s.mode, newStatus)
            .then(() => {
                setStations((prev) =>
                    prev.map((station) =>
                        station.mode === s.mode
                            ? {...station, printerStatus: newStatus}
                            : station,
                    ),
                );
                setConfigureStation(null);
                setUpdatingStatusMode(null);
                navigate(routeHelpers.menu());
                setCashierMode({type: "user"});
            })
            .catch((err) => {
                logger.warn(err);
                const description = describeNetworkError(err);
                setErrorMessage(description.join("\n"));
                setUpdatingStatusMode(null);
            });
    }

    function onAssignPrinter(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (assigning || !assignPrinterStation) {
            return;
        }

        setAssigning(true);
        printers
            .assign(printerIpAddress, assignPrinterStation.mode)
            .then(() => {
                setStations((prev) =>
                    prev.map((s) =>
                        s.mode === assignPrinterStation.mode
                            ? {...s, printerStatus: "online"}
                            : s,
                    ),
                );
                setAssigning(false);
                setAssignPrinterStation(null);
                setPrinterIpAddress("");
                navigate(routeHelpers.menu());
                setCashierMode({type: "user"});
            })
            .catch((err) => {
                logger.warn(err);
                const description = describeNetworkError(err);
                setErrorMessage(description.join("\n"));
                setAssigning(false);
            });
    }

    function onAssignBackups() {
        if (cashierMode.type !== "manager") {
            logger.warn(
                "attempting to update printer config when not in manager mode",
            );
            return;
        }

        if (assigning || !configureStation) {
            return;
        }

        setAssigning(true);
        printers
            .config(
                cashierMode.managerId,
                configureStation.mode,
                backupPreferences,
            )
            .then(() => {
                setStations((prev) =>
                    prev.map((s) =>
                        s.mode === configureStation.mode
                            ? {...s, backups: backupPreferences}
                            : s,
                    ),
                );
                setBackupPreferences([]);
                setConfigureStation(null);
                setAssigning(false);
                navigate(routeHelpers.menu());
                setCashierMode({type: "user"});
            })
            .catch((err) => {
                logger.warn(err);
                const description = describeNetworkError(err);
                setErrorMessage(description.join("\n"));
                setAssigning(false);
            });
    }

    function renderStatus(s: posModels.Station) {
        if (s.printerStatus === "none") {
            // no printer assigned
            return (
                <div className={styles.redStatus}>{strings.notAssigned}</div>
            );
        }

        const noBackupsText = !s.backups.length ? (
            <div className={styles.redStatus}>{strings.noBackups}</div>
        ) : null;

        if (s.printerStatus === "offline") {
            const hasOnlineBackups = s.backups.filter(
                (id) =>
                    stations.find((s) => s.mode === id)?.printerStatus ===
                    "online",
            ).length;

            if (!hasOnlineBackups && s.backups.length) {
                // offline printer with offline backups
                return (
                    <div className={styles.redStatus}>{strings.allOffline}</div>
                );
            }

            // offline printer w online or no backups
            return (
                <>
                    <div className={styles.offline}>{strings.offline}</div>
                    {noBackupsText}
                </>
            );
        }

        return noBackupsText;
    }

    const header = <ManagerHeader label={strings.printerList} />;

    if (loading) {
        return (
            <>
                {header}
                <div className={styles.loading}>
                    <Spinner />
                </div>
            </>
        );
    }

    function renderConfigDrawer() {
        if (configureStation === null) {
            return null;
        }

        const backupOptions = stations
            .filter((s) => s.mode !== configureStation.mode)
            .map((s) => ({id: s.mode, label: s.name}));

        return (
            <Drawer
                onClose={() => {
                    if (!updatingStatusMode && !assigning) {
                        setConfigureStation(null);
                        setBackupPreferences([]);
                    }
                }}
                isSmall={true}>
                <div className={styles.drawerContainer}>
                    <div className={styles.configHeader}>
                        <div
                            className={cn(
                                styles.drawerText,
                                styles.drawerHeaderLabel,
                            )}>
                            {strings.printerConfig}
                        </div>
                        <div className={styles.drawerText}>
                            {strings.printerName}
                        </div>
                        <div className={styles.configPrinterName}>
                            <div>{configureStation.name}</div>
                            <TextButton
                                className={styles.deletePrinterButton}
                                label={strings.deletePrinter}
                                onClick={() =>
                                    updatePrinterStatus(
                                        configureStation,
                                        "none",
                                    )
                                }
                                disabled={
                                    updatingStatusMode !== null || assigning
                                }
                            />
                        </div>
                    </div>
                    <div className={styles.configContent}>
                        <div className={styles.drawerText}>
                            {strings.printerBackup}
                        </div>
                        <PreferenceButtons
                            className={styles.preferenceButtons}
                            options={backupOptions}
                            order={backupPreferences}
                            setOrder={setBackupPreferences}
                        />
                    </div>
                    <div className={styles.configFooter}>
                        <OutlineButton
                            className={styles.configFooterButton}
                            label={strings.assignBackups}
                            onClick={onAssignBackups}
                            disabled={updatingStatusMode !== null}
                            loading={assigning}
                        />
                    </div>
                </div>
            </Drawer>
        );
    }

    return (
        <>
            {header}
            <div className={styles.container}>
                {stations.map((s) => (
                    <div className={styles.row} key={s.mode}>
                        <div
                            className={cn({
                                [styles.name]: true,
                                [styles.offlineName]:
                                    s.printerStatus === "offline",
                            })}>
                            <div>{s.name}</div>
                            {renderStatus(s)}
                        </div>
                        <div className={styles.rowButtons}>
                            <div className={styles.topRowButtons}>
                                <OutlineButton
                                    className={styles.topRowButton}
                                    label={
                                        s.printerStatus === "none"
                                            ? strings.assign
                                            : strings.configure
                                    }
                                    onClick={() => {
                                        if (s.printerStatus === "none") {
                                            setAssignPrinterStation(s);
                                        } else {
                                            setConfigureStation(s);
                                            setBackupPreferences(s.backups);
                                        }
                                    }}
                                    disabled={updatingStatusMode !== null}
                                />
                                <OutlineButton
                                    className={styles.topRowButton}
                                    mode={
                                        s.printerStatus === "online" ||
                                        s.printerStatus === "none"
                                            ? "red"
                                            : "white"
                                    }
                                    label={
                                        s.printerStatus === "online" ||
                                        s.printerStatus === "none"
                                            ? strings.markOffline
                                            : strings.markOnline
                                    }
                                    onClick={() =>
                                        updatePrinterStatus(
                                            s,
                                            s.printerStatus === "online"
                                                ? "offline"
                                                : "online",
                                        )
                                    }
                                    loading={updatingStatusMode === s.mode}
                                    disabled={
                                        (updatingStatusMode !== null &&
                                            updatingStatusMode !== s.mode) ||
                                        s.printerStatus === "none"
                                    }
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {renderConfigDrawer()}
            {assignPrinterStation !== null ? (
                <Drawer
                    onClose={() => {
                        if (!assigning) {
                            setAssignPrinterStation(null);
                            setPrinterIpAddress("");
                        }
                    }}
                    isSmall={true}>
                    <div className={styles.drawerContainer}>
                        <div className={styles.assignHeader}>
                            <div
                                className={cn(
                                    styles.drawerText,
                                    styles.drawerHeaderLabel,
                                )}>
                                {strings.assign}
                            </div>
                            <div className={styles.drawerText}>
                                {strings.stationName}
                            </div>
                            <div
                                className={cn(
                                    styles.assignText,
                                    styles.assignName,
                                )}>
                                {assignPrinterStation.name}
                            </div>
                        </div>
                        <form
                            className={styles.assignContent}
                            onSubmit={onAssignPrinter}>
                            <div className={styles.assignText}>
                                {strings.assignInstructions}
                            </div>
                            <div className={styles.assignInputLabel}>
                                {strings.printerIpAddress}
                            </div>
                            <TextInput
                                className={styles.assignInput}
                                value={printerIpAddress}
                                onChange={(e) =>
                                    setPrinterIpAddress(e.target.value)
                                }
                                onFocus={() => setKeyboardCollapsed(false)}
                                placeholder={strings.enterHere}
                                ref={inputRef}
                                inputMode="none"
                            />
                            <OutlineButton
                                label={strings.submit}
                                disabled={!printerIpAddress}
                                type="submit"
                                loading={assigning}
                            />
                        </form>
                        <div
                            className={cn(
                                styles.keyboardContainer,
                                keyboardCollapsed &&
                                    styles.keyboardContainerCollapsed,
                            )}>
                            <StyledKeyboard
                                currentInput={printerIpAddress}
                                visible={!keyboardCollapsed}
                                setVisible={(val: boolean) =>
                                    setKeyboardCollapsed(!val)
                                }
                                onChange={(input) => setPrinterIpAddress(input)}
                                onPressEnter={() => setKeyboardCollapsed(true)}
                                inputRefs={[inputRef]}
                                layout="numeric-decimal"
                            />
                        </div>
                    </div>
                </Drawer>
            ) : null}
            {errorMessage ? (
                <ErrorModal
                    errorMessage={errorMessage}
                    onClose={() => setErrorMessage("")}
                    showITInfo
                />
            ) : null}
        </>
    );
}
