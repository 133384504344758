import * as posActions from "../../lib/posActions";
import * as routeHelpers from "../../lib/routeHelpers";
import * as searchParamNames from "../../constants/searchParamNames";
import AddCashAmount from "../AddCashAmount";
import cn from "classnames";
import GiftCardsDrawer from "../GiftCardsDrawer";
import OutlineButton from "../OutlineButton";
import QuantityControl from "../QuantityControl";
import React, {useState, useEffect, useRef} from "react";
import StyledKeyboard from "../StyledKeyboard";
import styles from "./GiftCardsPurchase.module.css";
import TextModal from "../TextModal";
import useOnChangeCashAmount from "../../lib/useOnChangeCashAmount";
import {API, Lib} from "habit-core";
import {useAppDispatch, useAppSelector} from "../../lib/hooks";
import {useNavigate, useSearchParams} from "react-router-dom";

const MAX_GC_AMOUNT_CENTS = parseInt(
    import.meta.env.REACT_APP_MAX_GIFT_CARD_VALUE_CENTS ?? "",
);

const MAX_GC_AMOUNT_STRING =
    Lib.currency.centsToDollarString(MAX_GC_AMOUNT_CENTS);

const strings = {
    // TODO: #261 - support purchasing gift cards as part of regular order
    prohibited: "Action Prohibited",
    giftCards:
        "Purchasing gift cards at the same time as menu items is not currently supported. Please complete the order or remove the menu items from your cart to continue.",

    addToOrder: "Add To Order",
    updateOrder: "Update Order",
    enter: "Enter Gift Card Value",
    maxAmountExceeded: `The max value you can add to this card is ${MAX_GC_AMOUNT_STRING}.`,
};

const AMOUNT_OPTIONS_CENTS = [1000, 2000, 2500, 5000, 10000];

export default function GiftCardsPurchase() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const gcid = searchParams.get(searchParamNames.GIFT_CARD_ID);
    const giftCard = useAppSelector((state) =>
        gcid
            ? state.pos.currentOrder.giftCards.purchase.find(
                  (r) => r.id === gcid,
              )
            : null,
    );

    const [quantity, setQuantity] = useState(giftCard?.quantity ?? 1);
    const [amountSelectedCents, setAmountSelectedCents] =
        useState<API.models.USDCents | null>(giftCard?.balanceCents ?? null);
    const maxAmountExceeded = (amountSelectedCents ?? 0) > MAX_GC_AMOUNT_CENTS;

    const cashAmountRef = useRef<HTMLInputElement>(null);
    const [displayedCashAmount, setDisplayedCashAmount] = useState("");
    useEffect(() => {
        const floatVal = parseFloat(displayedCashAmount);
        if (
            isNaN(floatVal) ||
            amountSelectedCents !== Lib.currency.dollarsFloatToCents(floatVal)
        ) {
            setDisplayedCashAmount(
                amountSelectedCents
                    ? Lib.currency
                          .centsToDollarString(amountSelectedCents)
                          .replace("$", "")
                    : "",
            );
        }
    }, [amountSelectedCents]);

    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true);

    useEffect(() => {
        if (cashAmountRef.current && !keyboardCollapsed) {
            if (document.activeElement === cashAmountRef.current) {
                cashAmountRef?.current?.scrollIntoView();
            }
        }
    }, [keyboardCollapsed]);

    const orderData = useAppSelector((state) =>
        Lib.selectors.getMyOrderData(state),
    );
    const isRegularOrder = !!orderData.length;

    const onChangeCashAmount = useOnChangeCashAmount(
        setDisplayedCashAmount,
        setAmountSelectedCents,
    );

    return (
        <>
            <GiftCardsDrawer
                balanceCents={amountSelectedCents ?? 0}
                left={
                    <div className={styles.container}>
                        <div className={styles.addAmountContainer}>
                            <AddCashAmount
                                label={strings.enter}
                                optionsCents={AMOUNT_OPTIONS_CENTS}
                                cashAmountCents={amountSelectedCents}
                                setCashAmountCents={setAmountSelectedCents}
                                displayedCashAmount={displayedCashAmount}
                                ref={cashAmountRef}
                                onFocus={() => setKeyboardCollapsed(false)}
                                resetAmountOnSelectOption
                            />
                            {maxAmountExceeded ? (
                                <div className={styles.maxAmountExceededText}>
                                    {strings.maxAmountExceeded}
                                </div>
                            ) : null}
                        </div>
                        <div
                            className={cn(
                                styles.keyboardContainer,
                                keyboardCollapsed &&
                                    styles.keyboardContainerCollapsed,
                            )}>
                            <StyledKeyboard
                                currentInput={displayedCashAmount}
                                visible={!keyboardCollapsed}
                                setVisible={(val: boolean) =>
                                    setKeyboardCollapsed(!val)
                                }
                                onChange={onChangeCashAmount}
                                inputRefs={[cashAmountRef]}
                                layout="numeric"
                            />
                        </div>
                    </div>
                }
                right={
                    <div className={styles.rightContainer}>
                        <div className={styles.buttonsContainer}>
                            <QuantityControl
                                quantity={quantity}
                                setQuantity={setQuantity}
                            />
                            <OutlineButton
                                label={Lib.currency.centsToDollarString(
                                    amountSelectedCents ?? 0,
                                )}
                                label2={
                                    giftCard
                                        ? strings.updateOrder
                                        : strings.addToOrder
                                }
                                disabled={
                                    !amountSelectedCents || maxAmountExceeded
                                }
                                onClick={() => {
                                    if (!amountSelectedCents) {
                                        return;
                                    }

                                    if (giftCard) {
                                        dispatch(
                                            posActions.updateNewGiftCardRequest(
                                                giftCard.id,
                                                amountSelectedCents,
                                                quantity,
                                            ),
                                        );
                                    } else {
                                        dispatch(
                                            posActions.addNewGiftCardRequest(
                                                amountSelectedCents,
                                                quantity,
                                            ),
                                        );
                                    }
                                    navigate(routeHelpers.giftCards());
                                }}
                            />
                        </div>
                    </div>
                }
            />
            {isRegularOrder ? (
                <TextModal
                    title={strings.prohibited}
                    content={strings.giftCards}
                    onClose={() => navigate(-1)}
                />
            ) : null}
        </>
    );
}
