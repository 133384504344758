import * as posModels from "../lib/api/posModels";
import logger from "../lib/logger";
import {Preferences} from "@capacitor/preferences";

const STATION_MODE_KEY = "stationMode";
const LANE_ASSIGNMENT_KEY = "laneAssignment";

export async function getStoredStationMode() {
    try {
        const {value} = await Preferences.get({key: STATION_MODE_KEY});
        return value;
    } catch (err) {
        logger.warn(err);
        return null; // this shouldn't happen
    }
}

export async function setStoredStationMode(mode: posModels.StationMode) {
    await Preferences.set({key: STATION_MODE_KEY, value: mode});
}

export async function getStoredLaneAssignment() {
    try {
        const {value} = await Preferences.get({key: LANE_ASSIGNMENT_KEY});
        return value;
    } catch (err) {
        logger.warn(err);
        return null; // this shouldn't happen
    }
}

export async function setStoredLaneAssignment(
    laneAssignment: posModels.LaneAssignment,
) {
    await Preferences.set({key: LANE_ASSIGNMENT_KEY, value: laneAssignment});
}

export async function clearStoredLaneAssignment() {
    await Preferences.remove({key: LANE_ASSIGNMENT_KEY});
}
