import * as posModels from "../../lib/api/posModels";
import cn from "classnames";
import Drawer from "../Drawer";
import OutlineButton from "../OutlineButton";
import React, {useEffect, useRef, useState} from "react";
import StyledKeyboard from "../StyledKeyboard";
import styles from "./OrderFilterDrawer.module.css";
import TextInput from "../TextInput";
import ToggleButton from "../ToggleButton";
import {subDays} from "date-fns";
import {Lib} from "habit-core";
import {DEFAULT_FILTERS, Filters, datesMatch} from "../../lib/filterOrders";

const strings = {
    today: "Today",
    byDate: "By Date",
    bySource: "By Source",
    updateResults: "Update Results",
    resetFilters: "Reset Filters",
    filterOrders: "Filter Orders",
    frontOfHouse1: "Front Of House 1",
    frontOfHouse2: "Front Of House 2",
    driveThruStation: "Drive-Thru",
    kiosk: "Kiosk",
    web: "Online",
    mobile: "Mobile",
    phone: "Phone Call",
    delivery: "Delivery",
    curbside: "Curbside",
    searchBy: "Search by Name, Check or Amount",
};

type FilterSourceOption = {
    label: string;
    value: posModels.OrderSource | posModels.OrderType;
    stationModes?: posModels.StationMode[];
};

const FILTER_SOURCE_OPTIONS: FilterSourceOption[] = [
    {
        label: strings.frontOfHouse1,
        value: "pos",
        stationModes: ["front_of_house_1"],
    },
    {
        label: strings.frontOfHouse2,
        value: "pos",
        stationModes: ["front_of_house_2"],
    },
    {
        label: strings.driveThruStation,
        value: "pos",
        stationModes: ["drive_thru_order_fulfillment"],
    },
    {label: strings.kiosk, value: "kiosk"},
    {label: strings.web, value: "web"},
    {label: strings.mobile, value: "mobile"},
    {label: strings.phone, value: "phone"},
    {label: strings.delivery, value: "delivery"},
    {label: strings.curbside, value: "curbside"},
];

function getDateOptions(today: Date): {label: string; value: Date}[] {
    const day2 = subDays(today, 1);
    const day3 = subDays(today, 2);
    const day4 = subDays(today, 3);
    const day5 = subDays(today, 4);
    const day6 = subDays(today, 5);
    const day7 = subDays(today, 6);
    return [
        {label: strings.today, value: today},
        {label: Lib.dates.format(day2, "MM/DD"), value: day2},
        {label: Lib.dates.format(day3, "MM/DD"), value: day3},
        {label: Lib.dates.format(day4, "MM/DD"), value: day4},
        {label: Lib.dates.format(day5, "MM/DD"), value: day5},
        {label: Lib.dates.format(day6, "MM/DD"), value: day6},
        {label: Lib.dates.format(day7, "MM/DD"), value: day7},
    ];
}

type Props = {
    activeFilters: Filters;
    setActiveFilters: (filters: Filters) => void;
    onClose: () => void;
};

export default function OrderFilterDrawer(props: Props) {
    const today = new Date();
    const dateOptions = getDateOptions(today);
    const [intermediateFilters, setIntermediateFilters] = useState<Filters>(
        props.activeFilters,
    );

    /* keyboard */
    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current && !keyboardCollapsed) {
            if (document.activeElement === inputRef.current) {
                inputRef?.current?.scrollIntoView();
            }
        }
    }, [keyboardCollapsed]);

    function onSubmitFilters(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        props.setActiveFilters(intermediateFilters);
        props.onClose();
    }

    function getNewSources(
        prevFilters: Filters,
        option: FilterSourceOption,
    ): (posModels.OrderSource | posModels.OrderType)[] {
        if (prevFilters.sources.includes(option.value)) {
            // check if another filter with the same source has been selected, if so, do not remove the source
            if (
                option.stationModes &&
                prevFilters.stationModes.filter(
                    (mode) => !option.stationModes?.includes(mode),
                ).length !== 0
            ) {
                return [...prevFilters.sources];
            } else {
                return prevFilters.sources.filter((s) => s !== option.value);
            }
        } else {
            return [...prevFilters.sources, option.value];
        }
    }

    function getNewStationModes(
        prevFilters: Filters,
        option: FilterSourceOption,
    ): posModels.StationMode[] {
        if (
            option.stationModes &&
            prevFilters.stationModes.includes(option.stationModes[0])
        ) {
            return prevFilters.stationModes.filter(
                (mode) => !option.stationModes?.includes(mode),
            );
        } else {
            return [
                ...prevFilters.stationModes,
                ...(option.stationModes ?? []),
            ];
        }
    }

    return (
        <Drawer isSmall={true} onClose={props.onClose}>
            <div className={styles.filtersContainer}>
                <form
                    className={styles.filtersFormContainer}
                    onSubmit={onSubmitFilters}>
                    <div className={styles.filtersHeader}>
                        <div className={styles.filtersText}>
                            {strings.filterOrders}
                        </div>
                        <TextInput
                            className={styles.filtersInput}
                            value={intermediateFilters.text}
                            onChange={(e) =>
                                setIntermediateFilters((prev) => ({
                                    ...prev,
                                    text: e.target.value,
                                }))
                            }
                            onFocus={() => setKeyboardCollapsed(false)}
                            placeholder={strings.searchBy}
                            ref={inputRef}
                            inputMode="none"
                        />
                    </div>
                    <div className={styles.filtersContent}>
                        <div className={styles.filtersDate}>
                            <div
                                className={cn(
                                    styles.filtersText,
                                    styles.filtersType,
                                )}>
                                {strings.byDate}
                            </div>
                            {dateOptions.map((o) => (
                                <ToggleButton
                                    key={o.label}
                                    className={styles.filtersToggle}
                                    label={o.label}
                                    checked={
                                        intermediateFilters.dates !== null &&
                                        !!intermediateFilters.dates.find((d) =>
                                            datesMatch(d, o.value),
                                        )
                                    }
                                    onChange={() => {
                                        setIntermediateFilters((prev) => ({
                                            ...prev,
                                            dates: prev.dates.find((d) =>
                                                datesMatch(d, o.value),
                                            )
                                                ? prev.dates.filter(
                                                      (d) =>
                                                          !datesMatch(
                                                              d,
                                                              o.value,
                                                          ),
                                                  )
                                                : [...prev.dates, o.value],
                                        }));
                                    }}
                                />
                            ))}
                        </div>
                        <div className={styles.filtersSource}>
                            <div
                                className={cn(
                                    styles.filtersText,
                                    styles.filtersType,
                                )}>
                                {strings.bySource}
                            </div>
                            {FILTER_SOURCE_OPTIONS.map((o) => (
                                <ToggleButton
                                    key={o.label}
                                    className={styles.filtersToggle}
                                    label={o.label}
                                    checked={
                                        intermediateFilters.sources.includes(
                                            o.value,
                                        ) &&
                                        (o.stationModes === undefined ||
                                            intermediateFilters.stationModes.includes(
                                                o.stationModes[0],
                                            ))
                                    }
                                    onChange={() =>
                                        setIntermediateFilters((prev) => ({
                                            ...prev,
                                            sources: getNewSources(prev, o),
                                            stationModes: getNewStationModes(
                                                prev,
                                                o,
                                            ),
                                        }))
                                    }
                                />
                            ))}
                        </div>
                    </div>
                    <div className={styles.filtersFooter}>
                        <OutlineButton
                            className={styles.filtersFooterButton}
                            label={strings.updateResults}
                            type="submit"
                        />
                        <OutlineButton
                            className={styles.resetFiltersButton}
                            mode="red"
                            label={strings.resetFilters}
                            onClick={() => {
                                props.setActiveFilters(DEFAULT_FILTERS);
                                props.onClose();
                            }}
                        />
                    </div>
                </form>
                <div
                    className={cn(
                        styles.keyboardContainer,
                        keyboardCollapsed && styles.keyboardContainerCollapsed,
                    )}>
                    <StyledKeyboard
                        currentInput={intermediateFilters.text}
                        visible={!keyboardCollapsed}
                        setVisible={(val: boolean) =>
                            setKeyboardCollapsed(!val)
                        }
                        onChange={(input) =>
                            setIntermediateFilters((prev) => ({
                                ...prev,
                                text: input,
                            }))
                        }
                        onPressEnter={() => setKeyboardCollapsed(true)}
                        inputRefs={[inputRef]}
                    />
                </div>
            </div>
        </Drawer>
    );
}
