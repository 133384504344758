import * as Sentry from "@sentry/capacitor";

/* eslint-disable @typescript-eslint/no-explicit-any */
export default {
    log(...args: any) {
        if (import.meta.env.DEV) {
            console.log(...args);
        }
    },

    debug(...args: any) {
        if (import.meta.env.DEV) {
            console.debug(...args);
        }
    },

    warn(msg: any) {
        Sentry.captureException(msg);
        console.warn(msg);
    },

    error(err: Error | string) {
        Sentry.captureException(err);
        console.error(err);
    },

    info(msg: string) {
        console.info(msg);
    },

    trace(...args: any) {
        if (import.meta.env.DEV) {
            console.trace(...args);
        }
    },

    table(...args: any) {
        if (import.meta.env.DEV) {
            console.table(...args);
        }
    },
};
