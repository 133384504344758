import * as ccEndpoints from "../../lib/api/creditCardScanner";
import CreditCardScannerRegistration from "../CreditCardScannerRegisteration";
import React from "react";
import Spinner from "../Spinner";
import styles from "./DeviceSetup.module.css";
import TillAssignment from "../TillAssignment/TillAssignment";
import {useAppSelector} from "../../lib/hooks";
import {useEffect, useState} from "react";

const isCreditCardScannerEnabled =
    import.meta.env.REACT_APP_CREDIT_CARD_SCANNER_ENABLED === "true"
        ? true
        : false;

export default function DeviceSetup() {
    const stationMode = useAppSelector((state) => state.pos.station.mode);
    const [
        showCreditCardScannerRegistration,
        setShowCreditCardScannerRegistration,
    ] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!isCreditCardScannerEnabled) {
            setLoading(false);
            return;
        }

        ccEndpoints
            .testConnection(stationMode)
            .then((isRegistered) => {
                setShowCreditCardScannerRegistration(!isRegistered);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <div className={styles.loadingContainer}>
                <Spinner />
            </div>
        );
    }

    if (showCreditCardScannerRegistration) {
        return (
            <CreditCardScannerRegistration
                onSuccess={() => {
                    setShowCreditCardScannerRegistration(false);
                }}
            />
        );
    } else {
        return <TillAssignment />;
    }
}
