import * as posModels from "./posModels";
import instance from "./instance";
import {health as urls} from "./urls";

export function getExhaustiveHealth(): Promise<posModels.ExhaustiveHealthCheckResponse> {
    return instance
        .request({
            url: urls.getExhaustive,
            method: "GET",
        })
        .then((response) => response.data);
}
