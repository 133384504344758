import * as posActionTypes from "../constants/posActionTypes";
import * as posModels from "./api/posModels";
import {Reducer} from "redux";
import {Lib} from "habit-core";
import {ItemModifierData} from "habit-core/types/lib/selectors";

function createReducer<T>(
    initialState: T,
    handlers: {[actionType: string]: Reducer<T>},
): Reducer<T> {
    return function reducer(state = initialState, action) {
        if ({}.hasOwnProperty.call(handlers, action.type)) {
            return handlers[action.type](state, action);
        } else {
            return state;
        }
    };
}

export type PosState = {
    deviceId: string | null;
    station: {
        mode: posModels.StationMode;
        requiresDriveThruDetails: boolean;
        laneAssignment: posModels.LaneAssignment | null;
    };
    charitySettings: {
        isEnabled: boolean;
        charityName: string | null;
        showMinimal: boolean;
    };
    currentOrder: {
        id: string | null;
        orderNumber: number | null;
        referenceNumber: number | null;
        voidedItemIds: string[];
        voidedComboIds: string[];
        guestName: string | null;
        pagerNumber: number | null;
        driveThruDetails: posModels.DriveThruDetails;
        discounts: posModels.DiscountDetails[];
        giftCards: {
            addFunds: posModels.GiftCardAddFundsCartEntity[];
            purchase: posModels.GiftCardPurchaseCartEntity[];
        };
        startTime: Date | null;
        endTime: Date | null;
        payment: posModels.CurrentOrderPayment;
    };
    orders: {
        byId: {[orderId: string]: posModels.OrderParsed};
    };
    currentOrderVoided: {
        items: {
            pending: {
                byId: {
                    [id: string]: posModels.PendingVoidedItem;
                };
            };
            voided: {
                byId: {
                    [id: string]: posModels.VoidedItem;
                };
            };
        };
        combos: {
            pending: {
                byId: {
                    [id: string]: posModels.PendingVoidedCombo;
                };
            };
            voided: {
                byId: {
                    [id: string]: posModels.VoidedCombo;
                };
            };
        };
    };
    allPreps: {
        byId: {
            [id: string]: Lib.selectors.ItemModifierData;
        };
    };
    addOns: {
        byId: {
            [id: string]: string[];
        };
    };
};

const defaultState: PosState = {
    deviceId: null,
    station: {
        mode: "front_of_house_1",
        requiresDriveThruDetails: false,
        laneAssignment: null,
    },
    charitySettings: {
        isEnabled: false,
        charityName: null,
        showMinimal: false,
    },
    currentOrder: {
        id: null,
        orderNumber: null,
        referenceNumber: null,
        voidedItemIds: [],
        voidedComboIds: [],
        guestName: null,
        pagerNumber: null,
        driveThruDetails: {
            vehicleType: "",
            vehicleColor: "",
            eatingInCar: false,
            laneNumber: 1,
        },
        discounts: [],
        giftCards: {addFunds: [], purchase: []},
        startTime: null,
        endTime: null,
        payment: {
            cashPaidCents: 0,
            changeOwedCents: 0,
            giftCardsApplied: [],
            compCardsApplied: [],
            creditCardApplied: null,
        },
    },
    orders: {
        byId: {},
    },
    currentOrderVoided: {
        items: {
            pending: {
                byId: {},
            },
            voided: {
                byId: {},
            },
        },
        combos: {
            pending: {
                byId: {},
            },
            voided: {
                byId: {},
            },
        },
    },
    allPreps: {
        byId: {},
    },
    addOns: {
        byId: {},
    },
};

const POS_SET_DEVICE_ID: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {deviceId} = action;
    return {
        ...state,
        deviceId,
    };
};

const POS_STATION_INITIALIZE: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {mode, requiresDriveThruDetails, laneAssignment} = action;
    return {
        ...state,
        station: {
            ...state.station,
            mode,
            requiresDriveThruDetails,
            laneAssignment,
        },
    };
};

const POS_STATION_EDIT_MODE: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {stationMode, laneAssignment} = action;

    return {
        ...state,
        station: {
            ...state.station,
            mode: stationMode,
            laneAssignment: laneAssignment,
        },
    };
};

const POS_CHARITY_SETTINGS_INITIALIZE: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {isEnabled, charityName, showMinimal} = action;

    return {
        ...state,
        charitySettings: {
            ...state.charitySettings,
            isEnabled,
            charityName,
            showMinimal,
        },
    };
};

const POS_ALL_PREPS_INITIALIZE: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const byId: {[x: string]: Lib.selectors.ItemModifierData} = {};
    const {preps} = action;
    preps.forEach((prep: ItemModifierData) => {
        byId[prep.id] = prep;
    });

    return {
        ...state,
        allPreps: {
            byId: {
                ...state.allPreps.byId,
                ...byId,
            },
        },
    };
};

const POS_ADD_ONS_INITIALIZE: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const byId: {[x: string]: string[]} = {};
    const {itemsAddOnsInfo} = action;
    itemsAddOnsInfo.forEach((itemAddOnInfo: posModels.ItemAddOnsInfo) => {
        byId[itemAddOnInfo.itemId] = itemAddOnInfo.addOnItemIds;
    });

    return {
        ...state,
        addOns: {
            byId: {
                ...state.addOns.byId,
                ...byId,
            },
        },
    };
};

const POS_CURRENT_ORDER_SET_ID: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {id} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            id,
        },
    };
};

const POS_CURRENT_ORDER_SET_REF_NUM: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {referenceNumber} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            referenceNumber,
        },
    };
};

const POS_CURRENT_ORDER_SET_ID_REF_NUM_AND_NUMBER: Reducer<PosState> = (
    state,
    action,
) => {
    if (!state) {
        return defaultState;
    }

    const {id, referenceNumber, orderNumber} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            id,
            referenceNumber,
            orderNumber,
        },
    };
};

const POS_CURRENT_ORDER_REMOVE_REF_NUM: Reducer<PosState> = (state) => {
    if (!state) {
        return defaultState;
    }

    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            referenceNumber: null,
        },
    };
};

const POS_CURRENT_ORDER_CLEAR: Reducer<PosState> = (state) => {
    if (!state) {
        return defaultState;
    }

    return {
        ...state,
        currentOrder: defaultState.currentOrder,
    };
};

const POS_CURRENT_ORDER_SET_DRIVE_THRU_DETAILS: Reducer<PosState> = (
    state,
    action,
) => {
    if (!state) {
        return defaultState;
    }

    const {driveThruDetails} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            driveThruDetails,
        },
    };
};

const POS_CURRENT_ORDER_SET_CUSTOMER_INFO: Reducer<PosState> = (
    state,
    action,
) => {
    if (!state) {
        return defaultState;
    }

    const {guestName, pagerNumber} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            guestName,
            pagerNumber,
        },
    };
};

const POS_CURRENT_ORDER_SET_START_TIME: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {startTime} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            startTime,
        },
    };
};

const POS_CURRENT_ORDER_SET_END_TIME: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {endTime} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            endTime,
        },
    };
};

const POS_CURRENT_ORDER_SET_ORDER_NUMBER: Reducer<PosState> = (
    state,
    action,
) => {
    if (!state) {
        return defaultState;
    }

    const {orderNumber} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            orderNumber,
        },
    };
};

const POS_PAYMENT_SET_CASH_PAID_CENTS: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {amountCents} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            payment: {
                ...state.currentOrder.payment,
                cashPaidCents: amountCents,
            },
        },
    };
};

const POS_PAYMENT_ADD_TO_CASH_PAID_CENTS: Reducer<PosState> = (
    state,
    action,
) => {
    if (!state) {
        return defaultState;
    }

    const {amountCents} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            payment: {
                ...state.currentOrder.payment,
                cashPaidCents:
                    state.currentOrder.payment.cashPaidCents + amountCents,
            },
        },
    };
};

const POS_PAYMENT_SET_CHANGE_OWED_CENTS: Reducer<PosState> = (
    state,
    action,
) => {
    if (!state) {
        return defaultState;
    }

    const {amountCents} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            payment: {
                ...state.currentOrder.payment,
                changeOwedCents: amountCents,
            },
        },
    };
};

const POS_PAYMENT_APPLY_GIFT_CARD: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {giftCard} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            payment: {
                ...state.currentOrder.payment,
                giftCardsApplied: [
                    ...state.currentOrder.payment.giftCardsApplied,
                    giftCard,
                ],
            },
        },
    };
};

const POS_PAYMENT_REMOVE_GIFT_CARD: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {id} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            payment: {
                ...state.currentOrder.payment,
                giftCardsApplied:
                    state.currentOrder.payment.giftCardsApplied.filter(
                        (gc) => gc.id !== id,
                    ),
            },
        },
    };
};

const POS_PAYMENT_SET_GIFT_CARDS: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {giftCardsApplied: newGiftCardsApplied} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            payment: {
                ...state.currentOrder.payment,
                giftCardsApplied: newGiftCardsApplied,
            },
        },
    };
};

const POS_PAYMENT_APPLY_COMP_CARD: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {compCard} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            payment: {
                ...state.currentOrder.payment,
                compCardsApplied: [
                    ...state.currentOrder.payment.compCardsApplied,
                    compCard,
                ],
            },
        },
    };
};

const POS_PAYMENT_REMOVE_COMP_CARD: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {id} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            payment: {
                ...state.currentOrder.payment,
                compCardsApplied:
                    state.currentOrder.payment.compCardsApplied.filter(
                        (compCard) => compCard.id !== id,
                    ),
            },
        },
    };
};

const POS_PAYMENT_ADD_CREDIT_CARD: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {creditCard} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            payment: {
                ...state.currentOrder.payment,
                creditCardApplied: creditCard,
            },
        },
    };
};

const POS_PAYMENT_CLEAR: Reducer<PosState> = (state) => {
    if (!state) {
        return defaultState;
    }

    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            payment: {
                cashPaidCents: 0,
                changeOwedCents: 0,
                giftCardsApplied: [],
                compCardsApplied: [],
                creditCardApplied: null,
            },
        },
    };
};

const POS_ORDERS_SET: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {orders} = action;
    const byId: {[id: string]: posModels.OrderParsed} = {};
    orders.forEach((o: posModels.OrderParsed) => (byId[o.id] = o));

    return {
        ...state,
        orders: {
            ...state.orders,
            byId,
        },
    };
};

const POS_ADD_ORDER: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {order} = action;
    const byId: {[id: string]: posModels.OrderParsed} = {...state.orders.byId};
    byId[order.id] = order;

    return {
        ...state,
        orders: {
            ...state.orders,
            byId,
        },
    };
};

const POS_ORDERS_REMOVE: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {id} = action;
    const byId = {...state.orders.byId};
    delete byId[id];

    return {
        ...state,
        orders: {
            ...state.orders,
            byId,
        },
    };
};

const POS_PENDING_VOIDED_ITEM_ADD: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {pendingVoidedItem} = action;

    return {
        ...state,
        currentOrderVoided: {
            ...state.currentOrderVoided,
            items: {
                ...state.currentOrderVoided.items,
                pending: {
                    ...state.currentOrderVoided.items.pending,
                    byId: {
                        ...state.currentOrderVoided.items.pending.byId,
                        [pendingVoidedItem.id]: pendingVoidedItem,
                    },
                },
            },
        },
    };
};

const POS_PENDING_VOIDED_COMBO_ADD: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {pendingVoidedCombo} = action;

    return {
        ...state,
        currentOrderVoided: {
            ...state.currentOrderVoided,
            combos: {
                ...state.currentOrderVoided.combos,
                pending: {
                    ...state.currentOrderVoided.combos.pending,
                    byId: {
                        ...state.currentOrderVoided.combos.pending.byId,
                        [pendingVoidedCombo.id]: pendingVoidedCombo,
                    },
                },
            },
        },
    };
};

const POS_PENDING_VOIDED_ITEM_REMOVE: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {id} = action;
    const byId = {...state.currentOrderVoided.items.pending.byId};
    delete byId[id];

    return {
        ...state,
        currentOrderVoided: {
            ...state.currentOrderVoided,
            items: {
                ...state.currentOrderVoided.items,
                pending: {
                    ...state.currentOrderVoided.items.pending,
                    byId,
                },
            },
        },
    };
};

const POS_PENDING_VOIDED_COMBO_REMOVE: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {id} = action;
    const byId = {...state.currentOrderVoided.combos.pending.byId};
    delete byId[id];

    return {
        ...state,
        currentOrderVoided: {
            ...state.currentOrderVoided,
            combos: {
                ...state.currentOrderVoided.combos,
                pending: {
                    ...state.currentOrderVoided.combos.pending,
                    byId,
                },
            },
        },
    };
};

const POS_PENDING_VOIDED_ITEM_CHANGE_QUANTITY: Reducer<PosState> = (
    state,
    action,
) => {
    if (!state) {
        return defaultState;
    }

    const {id, quantity} = action;

    return {
        ...state,
        currentOrderVoided: {
            ...state.currentOrderVoided,
            items: {
                ...state.currentOrderVoided.items,
                pending: {
                    ...state.currentOrderVoided.items.pending,
                    byId: {
                        ...state.currentOrderVoided.items.pending.byId,
                        [id]: {
                            ...state.currentOrderVoided.items.pending.byId[id],
                            quantity,
                        },
                    },
                },
            },
        },
    };
};

const POS_PENDING_VOIDED_COMBO_CHANGE_QUANTITY: Reducer<PosState> = (
    state,
    action,
) => {
    if (!state) {
        return defaultState;
    }

    const {id, quantity} = action;

    return {
        ...state,
        currentOrderVoided: {
            ...state.currentOrderVoided,
            combos: {
                ...state.currentOrderVoided.combos,
                pending: {
                    ...state.currentOrderVoided.combos.pending,
                    byId: {
                        ...state.currentOrderVoided.combos.pending.byId,
                        [id]: {
                            ...state.currentOrderVoided.combos.pending.byId[id],
                            quantity,
                        },
                    },
                },
            },
        },
    };
};

const POS_PENDING_VOIDED_CLEAR: Reducer<PosState> = (state) => {
    if (!state) {
        return defaultState;
    }

    return {
        ...state,
        currentOrderVoided: {
            ...state.currentOrderVoided,
            items: {
                ...state.currentOrderVoided.items,
                pending: {
                    byId: {},
                },
            },
            combos: {
                ...state.currentOrderVoided.combos,
                pending: {
                    byId: {},
                },
            },
        },
    };
};

const POS_CURRENT_VOIDED_COMBOS_ADD: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {voidedCombos} = action;
    const byId = {
        ...state.currentOrderVoided.combos.voided.byId,
    };
    voidedCombos.forEach(
        (combo: posModels.VoidedCombo) => (byId[combo.id] = combo),
    );
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            voidedComboIds: [
                ...state.currentOrder.voidedComboIds,
                ...voidedCombos.map((c: posModels.VoidedCombo) => c.id),
            ],
        },
        currentOrderVoided: {
            ...state.currentOrderVoided,
            combos: {
                ...state.currentOrderVoided.combos,
                voided: {
                    ...state.currentOrderVoided.combos.voided,
                    byId,
                },
            },
        },
    };
};

const POS_CURRENT_VOIDED_ITEMS_ADD: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {voidedItems} = action;
    const byId = {
        ...state.currentOrderVoided.items.voided.byId,
    };
    voidedItems.forEach((item: posModels.VoidedItem) => (byId[item.id] = item));
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            voidedItemIds: [
                ...state.currentOrder.voidedItemIds,
                ...voidedItems.map((i: posModels.VoidedItem) => i.id),
            ],
        },
        currentOrderVoided: {
            ...state.currentOrderVoided,
            items: {
                ...state.currentOrderVoided.items,
                voided: {
                    ...state.currentOrderVoided.items.voided,
                    byId,
                },
            },
        },
    };
};

const POS_CURRENT_VOIDED_COMBOS_REMOVE: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {id} = action;
    const byId = {
        ...state.currentOrderVoided.combos.voided.byId,
    };
    delete byId[id];

    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            voidedComboIds: state.currentOrder.voidedComboIds.filter(
                (cid) => cid !== id,
            ),
        },
        currentOrderVoided: {
            ...state.currentOrderVoided,
            combos: {
                ...state.currentOrderVoided.combos,
                voided: {
                    ...state.currentOrderVoided.combos.voided,
                    byId,
                },
            },
        },
    };
};

const POS_CURRENT_VOIDED_ITEMS_REMOVE: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {id} = action;
    const byId = {
        ...state.currentOrderVoided.items.voided.byId,
    };
    delete byId[id];

    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            voidedItemIds: state.currentOrder.voidedItemIds.filter(
                (iid) => iid !== id,
            ),
        },
        currentOrderVoided: {
            ...state.currentOrderVoided,
            items: {
                ...state.currentOrderVoided.items,
                voided: {
                    ...state.currentOrderVoided.items.voided,
                    byId,
                },
            },
        },
    };
};

const POS_CURRENT_VOIDED_ITEMS_CHANGE_QUANTITY: Reducer<PosState> = (
    state,
    action,
) => {
    if (!state) {
        return defaultState;
    }

    const {id, quantity} = action;

    return {
        ...state,
        currentOrderVoided: {
            ...state.currentOrderVoided,
            items: {
                ...state.currentOrderVoided.items,
                voided: {
                    ...state.currentOrderVoided.items.voided,
                    byId: {
                        ...state.currentOrderVoided.items.voided.byId,
                        [id]: {
                            ...state.currentOrderVoided.items.voided.byId[id],
                            quantity,
                        },
                    },
                },
            },
        },
    };
};

const POS_CURRENT_VOIDED_COMBOS_CHANGE_QUANTITY: Reducer<PosState> = (
    state,
    action,
) => {
    if (!state) {
        return defaultState;
    }

    const {id, quantity} = action;

    return {
        ...state,
        currentOrderVoided: {
            ...state.currentOrderVoided,
            combos: {
                ...state.currentOrderVoided.combos,
                voided: {
                    ...state.currentOrderVoided.combos.voided,
                    byId: {
                        ...state.currentOrderVoided.combos.voided.byId,
                        [id]: {
                            ...state.currentOrderVoided.combos.voided.byId[id],
                            quantity,
                        },
                    },
                },
            },
        },
    };
};

const POS_CURRENT_ORDER_VOIDED_CLEAR: Reducer<PosState> = (state) => {
    if (!state) {
        return defaultState;
    }

    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            voidedComboIds: defaultState.currentOrder.voidedComboIds,
            voidedItemIds: defaultState.currentOrder.voidedItemIds,
        },
        currentOrderVoided: defaultState.currentOrderVoided,
    };
};

const POS_CURRENT_ORDER_ADD_DISCOUNT: Reducer<PosState> = (state, action) => {
    if (!state) {
        return defaultState;
    }

    const {
        name,
        discountCode,
        amountCents,
        amountPercentage,
        employeeId,
        reason,
        discountType,
    } = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            discounts: [
                ...state.currentOrder.discounts,
                {
                    name,
                    discountCode,
                    amountCents,
                    amountPercentage,
                    employeeId,
                    reason,
                    discountType,
                },
            ],
        },
    };
};

const POS_CURRENT_ORDER_REMOVE_DISCOUNT: Reducer<PosState> = (
    state,
    action,
) => {
    if (!state) {
        return defaultState;
    }

    const {discountCode} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            discounts: state.currentOrder.discounts.filter(
                (discount) => discount.discountCode !== discountCode,
            ),
        },
    };
};

const POS_CURRENT_ORDER_ADD_EXISTING_GIFT_CARD_REQUEST: Reducer<PosState> = (
    state,
    action,
) => {
    if (!state) {
        return defaultState;
    }

    const {id, cardNumber, originalBalanceCents, amountToAddCents} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            giftCards: {
                ...state.currentOrder.giftCards,
                addFunds: [
                    ...state.currentOrder.giftCards.addFunds,
                    {id, cardNumber, originalBalanceCents, amountToAddCents},
                ],
            },
        },
    };
};

const POS_CURRENT_ORDER_UPDATE_EXISTING_GIFT_CARD_REQUEST: Reducer<PosState> = (
    state,
    action,
) => {
    if (!state) {
        return defaultState;
    }

    const {id, amountToAddCents} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            giftCards: {
                ...state.currentOrder.giftCards,
                addFunds: state.currentOrder.giftCards.addFunds.map((r) =>
                    r.id === id ? {...r, amountToAddCents} : r,
                ),
            },
        },
    };
};

const POS_CURRENT_ORDER_REMOVE_EXISTING_GIFT_CARD_REQUEST: Reducer<PosState> = (
    state,
    action,
) => {
    if (!state) {
        return defaultState;
    }

    const {id} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            giftCards: {
                ...state.currentOrder.giftCards,
                addFunds: state.currentOrder.giftCards.addFunds.filter(
                    (gc) => gc.id !== id,
                ),
            },
        },
    };
};

const POS_CURRENT_ORDER_ADD_NEW_GIFT_CARD_REQUEST: Reducer<PosState> = (
    state,
    action,
) => {
    if (!state) {
        return defaultState;
    }

    const {id, balanceCents, quantity} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            giftCards: {
                ...state.currentOrder.giftCards,
                purchase: [
                    ...state.currentOrder.giftCards.purchase,
                    {id, balanceCents, quantity},
                ],
            },
        },
    };
};

const POS_CURRENT_ORDER_UPDATE_NEW_GIFT_CARD_REQUEST: Reducer<PosState> = (
    state,
    action,
) => {
    if (!state) {
        return defaultState;
    }

    const {id, balanceCents, quantity} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            giftCards: {
                ...state.currentOrder.giftCards,
                purchase: state.currentOrder.giftCards.purchase.map((gc) =>
                    gc.id === id ? {...gc, balanceCents, quantity} : gc,
                ),
            },
        },
    };
};

const POS_CURRENT_ORDER_REMOVE_NEW_GIFT_CARD_REQUEST: Reducer<PosState> = (
    state,
    action,
) => {
    if (!state) {
        return defaultState;
    }

    const {id} = action;
    return {
        ...state,
        currentOrder: {
            ...state.currentOrder,
            giftCards: {
                ...state.currentOrder.giftCards,
                purchase: state.currentOrder.giftCards.purchase.filter(
                    (gc) => gc.id !== id,
                ),
            },
        },
    };
};

export default createReducer(defaultState, {
    [posActionTypes.POS_SET_DEVICE_ID]: POS_SET_DEVICE_ID,
    [posActionTypes.POS_STATION_INITIALIZE]: POS_STATION_INITIALIZE,
    [posActionTypes.POS_STATION_EDIT_MODE]: POS_STATION_EDIT_MODE,
    [posActionTypes.POS_CHARITY_SETTINGS_INITIALIZE]:
        POS_CHARITY_SETTINGS_INITIALIZE,
    [posActionTypes.POS_ALL_PREPS_INITIALIZE]: POS_ALL_PREPS_INITIALIZE,
    [posActionTypes.POS_ADD_ONS_INITIALIZE]: POS_ADD_ONS_INITIALIZE,
    [posActionTypes.POS_CURRENT_ORDER_SET_ID]: POS_CURRENT_ORDER_SET_ID,
    [posActionTypes.POS_CURRENT_ORDER_SET_REF_NUM]:
        POS_CURRENT_ORDER_SET_REF_NUM,
    [posActionTypes.POS_CURRENT_ORDER_SET_ID_REF_NUM_AND_NUMBER]:
        POS_CURRENT_ORDER_SET_ID_REF_NUM_AND_NUMBER,
    [posActionTypes.POS_CURRENT_ORDER_REMOVE_REF_NUM]:
        POS_CURRENT_ORDER_REMOVE_REF_NUM,
    [posActionTypes.POS_CURRENT_ORDER_CLEAR]: POS_CURRENT_ORDER_CLEAR,
    [posActionTypes.POS_CURRENT_ORDER_SET_DRIVE_THRU_DETAILS]:
        POS_CURRENT_ORDER_SET_DRIVE_THRU_DETAILS,
    [posActionTypes.POS_CURRENT_ORDER_SET_CUSTOMER_INFO]:
        POS_CURRENT_ORDER_SET_CUSTOMER_INFO,
    [posActionTypes.POS_CURRENT_ORDER_SET_START_TIME]:
        POS_CURRENT_ORDER_SET_START_TIME,
    [posActionTypes.POS_CURRENT_ORDER_SET_END_TIME]:
        POS_CURRENT_ORDER_SET_END_TIME,
    [posActionTypes.POS_CURRENT_ORDER_SET_ORDER_NUMBER]:
        POS_CURRENT_ORDER_SET_ORDER_NUMBER,
    [posActionTypes.POS_ORDERS_SET]: POS_ORDERS_SET,
    [posActionTypes.POS_ADD_ORDER]: POS_ADD_ORDER,
    [posActionTypes.POS_ORDERS_REMOVE]: POS_ORDERS_REMOVE,
    [posActionTypes.POS_PENDING_VOIDED_ITEM_ADD]: POS_PENDING_VOIDED_ITEM_ADD,
    [posActionTypes.POS_PENDING_VOIDED_COMBO_ADD]: POS_PENDING_VOIDED_COMBO_ADD,
    [posActionTypes.POS_PENDING_VOIDED_ITEM_REMOVE]:
        POS_PENDING_VOIDED_ITEM_REMOVE,
    [posActionTypes.POS_PENDING_VOIDED_COMBO_REMOVE]:
        POS_PENDING_VOIDED_COMBO_REMOVE,
    [posActionTypes.POS_PENDING_VOIDED_ITEM_CHANGE_QUANTITY]:
        POS_PENDING_VOIDED_ITEM_CHANGE_QUANTITY,
    [posActionTypes.POS_PENDING_VOIDED_COMBO_CHANGE_QUANTITY]:
        POS_PENDING_VOIDED_COMBO_CHANGE_QUANTITY,
    [posActionTypes.POS_PENDING_VOIDED_CLEAR]: POS_PENDING_VOIDED_CLEAR,
    [posActionTypes.POS_CURRENT_VOIDED_COMBOS_ADD]:
        POS_CURRENT_VOIDED_COMBOS_ADD,
    [posActionTypes.POS_CURRENT_VOIDED_ITEMS_ADD]: POS_CURRENT_VOIDED_ITEMS_ADD,
    [posActionTypes.POS_CURRENT_VOIDED_COMBOS_REMOVE]:
        POS_CURRENT_VOIDED_COMBOS_REMOVE,
    [posActionTypes.POS_CURRENT_VOIDED_ITEMS_REMOVE]:
        POS_CURRENT_VOIDED_ITEMS_REMOVE,
    [posActionTypes.POS_CURRENT_VOIDED_ITEMS_CHANGE_QUANTITY]:
        POS_CURRENT_VOIDED_ITEMS_CHANGE_QUANTITY,
    [posActionTypes.POS_CURRENT_VOIDED_COMBOS_CHANGE_QUANTITY]:
        POS_CURRENT_VOIDED_COMBOS_CHANGE_QUANTITY,
    [posActionTypes.POS_CURRENT_ORDER_VOIDED_CLEAR]:
        POS_CURRENT_ORDER_VOIDED_CLEAR,
    [posActionTypes.POS_CURRENT_ORDER_ADD_DISCOUNT]:
        POS_CURRENT_ORDER_ADD_DISCOUNT,
    [posActionTypes.POS_CURRENT_ORDER_REMOVE_DISCOUNT]:
        POS_CURRENT_ORDER_REMOVE_DISCOUNT,
    [posActionTypes.POS_CURRENT_ORDER_ADD_EXISTING_GIFT_CARD_REQUEST]:
        POS_CURRENT_ORDER_ADD_EXISTING_GIFT_CARD_REQUEST,
    [posActionTypes.POS_CURRENT_ORDER_UPDATE_EXISTING_GIFT_CARD_REQUEST]:
        POS_CURRENT_ORDER_UPDATE_EXISTING_GIFT_CARD_REQUEST,
    [posActionTypes.POS_CURRENT_ORDER_REMOVE_EXISTING_GIFT_CARD_REQUEST]:
        POS_CURRENT_ORDER_REMOVE_EXISTING_GIFT_CARD_REQUEST,
    [posActionTypes.POS_CURRENT_ORDER_ADD_NEW_GIFT_CARD_REQUEST]:
        POS_CURRENT_ORDER_ADD_NEW_GIFT_CARD_REQUEST,
    [posActionTypes.POS_CURRENT_ORDER_UPDATE_NEW_GIFT_CARD_REQUEST]:
        POS_CURRENT_ORDER_UPDATE_NEW_GIFT_CARD_REQUEST,
    [posActionTypes.POS_CURRENT_ORDER_REMOVE_NEW_GIFT_CARD_REQUEST]:
        POS_CURRENT_ORDER_REMOVE_NEW_GIFT_CARD_REQUEST,
    [posActionTypes.POS_PAYMENT_SET_CASH_PAID_CENTS]:
        POS_PAYMENT_SET_CASH_PAID_CENTS,
    [posActionTypes.POS_PAYMENT_SET_CHANGE_OWED_CENTS]:
        POS_PAYMENT_SET_CHANGE_OWED_CENTS,
    [posActionTypes.POS_PAYMENT_APPLY_GIFT_CARD]: POS_PAYMENT_APPLY_GIFT_CARD,
    [posActionTypes.POS_PAYMENT_REMOVE_GIFT_CARD]: POS_PAYMENT_REMOVE_GIFT_CARD,
    [posActionTypes.POS_PAYMENT_SET_GIFT_CARDS]: POS_PAYMENT_SET_GIFT_CARDS,
    [posActionTypes.POS_PAYMENT_APPLY_COMP_CARD]: POS_PAYMENT_APPLY_COMP_CARD,
    [posActionTypes.POS_PAYMENT_REMOVE_COMP_CARD]: POS_PAYMENT_REMOVE_COMP_CARD,
    [posActionTypes.POS_PAYMENT_ADD_CREDIT_CARD]: POS_PAYMENT_ADD_CREDIT_CARD,
    [posActionTypes.POS_PAYMENT_CLEAR]: POS_PAYMENT_CLEAR,
    [posActionTypes.POS_PAYMENT_ADD_TO_CASH_PAID_CENTS]:
        POS_PAYMENT_ADD_TO_CASH_PAID_CENTS,
});
