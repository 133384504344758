import * as posModels from "../../lib/api/posModels";
import OrderDetailsCustomizationList from "../OrderDetailsCustomizationList";
import React from "react";
import styles from "./OrderDetailsListItem.module.css";
import {Lib} from "habit-core";

const strings = {
    quantity: "Qty :",
};

type Props =
    | {type: "combo"; entity: posModels.OrderCombo}
    | {type: "item"; entity: posModels.OrderItem};

export default function OrderDetailsListItem({type, entity}: Props) {
    const priceCents = entity.unitPriceCents * entity.quantity;

    if (type === "item") {
        return (
            <div key={entity.id} className={styles.detailListItem}>
                <div className={styles.detailListItemLeft}>
                    <div className={styles.detailListItemName}>
                        {entity.name}
                    </div>
                    {entity.modifiers.length ? (
                        <OrderDetailsCustomizationList
                            modifiers={entity.modifiers}
                        />
                    ) : null}
                </div>
                <div>
                    <div className={styles.detailListItemPrice}>
                        {Lib.currency.centsToDollarString(priceCents)}
                    </div>
                    <div className={styles.detailListItemQuantity}>
                        {strings.quantity} {entity.quantity}
                    </div>
                </div>
            </div>
        );
    }

    if (type === "combo") {
        return (
            <div key={entity.id} className={styles.detailListItem}>
                <div className={styles.detailListItemLeft}>
                    <div className={styles.detailListItemName}>
                        {entity.name}
                    </div>
                    <div>
                        {entity.items.map((item, i) => {
                            return (
                                <div key={i}>
                                    <div
                                        className={
                                            styles.detailListComboItemName
                                        }>
                                        {item.name}
                                    </div>
                                    {item.modifiers.length ? (
                                        <OrderDetailsCustomizationList
                                            modifiers={item.modifiers}
                                        />
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div>
                    <div className={styles.detailListItemPrice}>
                        {Lib.currency.centsToDollarString(priceCents)}
                    </div>
                    <div className={styles.detailListItemQuantity}>
                        {strings.quantity} {entity.quantity}
                    </div>
                </div>
            </div>
        );
    }

    return null;
}
