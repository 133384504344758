import * as routeHelpers from "../../lib/routeHelpers";
import {Lib} from "habit-core";
import {Outlet, useParams, useNavigate} from "react-router-dom";
import {useAppSelector} from "../../lib/hooks";
import cn from "classnames";
import flatten from "lodash.flatten";
import logger from "../../lib/logger";
import MenuComboLink from "../MenuComboLink";
import MenuGroupingLink from "../MenuGroupingLink";
import MenuItemLink from "../MenuItemLink";
import React, {useMemo, useEffect} from "react";
import styles from "./MenuCategoryItems.module.css";

type Props = {
    className?: string;
};

type Params = {
    categoryId: string;
};

export default function MenuCategoryItems(props: Props) {
    const navigate = useNavigate();
    const {categoryId} = useParams<Params>();
    const menuId = useAppSelector((state) => state.currentOrder.menuId);
    const categoriesById = useAppSelector((state) => state.categories.byId);
    useEffect(() => {
        if (
            !menuId ||
            !categoryId ||
            (categoryId !== routeHelpers.OFF_MENU_CATEGORY_ID &&
                !categoriesById[categoryId])
        ) {
            logger.warn(
                `invalid menu id (${menuId}) and/or category id (${categoryId})`,
            );
            navigate(routeHelpers.menu(), {replace: true});
        }
    }, []);
    const getSections = useMemo(
        () => Lib.selectors.createGetMenuCategorySections(false, true),
        [],
    );

    const getOffMenuSections = useMemo(
        () => Lib.selectors.getOffMenuCombosAndItems(),
        [],
    );

    const sections = useAppSelector((state) =>
        categoryId === routeHelpers.OFF_MENU_CATEGORY_ID
            ? getOffMenuSections(state, menuId ?? "")
            : getSections(state, categoryId ?? "", menuId ?? ""),
    );

    const flattenedSections = flatten(sections.map((x) => x.data));
    const displaySize = flattenedSections.length > 10 ? "small" : "regular";
    const numColumns = flattenedSections.length > 12 ? 3 : 2;

    if (
        !menuId ||
        !categoryId ||
        (categoryId !== routeHelpers.OFF_MENU_CATEGORY_ID &&
            !categoriesById[categoryId])
    ) {
        return null;
    }

    return (
        <div className={cn(styles.container, props.className)}>
            {flattenedSections.map((x) => {
                if (x.type === "combo" && x.comboId) {
                    return (
                        <MenuComboLink
                            key={x.comboId}
                            comboId={x.comboId}
                            categoryId={categoryId}
                            menudId={menuId}
                            numColumns={numColumns}
                            displaySize={displaySize}
                        />
                    );
                } else if (x.type === "item" && x.itemId) {
                    return (
                        <MenuItemLink
                            key={x.itemId}
                            itemId={x.itemId}
                            categoryId={categoryId}
                            menuId={menuId}
                            numColumns={numColumns}
                            displaySize={displaySize}
                        />
                    );
                } else if (x.type === "grouping") {
                    return (
                        <MenuGroupingLink
                            key={x.groupingId}
                            groupingId={x.groupingId}
                            categoryId={categoryId}
                            menuId={menuId}
                            numColumns={numColumns}
                            displaySize={displaySize}
                        />
                    );
                } else {
                    logger.warn("unknown section product data");
                }

                return null;
            })}

            <Outlet />
            <div
                className={numColumns === 2 ? styles.spacer : styles.spacer3Col}
            />
            <div
                className={numColumns === 2 ? styles.spacer : styles.spacer3Col}
            />
            <div
                className={numColumns === 2 ? styles.spacer : styles.spacer3Col}
            />
            <div
                className={numColumns === 2 ? styles.spacer : styles.spacer3Col}
            />
        </div>
    );
}
