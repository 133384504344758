import Modal from "../Modal";
import OutlineButton from "../OutlineButton";
import React from "react";
import styles from "./ConfirmationModal.module.css";

const strings = {
    cancelLabel: "No",
    confirmLabel: "Yes",
};

type Props = {
    isOpen: boolean;
    title: string;
    cancelLabel?: string;
    confirmLabel?: string;
    onPressCancel: () => void;
    onPressConfirm: () => void;
};

export function ConfirmationModal({
    isOpen,
    title,
    cancelLabel = strings.cancelLabel,
    confirmLabel = strings.confirmLabel,
    onPressCancel,
    onPressConfirm,
}: Props) {
    if (!isOpen) {
        return null;
    }

    return (
        <Modal onClose={onPressCancel}>
            <div className={styles.container}>
                <div className={styles.title}>{title}</div>
                <div className={styles.buttonsContainer}>
                    <OutlineButton
                        label={cancelLabel}
                        onClick={onPressCancel}
                        className={styles.button}
                    />
                    <OutlineButton
                        label={confirmLabel}
                        onClick={onPressConfirm}
                        className={styles.button}
                    />
                </div>
            </div>
        </Modal>
    );
}
