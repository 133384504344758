import * as posModels from "./posModels";
import instance from "./instance";
import {printers as urls} from "./urls";

export function get(): Promise<{stations: posModels.Station[]}> {
    return instance
        .request({
            url: urls.get,
            method: "GET",
        })
        .then((response) => response.data);
}

export function setStatus(
    managerId: string,
    stationMode: posModels.StationMode,
    printerStatus: posModels.PrinterStatus,
): Promise<void> {
    return instance
        .request({
            url: urls.status,
            method: "POST",
            data: {
                managerId,
                stationMode,
                printerStatus,
            },
        })
        .then((response) => response.data);
}

export function assign(
    ipAddress: string,
    stationMode: posModels.StationMode,
): Promise<void> {
    return instance
        .request({
            url: urls.assign,
            method: "POST",
            data: {
                ipAddress,
                stationMode,
            },
        })
        .then((response) => response.data);
}

export function config(
    managerId: string,
    stationMode: posModels.StationMode,
    backups: string[],
): Promise<void> {
    return instance
        .request({
            url: urls.config,
            method: "POST",
            data: {
                managerId,
                stationMode,
                backups,
            },
        })
        .then((response) => response.data);
}

export function printReceipt(
    orderId: string,
    stationMode: posModels.StationMode,
): Promise<void> {
    return instance
        .request({
            url: urls.printReceipt,
            method: "POST",
            data: {orderId, stationMode},
        })
        .then((response) => response.data);
}
