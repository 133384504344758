import * as routeHelpers from "../../lib/routeHelpers";
import arrowIcon from "../../images/arrow-in-circle.svg";
import cn from "classnames";
import debounce from "lodash/debounce";
import describeNetworkError from "../../lib/describeNetworkError";
import fundraiserIcon from "../../images/fundraiser-icon.svg";
import fundraiserSuccessIcon from "../../images/fundraiser-success-icon.svg";
import fundraiserErrorIcon from "../../images/fundraiser-error-icon.svg";
import OutlineButton from "../OutlineButton";
import PaymentHeader from "../PaymentHeader";
import React, {
    FormEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import StyledKeyboard from "../StyledKeyboard";
import styles from "./Fundraiser.module.css";
import TextInputWithResetButton from "../TextInputWithResetButton";
import {Actions} from "habit-core";
import {useAppDispatch} from "../../lib/hooks";
import {useExternalInput} from "../../lib/api/useExternalInput";
import {useLocation, useNavigate} from "react-router-dom";

const strings = {
    fundraiser: "Fundraiser",
    scanCode: "Scan Code",
    instruction:
        "Please scan the fundraiser barcode or manually enter the barcode number.",
    notWorking: "Code Not Working?",
    enterCode: "Enter Code Manually",
    submit: "Submit",
    enterFundraiserCodeInput: "Enter Fundraiser Code Here",
    valid: "Fundraiser Code Is Valid!",
    codeApplied: "The code has successfully been applied to this order.",
    error: "This Code Could Not Be Validated.",
    ok: "OK",
    returnToCheckout: "Return To Checkout",
};

type NameState = {
    name?: string;
};

export default function Fundraiser() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as NameState;
    const customerName = locationState?.name ?? null;

    const [fundraiserCode, setFundraiserCode] = useState<string | null>(null);
    const {externalInput: externalFundraiserCode, clearExternalInput} =
        useExternalInput(fundraiserCode === null, true, false);
    const [validatingFundraiserCode, setValidatingFundraiserCode] =
        useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccessScreen, setShowSuccessScreen] = useState(false);
    const [showFailureScreen, setShowFailureScreen] = useState(false);

    /* keyboard */
    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current && !keyboardCollapsed) {
            if (document.activeElement === inputRef.current) {
                inputRef?.current?.scrollIntoView();
            }
        }
    }, [keyboardCollapsed]);

    useEffect(() => {
        if (fundraiserCode === "" && inputRef.current) {
            inputRef.current.focus();
            setKeyboardCollapsed(false);
        }
    }, [fundraiserCode]);

    const onSubmitFundraiserCode = (fundraiserCode: string | null) => {
        if (!fundraiserCode) {
            return;
        }

        setValidatingFundraiserCode(true);
        dispatch(
            Actions.currentOrderActions.validateFundraiserCode(fundraiserCode),
        )
            .then(() => {
                setShowSuccessScreen(true);
            })
            .catch((err) => {
                setShowFailureScreen(true);
                setErrorMessage(describeNetworkError(err).join("\n"));
                clearExternalInput();
            })
            .finally(() => {
                setValidatingFundraiserCode(false);
            });
    };

    const debouncedOnSubmitFundraiserCode = useCallback(
        debounce(onSubmitFundraiserCode, 300),
        [],
    );

    useEffect(() => {
        debouncedOnSubmitFundraiserCode(externalFundraiserCode);
    }, [externalFundraiserCode]);

    const onSubmitFundraiserCodeForm = (e: FormEvent) => {
        e.preventDefault();
        debouncedOnSubmitFundraiserCode(fundraiserCode);
    };

    function renderFundraiserInputScreen() {
        return (
            <>
                <img className={styles.image} src={fundraiserIcon} alt="" />
                <div className={styles.mainText}>{strings.scanCode}</div>
                <div className={styles.instructionText}>
                    {strings.instruction}
                </div>
                <div className={styles.notWorking}>{strings.notWorking}</div>
                {fundraiserCode === null ? (
                    <OutlineButton
                        className={styles.enterFundraiserCodeButton}
                        label={strings.enterCode}
                        onClick={() => {
                            setFundraiserCode("");
                        }}
                        type="button"
                        loading={validatingFundraiserCode}
                    />
                ) : (
                    <form
                        className={styles.inputContainer}
                        onSubmit={onSubmitFundraiserCodeForm}>
                        <TextInputWithResetButton
                            ref={inputRef}
                            value={fundraiserCode}
                            setValue={(value: string) => {
                                setFundraiserCode(value);
                                setErrorMessage("");
                            }}
                            onFocus={() => setKeyboardCollapsed(false)}
                            placeholder={strings.enterFundraiserCodeInput}
                            required={true}
                        />
                        <OutlineButton
                            className={styles.submitButton}
                            label={strings.submit}
                            type="submit"
                            disabled={!fundraiserCode}
                            loading={validatingFundraiserCode}
                        />
                    </form>
                )}
            </>
        );
    }

    function renderFundraiserSuccessScreen() {
        return (
            <>
                <img
                    className={styles.image}
                    src={fundraiserSuccessIcon}
                    alt=""
                />
                <div className={styles.mainText}>{strings.valid}</div>
                <div className={styles.instructionText}>
                    {strings.codeApplied}
                </div>
                <OutlineButton
                    className={styles.dismissButton}
                    label={strings.returnToCheckout}
                    onClick={() => {
                        navigate(routeHelpers.payment(), {
                            state: {
                                name: customerName,
                            },
                        });
                    }}
                />
            </>
        );
    }

    function resetState(navToCheckout = false) {
        clearExternalInput();
        setShowFailureScreen(false);
        setShowSuccessScreen(false);
        setFundraiserCode(null);
        setErrorMessage("");
        if (navToCheckout) {
            navigate(routeHelpers.payment(), {
                state: {
                    name: customerName,
                },
            });
        }
    }

    function renderFundraiserFailureScreen() {
        return (
            <>
                <img
                    className={styles.image}
                    src={fundraiserErrorIcon}
                    alt=""
                />
                <div className={styles.mainText}>{strings.error}</div>
                <div className={styles.instructionText}>{errorMessage}</div>
                <OutlineButton
                    className={styles.dismissButton}
                    label={strings.ok}
                    loading={false}
                    onClick={() => resetState()}
                />
            </>
        );
    }

    function renderContent() {
        if (showSuccessScreen) {
            return renderFundraiserSuccessScreen();
        } else if (showFailureScreen) {
            return renderFundraiserFailureScreen();
        } else {
            return renderFundraiserInputScreen();
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <PaymentHeader
                    showCheckoutReturnPrompt={false}
                    customerName={customerName}
                />
                <div className={styles.backPrompt}>
                    <button
                        className={styles.backPromptButton}
                        onClick={() =>
                            navigate(routeHelpers.payment(), {
                                state: {
                                    name: customerName,
                                },
                            })
                        }>
                        <img src={arrowIcon} alt="" />
                        <div className={styles.backPromptText}>
                            {strings.fundraiser}
                        </div>
                    </button>
                </div>
            </div>

            <div className={styles.contentContainer}>{renderContent()}</div>
            <div
                className={cn(
                    styles.keyboardContainer,
                    keyboardCollapsed && styles.keyboardContainerCollapsed,
                )}>
                <StyledKeyboard
                    currentInput={fundraiserCode ?? ""}
                    visible={!keyboardCollapsed}
                    setVisible={(val: boolean) => setKeyboardCollapsed(!val)}
                    onChange={setFundraiserCode}
                    onPressEnter={() => {
                        debouncedOnSubmitFundraiserCode(fundraiserCode);
                    }}
                    inputRefs={[inputRef]}
                    layout={"numeric"}
                />
            </div>
        </div>
    );
}
