import Drawer from "../Drawer";
import OutlineButton from "../OutlineButton";
import React from "react";
import registerIcon from "../../images/register.svg";
import styles from "./OpenRegisterDrawer.module.css";
import TextButton from "../TextButton";

const strings = {
    no: "No, Thanks",
    openRegister: "Open Register’s Drawer",
    prompt: "Open The Register’s Drawer?",
    instructions1: "By continuing forward with this action,",
    instructions2: "this register’s drawer will be open and the",
    instructions3: "action will be logged.",
};

type Props = {
    onClose: () => void;
    onOpenRegister: () => void;
    loading?: boolean;
};

export default function OpenRegisterDrawer(props: Props) {
    return (
        <Drawer onClose={props.onClose} isSmall={true}>
            <div className={styles.closedContainer}>
                <div className={styles.innerContainer}>
                    <img className={styles.icon} src={registerIcon} alt="" />
                    <div className={styles.prompt}>{strings.prompt}</div>
                    <div className={styles.instructions}>
                        {strings.instructions1}
                    </div>
                    <div className={styles.instructions}>
                        {strings.instructions2}
                    </div>
                    <div className={styles.instructions}>
                        {strings.instructions3}
                    </div>
                </div>
                <div className={styles.buttonsContainer}>
                    <OutlineButton
                        className={styles.openButton}
                        mode="blue"
                        onClick={props.onOpenRegister}
                        loading={props.loading}>
                        <div className={styles.buttonLabel}>
                            {strings.openRegister}
                        </div>
                    </OutlineButton>
                    <TextButton
                        className={styles.buttonLabel}
                        label={strings.no}
                        onClick={props.onClose}
                    />
                </div>
            </div>
        </Drawer>
    );
}
