import cn from "classnames";
import CustomizationList from "../CustomizationList";
import logger from "../../lib/logger";
import OutlineButton from "../OutlineButton";
import React from "react";
import styles from "./SelectableCheckoutComboOrItem.module.css";
import {Lib} from "habit-core";
import {useAppSelector} from "../../lib/hooks";

const strings = {
    quantity: "Qty :",
};

type Props = {
    className?: string;
    entity: Lib.selectors.CartEntityItemOrCombo;
    selected: boolean;
    onSelect: (
        type: "item" | "combo",
        customizationId: string,
        quantity: number,
    ) => void;
    onDeSelect: (type: "item" | "combo", customizationId: string) => void;
    disabled: boolean;
};

export default function SelectableCheckoutComboOrItem({
    className,
    entity,
    selected,
    onSelect,
    onDeSelect,
    disabled,
}: Props) {
    const menuId = useAppSelector((state) => state.currentOrder.menuId);
    const itemsById = useAppSelector((state) => state.items.byId);
    const itemCustomizationsById = useAppSelector(
        (state) => state.customizations.items.byInternalId,
    );
    const comboCustomizationsById = useAppSelector(
        (state) => state.customizations.combos.byInternalId,
    );

    let priceCents = 0;
    let quantity = 1;
    let comboItems = null;
    let itemModifiers = null;
    let itemId = null;
    if (entity.type === "combo") {
        const customization = comboCustomizationsById[entity.customizationId];
        priceCents = useAppSelector((state) =>
            Lib.selectors.getComboUnitPriceCents(
                customization.comboId,
                menuId,
                customization.itemCustomizationIds.map(
                    (id) => itemCustomizationsById[id],
                ),
                state.menuItems.byMenuId,
                state.menuItemModifiers.byMenuId,
                state.menuCombos.byMenuId,
                state.combos.byId,
                false,
                false,
            ),
        );
        quantity = customization.quantity;

        comboItems = customization.itemCustomizationIds.map((id) => {
            const itemCustomization = itemCustomizationsById[id];
            const name = itemsById[itemCustomization.itemId].name;
            const modifiers = itemCustomization.modifierSelections;
            return {id, name, modifiers, itemId: itemCustomization.itemId};
        });
    } else if (entity.type === "item") {
        const customization = itemCustomizationsById[entity.customizationId];
        priceCents = useAppSelector((state) =>
            menuId
                ? state.menuItems.byMenuId[menuId][customization.itemId]
                      .priceCents
                : 0,
        );
        quantity = customization.quantity;

        itemModifiers = customization.modifierSelections;
        itemId = customization.itemId;
    }

    if (!menuId) {
        logger.warn("No menu id for current order");
        return null;
    }

    return (
        <OutlineButton
            className={cn(
                styles.container,
                selected && styles.selected,
                disabled && styles.disabled,
                className,
            )}
            mode={selected ? "solid_blue" : "blue"}
            disabled={disabled}
            onClick={
                selected
                    ? () => onDeSelect(entity.type, entity.customizationId)
                    : () =>
                          onSelect(
                              entity.type,
                              entity.customizationId,
                              quantity,
                          )
            }>
            <div className={styles.topRow}>
                <div className={styles.name}>{entity.name}</div>
                <div className={styles.price}>
                    {Lib.currency.centsToDollarString(priceCents)}
                </div>
            </div>
            <div className={styles.bottomRow}>
                <div className={styles.modifiersContainer}>
                    {itemModifiers && itemId ? (
                        <CustomizationList
                            textColor={selected ? "white" : "normal"}
                            modifierSelections={itemModifiers}
                            itemId={itemId}
                            hidePrices={true}
                            editable={false}
                            parentQuantity={quantity}
                        />
                    ) : null}

                    {comboItems
                        ? comboItems.map((c, i) => (
                              <div key={c.id}>
                                  <div
                                      className={cn({
                                          [styles.comboItemName]: true,
                                          [styles.comboItemTop]: i === 0,
                                      })}>
                                      {c.name}
                                  </div>
                                  {
                                      <CustomizationList
                                          textColor={
                                              selected ? "white" : "normal"
                                          }
                                          modifierSelections={c.modifiers}
                                          itemId={c.itemId}
                                          hidePrices={true}
                                          editable={false}
                                      />
                                  }
                              </div>
                          ))
                        : null}
                </div>
                <div className={styles.quantity}>
                    {strings.quantity} {quantity}
                </div>
            </div>
        </OutlineButton>
    );
}
