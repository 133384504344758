import * as posModels from "./posModels";
import instance from "./instance";
import {API, Lib} from "habit-core";
import {paymentMethods as urls} from "./urls";

export function noSale(managerId: string): Promise<void> {
    return instance
        .request({
            url: urls.noSale,
            method: "POST",
            data: {
                managerId,
            },
        })
        .then((response) => response.data);
}

export function getGiftCardBalance(
    giftCardNumber: string,
    print = false,
): Promise<{
    balanceCents: API.models.USDCents;
    eligibleForCashOut: boolean;
    cardType: posModels.CardType;
}> {
    return instance
        .request({
            url: urls.getGiftCardBalance + giftCardNumber,
            method: "GET",
            params: {
                print,
            },
        })
        .then((response) => response.data);
}

export function reduceGiftCardBalance(
    orderId: string,
    giftCardNumber: string,
    amountCents: API.models.USDCents,
): Promise<posModels.GiftCardsReduceBalanceResponse> {
    return instance
        .request({
            url: urls.reduceGiftCardBalance + giftCardNumber,
            method: "POST",
            data: {
                amount: Lib.currency.centsToDollarFloat(amountCents),
                orderId,
            },
        })
        .then((response) => response.data);
}

export function cashOutGiftCard(giftCardNumber: string): Promise<void> {
    return instance
        .request({
            url: urls.cashOutGiftCard(giftCardNumber),
            method: "POST",
        })
        .then((response) => response.data);
}

export function increaseGiftCardBalance(
    orderId: string,
    giftCards: {cardNumber: string; amountCents: API.models.USDCents}[],
): Promise<posModels.GiftCardsIncreaseBalanceResponse> {
    return instance
        .request({
            url: urls.addFundsGiftCard,
            method: "POST",
            data: {
                orderId,
                giftCards: giftCards.map((gc) => ({
                    ...gc,
                    amount: Lib.currency.centsToDollarFloat(gc.amountCents),
                })),
            },
        })
        .then((response) => response.data);
}
