import cn from "classnames";
import Modal from "../Modal";
import OutlineButton from "../OutlineButton";
import React from "react";
import styles from "./TextModal.module.css";

const strings = {
    cancel: "Cancel",
    submit: "Submit",
};

type Props = {
    className?: string;
    overlayClassName?: string;
    title: string;
    content: string | React.ReactNode;
    onClose: () => void;
    cancelLabel?: string;
    onSubmit?: () => void;
    submitLabel?: string;
    submitLoading?: boolean;
    submitMode?: "blue" | "solid_blue" | "white" | "red";
};

export default function TextModal(props: Props) {
    return (
        <Modal
            onClose={props.submitLoading ? undefined : props.onClose}
            className={cn(styles.modal, props.className)}
            overlayClassName={props.overlayClassName}>
            <div className={styles.container}>
                <div className={styles.title}>{props.title}</div>
                <div className={styles.content}>{props.content}</div>
                <div className={styles.buttons}>
                    <OutlineButton
                        label={props.cancelLabel ?? strings.cancel}
                        onClick={props.onClose}
                        disabled={props.submitLoading}
                    />
                    {props.onSubmit ? (
                        <OutlineButton
                            className={styles.submit}
                            mode={props.submitMode ?? "white"}
                            label={props.submitLabel ?? strings.submit}
                            onClick={props.onSubmit}
                            loading={props.submitLoading}
                        />
                    ) : null}
                </div>
            </div>
        </Modal>
    );
}
