import {API} from "habit-core";
import React from "react";

interface Props extends React.ComponentProps<"img"> {
    className?: string;
    retinaImages: API.models.Image[];
}

export default function RetinaImage({className, retinaImages, ...rest}: Props) {
    if (retinaImages.length === 0) {
        return <div className={className}></div>;
    }

    return (
        <img
            {...rest}
            src={retinaImages[0].url}
            srcSet={retinaImages
                .map((img) => `${img.url} ${img.scale}x`)
                .join(", ")}
            className={className}
            style={
                import.meta.env.REACT_APP_MOCK_API === "true"
                    ? {opacity: 0.5}
                    : undefined
            }
        />
    );
}
