import * as posModels from "../../lib/api/posModels";
import * as printers from "../../lib/api/printers";
import cn from "classnames";
import describeNetworkError from "../../lib/describeNetworkError";
import Drawer from "../Drawer";
import ErrorModal from "../ErrorModal";
import logger from "../../lib/logger";
import OrderDetailsListItem from "../OrderDetailsListItem";
import OutlineButton from "../OutlineButton";
import React, {useMemo, useState} from "react";
import styles from "./OrderDetailsDrawer.module.css";
import TextModal from "../TextModal";
import {API, Lib} from "habit-core";
import {useAppSelector} from "../../lib/hooks";

const strings = {
    detailsHeader: (orderNumber: number) => `Order #${orderNumber}`,
    orderStarted: "Order Started :",
    cancelled: "Order Cancelled",
    fulfilled: "Order Fulfilled",
    orderDetails: "Order Details",
    rePrint: "Re-Print",
    print: "Print",
    requestSent: "Request Sent",
    confirmPrinted:
        "Your request to print this order's receipt has been submitted",
    ok: "OK",
    cash: "Cash",
    giftCard: "Gift Card",
    creditCard: "Credit Card",
    compCard: "Comp Card",
    unknown: "Unknown",
    guestNameLabel: "Guest Name: ",
    pagerNumberLabel: "Pager #",
    gcDetailsString: (quantity: number, amountCents: API.models.USDCents) =>
        `${quantity}X ${Lib.currency.centsToDollarString(
            Math.abs(amountCents),
        )} ${amountCents < 0 ? "gift card cash out" : "gift card"}`,
    failedToLoad: (quantity: number) => ` - ${quantity}X FAILED TO LOAD VALUE`,
};

type Props = {
    order: posModels.OrderParsed;
    onClose: () => void;
    showReprintButton?: boolean;
    showPrintButton?: boolean;
};

export default function OrderDetailsDrawer(props: Props) {
    const [loading, setLoading] = useState(false);
    const [confirmPrinted, setConfirmPrinted] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const currentStation = useAppSelector((state) => state.pos.station);

    const giftCardsAmountsAndQuantity: {[amountCents: string]: number} = {};
    const giftCardsAmountsAndFailedQuantity: {[amountCents: string]: number} =
        {};
    props.order.giftCards.forEach((gcPurchaseDetails) => {
        const amountString = gcPurchaseDetails.amountCents.toString();
        const currentQuantity = giftCardsAmountsAndQuantity[amountString] ?? 0;
        giftCardsAmountsAndQuantity[amountString] = currentQuantity + 1;
        if (gcPurchaseDetails.cardNumber === null) {
            const currentFailedQuantity =
                giftCardsAmountsAndFailedQuantity[amountString] ?? 0;
            giftCardsAmountsAndFailedQuantity[amountString] =
                currentFailedQuantity + 1;
        }
    });

    function onPrint() {
        if (loading || !currentStation.mode) {
            return;
        }

        setLoading(true);
        printers
            .printReceipt(props.order.id, currentStation.mode)
            .then(() => {
                setLoading(false);
                setConfirmPrinted(true);
            })
            .catch((err) => {
                logger.warn(err);
                const description = describeNetworkError(err);
                setErrorMessage(description.join("\n"));
                setLoading(false);
            });
    }

    function getPaymentLabel(tender: posModels.TransactionTender) {
        switch (tender) {
            case "cash":
                return strings.cash;
            case "credit":
                return strings.creditCard;
            case "gift_card":
                return strings.giftCard;
            case "comp_card":
                return strings.compCard;
            default:
                return strings.unknown;
        }
    }

    function renderFulfilledDate() {
        let date = null;
        if (props.order.status === "cancelled" && props.order.cancelledTime) {
            date = props.order.cancelledTime;
        } else if (props.order.status === "paid" && props.order.pickupTime) {
            date = props.order.pickupTime;
        }

        return date ? (
            <div>{Lib.dates.format(date, "MM/DD/YY [at] hh:mma")}</div>
        ) : null;
    }

    const guestNameOrPagerNumber = useMemo(() => {
        let content: string | undefined = undefined;
        if (props.order.guestName) {
            content = strings.guestNameLabel + props.order.guestName;
        } else if (props.order.pagerNumber) {
            content = strings.pagerNumberLabel + props.order.pagerNumber;
        }
        return content;
    }, [props.order.guestName, props.order.pagerNumber]);
    return (
        <>
            <Drawer
                onClose={() => {
                    if (!loading) {
                        props.onClose();
                    }
                }}
                isSmall={true}>
                <div
                    className={cn(
                        styles.detailsContainer,
                        props.showPrintButton
                            ? styles.detailsContainerWithFooter
                            : null,
                    )}>
                    <div className={styles.detailsHeader}>
                        <div
                            className={cn(
                                styles.detailsTitle,
                                styles.detailsOrderNumber,
                            )}>
                            {strings.detailsHeader(props.order.orderNumber)}
                        </div>
                        {props.order.error ? (
                            <div className={styles.error}>
                                {props.order.error}
                            </div>
                        ) : null}
                        <div className={styles.startedContainer}>
                            <div className={styles.startedSection}>
                                <div className={styles.detailsTitle}>
                                    {strings.orderStarted}
                                </div>
                                <div className={cn(styles.startedDate)}>
                                    <div>
                                        {Lib.dates.format(
                                            props.order.orderDate,
                                            "MM/DD/YY [at] hh:mma",
                                        )}
                                    </div>
                                    {guestNameOrPagerNumber ? (
                                        <div>{guestNameOrPagerNumber}</div>
                                    ) : null}
                                </div>
                                {props.order.transactions.length ? (
                                    <div className={styles.paymentSection}>
                                        {props.order.transactions.map((t) => (
                                            <div
                                                key={t.id}
                                                className={cn(
                                                    styles.paymentRow,
                                                    styles.detailsTitle,
                                                )}>
                                                <div>
                                                    {getPaymentLabel(t.tender)}
                                                </div>
                                                <div>
                                                    {Lib.currency.centsToDollarString(
                                                        t.amountCents,
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                                {props.order.discounts.length ? (
                                    <div className={styles.paymentSection}>
                                        {props.order.discounts.map((d) => (
                                            <div
                                                key={d.id}
                                                className={cn(
                                                    styles.paymentRow,
                                                    styles.detailsTitle,
                                                )}>
                                                <div>{d.name}</div>
                                                <div>
                                                    {Lib.currency.centsToDollarString(
                                                        d.priceCents,
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                            </div>
                            {props.showReprintButton ? (
                                <OutlineButton
                                    className={styles.rePrintButton}
                                    label={strings.rePrint}
                                    onClick={onPrint}
                                    loading={loading}
                                />
                            ) : (
                                <div className={styles.startedSection}>
                                    <div className={styles.detailsTitle}>
                                        {props.order.status === "paid"
                                            ? `${strings.fulfilled} :`
                                            : null}
                                        {props.order.status === "cancelled"
                                            ? `${strings.cancelled} :`
                                            : null}
                                    </div>
                                    <div className={styles.startedDate}>
                                        {renderFulfilledDate()}
                                        {props.order.cancelledManagerName ? (
                                            <div>
                                                {
                                                    props.order
                                                        .cancelledManagerName
                                                }
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={styles.detailsContent}>
                        <div
                            className={cn(
                                styles.detailsTitle,
                                styles.itemsHeader,
                            )}>
                            {strings.orderDetails}
                        </div>
                        {props.order.combos.map((c) => (
                            <OrderDetailsListItem
                                key={c.id}
                                type="combo"
                                entity={c}
                            />
                        ))}
                        {props.order.items.map((i) => (
                            <OrderDetailsListItem
                                key={i.id}
                                type="item"
                                entity={i}
                            />
                        ))}
                        {props.order.giftCards.length
                            ? Object.keys(giftCardsAmountsAndQuantity).map(
                                  (gcAmountCentsStr, index) => {
                                      return (
                                          <div
                                              className={
                                                  styles.gcPurchaseDetails
                                              }
                                              key={index}>
                                              {strings.gcDetailsString(
                                                  giftCardsAmountsAndQuantity[
                                                      gcAmountCentsStr
                                                  ],
                                                  +gcAmountCentsStr,
                                              )}
                                              {(giftCardsAmountsAndFailedQuantity[
                                                  gcAmountCentsStr
                                              ] ?? 0) !== 0
                                                  ? strings.failedToLoad(
                                                        giftCardsAmountsAndFailedQuantity[
                                                            gcAmountCentsStr
                                                        ],
                                                    )
                                                  : ""}
                                          </div>
                                      );
                                  },
                              )
                            : null}
                    </div>
                </div>
                {props.showPrintButton ? (
                    <div className={styles.detailsFooter}>
                        <OutlineButton
                            className={styles.detailsPrint}
                            label={strings.print}
                            onClick={onPrint}
                            loading={loading}
                        />
                    </div>
                ) : null}
            </Drawer>
            {confirmPrinted ? (
                <TextModal
                    onClose={() => setConfirmPrinted(false)}
                    title={strings.requestSent}
                    content={strings.confirmPrinted}
                    cancelLabel={strings.ok}
                />
            ) : null}
            {errorMessage ? (
                <ErrorModal
                    errorMessage={errorMessage}
                    onClose={() => setErrorMessage("")}
                    showITInfo
                />
            ) : null}
        </>
    );
}
