import {useCallback, useEffect, useState} from "react";

// see https://myelo.elotouch.com/support/s/article/MSR-E001002-Scan-Errors-and-Inconsistencies
const BAD_TRACK_1_TEXT = "%E?";
const BAD_TRACK_2_TEXT = ";E?";

const badReadError = {
    title: "Card Read Error",
    message:
        "Unable to read card. Please try again and ensure you swipe the entire card straight through the reader.",
    type: "info" as const,
};

export function useExternalInput(
    enabled: boolean,
    acceptNonNumbers = false,
    checkForBadRead = true,
) {
    const [externalInput, setExternalInput] = useState("");
    const [last3Seen, setLast3Seen] = useState("");
    const [cardReadError, setCardReadError] = useState<{
        title: string;
        message: string;
        type: "info" | "warning";
    } | null>(null);

    const handleExternalInput = useCallback(
        (e: KeyboardEvent) => {
            if (enabled) {
                setExternalInput((prevexternalInput) => {
                    // ignore non alpha numeric keystrokes (e.g Shift, Clear, etc)
                    if (e.key.length > 1) {
                        return prevexternalInput;
                    }

                    if (acceptNonNumbers) {
                        return prevexternalInput.concat(e.key);
                    }

                    return prevexternalInput
                        .concat(e.key)
                        .replace(new RegExp(/\D+/g), "");
                });

                if (checkForBadRead) {
                    // the bad track characters all have a length of 1
                    if (e.key.length === 1) {
                        if (last3Seen.length < 3) {
                            setLast3Seen(
                                (prevLast3Seen) => prevLast3Seen + e.key,
                            );
                        } else {
                            setLast3Seen(
                                (prevLast3Seen) =>
                                    prevLast3Seen.slice(1) + e.key,
                            );
                        }
                    }
                }
            }
        },
        [enabled],
    );

    //clear out card number when disabled
    useEffect(() => {
        if (!enabled) {
            setExternalInput("");
        }
    }, [enabled]);

    useEffect(() => {
        document.addEventListener("keydown", handleExternalInput);
        return () => {
            document.removeEventListener("keydown", handleExternalInput);
        };
    }, [handleExternalInput]);

    useEffect(() => {
        if (last3Seen === BAD_TRACK_1_TEXT || last3Seen === BAD_TRACK_2_TEXT) {
            setCardReadError(badReadError);
        }
    }, [last3Seen]);

    const clearExternalInput = () => {
        setExternalInput("");
        setLast3Seen("");
        setCardReadError(null);
    };

    return {externalInput, clearExternalInput, cardReadError};
}
