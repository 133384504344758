import addIcon from "../../images/add.svg";
import addIconGrey from "../../images/add-grey.svg";
import addIconWhite from "../../images/add-white.svg";
import closeIcon from "../../images/close.svg";
import cn from "classnames";
import easyIcon from "../../images/easy.svg";
import easyIconGrey from "../../images/easy-grey.svg";
import easyIconWhite from "../../images/easy-white.svg";
import extraIcon from "../../images/extra.svg";
import extraIconGrey from "../../images/extra-grey.svg";
import extraIconWhite from "../../images/extra-white.svg";
import EditQuantityButtons from "../EditQuantityButtons";
import includedIcon from "../../images/included.svg";
import includedIconGrey from "../../images/included-grey.svg";
import includedIconWhite from "../../images/included-white.svg";
import logger from "../../lib/logger";
import noneIcon from "../../images/none.svg";
import noneIconGrey from "../../images/none-grey.svg";
import noneIconWhite from "../../images/none-white.svg";
import onlyIcon from "../../images/only.svg";
import onlyIconGrey from "../../images/only-grey.svg";
import onlyIconWhite from "../../images/only-white.svg";
import onSideIcon from "../../images/on-side.svg";
import onSideIconGrey from "../../images/on-side-grey.svg";
import onSideIconWhite from "../../images/on-side-white.svg";
import OutlineButton from "../OutlineButton";
import React from "react";
import sortBy from "lodash.sortby";
import styles from "./CustomizationList.module.css";
import subIcon from "../../images/sub.svg";
import subIconGrey from "../../images/sub-grey.svg";
import subIconWhite from "../../images/sub-white.svg";
import {API, Constants, Lib} from "habit-core";
import {useAppSelector} from "../../lib/hooks";

const strings = {
    add: "Add",
    sub: "Sub",
    none: "No",
    easy: "Easy",
    only: "Only",
    included: "Incl",
    extraSelectionName: "Extra",
    extraDisplayed: "Xtra",
    onSide: "Ots",
};

const icons = {
    add: {
        normal: addIcon,
        white: addIconWhite,
        grey: addIconGrey,
    },
    sub: {
        normal: subIcon,
        white: subIconWhite,
        grey: subIconGrey,
    },
    none: {
        normal: noneIcon,
        white: noneIconWhite,
        grey: noneIconGrey,
    },
    easy: {
        normal: easyIcon,
        white: easyIconWhite,
        grey: easyIconGrey,
    },
    extra: {
        normal: extraIcon,
        white: extraIconWhite,
        grey: extraIconGrey,
    },
    included: {
        normal: includedIcon,
        white: includedIconWhite,
        grey: includedIconGrey,
    },
    onSide: {
        normal: onSideIcon,
        white: onSideIconWhite,
        grey: onSideIconGrey,
    },
    only: {
        normal: onlyIcon,
        white: onlyIconWhite,
        grey: onlyIconGrey,
    },
};

type Props = {
    className?: string;
    modifierSelections: API.models.ModifierSelectionDict;
    itemId: string;
    onChangeModifier?: (
        modifierId: string,
        selectionId: string,
        quantity: number,
        isDefault: boolean,
        isRequired: boolean,
        itemId?: string,
    ) => void;
    hidePrices?: boolean;
    textColor: "normal" | "white" | "grey";
    editable: boolean;
    parentQuantity?: number;
    showModifierQuantityString?: boolean;
};

export default function CustomizationList(props: Props) {
    const menuId = useAppSelector((state) => state.currentOrder.menuId);

    const itemsById = useAppSelector((state) => state.items.byId);
    const modifiers = useAppSelector((state) => state.modifiers.byId);
    const selections = useAppSelector((state) => state.modifierSelections.byId);
    const allPrepsModifiers = useAppSelector((state) => state.pos.allPreps);
    const currentMenuItemModifiers = useAppSelector((state) =>
        menuId ? state.menuItemModifiers.byMenuId[menuId][props.itemId] : {},
    );

    if (!menuId) {
        logger.warn("No menu id for current order");
        return null;
    }

    function getTypeIcon(
        type: API.models.MenuItemModifierSelectionType | null,
    ) {
        switch (type) {
            case "add":
                return <img src={icons.add[props.textColor]} />;
            case "sub":
                return <img src={icons.sub[props.textColor]} />;
            case "none":
                return <img src={icons.none[props.textColor]} />;
            case "easy":
                return <img src={icons.easy[props.textColor]} />;
            case "extra":
                return <img src={icons.extra[props.textColor]} />;
            case "on side":
                return <img src={icons.onSide[props.textColor]} />;
            case "only":
                return <img src={icons.only[props.textColor]} />;
            case null:
                return <img src={icons.included[props.textColor]} />;
            default:
                logger.warn(`unsupported mod type ${type}`);
                return null;
        }
    }

    function getTypeName(
        type: API.models.MenuItemModifierSelectionType | null,
        selectionName?: string,
    ) {
        switch (type) {
            case "add":
                if (selectionName === strings.extraSelectionName) {
                    return strings.extraDisplayed;
                }
                return strings.add;
            case "sub":
                return strings.sub;
            case "none":
                return strings.none;
            case "easy":
                return strings.easy;
            case "extra":
                return strings.extraDisplayed;
            case "on side":
                return strings.onSide;
            case "only":
                return strings.only;
            case null:
                return strings.included;
            default:
                logger.warn(`unknown mod type ${type}`);
                return "";
        }
    }

    function getItemModifierString(
        modifierName: string,
        selectionName: string,
        type: API.models.MenuItemModifierSelectionType | null,
        quantity: number,
        showQuantity?: boolean,
    ) {
        if (type === "sub" || type === null) {
            if (showQuantity && quantity > 1) {
                return `${quantity}X - ${modifierName} - ${selectionName}`;
            } else {
                return `${modifierName} - ${selectionName}`;
            }
        } else {
            if (showQuantity && quantity > 1) {
                return `${quantity}X - ${modifierName}`;
            } else {
                return modifierName;
            }
        }
    }

    const data = Object.keys(props.modifierSelections).map((modifierId) => {
        const selectionId = props.modifierSelections[modifierId].selectionId;
        const menuItemModSelection = currentMenuItemModifiers[
            modifierId
        ]?.selections.find((s) => s.id === selectionId);
        const allPrepsModifierData = allPrepsModifiers.byId[modifierId];
        const allPrepsSelectionData = allPrepsModifierData?.selections.find(
            (selection) => selection.id === selectionId,
        );
        const selectionUnitPriceCents =
            menuItemModSelection?.priceCents ??
            allPrepsSelectionData?.priceCents ??
            0;
        const selectionPriceCents =
            selectionUnitPriceCents *
            props.modifierSelections[modifierId].quantity;
        return {
            modifierId: modifierId,
            selectionId: selectionId,
            modifierName:
                modifiers[modifierId]?.name ?? allPrepsModifierData?.name ?? "",
            selectionName:
                selections[selectionId]?.name ??
                allPrepsSelectionData?.name ??
                "",
            priceCents: selectionPriceCents * (props.parentQuantity ?? 1),

            quantity: props.modifierSelections[modifierId].quantity,
            min: menuItemModSelection?.min ?? allPrepsSelectionData?.min ?? 1,
            max: menuItemModSelection?.max ?? allPrepsSelectionData?.max ?? 1,
            isDefault: !allPrepsSelectionData
                ? currentMenuItemModifiers[modifierId]
                      ?.defaultModifierSelectionId === selectionId
                : allPrepsSelectionData?.default ?? false,
            isRequired: !!itemsById[props.itemId].modifiers.find(
                (m) =>
                    m.modifierId === modifierId &&
                    m.type === Constants.modifierType.REQUIRED_CHOICE,
            ),
            type:
                menuItemModSelection?.type ??
                allPrepsSelectionData?.type ??
                null,
        };
    });

    const sorted = sortBy(data, (x) => x.modifierName);

    return (
        <div className={props.className}>
            {sorted.map((x) => (
                <div
                    key={x.modifierId}
                    className={cn(
                        props.editable && styles.editable,
                        props.textColor === "white" && styles.whiteText,
                        props.textColor === "grey" && styles.greyText,
                    )}>
                    <div
                        className={cn(
                            styles.selectionNameRow,
                            props.editable && styles.editableNameRow,
                        )}>
                        <div className={styles.leftContainer}>
                            <div className={styles.modText}>
                                <div className={styles.typeContainer}>
                                    {getTypeIcon(x.type)}
                                    <div
                                        className={cn({
                                            [styles.typeName]: true,
                                            [styles.whiteText]:
                                                props.textColor === "white",
                                            [styles.greyText]:
                                                props.textColor === "grey",
                                            [styles.add]: x.type === "add",
                                            [styles.sub]: x.type === "sub",
                                            [styles.none]: x.type === "none",
                                            [styles.easy]: x.type === "easy",
                                            [styles.onSide]:
                                                x.type === "on side",
                                        })}>
                                        {getTypeName(x.type, x.selectionName)}
                                    </div>
                                </div>
                                <div
                                    className={cn(
                                        styles.modName,
                                        props.textColor === "white" &&
                                            styles.whiteText,
                                        props.textColor === "grey" &&
                                            styles.greyText,
                                    )}>
                                    {getItemModifierString(
                                        x.modifierName,
                                        x.selectionName,
                                        x.type,
                                        x.quantity,
                                        props.showModifierQuantityString,
                                    )}
                                </div>
                            </div>
                            {props.editable && x.min !== x.max ? (
                                <EditQuantityButtons
                                    className={styles.quantityButtons}
                                    current={x.quantity}
                                    max={x.max}
                                    onChange={(newQuantity: number) => {
                                        if (props.onChangeModifier) {
                                            props.onChangeModifier(
                                                x.modifierId,
                                                x.selectionId,
                                                newQuantity,
                                                x.isDefault,
                                                x.isRequired,
                                                props.itemId,
                                            );
                                        }
                                    }}
                                />
                            ) : null}
                        </div>
                        {/* TODO: #38 - Required modifier indicator? */}
                        <div className={styles.rightContainer}>
                            {!props.hidePrices && x.priceCents !== 0 ? (
                                <div
                                    className={cn(
                                        styles.modText,
                                        styles.price,
                                        props.editable && styles.priceEditable,
                                        props.textColor === "white" &&
                                            styles.whiteText,
                                        props.textColor === "grey" &&
                                            styles.greyText,
                                    )}>
                                    {Lib.currency.centsToDollarString(
                                        x.priceCents,
                                    )}
                                </div>
                            ) : (
                                <div className={styles.spacer} />
                            )}
                            {!props.editable || x.isDefault ? null : (
                                <OutlineButton
                                    className={styles.removeModButton}
                                    onClick={() => {
                                        let defaultSelectionId = "";
                                        const menuItemMod =
                                            currentMenuItemModifiers[
                                                x.modifierId
                                            ];
                                        if (menuItemMod) {
                                            defaultSelectionId =
                                                menuItemMod.defaultModifierSelectionId;
                                        } else {
                                            const allPrepsModSelection =
                                                allPrepsModifiers.byId[
                                                    x.modifierId
                                                ].selections;

                                            if (allPrepsModSelection) {
                                                defaultSelectionId =
                                                    allPrepsModSelection.find(
                                                        (selection) =>
                                                            selection.default,
                                                    )?.id ?? "";
                                            }
                                        }
                                        if (props.onChangeModifier) {
                                            props.onChangeModifier(
                                                x.modifierId,
                                                defaultSelectionId,
                                                1,
                                                true,
                                                x.isRequired,
                                                props.itemId,
                                            );
                                        }
                                    }}>
                                    <img
                                        className={styles.removeModIcon}
                                        src={closeIcon}
                                        alt=""
                                    />
                                </OutlineButton>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

CustomizationList.defaultProps = {
    textColor: "normal",
};
