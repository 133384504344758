import flatten from "lodash.flatten";
import {AxiosError} from "axios";

const strings = {
    noNetwork:
        "Unable to reach the server. Please check the network connection and try again.",
    genericValidation:
        "An error has occurred. Please double check the form and try again.",
    authorizationError: "You are not authorized to make this request.",
    serverError: "An unexpected server error has occurred. Please try again.",
};

function parseErrorsResponse(
    genericError: string,
    data?: {errors?: {[key: string]: string}},
): string[] {
    if (data && data.errors) {
        const strs = Object.values(data.errors);
        const flattened = flatten(strs);
        if (flattened.length > 0) {
            return flattened;
        }
    }

    return [genericError];
}

export default function describeNetworkError(err: AxiosError) {
    if (!err.response) {
        return [strings.noNetwork];
    }

    if (err.response.status === 400) {
        return parseErrorsResponse(
            strings.genericValidation,
            err.response.data,
        );
    }

    if (err.response.status === 401) {
        return [strings.authorizationError];
    }

    return parseErrorsResponse(strings.serverError, err.response.data);
}
