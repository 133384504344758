import * as routeHelpers from "../../lib/routeHelpers";
import DateDropdown from "../DateDropdown";
import describeNetworkError from "../../lib/describeNetworkError";
import Drawer from "../Drawer";
import ErrorModal from "../ErrorModal";
import React, {useState, useMemo, useEffect, useContext} from "react";
import Spinner from "../Spinner";
import styles from "./ManagerReportsDrawer.module.css";
import {CashierModeContext} from "../CashierModeContext";
import {subDays} from "date-fns";
import {useNavigate} from "react-router-dom";

const strings = {
    noData: "No Data to Show",
};

type Props<T> = {
    title: string;
    children: (data: T) => React.ReactNode;
    getData: (d: Date, isTraining?: boolean) => Promise<T>;
};

const NUM_OPTIONS = 14;

export default function ManagerReportsDrawer<T>(props: Props<T>) {
    const navigate = useNavigate();
    const {isTraining} = useContext(CashierModeContext);

    const options = useMemo(() => {
        const now = new Date();
        return [...Array(NUM_OPTIONS)].map((_, i) => subDays(now, i));
    }, []);

    const [selectedDateIndex, setSelectedDateIndex] = useState(0);

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [data, setData] = useState<T | null>(null);

    useEffect(() => {
        if (loading) {
            return;
        }

        setLoading(true);
        props
            .getData(options[selectedDateIndex], isTraining)
            .then((d) => setData(d))
            .catch((err) =>
                setErrorMessage(describeNetworkError(err).join("\n")),
            )
            .finally(() => setLoading(false));
    }, [selectedDateIndex]);

    function renderContent() {
        if (loading) {
            return (
                <div className={styles.contentContainer}>
                    <Spinner />
                </div>
            );
        }
        if (data === null) {
            return (
                <div className={styles.contentContainer}>{strings.noData}</div>
            );
        }
        return props.children(data);
    }

    return (
        <Drawer onClose={() => navigate(routeHelpers.reports())}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <DateDropdown
                        className={styles.dropdown}
                        title={props.title}
                        onChange={(i) => setSelectedDateIndex(i)}
                        value={selectedDateIndex}
                        options={options}
                    />
                </div>
                {renderContent()}
                {errorMessage ? (
                    <ErrorModal
                        errorMessage={errorMessage}
                        onClose={() => navigate(routeHelpers.reports())}
                        showITInfo
                    />
                ) : null}
            </div>
        </Drawer>
    );
}
