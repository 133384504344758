import cn from "classnames";
import React from "react";
import styles from "./Modal.module.css";

type Props = {
    className?: string;
    overlayClassName?: string;
    onClose?: () => void;
    children: React.ReactNode;
};

export default function Modal(props: Props) {
    return (
        <>
            <button
                className={cn(styles.overlay, props.overlayClassName)}
                onClick={props.onClose}
            />
            <div className={cn(styles.container, props.className)}>
                {props.children}
            </div>
        </>
    );
}
