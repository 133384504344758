import cn from "classnames";
import minusGreyIcon from "../../images/minus-grey.svg";
import minusIcon from "../../images/minus.svg";
import plusGreyIcon from "../../images/plus-grey.svg";
import plusIcon from "../../images/plus.svg";
import React from "react";
import styles from "./EditQuantityButtons.module.css";

const strings = {
    quantity: "QTY",
};

type Props = {
    className?: string;
    current: number;
    max: number;
    onChange: (newQuantity: number) => void;
};

export default function EditQuantityButtons(props: Props) {
    return (
        <div className={cn(styles.container, props.className)}>
            <div className={styles.quantity}>{strings.quantity}</div>
            <div className={styles.quantityControls}>
                <button
                    className={styles.quantityButton}
                    onClick={() => props.onChange(props.current - 1)}
                    disabled={props.current === 1}>
                    <img
                        src={props.current === 1 ? minusGreyIcon : minusIcon}
                        alt=""
                    />
                </button>
                <div className={styles.quantityAmount}>{props.current}</div>
                <button
                    className={styles.quantityButton}
                    onClick={() => props.onChange(props.current + 1)}
                    disabled={props.current === props.max}>
                    <img
                        src={
                            props.current === props.max
                                ? plusGreyIcon
                                : plusIcon
                        }
                        alt=""
                    />
                </button>
            </div>
        </div>
    );
}
