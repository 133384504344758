import * as posActions from "../../lib/posActions";
import * as routeHelpers from "../../lib/routeHelpers";
import * as searchParamNames from "../../constants/searchParamNames";
import AddCashAmount from "../AddCashAmount";
import addIcon from "../../images/add.svg";
import cn from "classnames";
import GiftCardsDrawer from "../GiftCardsDrawer";
import OutlineButton from "../OutlineButton";
import React, {useState, useEffect, useRef} from "react";
import StyledKeyboard from "../StyledKeyboard";
import styles from "./GiftCardsAddFunds.module.css";
import useOnChangeCashAmount from "../../lib/useOnChangeCashAmount";
import {API, Lib} from "habit-core";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../lib/hooks";

const MAX_GC_AMOUNT_CENTS = parseInt(
    import.meta.env.REACT_APP_MAX_GIFT_CARD_VALUE_CENTS ?? "",
);

const MAX_GC_AMOUNT_STRING =
    Lib.currency.centsToDollarString(MAX_GC_AMOUNT_CENTS);

const strings = {
    addAmount: "Add Amount To Gift Card Value",
    add: "Add",
    giftCardValue: "Gift Card Value",
    addToCard: "Add To Card",
    maxAmountExceeded: (maxAmount: API.models.USDCents) =>
        `Gift card balance cannot exceed ${MAX_GC_AMOUNT_STRING}. The max value you can add to this card is ${Lib.currency.centsToDollarString(
            maxAmount,
        )}.`,
};

type GiftCardsAddFundsState = {
    cardNumber: string;
    originalBalanceCents: API.models.USDCents;
};

const AMOUNT_OPTIONS_CENTS = [100, 500, 1000, 2000, 5000, 10000];

export default function GiftCardsAddFunds() {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const locationState = location.state as GiftCardsAddFundsState | undefined;
    const [searchParams] = useSearchParams();
    const gcid = searchParams.get(searchParamNames.GIFT_CARD_ID);
    const giftCard = useAppSelector((state) =>
        gcid
            ? state.pos.currentOrder.giftCards.addFunds.find(
                  (r) => r.id === gcid,
              )
            : null,
    );
    const maxAmountToAdd =
        MAX_GC_AMOUNT_CENTS -
        (giftCard?.originalBalanceCents ??
            locationState?.originalBalanceCents ??
            0);

    const [amountToAddCents, setAmountToAddCents] =
        useState<API.models.USDCents | null>(
            giftCard ? giftCard.amountToAddCents : null,
        );

    const maxAmountExceeded = (amountToAddCents ?? 0) > maxAmountToAdd;

    const [displayedCashAmount, setDisplayedCashAmount] = useState("");
    useEffect(() => {
        const floatVal = parseFloat(displayedCashAmount);
        if (
            isNaN(floatVal) ||
            amountToAddCents !== Lib.currency.dollarsFloatToCents(floatVal)
        ) {
            setDisplayedCashAmount(
                amountToAddCents
                    ? Lib.currency
                          .centsToDollarString(amountToAddCents)
                          .replace("$", "")
                    : "",
            );
        }
    }, [amountToAddCents]);

    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true);
    const cashAmountRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (cashAmountRef.current && !keyboardCollapsed) {
            if (document.activeElement === cashAmountRef.current) {
                cashAmountRef?.current?.scrollIntoView();
            }
        }
    }, [keyboardCollapsed]);

    const onChangeCashAmount = useOnChangeCashAmount(
        setDisplayedCashAmount,
        setAmountToAddCents,
    );

    const cardNumber = giftCard?.cardNumber ?? locationState?.cardNumber;
    const originalBalanceCents =
        giftCard?.originalBalanceCents ?? locationState?.originalBalanceCents;

    if (!cardNumber || originalBalanceCents === undefined) {
        return null;
    }

    return (
        <GiftCardsDrawer
            balanceCents={originalBalanceCents}
            left={
                <div className={styles.container}>
                    <div className={styles.addAmountContainer}>
                        <AddCashAmount
                            ref={cashAmountRef}
                            onFocus={() => setKeyboardCollapsed(false)}
                            label={strings.addAmount}
                            optionsCents={AMOUNT_OPTIONS_CENTS}
                            cashAmountCents={amountToAddCents}
                            setCashAmountCents={setAmountToAddCents}
                            displayedCashAmount={displayedCashAmount}
                            resetAmountOnSelectOption
                        />
                        {maxAmountExceeded ? (
                            <div className={styles.maxAmountExceededText}>
                                {strings.maxAmountExceeded(maxAmountToAdd)}
                            </div>
                        ) : null}
                    </div>
                    <div
                        className={cn(
                            styles.keyboardContainer,
                            keyboardCollapsed &&
                                styles.keyboardContainerCollapsed,
                        )}>
                        <StyledKeyboard
                            currentInput={displayedCashAmount}
                            visible={!keyboardCollapsed}
                            setVisible={(val: boolean) =>
                                setKeyboardCollapsed(!val)
                            }
                            onChange={onChangeCashAmount}
                            inputRefs={[cashAmountRef]}
                            layout="numeric"
                        />
                    </div>
                </div>
            }
            right={
                <>
                    {amountToAddCents !== null ? (
                        <div className={styles.giftCardItem}>
                            <img src={addIcon} alt="" />
                            <div className={styles.addText}>{strings.add}</div>
                            <div className={styles.valueText}>
                                {strings.giftCardValue}
                            </div>
                            <div className={styles.value}>
                                {Lib.currency.centsToDollarString(
                                    amountToAddCents,
                                )}
                            </div>
                        </div>
                    ) : null}
                    <div className={styles.buttonContainer}>
                        <div className={styles.divider} />
                        <OutlineButton
                            className={styles.button}
                            label={Lib.currency.centsToDollarString(
                                amountToAddCents ?? 0,
                            )}
                            label2={strings.addToCard}
                            disabled={
                                amountToAddCents === null ||
                                amountToAddCents > maxAmountToAdd
                            }
                            onClick={() => {
                                if (amountToAddCents === null) {
                                    return;
                                }

                                if (gcid) {
                                    dispatch(
                                        posActions.updateExistingGiftCardRequest(
                                            gcid,
                                            amountToAddCents,
                                        ),
                                    );
                                } else {
                                    dispatch(
                                        posActions.addExistingGiftCardRequest(
                                            cardNumber,
                                            originalBalanceCents,
                                            amountToAddCents,
                                        ),
                                    );
                                }
                                navigate(routeHelpers.giftCards());
                            }}
                        />
                    </div>
                </>
            }
        />
    );
}
