import OutlineButton from "../OutlineButton";
import React from "react";
import styles from "./ManagerHeader.module.css";

type Props = {
    label: string;
    extraText?: string;
    buttonLabel?: string;
    buttonOnClick?: () => void;
    buttonDisabled?: boolean;
    buttonLoading?: boolean;
};

export default function ManagerHeader(props: Props) {
    return (
        <div className={styles.header}>
            <div className={styles.label}>{props.label}</div>
            {props.extraText ? (
                <div className={styles.extraText}>{props.extraText}</div>
            ) : null}
            {props.buttonLabel ? (
                <OutlineButton
                    className={styles.button}
                    label={props.buttonLabel}
                    onClick={props.buttonOnClick}
                    disabled={props.buttonDisabled}
                    loading={props.buttonLoading}
                />
            ) : null}
        </div>
    );
}
