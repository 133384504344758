import * as posActions from "./posActions";
import * as posSelectors from "./posSelectors";
import usePrevious from "./usePrevious";
import {Lib} from "habit-core";
import {useAppDispatch, useAppSelector} from "./hooks";
import {useEffect} from "react";

export default function useUpdateBackendOrderContents(enabled: boolean) {
    const prevEnabled = usePrevious(enabled);
    const dispatch = useAppDispatch();

    /* possible order content */
    const orderData = useAppSelector((state) =>
        Lib.selectors.getMyOrderData(state, "date_added"),
    );
    const giftCardsToPurchase = useAppSelector(
        (state) => state.pos.currentOrder.giftCards.purchase,
    );
    const giftCardsToAddFunds = useAppSelector(
        (state) => state.pos.currentOrder.giftCards.addFunds,
    );

    /* order pricing info */
    const charityRoundUpAmountCents = useAppSelector((state) =>
        posSelectors.getCharityRoundUpSansVoidedCents(state, []),
    );
    const subtotalCents = useAppSelector((state) =>
        posSelectors.getCurrentOrderSubtotalSansVoidedCents(state),
    );
    const taxCents = useAppSelector((state) =>
        posSelectors.getCurrentOrderTaxSansVoidedCents(state, []),
    );
    const totalCents = subtotalCents + taxCents;

    const currentOrderStartTime = useAppSelector(
        (state) => state.pos.currentOrder.startTime,
    );
    const driveThruDetails = useAppSelector(
        (state) => state.pos.currentOrder.driveThruDetails,
    );

    useEffect(() => {
        if (
            !enabled ||
            (!prevEnabled &&
                orderData.length === 0 &&
                giftCardsToPurchase.length === 0 &&
                giftCardsToAddFunds.length === 0) ||
            currentOrderStartTime === null
        ) {
            return;
        }

        dispatch(
            posActions.placeCurrentOrder(
                null,
                null,
                true, // TODO: should we derive this based on the station mode? (this should really only be used when mode is order taker)
                charityRoundUpAmountCents,
                subtotalCents,
                null,
                taxCents,
                totalCents,
                "in_creation",
                driveThruDetails,
                null,
            ),
        );
    }, [
        enabled,
        orderData,
        giftCardsToPurchase,
        giftCardsToAddFunds,
        subtotalCents,
        taxCents,
        driveThruDetails,
        currentOrderStartTime,
    ]);
}
