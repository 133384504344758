import cn from "classnames";
import {models} from "habit-core/types/api";
import React from "react";
import RetinaImage from "../RetinaImage";
import styles from "./ComboItem.module.css";
import CustomizationList from "../CustomizationList";

type Props = {
    itemId: string;
    active: boolean;
    className?: string;
    name: string;
    modifierSelections: models.ModifierSelectionDict;
    onChangeModifier: (
        modifierId: string,
        selectionId: string,
        quantity: number,
        isDefault: boolean,
        isRequired: boolean,
    ) => void;
    onClick?: () => void;
    images: models.Image[];
};

export default function ComboItem(props: Props) {
    return (
        <div
            className={cn(
                styles.comboItem,
                props.active ? styles.comboItemActive : null,
                props.images.length ? styles.comboItemWithImage : null,
                props.className,
            )}
            onClick={props.onClick}>
            <div className={styles.topRow}>
                {props.images.length ? (
                    <div className={styles.imageContainer}>
                        <RetinaImage
                            retinaImages={props.images}
                            className={styles.image}
                        />
                    </div>
                ) : null}
                <div className={styles.name}>{props.name}</div>
            </div>
            {Object.keys(props.modifierSelections).length > 0 ? (
                <div className={styles.divider} />
            ) : null}
            <CustomizationList
                modifierSelections={props.modifierSelections}
                itemId={props.itemId}
                onChangeModifier={props.onChangeModifier}
                editable={true}
            />
        </div>
    );
}
