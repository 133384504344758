import * as posSelectors from "../../lib/posSelectors";
import {useEffect} from "react";
import {API, Lib, ReduxModels} from "habit-core";
import {useAppSelector} from "../../lib/hooks";
import {
    EntityData,
    FullOrderData,
    ItemModifierData,
    getCustomerDisplayHtml,
} from "../CustomerDisplay";
import SecondaryDisplayPlugin from "../../secondary-display-plugin";
import logger from "../../lib/logger";

export function getItemModifiers(
    itemCustomizationId: string,
    itemCustomizationsById: {
        [internalId: string]: ReduxModels.ItemCustomization;
    },
    modifiersById: {
        [modifierId: string]: ReduxModels.ModifierRedux;
    },
    modifierSelectionsById: {
        [modifierSelectionId: string]: ReduxModels.ModifierSelectionRedux;
    },
    menuItemModifiers: {
        [itemId: string]: {
            [modifierId: string]: API.models.MenuItemModifier;
        };
    },
    allPrepsModifiersById: {
        [id: string]: Lib.selectors.ItemModifierData;
    },
): ItemModifierData[] {
    const itemCustomization = itemCustomizationsById[itemCustomizationId];
    const modifierIds = Object.keys(itemCustomization.modifierSelections);
    return modifierIds.map((id) => {
        const modifier = modifiersById[id];
        const allPrepsModifier = allPrepsModifiersById[id];
        const modSelection = itemCustomization.modifierSelections[id];
        const selection = modifierSelectionsById[modSelection.selectionId];
        const allPrepsSelection = allPrepsModifier?.selections?.find(
            (selection) => selection.id === modSelection.selectionId,
        );
        const selectionQuantity = modSelection.quantity;
        const currentMenuItemModifiers =
            menuItemModifiers[itemCustomization.itemId];
        const menuItemModSelection =
            currentMenuItemModifiers && selection
                ? currentMenuItemModifiers[id]?.selections.find(
                      (s) => s.id === selection.id,
                  )
                : undefined;
        return {
            modifierId: id,
            modifierName: modifier?.name ?? allPrepsModifier?.name ?? "",
            selectionId: selection?.id ?? allPrepsSelection?.id ?? "",
            selectionName: selection?.name ?? allPrepsSelection?.name ?? "",
            priceCents:
                menuItemModSelection?.priceCents ??
                allPrepsSelection?.priceCents ??
                undefined,
            quantity: selectionQuantity,
        };
    });
}

const SecondaryDisplay = () => {
    const orderData = useAppSelector((state) =>
        Lib.selectors.getMyOrderData(state, "date_added"),
    );
    const orderNumber = useAppSelector(
        (state) => state.pos.currentOrder.orderNumber,
    ); // order number will be null until the order has been paid for.

    const salesTaxRate =
        useAppSelector(
            (state) =>
                state.stores.byId[state.currentOrder.storeId ?? ""]?.taxRate ??
                0,
        ) * 100;

    const subtotalCents = useAppSelector((state) =>
        posSelectors.getCurrentOrderSubtotalSansVoidedCents(state),
    );
    const charityRoundUpAmountCents = useAppSelector((state) =>
        posSelectors.getCharityRoundUpSansVoidedCents(state, []),
    );
    const salesTaxCents = useAppSelector((state) =>
        posSelectors.getCurrentOrderTaxSansVoidedCents(state, []),
    );
    const discountAmountCents = useAppSelector((state) =>
        posSelectors.getCurrentOrderDiscountAmountCents(state),
    );
    const compCardsApplied = useAppSelector(
        (state) => state.pos.currentOrder.payment.compCardsApplied,
    );
    const compCardAppliedCents = compCardsApplied.reduce(
        (sum, cc) => (cc.appliedCents ?? 0) + sum,
        0,
    );
    const displayedSubtotalCents = Math.max(
        0,
        subtotalCents - discountAmountCents - compCardAppliedCents,
    );
    const displayedTotalCents =
        displayedSubtotalCents + charityRoundUpAmountCents + salesTaxCents;

    const comboCustomizationsById = useAppSelector(
        (state) => state.customizations.combos.byInternalId,
    );
    const itemsById = useAppSelector((state) => state.items.byId);
    const itemCustomizationsById = useAppSelector(
        (state) => state.customizations.items.byInternalId,
    );
    const modifiersById = useAppSelector((state) => state.modifiers.byId);
    const modifierSelectionsById = useAppSelector(
        (state) => state.modifierSelections.byId,
    );
    const allPrepsModifiersById = useAppSelector(
        (state) => state.pos.allPreps.byId,
    );

    const menuId = useAppSelector((state) => state.currentOrder.menuId);

    const menuItemsByMenuId = useAppSelector(
        (state) => state.menuItems.byMenuId,
    );

    const menuItemsById = menuId ? menuItemsByMenuId[menuId] : {};

    const menuItemModifiersByMenuId = useAppSelector(
        (state) => state.menuItemModifiers.byMenuId,
    );

    const menuItemModifiers = menuId ? menuItemModifiersByMenuId[menuId] : {};

    const menuCombosByMenuId = useAppSelector(
        (state) => state.menuCombos.byMenuId,
    );

    const combosById = useAppSelector((state) => state.combos.byId);

    useEffect(() => {
        renderCustomerDisplay();
    }, [orderData, orderNumber, discountAmountCents]);

    const getFullOrderData = (): FullOrderData => {
        const entities: EntityData[] = [];
        // if there is an order number, we want to clear the customer display screen, do not return any entities.
        if (orderNumber === null) {
            for (const entity of orderData as Lib.selectors.CartEntityItemOrCombo[]) {
                let priceSansModifiersCents = 0;
                let quantity = 1;
                let comboItems: {
                    id: string;
                    name: string;
                    modifiers: ItemModifierData[];
                }[] = [];
                let itemModifiers: ItemModifierData[] = [];
                if (entity.type === "combo") {
                    const customization =
                        comboCustomizationsById[entity.customizationId];
                    quantity = customization.quantity;
                    priceSansModifiersCents =
                        Lib.selectors.getComboUnitPriceCents(
                            customization.comboId,
                            menuId,
                            customization.itemCustomizationIds.map(
                                (id) => itemCustomizationsById[id],
                            ),
                            menuItemsByMenuId,
                            menuItemModifiersByMenuId,
                            menuCombosByMenuId,
                            combosById,
                            false,
                            false,
                        ) * quantity;
                    comboItems = customization.itemCustomizationIds.map(
                        (id) => {
                            const itemCustomization =
                                itemCustomizationsById[id];
                            const name =
                                itemsById[itemCustomization.itemId].name;
                            const modifiers = getItemModifiers(
                                id,
                                itemCustomizationsById,
                                modifiersById,
                                modifierSelectionsById,
                                menuItemModifiers,
                                allPrepsModifiersById,
                            );
                            return {id, name, modifiers};
                        },
                    );
                } else if (entity.type === "item") {
                    const customization =
                        itemCustomizationsById[entity.customizationId];
                    priceSansModifiersCents =
                        menuItemsById[customization.itemId].priceCents;
                    quantity = customization.quantity;
                    itemModifiers = getItemModifiers(
                        entity.customizationId,
                        itemCustomizationsById,
                        modifiersById,
                        modifierSelectionsById,
                        menuItemModifiers,
                        allPrepsModifiersById,
                    );
                }

                entities.push({
                    ...entity,
                    priceCents: priceSansModifiersCents,
                    quantity,
                    comboItems,
                    itemModifiers,
                });
            }
        }

        entities.reverse();
        return {
            salesTaxRate,
            subtotalCents: displayedSubtotalCents,
            salesTaxCents,
            charityRoundUpAmountCents,
            discountAmountCents,
            compCardAppliedCents,
            totalCents: displayedTotalCents,
            entities,
        };
    };

    const renderCustomerDisplay = () => {
        SecondaryDisplayPlugin.setContent({
            value: getCustomerDisplayHtml(getFullOrderData()),
        }).catch((err) => {
            logger.log("error loading customer display", err);
        });
    };

    return null;
};

export default SecondaryDisplay;
