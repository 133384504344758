import posReducers from "./lib/posReducers";
import thunk, {ThunkDispatch} from "redux-thunk";
import {createLogger} from "redux-logger";
import {Reducers} from "habit-core";
import {
    createStore,
    applyMiddleware,
    combineReducers,
    AnyAction,
    Middleware,
} from "redux";

const middleware: Middleware[] = [thunk];
if (import.meta.env.DEV) {
    const logger = createLogger({
        collapsed: true,
        duration: true,
    });

    middleware.push(logger);
}

const reducer = combineReducers({...Reducers, pos: posReducers});
const store = createStore(reducer, {}, applyMiddleware(...middleware));
export default store;
export type RootState = ReturnType<typeof store.getState>;

// TODO: #187 - Low priority, find a way to derive this type https://redux.js.org/usage/usage-with-typescript#define-root-state-and-dispatch-types
export type AppDispatch = ThunkDispatch<RootState, void, AnyAction>;
