import logger from "../logger";
import {registerPlugin} from "@capacitor/core";
import {Capacitor} from "@capacitor/core";

interface CashDrawerPlugin {
    open(): Promise<void>;
}

const cashDrawer = registerPlugin<CashDrawerPlugin>("CashDrawer");

export const openCashDrawer = async (isTraining = false) => {
    if (isTraining) {
        return Promise.resolve();
    }

    return cashDrawer.open().catch((err) => {
        if (Capacitor.isNativePlatform()) {
            logger.error(err);
            throw Error(err);
        } else {
            logger.debug(err); // this is not expected to work on web, no need to notify sentry
        }
    });
};
