import * as posActions from "./posActions";
import * as posModels from "./api/posModels";
import describeNetworkError from "./describeNetworkError";
import logger from "./logger";
import {useState, useEffect, useRef} from "react";
import {useAppDispatch} from "./hooks";

const REFRESH_DELAY = 15000;

export default function useRefreshOrders(
    orderStatuses: posModels.OrderStatus[] = [],
    orderSources: posModels.OrderSource[] = [],
    training = false,
) {
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(false);
    const [initialErrorMessage, setInitialErrorMessage] = useState("");

    const intervalID = useRef(0);

    useEffect(() => {
        function refreshOrders() {
            dispatch(
                posActions.getOrders(orderStatuses, orderSources, training),
            ).catch((err) => logger.warn(err));
        }

        setInitialLoading(true);
        dispatch(posActions.getOrders(orderStatuses, orderSources, training))
            .then(() => {
                setInitialLoading(false);
                intervalID.current = window.setInterval(
                    refreshOrders,
                    REFRESH_DELAY,
                );
            })
            .catch((err) => {
                logger.warn(err);
                setInitialErrorMessage(describeNetworkError(err).join("\n"));
                setInitialLoading(false);
            });

        return () => {
            window.clearInterval(intervalID.current);
        };
    }, []);

    function closeInitialErrorMessage() {
        setInitialErrorMessage("");
    }

    return {initialLoading, initialErrorMessage, closeInitialErrorMessage};
}
