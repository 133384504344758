export const POS_SET_DEVICE_ID = "POS_SET_DEVICE_ID";
export const POS_STATION_INITIALIZE = "POS_STATION_INITIALIZE";
export const POS_STATION_EDIT_MODE = "POS_STATION_EDIT_MODE";
export const POS_CHARITY_SETTINGS_INITIALIZE =
    "POS_CHARITY_SETTINGS_INITIALIZE";
export const POS_ALL_PREPS_INITIALIZE = "POS_ALL_PREPS_INITIALIZE";
export const POS_ADD_ONS_INITIALIZE = "POS_ADD_ONS_INITIALIZE";
export const POS_CURRENT_ORDER_SET_ID = "POS_CURRENT_ORDER_SET_ID";
export const POS_CURRENT_ORDER_SET_REF_NUM = "POS_CURRENT_ORDER_SET_REF_NUM";
export const POS_CURRENT_ORDER_SET_ID_REF_NUM_AND_NUMBER =
    "POS_CURRENT_ORDER_SET_ID_REF_NUM_AND_NUMBER";
export const POS_CURRENT_ORDER_REMOVE_REF_NUM =
    "POS_CURRENT_ORDER_REMOVE_REF_NUM";
export const POS_CURRENT_ORDER_CLEAR = "POS_CURRENT_ORDER_CLEAR";
export const POS_CURRENT_ORDER_SET_CUSTOMER_INFO =
    "POS_CURRENT_ORDER_SET_CUSTOMER_INFO";
export const POS_CURRENT_ORDER_SET_DRIVE_THRU_DETAILS =
    "POS_CURRENT_ORDER_SET_DRIVE_THRU_DETAILS";
export const POS_CURRENT_ORDER_SET_START_TIME =
    "POS_CURRENT_ORDER_SET_START_TIME";
export const POS_CURRENT_ORDER_SET_END_TIME = "POS_CURRENT_ORDER_SET_END_TIME";
export const POS_CURRENT_ORDER_SET_ORDER_NUMBER =
    "POS_CURRENT_ORDER_SET_ORDER_NUMBER";
export const POS_ORDERS_SET = "POS_ORDERS_SET";
export const POS_ADD_ORDER = "POS_ADD_ORDER";
export const POS_ORDERS_REMOVE = "POS_ORDERS_REMOVE";
export const POS_PENDING_VOIDED_ITEM_ADD = "POS_PENDING_VOIDED_ITEM_ADD";
export const POS_PENDING_VOIDED_COMBO_ADD = "POS_PENDING_VOIDED_COMBO_ADD";
export const POS_PENDING_VOIDED_ITEM_REMOVE = "POS_PENDING_VOIDED_ITEM_REMOVE";
export const POS_PENDING_VOIDED_COMBO_REMOVE =
    "POS_PENDING_VOIDED_COMBO_REMOVE";
export const POS_PENDING_VOIDED_ITEM_CHANGE_QUANTITY =
    "POS_PENDING_VOIDED_ITEM_CHANGE_QUANTITY";
export const POS_PENDING_VOIDED_COMBO_CHANGE_QUANTITY =
    "POS_PENDING_VOIDED_COMBO_CHANGE_QUANTITY";
export const POS_PENDING_VOIDED_CLEAR = "POS_PENDING_VOIDED_CLEAR";
export const POS_CURRENT_VOIDED_COMBOS_ADD = "POS_CURRENT_VOIDED_COMBOS_ADD";
export const POS_CURRENT_VOIDED_ITEMS_ADD = "POS_CURRENT_VOIDED_ITEMS_ADD";
export const POS_CURRENT_VOIDED_COMBOS_REMOVE =
    "POS_CURRENT_VOIDED_COMBOS_REMOVE";
export const POS_CURRENT_VOIDED_ITEMS_REMOVE =
    "POS_CURRENT_VOIDED_ITEMS_REMOVE";
export const POS_CURRENT_VOIDED_ITEMS_CHANGE_QUANTITY =
    "POS_CURRENT_VOIDED_ITEMS_CHANGE_QUANTITY";
export const POS_CURRENT_VOIDED_COMBOS_CHANGE_QUANTITY =
    "POS_CURRENT_VOIDED_COMBOS_CHANGE_QUANTITY";
export const POS_CURRENT_ORDER_VOIDED_CLEAR = "POS_CURRENT_ORDER_VOIDED_CLEAR";
export const POS_CURRENT_ORDER_ADD_DISCOUNT = "POS_CURRENT_ORDER_ADD_DISCOUNT";
export const POS_CURRENT_ORDER_REMOVE_DISCOUNT =
    "POS_CURRENT_ORDER_REMOVE_DISCOUNT";
export const POS_CURRENT_ORDER_ADD_EXISTING_GIFT_CARD_REQUEST =
    "POS_CURRENT_ORDER_ADD_EXISTING_GIFT_CARD_REQUEST";
export const POS_CURRENT_ORDER_UPDATE_EXISTING_GIFT_CARD_REQUEST =
    "POS_CURRENT_ORDER_UPDATE_EXISTING_GIFT_CARD_REQUEST";
export const POS_CURRENT_ORDER_REMOVE_EXISTING_GIFT_CARD_REQUEST =
    "POS_CURRENT_ORDER_REMOVE_EXISTING_GIFT_CARD_REQUEST";
export const POS_CURRENT_ORDER_ADD_NEW_GIFT_CARD_REQUEST =
    "POS_CURRENT_ORDER_ADD_NEW_GIFT_CARD_REQUEST";
export const POS_CURRENT_ORDER_UPDATE_NEW_GIFT_CARD_REQUEST =
    "POS_CURRENT_ORDER_UPDATE_NEW_GIFT_CARD_REQUEST";
export const POS_CURRENT_ORDER_REMOVE_NEW_GIFT_CARD_REQUEST =
    "POS_CURRENT_ORDER_REMOVE_NEW_GIFT_CARD_REQUEST";
export const POS_PAYMENT_SET_CASH_PAID_CENTS =
    "POS_PAYMENT_SET_CASH_PAID_CENTS";
export const POS_PAYMENT_ADD_TO_CASH_PAID_CENTS =
    "POS_PAYMENT_ADD_TO_CASH_PAID_CENTS";
export const POS_PAYMENT_SET_CHANGE_OWED_CENTS =
    "POS_PAYMENT_SET_CHANGE_OWED_CENTS";
export const POS_PAYMENT_APPLY_GIFT_CARD = "POS_PAYMENT_APPLY_GIFT_CARD";
export const POS_PAYMENT_REMOVE_GIFT_CARD = "POS_PAYMENT_REMOVE_GIFT_CARD";
export const POS_PAYMENT_SET_GIFT_CARDS = "POS_PAYMENT_SET_GIFT_CARDS";
export const POS_PAYMENT_APPLY_COMP_CARD = "POS_PAYMENT_APPLY_COMP_CARD";
export const POS_PAYMENT_REMOVE_COMP_CARD = "POS_PAYMENT_REMOVE_COMP_CARD";
export const POS_PAYMENT_ADD_CREDIT_CARD = "POS_PAYMENT_ADD_CREDIT_CARD";
export const POS_PAYMENT_CLEAR = "POS_PAYMENT_CLEAR";
