import {API, Constants, ReduxModels} from "habit-core";

export default function getInitialModSelections(
    itemsById: {
        [itemId: string]: API.models.Item;
    },
    itemId: string,
    menuItemsByMenuId: {
        [menuId: string]: {
            [itemId: string]: {modifierIds: string[]};
        };
    },
    menuId: string,
    menuItemModifiersByMenuId: {
        [menuId: string]: {
            [itemId: string]: {
                [modifierId: string]: API.models.MenuItemModifier;
            };
        };
    },
    itemCustomization: ReduxModels.ItemCustomization | null,
    includeDefaultsForRequiredMods: boolean,
): API.models.ModifierSelectionDict {
    const modSelections = itemCustomization
        ? itemCustomization.modifierSelections
        : {};

    if (!includeDefaultsForRequiredMods) {
        return modSelections;
    }

    const item = itemsById[itemId];
    const menuItem = menuItemsByMenuId[menuId][itemId];

    if (menuItem) {
        const requiredModifierIds = menuItem.modifierIds.filter((id) =>
            item.modifiers.some(
                (m) =>
                    m.modifierId === id &&
                    m.type === Constants.modifierType.REQUIRED_CHOICE,
            ),
        );
        requiredModifierIds.forEach((modId) => {
            const itemMod = item.modifiers.find((m) => m.modifierId === modId);
            const menuItemMod =
                menuItemModifiersByMenuId[menuId][itemId][modId];
            if (itemMod && !modSelections[modId]) {
                const defaultModifierSelectionId =
                    menuItemMod.defaultModifierSelectionId;
                const selection = menuItemMod.selections.find(
                    (s) => s.id === defaultModifierSelectionId,
                );
                modSelections[modId] = {
                    selectionId: defaultModifierSelectionId,
                    quantity: selection && selection.min ? selection.min : 1,
                };
            }
        });
    }

    return modSelections;
}
