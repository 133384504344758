import * as routeHelpers from "../../lib/routeHelpers";
import cn from "classnames";
import Drawer from "../Drawer";
import giftCard1 from "../../images/giftcard.png";
import giftCard2 from "../../images/giftcard@2x.png";
import giftCard3 from "../../images/giftcard@3x.png";
import React from "react";
import SplitDrawerContent from "../SplitDrawerContent";
import styles from "./GiftCardsDrawer.module.css";
import {useNavigate} from "react-router-dom";
import {API, Lib} from "habit-core";

const strings = {
    giftCard: "Gift Card",
};

type Props = {
    className?: string;
    left?: React.ReactNode;
    right?: React.ReactNode;
    balanceCents: API.models.USDCents;
};

export default function GiftCardsDrawer(props: Props) {
    const navigate = useNavigate();

    return (
        <Drawer onClose={() => navigate(routeHelpers.giftCards())}>
            <SplitDrawerContent
                className={cn(styles.content, props.className)}
                left={props.left}
                right={
                    <>
                        <div className={styles.rightContainer}>
                            <img
                                src={giftCard1}
                                srcSet={`${giftCard1} 1x, ${giftCard2} 2x, ${giftCard3} 3x`}
                                alt=""
                            />
                            <div
                                className={cn(
                                    styles.rightText,
                                    styles.rightTitle,
                                )}>
                                {strings.giftCard}
                            </div>
                            <div
                                className={cn(
                                    styles.rightText,
                                    styles.rightBalance,
                                )}>
                                {Lib.currency.centsToDollarString(
                                    props.balanceCents,
                                )}
                            </div>
                        </div>
                        {props.right}
                    </>
                }
            />
        </Drawer>
    );
}
