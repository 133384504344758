import * as posModels from "../../lib/api/posModels";
import addIcon from "../../images/add.svg";
import cn from "classnames";
import easyIcon from "../../images/easy.svg";
import extraIcon from "../../images/extra.svg";
import includedIcon from "../../images/included.svg";
import logger from "../../lib/logger";
import noneIcon from "../../images/none.svg";
import onlyIcon from "../../images/only.svg";
import onSideIcon from "../../images/on-side.svg";
import React from "react";
import styles from "./OrderDetailsCustomizationList.module.css";
import subIcon from "../../images/sub.svg";
import {API, Lib} from "habit-core";

const strings = {
    add: "Add",
    sub: "Sub",
    none: "No",
    easy: "Easy",
    only: "Only",
    included: "Incl",
    extraSelectionName: "Extra",
    extraDisplayed: "Xtra",
    onSide: "Ots",
};

type Props = {
    modifiers: posModels.OrderModifier[];
};

export default function OrderDetailsCustomizationList({modifiers}: Props) {
    function getTypeIcon(
        type: API.models.MenuItemModifierSelectionType | null,
    ) {
        switch (type) {
            case "add":
                return <img src={addIcon} />;
            case "sub":
                return <img src={subIcon} />;
            case "none":
                return <img src={noneIcon} />;
            case "easy":
                return <img src={easyIcon} />;
            case "on side":
                return <img src={onSideIcon} />;
            case "only":
                return <img src={onlyIcon} />;
            case "extra":
                return <img src={extraIcon} />;
            case null:
                return <img src={includedIcon} />;
            default:
                logger.warn(`unsupported mod type ${type}`);
                return null;
        }
    }

    function getTypeName(
        type: API.models.MenuItemModifierSelectionType | null,
        selectionName: string | null,
    ) {
        switch (type) {
            case "add":
                if (selectionName === strings.extraSelectionName) {
                    return strings.extraDisplayed;
                }
                return strings.add;
            case "sub":
                return strings.sub;
            case "none":
                return strings.none;
            case "easy":
                return strings.easy;
            case "on side":
                return strings.onSide;
            case "only":
                return strings.only;
            case "extra":
                return strings.extraDisplayed;
            case null:
                return strings.included;
            default:
                logger.warn(`unknown mod type ${type}`);
                return "";
        }
    }

    function getItemModifierString(
        modifierName: string | null,
        selectionName: string | null,
        type: API.models.MenuItemModifierSelectionType | null,
        quantity?: number | null,
    ) {
        if (modifierName !== null && selectionName !== null) {
            if (type === "sub" || type === null) {
                if (quantity && quantity > 1) {
                    return `${quantity}X - ${modifierName} - ${selectionName}`;
                } else {
                    return `${modifierName} - ${selectionName}`;
                }
            } else {
                if (quantity && quantity > 1) {
                    return `${quantity}X - ${modifierName}`;
                } else {
                    return modifierName;
                }
            }
        } else {
            if (selectionName === null) {
                return modifierName;
            } else {
                return "Unknown";
            }
        }
    }

    return (
        <div>
            {modifiers.map((mod) => {
                return (
                    <div key={mod.modifierId}>
                        <div className={cn(styles.selectionNameRow)}>
                            <div className={styles.leftContainer}>
                                <div className={styles.modText}>
                                    <div className={styles.typeContainer}>
                                        {getTypeIcon(mod.type)}
                                        <div
                                            className={cn({
                                                [styles.typeName]: true,
                                                [styles.add]:
                                                    mod.type === "add",
                                                [styles.sub]:
                                                    mod.type === "sub",
                                                [styles.none]:
                                                    mod.type === "none",
                                                [styles.easy]:
                                                    mod.type === "easy",
                                                [styles.onSide]:
                                                    mod.type === "on side",
                                            })}>
                                            {getTypeName(
                                                mod.type,
                                                mod.modifierSelectionName,
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.modName}>
                                        {getItemModifierString(
                                            mod.modifierName,
                                            mod.modifierSelectionName,
                                            mod.type,
                                            mod.quantity,
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* TODO: #38 - Required modifier indicator? */}
                            <div className={styles.rightContainer}>
                                {mod.priceCents !== 0 ? (
                                    <div
                                        className={cn(
                                            styles.modText,
                                            styles.price,
                                        )}>
                                        {Lib.currency.centsToDollarString(
                                            mod.priceCents,
                                        )}
                                    </div>
                                ) : (
                                    <div className={styles.spacer} />
                                )}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
