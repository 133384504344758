import * as posActions from "../../lib/posActions";
import * as posSelectors from "../../lib/posSelectors";
import * as routeHelpers from "../../lib/routeHelpers";
import carIcon from "../../images/car.svg";
import carIconWhite from "../../images/car-white.svg";
import Checkbox from "../Checkbox";
import cn from "classnames";
import convertibleIcon from "../../images/convertible.svg";
import convertibleIconWhite from "../../images/convertible-white.svg";
import describeNetworkError from "../../lib/describeNetworkError";
import ErrorModal from "../ErrorModal";
import logger from "../../lib/logger";
import motorcycleIcon from "../../images/motorcycle.svg";
import motorcycleIconWhite from "../../images/motorcycle-white.svg";
import OutlineButton from "../OutlineButton";
import React, {useContext, useState} from "react";
import styles from "./DriveThroughIdentification.module.css";
import suvIcon from "../../images/suv.svg";
import suvIconWhite from "../../images/suv-white.svg";
import TextButton from "../TextButton";
import ToggleButton from "../ToggleButton";
import truckIcon from "../../images/truck.svg";
import truckIconWhite from "../../images/truck-white.svg";
import utilityIcon from "../../images/utility.svg";
import utilityIconWhite from "../../images/utility-white.svg";
import vanIcon from "../../images/van-rv.svg";
import vanIconWhite from "../../images/van-rv-white.svg";
import {CashierModeContext} from "../CashierModeContext";
import {useAppSelector, useAppDispatch} from "../../lib/hooks";
import {useNavigate} from "react-router-dom";

const strings = {
    car: "Car",
    truck: "Truck",
    convertible: "Convertible",
    motorcycle: "Motorcycle",
    suv: "SUV",
    van: "Van / RV",
    utility: "Utility",
    vehicleType: "Vehicle Type",
    vehicleColor: "Vehicle Color (General)",
    white: "White",
    black: "Black",
    blue: "Blue",
    orange: "Orange",
    pink: "Pink / Purple",
    grey: "Grey",
    red: "Red",
    green: "Green",
    yellow: "Yellow",
    brown: "Brown",
    eatingInCar: "Eating in Car",
    submit: "Submit Order",
    orderPlaced: "Order Placed",
    skip: "Skip",
};

const typeOptions = [
    {icon: carIcon, iconWhite: carIconWhite, name: strings.car},
    {icon: suvIcon, iconWhite: suvIconWhite, name: strings.suv},
    {icon: truckIcon, iconWhite: truckIconWhite, name: strings.truck},
    {icon: vanIcon, iconWhite: vanIconWhite, name: strings.van},
    {
        icon: convertibleIcon,
        iconWhite: convertibleIconWhite,
        name: strings.convertible,
    },
    {icon: utilityIcon, iconWhite: utilityIconWhite, name: strings.utility},
    {
        icon: motorcycleIcon,
        iconWhite: motorcycleIconWhite,
        name: strings.motorcycle,
    },
];

const colorOptions = [
    {name: strings.white, color: "#ffffff"},
    {name: strings.grey, color: "#C4C4C4"},
    {name: strings.black, color: "#333333"},
    {name: strings.red, color: "#FF3232"},
    {name: strings.blue, color: "#002BC1"},
    {name: strings.green, color: "#02C224"},
    {name: strings.orange, color: "#FF8808"},
    {name: strings.yellow, color: "#FFE908"},
    {name: strings.pink, color: "#D337EC"},
    {name: strings.brown, color: "#887157"},
];

export default function DriveThroughIdentification() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const {isTraining} = useContext(CashierModeContext);

    const vehicleType = useAppSelector(
        (state) => state.pos.currentOrder.driveThruDetails.vehicleType,
    );
    const vehicleColor = useAppSelector(
        (state) => state.pos.currentOrder.driveThruDetails.vehicleColor,
    );
    const eatingInCar = useAppSelector(
        (state) => state.pos.currentOrder.driveThruDetails.eatingInCar,
    );
    const laneNumber = useAppSelector(
        (state) => state.pos.currentOrder.driveThruDetails.laneNumber,
    );

    const storeId = useAppSelector((state) => state.currentOrder.storeId);

    const subtotalCents = useAppSelector((state) =>
        posSelectors.getCurrentOrderSubtotalSansVoidedCents(state),
    );
    const charityRoundUpAmountCents = useAppSelector((state) =>
        posSelectors.getCharityRoundUpSansVoidedCents(state, []),
    );
    const taxCents = useAppSelector((state) =>
        posSelectors.getCurrentOrderTaxSansVoidedCents(state, []),
    );

    const orderTotalCents =
        subtotalCents + charityRoundUpAmountCents + taxCents;

    function onSubmit(carDetailsRequired = true) {
        if (!storeId) {
            logger.warn("No store id to place pending drive thru order");
            return;
        }
        if (
            loading ||
            (carDetailsRequired && (!vehicleColor || !vehicleType))
        ) {
            return;
        }

        setLoading(true);
        dispatch(posActions.reserveCurrentOrderRefNumIfNeeded(isTraining))
            .then(() => {
                dispatch(
                    posActions.placeCurrentOrder(
                        null,
                        null,
                        true,
                        charityRoundUpAmountCents,
                        subtotalCents,
                        null,
                        taxCents,
                        orderTotalCents,
                        "pending",
                        {
                            vehicleType,
                            vehicleColor,
                            eatingInCar,
                            laneNumber,
                        },
                        null,
                    ),
                )
                    .then(() => {
                        navigate(routeHelpers.orderComplete(), {
                            state: {
                                header: strings.orderPlaced,
                            },
                        });
                    })
                    .catch((err) => {
                        logger.warn(err);
                        setErrorMessage(describeNetworkError(err).join("\n"));
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .catch((err) => {
                logger.warn(err);
                setLoading(false);
                setErrorMessage(describeNetworkError(err).join("\n"));
            });
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.main}>
                    <div className={styles.sectionHeader}>
                        {strings.vehicleType}
                    </div>
                    <div className={styles.sectionContainer}>
                        {typeOptions.map((option, i) => {
                            const toggled = vehicleType === option.name;
                            return (
                                <ToggleButton
                                    key={option.name}
                                    className={cn({
                                        [styles.optionButton]: true,
                                        [styles.carButton]: true,
                                        [styles.firstColumn]: i % 2 === 0,
                                    })}
                                    label={option.name}
                                    leftContent={
                                        <img
                                            className={styles.carIcon}
                                            src={
                                                toggled
                                                    ? option.iconWhite
                                                    : option.icon
                                            }
                                            alt=""
                                        />
                                    }
                                    checked={toggled}
                                    onChange={() =>
                                        dispatch(
                                            posActions.setCurrentOrderDriveThruDetails(
                                                {
                                                    vehicleType: toggled
                                                        ? ""
                                                        : option.name,
                                                    vehicleColor,
                                                    eatingInCar,
                                                    laneNumber,
                                                },
                                            ),
                                        )
                                    }
                                    disabled={loading}
                                />
                            );
                        })}
                    </div>
                    <div className={styles.sectionHeader}>
                        {strings.vehicleColor}
                    </div>
                    <div className={styles.sectionContainer}>
                        {colorOptions.map((option, i) => {
                            const toggled = vehicleColor === option.name;
                            return (
                                <ToggleButton
                                    key={option.name}
                                    className={cn({
                                        [styles.optionButton]: true,
                                        [styles.colorButton]: true,
                                        [styles.firstColumn]: i % 2 === 0,
                                    })}
                                    label={option.name}
                                    leftContent={
                                        <div
                                            className={cn({
                                                [styles.colorBlock]: true,
                                                [styles.colorBlockBorder]:
                                                    option.name ===
                                                        strings.white &&
                                                    !toggled,
                                            })}
                                            style={{
                                                backgroundColor: option.color,
                                            }}
                                        />
                                    }
                                    checked={toggled}
                                    onChange={() =>
                                        dispatch(
                                            posActions.setCurrentOrderDriveThruDetails(
                                                {
                                                    vehicleType,
                                                    vehicleColor: toggled
                                                        ? ""
                                                        : option.name,
                                                    eatingInCar,
                                                    laneNumber,
                                                },
                                            ),
                                        )
                                    }
                                    disabled={loading}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className={styles.footer}>
                    <Checkbox
                        label={strings.eatingInCar}
                        checked={eatingInCar}
                        onChange={(e) =>
                            dispatch(
                                posActions.setCurrentOrderDriveThruDetails({
                                    vehicleType,
                                    vehicleColor,
                                    eatingInCar: e.target.checked,
                                    laneNumber,
                                }),
                            )
                        }
                        disabled={loading}
                    />
                    <div className={styles.submitButtonsContainer}>
                        <TextButton
                            className={styles.skipCarDetailsButton}
                            label={strings.skip}
                            onClick={() => onSubmit(false)}
                        />
                        <OutlineButton
                            label={strings.submit}
                            disabled={!vehicleType || !vehicleColor}
                            loading={loading}
                            onClick={onSubmit}
                        />
                    </div>
                </div>
            </div>
            {errorMessage ? (
                <ErrorModal
                    errorMessage={errorMessage}
                    onClose={() => setErrorMessage("")}
                    showITInfo
                />
            ) : null}
        </>
    );
}
