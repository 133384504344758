import React from "react";
import ToggleButton from "../ToggleButton";
import {useAppSelector} from "../../lib/hooks";
import {API, Constants, Lib} from "habit-core";

type Props = {
    className?: string;
    id: string;
    itemId: string;
    menuId: string;
    modifierSelections: API.models.ModifierSelectionDict;
    onChange?: (
        modifierId: string,
        selectionId: string,
        quantity: number,
        isDefault: boolean,
        isRequired: boolean,
    ) => void;
};

export default function FeaturedModifierSelection(props: Props) {
    const feature = useAppSelector(
        (state) => state.featuredSelections.byId[props.id],
    );

    const selection = useAppSelector(
        (state) => state.modifierSelections.byId[feature.selectionId],
    );

    const globalItemModifier = useAppSelector((state) =>
        state.items.byId[props.itemId].modifiers.find(
            (x) => x.modifierId === selection.modifierId,
        ),
    );

    const menuItemModifier = useAppSelector(
        (state) =>
            state.menuItemModifiers.byMenuId[props.menuId][props.itemId][
                selection.modifierId
            ],
    );

    const menuItemModifierSelection = menuItemModifier.selections.find(
        (x) => x.id === selection.id,
    );

    if (!menuItemModifierSelection) {
        return null;
    }

    const checked =
        props.modifierSelections[selection.modifierId]?.selectionId ===
            selection.id ||
        (!props.modifierSelections[selection.modifierId] &&
            selection.id === menuItemModifier.defaultModifierSelectionId);

    const onChange = (checked: boolean) => {
        const nextSelectionId = checked
            ? selection.id
            : menuItemModifier.defaultModifierSelectionId;

        const isDefault =
            globalItemModifier &&
            globalItemModifier.type === Constants.modifierType.REQUIRED_CHOICE
                ? false
                : nextSelectionId ===
                  menuItemModifier.defaultModifierSelectionId;

        if (props.onChange) {
            props.onChange(
                selection.modifierId,
                nextSelectionId,
                menuItemModifierSelection.min ?? 1,
                isDefault,
                false,
            );
        }
    };

    const priceCents = menuItemModifierSelection.priceCents;

    return (
        <ToggleButton
            className={props.className}
            label={feature.name}
            label2={`${
                priceCents > 0 ? "+" : "-"
            }${Lib.currency.centsToDollarString(priceCents)}`}
            onChange={onChange}
            checked={checked}
        />
    );
}
