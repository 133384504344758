import cn from "classnames";
import Modal from "../Modal";
import OutlineButton from "../OutlineButton";
import React, {useState, useEffect} from "react";
import SplitInput from "./SplitInput";
import styles from "./SplitModal.module.css";
import TriangleIcon from "../../images/triangle.svg";

const strings = {
    split: "Split",
    totalQuantity: "Total QTY :",
    cancel: "Cancel",
    splitItem: "Split Item",
};

type Props = {
    className?: string;
    overlayClassName?: string;
    productName: string;
    totalQuantity: number;
    onClose: () => void;
    onSplit: (newQuantity: number) => void;
};

export default function SplitModal(props: Props) {
    const [newQuantity, setNewQuantity] = useState(1);
    const [newInputValue, setNewInputValue] = useState("1");
    const [currentInputValue, setCurrentInputValue] = useState(
        (props.totalQuantity - 1).toString(),
    );

    useEffect(() => {
        if (newInputValue) {
            setNewQuantity(parseInt(newInputValue));
        }
    }, [newInputValue]);

    function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        props.onSplit(newQuantity);
    }

    return (
        <Modal
            onClose={props.onClose}
            className={cn(props.className, styles.modal)}
            overlayClassName={props.overlayClassName}>
            <form className={styles.container} onSubmit={onSubmit}>
                <div className={styles.header}>
                    <div>
                        <div className={styles.name}>
                            {strings.split} {props.productName}
                        </div>
                        <div className={styles.total}>
                            {strings.totalQuantity} {props.totalQuantity}
                        </div>
                    </div>
                    <button
                        className={styles.cancel}
                        onClick={props.onClose}
                        type="button">
                        {strings.cancel}
                    </button>
                </div>

                <div className={styles.middle}>
                    <div className={styles.quantityButtons}>
                        <OutlineButton
                            className={cn(
                                styles.quantityButton,
                                styles.quantityButtonTop,
                            )}
                            disabled={newQuantity >= props.totalQuantity - 1}
                            onClick={() => {
                                setNewInputValue((prev) =>
                                    prev
                                        ? (parseInt(prev) + 1).toString()
                                        : (props.totalQuantity - 1).toString(),
                                );
                                setCurrentInputValue((prev) =>
                                    prev
                                        ? (parseInt(prev) - 1).toString()
                                        : "1",
                                );
                            }}>
                            <img src={TriangleIcon} alt="" />
                        </OutlineButton>
                        <OutlineButton
                            className={cn(
                                styles.quantityButton,
                                styles.quantityButtonBottom,
                            )}
                            disabled={newQuantity <= 1}
                            onClick={() => {
                                setNewInputValue((prev) =>
                                    prev
                                        ? (parseInt(prev) - 1).toString()
                                        : "1",
                                );
                                setCurrentInputValue((prev) =>
                                    prev
                                        ? (parseInt(prev) + 1).toString()
                                        : (props.totalQuantity - 1).toString(),
                                );
                            }}>
                            <img
                                className={styles.downIcon}
                                src={TriangleIcon}
                                alt=""
                            />
                        </OutlineButton>
                    </div>
                    <SplitInput
                        id="old-quantity"
                        value={currentInputValue}
                        setValue={(v) => {
                            if (v && parseInt(v) > props.totalQuantity - 1) {
                                return;
                            }

                            setCurrentInputValue(v);
                            setNewInputValue(
                                v
                                    ? (
                                          props.totalQuantity - parseInt(v)
                                      ).toString()
                                    : "",
                            );
                        }}
                        min={1}
                        max={props.totalQuantity - 1}
                    />
                    <SplitInput
                        id="new-quantity"
                        className={styles.quantityBoxEnd}
                        value={newInputValue}
                        setValue={(v) => {
                            if (v && parseInt(v) > props.totalQuantity - 1) {
                                return;
                            }

                            setNewInputValue(v);
                            setCurrentInputValue(
                                v
                                    ? (
                                          props.totalQuantity - parseInt(v)
                                      ).toString()
                                    : "",
                            );
                        }}
                        min={1}
                        max={props.totalQuantity - 1}
                    />
                </div>

                <OutlineButton
                    className={styles.splitButton}
                    label={strings.splitItem}
                    onClick={() => props.onSplit(newQuantity)}
                    disabled={!newInputValue || !currentInputValue}
                    type="submit"
                />
            </form>
        </Modal>
    );
}
