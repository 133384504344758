import cn from "classnames";
import React, {useEffect, useState} from "react";
import styles from "./TimeSince.module.css";
import {differenceInMilliseconds} from "date-fns";

type Props = {
    className?: string;
    startTime: Date;
    endTime?: Date;
};

export default function TimeSince(props: Props) {
    const [timeSince, setTimeSince] = useState("0:00");

    useEffect(() => {
        function updateTime(earlierDate: Date, laterDate: Date) {
            const millisecondsSince = differenceInMilliseconds(
                laterDate,
                earlierDate,
            );
            const minutesSince = Math.floor(millisecondsSince / 60000);
            const secondsSince = ((millisecondsSince % 60000) / 1000).toFixed(
                0,
            );
            setTimeSince(
                minutesSince +
                    ":" +
                    (parseInt(secondsSince) < 10 ? "0" : "") +
                    secondsSince,
            );
        }

        let i = 0;
        if (props.endTime) {
            updateTime(props.startTime, props.endTime);
        } else {
            i = window.setInterval(
                () => updateTime(props.startTime, new Date()),
                1000,
            );
        }
        return () => clearInterval(i);
    }, [props.startTime, props.endTime]);

    return <div className={cn(styles.time, props.className)}>{timeSince}</div>;
}
