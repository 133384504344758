import * as posModels from "../api/posModels";
import * as urls from "./urls";
import MockAdapter from "axios-mock-adapter";
import {API, Lib} from "habit-core";
import {AxiosInstance, AxiosRequestConfig} from "axios";

type MockHandler<T = void> = (
    cfg: AxiosRequestConfig,
) => [status: number, data?: T, headers?: any]; // eslint-disable-line @typescript-eslint/no-explicit-any

const mockReduceGiftCardBalance: MockHandler<{
    balanceCents: API.models.USDCents;
}> = (cfg: AxiosRequestConfig) => {
    const data = JSON.parse(cfg.data);
    return [
        200,
        {
            balanceCents: Math.max(
                1052 - Lib.currency.dollarsFloatToCents(data.amount),
                0,
            ),
        },
    ];
};

const mockGetTillAssignments: MockHandler<posModels.TillAssignments> = () => {
    return [
        200,
        {
            numTills: 6,
            assignedTills: [
                {
                    tillNumber: 5,
                    deviceId: null,
                },
            ],
        },
    ];
};

let mock: MockAdapter | null = null;

export function setMockResponses(instance: AxiosInstance) {
    if (mock !== null) {
        return;
    }

    mock = new MockAdapter(instance, {delayResponse: 500});

    mock.onPost(urls.paymentMethods.reduceGiftCardBalanceRegex).reply(
        mockReduceGiftCardBalance,
    );
    mock.onPost(urls.tills.logAssignment).reply(200);
    mock.onPost(API.orders.urls.validateFundraiserCode).reply(200);

    mock.onPost(urls.orders.reserveOrderId).passThrough();
    mock.onPost(urls.orders.reserveOrderReferenceNumber).passThrough();
    mock.onPost(urls.orders.place).passThrough();
    mock.onGet(urls.orders.get).passThrough();
    mock.onGet(API.menus.urls.get).passThrough();
    mock.onGet(urls.menus.getByStoreSimpleRegex).passThrough();
    mock.onGet(API.menus.urls.getLastMenuUpdateDate).passThrough();
    mock.onGet(urls.menus.globalMenuLastUpdateDate).passThrough();
    mock.onGet(urls.menus.storeMenuLastUpdateDateRegex).passThrough();
    mock.onGet(urls.stores.getSettingsLastUpdateDate).passThrough();
    mock.onGet(urls.menus.allPrepsRegex).passThrough();
    mock.onGet(urls.menus.addOns).passThrough();
    mock.onPost(API.orders.urls.upsell).passThrough();
    mock.onPost(API.orders.urls.validateDiscount).passThrough();
    mock.onGet(API.stores.urls.getById).passThrough();
    mock.onGet(urls.stores.getSettings).passThrough();
    mock.onPost(urls.tills.logAssignment).passThrough();
    mock.onGet(urls.tills.getAssignments).reply(mockGetTillAssignments);
    mock.onPost(urls.users.validateCardNumber).passThrough();
    mock.onPost(urls.users.validateId).passThrough();
    mock.onPost(urls.users.associateCard).passThrough();
    mock.onGet(urls.reports.timePeriod).passThrough();
    mock.onGet(urls.reports.sales).passThrough();
    mock.onGet(urls.reports.balance).passThrough();
    mock.onPost(urls.printers.printReceipt).passThrough();

    mock.onAny().reply(500);
}

export function removeMock() {
    mock?.restore();
    mock = null;
}
