import React, {createContext, useRef} from "react";

type UpdateDateContext = {
    globalMenuLastUpdateDateRef: React.MutableRefObject<Date | null>;
    storeMenuLastUpdateDateRef: React.MutableRefObject<Date | null>;
    storeSettingsLastUpdateDateRef: React.MutableRefObject<Date | null>;
};

export const RefreshContext = createContext<UpdateDateContext>({
    globalMenuLastUpdateDateRef: React.createRef(),
    storeMenuLastUpdateDateRef: React.createRef(),
    storeSettingsLastUpdateDateRef: React.createRef(),
});

export function RefreshProvider(props: {children: React.ReactNode}) {
    const globalMenuLastUpdateDateRef = useRef<Date | null>(null);
    const storeMenuLastUpdateDateRef = useRef<Date | null>(null);
    const storeSettingsLastUpdateDateRef = useRef<Date | null>(null);

    return (
        <RefreshContext.Provider
            value={{
                globalMenuLastUpdateDateRef,
                storeMenuLastUpdateDateRef,
                storeSettingsLastUpdateDateRef,
            }}>
            {props.children}
        </RefreshContext.Provider>
    );
}
