import instance from "./instance";
import * as models from "./posModels";
import * as urls from "./urls";

export function getStoreSettings(): Promise<models.StoreSettings> {
    return instance
        .request({
            url: urls.stores.getSettings,
            method: "GET",
        })
        .then((response) => response.data);
}

export function getStoreSettingsLastUpdateDate(): Promise<Date> {
    return instance
        .request({
            url: urls.stores.getSettingsLastUpdateDate,
            method: "GET",
        })
        .then((response) => {
            return response.data.lastStoreSettingsUpdateDate;
        });
}
