import * as routeHelpers from "../../lib/routeHelpers";
import * as posActions from "../../lib/posActions";
import * as posMenuEndpoints from "../../lib/api/menus";
import * as posStoreEndpoints from "../../lib/api/stores";
import completeIcon from "../../images/complete.svg";
import OutlineButton from "../OutlineButton";
import React, {useContext, useEffect} from "react";
import styles from "./OrderComplete.module.css";
import {useAppDispatch, useAppSelector} from "../../lib/hooks";
import {useNavigate, useLocation} from "react-router-dom";
import {CashierModeContext} from "../CashierModeContext";
import {RefreshContext} from "../RefreshContext";
import logger from "../../lib/logger";
import {Actions, API, Constants} from "habit-core";

const strings = {
    orderComplete: (orderNumber: number) => `Order #${orderNumber} Completed`,
    startNewOrder: "Start a New Order",
    returnToOrders: "Return to Orders",
};

type OrderCompleteState = {
    header: string;
    subheader?: string;
    subheaderClassName?: string;
    instructions?: string;
    button?: string;
};

export default function OrderComplete() {
    const dispatch = useAppDispatch();
    const {isTraining} = useContext(CashierModeContext);
    const storeId = useAppSelector((state) => state.currentOrder.storeId);
    const defaultCategoryId = useAppSelector(
        (state) =>
            state.menus.byId[state.currentOrder.menuId ?? ""].defaultCategoryId,
    );
    const orderNumber = useAppSelector(
        (state) => state.pos.currentOrder.orderNumber,
    ); // order number should not be null when we arrive here after customer paid for order.
    const stationMode = useAppSelector((state) => state.pos.station.mode);
    const isDriveThruFulfillment =
        stationMode === "drive_thru_order_fulfillment";

    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as OrderCompleteState;

    const {
        globalMenuLastUpdateDateRef,
        storeMenuLastUpdateDateRef,
        storeSettingsLastUpdateDateRef,
    } = useContext(RefreshContext);

    useEffect(() => {
        return () => {
            let nextOrderType: API.models.OrderType = "in_store";
            if (
                stationMode === "drive_thru_order_taker" ||
                stationMode === "drive_thru_order_fulfillment"
            ) {
                nextOrderType = "drive_thru";
            }
            dispatch(
                posActions.clearCurrentOrder(storeId ?? "", nextOrderType),
            );
            if (!isDriveThruFulfillment) {
                dispatch(posActions.reserveCurrentOrderId(isTraining));
            }
            Promise.all([
                posMenuEndpoints.getGlobalMenuLastUpdateDate(),
                posMenuEndpoints.getStoreMenuLastUpdateDate(storeId ?? ""),
                posStoreEndpoints.getStoreSettingsLastUpdateDate(),
            ])
                .then(
                    async ([
                        _globalMenuLastUpdateDate,
                        _storeMenuLastUpdateDate,
                        _storeSettingsLastUpdateDate,
                    ]) => {
                        if (
                            globalMenuLastUpdateDateRef.current === null ||
                            globalMenuLastUpdateDateRef.current <
                                _globalMenuLastUpdateDate
                        ) {
                            await dispatch(
                                Actions.menuActions.loadGlobalMenu(),
                            );
                            await dispatch(posActions.initializePosAddOns());
                            globalMenuLastUpdateDateRef.current =
                                _globalMenuLastUpdateDate;
                        }
                        if (
                            storeMenuLastUpdateDateRef.current === null ||
                            storeMenuLastUpdateDateRef.current <
                                _storeMenuLastUpdateDate
                        ) {
                            await dispatch(
                                posActions.refreshStoreMenu(
                                    storeId ?? "",
                                    nextOrderType,
                                ),
                            );
                            await dispatch(
                                posActions.initializePosAllPreps(storeId ?? ""),
                            );
                            storeMenuLastUpdateDateRef.current =
                                _storeMenuLastUpdateDate;
                        }
                        if (
                            storeSettingsLastUpdateDateRef.current === null ||
                            storeSettingsLastUpdateDateRef.current <
                                _storeSettingsLastUpdateDate
                        ) {
                            const storeSettings =
                                await posStoreEndpoints.getStoreSettings();
                            const charitySettings =
                                storeSettings.charitySettings;
                            await dispatch(
                                posActions.initializeCharityRoundUpSettingsImmediate(
                                    charitySettings.isEnabled,
                                    charitySettings.charityName,
                                    charitySettings.showMinimal,
                                ),
                            );

                            // initialize store address and tax rate.
                            const storeInfo = {
                                id: storeSettings.storeId,
                                taxRate: storeSettings.taxRate,
                                address: storeSettings.address,
                                established: "n/a",
                                name: "n/a",
                                status: Constants.storeStatus.ONLINE,
                                storeNumber: 0,
                                maxOrderCost: 500,
                                maxOrderCostCents: 50000,
                                supportsCurbside: false,
                                supportsCharlane: false,
                                curbsideAutoCheckIn: {enabled: false},
                                supportsDineIn: true,
                                hours: {
                                    driveThru: [],
                                    inStore: [],
                                    modified: {
                                        driveThru: [],
                                        inStore: [],
                                    },
                                },
                                images: [],
                                supportsQrToGo: false,
                                supportsQrDineIn: false,
                                isItemComboUpsellEnabled: false,
                                supportsPickupLane: false,
                                maxGiftCardCashOutCents:
                                    storeSettings.maxGiftCardCashOutCents,
                                fundraiserSupportEnabled: false,
                                isCondimentUpsellEnabled: false,
                            };
                            await dispatch(
                                Actions.storeActions.setStores([storeInfo]),
                            );
                            storeSettingsLastUpdateDateRef.current =
                                _storeSettingsLastUpdateDate;
                        }
                    },
                )
                .catch((err) => {
                    logger.warn(err);
                });
        };
    }, [isTraining]);

    const startNewOrder = () => {
        if (isDriveThruFulfillment) {
            navigate(routeHelpers.otherOrders());
        } else {
            if (defaultCategoryId) {
                navigate(routeHelpers.category(defaultCategoryId));
            } else {
                navigate(routeHelpers.menu());
            }
        }
    };

    const defaultButtonText = isDriveThruFulfillment
        ? strings.returnToOrders
        : strings.startNewOrder;

    if (!storeId) {
        return null;
    }

    return (
        <div className={styles.container}>
            <img className={styles.icon} src={completeIcon} />

            <div className={styles.text}>
                <div className={styles.header}>
                    {locationState?.header ??
                        strings.orderComplete(orderNumber ?? 0)}
                </div>
                {locationState?.subheader ? (
                    <div
                        className={
                            locationState?.subheaderClassName ??
                            styles.subheader
                        }>
                        {locationState?.subheader}
                    </div>
                ) : null}
                {locationState?.instructions ? (
                    <div className={styles.instructions}>
                        {locationState?.instructions}
                    </div>
                ) : null}
            </div>

            <div className={styles.spacer} />

            <OutlineButton
                className={styles.button}
                label={locationState?.button ?? defaultButtonText}
                mode="blue"
                onClick={startNewOrder}
            />
        </div>
    );
}
