import * as posModels from "./posModels";
import instance from "./instance";
import {Lib} from "habit-core";
import {reports as urls} from "./urls";

function parseBalanceReport(
    report: posModels.BalanceReportAPIResponse,
): posModels.BalanceReport {
    return {
        ...report,
        cashSummaries: report.cashSummaries.map((c) => ({
            ...c,
            createDate: Lib.dates.safeParseDate(c.createDate) as Date,
        })),
    };
}

export function getTimePeriod(
    date: Date,
    isTraining = false,
): Promise<posModels.TimePeriodReport> {
    return instance
        .request({
            url: urls.timePeriod,
            method: "GET",
            params: {
                date: date.toLocaleDateString("en-ca"),
                isTraining,
            },
        })
        .then((response) => response.data);
}

export function getBalance(
    date: Date,
    isTraining = false,
): Promise<posModels.BalanceReport> {
    return instance
        .request({
            url: urls.balance,
            method: "GET",
            params: {
                date: date.toLocaleDateString("en-ca"),
                isTraining,
            },
        })
        .then((response) => parseBalanceReport(response.data));
}

export function getSalesDetail(
    date: Date,
    isTraining = false,
): Promise<posModels.SalesReport> {
    return instance
        .request({
            url: urls.sales,
            method: "GET",
            params: {
                date: date.toLocaleDateString("en-ca"),
                isTraining,
            },
        })
        .then((response) => response.data);
}
