import * as posModels from "./api/posModels";
import {Lib, ReduxModels} from "habit-core";

export default function reduxToPosItemCustomization(
    itemCustomization: ReduxModels.ItemCustomization,
): posModels.ItemCustomization {
    return {
        itemId: itemCustomization.itemId,
        modifiers: Lib.util.modsObjToArr(itemCustomization.modifierSelections),
    };
}
