import * as routeHelpers from "../../lib/routeHelpers";
import MenuProductLink from "../MenuProductLink";
import React from "react";
import styles from "./GiftCardsHome.module.css";
import {Outlet} from "react-router-dom";
import {useAppSelector} from "../../lib/hooks";

const strings = {
    purchase: "Gift Cards Purchase",
    balance: "Gift Cards Balance",
    cashOut: "Gift Card Cash-Out",
};

type GIFT_CARD_LINK = {
    id: "purchase" | "balance" | "cash-out";
    to: string;
    label: string;
};

const LINKS: GIFT_CARD_LINK[] = [
    {
        id: "purchase",
        to: routeHelpers.giftCardsPurchase(),
        label: strings.purchase,
    },
    {
        id: "balance",
        to: routeHelpers.giftCardsBalance(),
        label: strings.balance,
    },
    {
        id: "cash-out",
        to: routeHelpers.giftCardsCashOut(),
        label: strings.cashOut,
    },
];

export default function GiftCardsHome() {
    const maxGiftCardCashOutCents = useAppSelector(
        (state) =>
            state.stores.byId[state.currentOrder.storeId ?? ""]
                ?.maxGiftCardCashOutCents ?? 0,
    );

    const stationMode = useAppSelector((state) => state.pos.station.mode);

    const getIsDisabled = (id: "purchase" | "balance" | "cash-out") => {
        if (id === "cash-out") {
            return (
                maxGiftCardCashOutCents === 0 ||
                stationMode === "drive_thru_order_fulfillment"
            );
        }

        if (id === "purchase") {
            return stationMode === "drive_thru_order_fulfillment";
        }

        return false;
    };

    return (
        <div className={styles.container}>
            {LINKS.map((l) => (
                <MenuProductLink
                    key={l.label}
                    url={l.to}
                    name={l.label}
                    hideImage={true}
                    disabled={getIsDisabled(l.id)}
                />
            ))}
            <Outlet />
            <div className={styles.spacer} />
        </div>
    );
}
