import * as orderDisplayUtil from "../../lib/orderDisplayUtil";
import * as posActions from "../../lib/posActions";
import * as posModels from "../../lib/api/posModels";
import * as routeHelpers from "../../lib/routeHelpers";
import cn from "classnames";
import describeNetworkError from "../../lib/describeNetworkError";
import ErrorModal from "../ErrorModal";
import logger from "../../lib/logger";
import ManagerHeader from "../ManagerHeader";
import OrderDetailsDrawer from "../OrderDetailsDrawer";
import OrderFilterDrawer from "../OrderFilterDrawer";
import OutlineButton from "../OutlineButton";
import React, {useState, useContext, useMemo} from "react";
import sortBy from "lodash.sortby";
import Spinner from "../Spinner";
import styles from "./ManagerCancel.module.css";
import TextModal from "../TextModal";
import useRefreshOrders from "../../lib/useRefreshOrders";
import {CashierModeContext} from "../CashierModeContext";
import {
    DEFAULT_FILTERS,
    Filters,
    getFilteredOrders,
} from "../../lib/filterOrders";
import {getIsCurrentOrderEmpty} from "../../lib/posSelectors";
import {Lib} from "habit-core";
import {useAppDispatch, useAppSelector} from "../../lib/hooks";
import {useNavigate} from "react-router-dom";

const strings = {
    cancelOrder: "Cancel Order",
    clearOrder: "Clear Order",
    currentOrder: "Current Order :",
    orderNum: "Order #",
    pending: "Pending Orders :",
    noOrders: "No Orders",
    open: "Open Order",
    closed: "Closed Orders :",
    cancelled: "Order Cancelled",
    fulfilled: "Order Fulfilled",
    viewDetails: "View Details",
    confirm: "Confirmation",
    confirmCancelText: "Are you sure you want to cancel this order?",
    confirmClearText: "Are you sure you want to clear this order?",
    goBack: "Go Back",
    submitCancel: "Cancel This Order",
    submitClear: "Clear This Order",
    confirmOpenText1: (orderNum: number) =>
        `Are you sure you want to open order #${orderNum}?`,
    confirmOpenText2: "This will overwrite any current order data.",
    submitOpen: "Open This Order",
    requestSent: "Request Sent",
    confirmPrinted:
        "Your request to print this order's receipt has been submitted",
    ok: "OK",
    filter: "Filter Orders",
    filtersApplied: (num: number) =>
        `${num} ${num === 1 ? "Filter" : "Filters"} Applied`,
};

export default function ManagerCancel() {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [cancelOrderId, setCancelOrderId] = useState<string | null>(null);
    const [cancelling, setCancelling] = useState(false);
    const [orderDetails, setOrderDetails] =
        useState<posModels.OrderParsed | null>(null);
    const dispatch = useAppDispatch();
    const {cashierMode, setCashierMode, isTraining} =
        useContext(CashierModeContext);

    const {initialLoading, initialErrorMessage, closeInitialErrorMessage} =
        useRefreshOrders(["pending", "paid", "cancelled"], ["pos"], isTraining);

    const currentOrder = useAppSelector((state) => state.currentOrder);
    const posCurrentOrder = useAppSelector((state) => state.pos.currentOrder);
    const currentOrderIsEmpty = useAppSelector((state) =>
        getIsCurrentOrderEmpty(state),
    );

    /* filtering related state */
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
    const [activeFilters, setActiveFilters] =
        useState<Filters>(DEFAULT_FILTERS);

    /* pending and closed orders */
    const ordersById = useAppSelector((state) => state.pos.orders.byId);

    const unfilteredPendingOrders = useMemo(() => {
        const orderIds = Object.keys(ordersById);
        return orderIds
            .filter(
                (id) =>
                    ordersById[id].status === "pending" &&
                    ordersById[id].orderNumber !== posCurrentOrder.orderNumber,
            )
            .map((id) => ordersById[id]);
    }, [ordersById]);

    const filteredPendingOrders = useMemo(() => {
        return getFilteredOrders(unfilteredPendingOrders, activeFilters);
    }, [unfilteredPendingOrders, activeFilters]);

    const sortedClosedOrders = useMemo(() => {
        const orderIds = Object.keys(ordersById);
        const closedOrders = orderIds
            .filter(
                (id) =>
                    ordersById[id].status === "paid" ||
                    ordersById[id].status === "cancelled",
            )
            .map((id) => ordersById[id]);
        return sortBy(
            closedOrders,
            (o) => o.orderDate.getDate(),
            (o) => o.orderNumber,
        ).reverse();
    }, [ordersById]);

    const filteredAndSortedClosedOrders = useMemo(() => {
        return getFilteredOrders(sortedClosedOrders, activeFilters);
    }, [sortedClosedOrders, activeFilters]);

    const subtotalCents = useAppSelector((state) =>
        Lib.selectors.getCurrentOrderSubtotalCents(state, false),
    );

    function onCancelPendingOrder() {
        if (cashierMode.type !== "manager") {
            logger.warn(
                "attempting to cancel pending order when not in manager mode",
            );
            return;
        }

        if (cancelling || !cancelOrderId) {
            return;
        }

        const order = ordersById[cancelOrderId];
        setCancelling(true);
        dispatch(posActions.cancelOrder(order.id))
            .then(() => {
                setCancelling(false);
                setCancelOrderId(null);
                navigate(routeHelpers.menu());
                setCashierMode({type: "user"});
            })
            .catch((err) => {
                logger.warn(err);
                const description = describeNetworkError(err);
                setErrorMessage(description.join("\n"));
                setCancelling(false);
                setCancelOrderId(null);
            });
    }

    function onCancelCurrentOrder() {
        if (cashierMode.type !== "manager") {
            logger.warn(
                "attempting to cancel pending order when not in manager mode",
            );
            return;
        }

        if (
            currentOrder.orderType === null ||
            currentOrder.orderType === "curbside" ||
            currentOrder.orderType === "delivery"
        ) {
            logger.warn(
                `attempting to cancel current pos order with status of ${currentOrder.orderType}`,
            );
            return;
        }

        if (posCurrentOrder.id === null) {
            logger.warn(
                "attempting to cancel current pos order with null current order id",
            );
            return;
        }

        if (cancelling) {
            return;
        }

        dispatch(posActions.removeAllProductsFromCurrentOrder());
        setCancelOrderId(null);
        navigate(routeHelpers.menu());
        setCashierMode({type: "user"});
    }

    const numActiveFilters =
        activeFilters.dates.length +
        (activeFilters.stationModes.length
            ? Math.ceil(activeFilters.stationModes.length / 2) - 1
            : 0) +
        activeFilters.sources.length +
        (activeFilters.text ? 1 : 0);

    const header = (
        <ManagerHeader
            label={strings.cancelOrder}
            extraText={
                numActiveFilters !== 0
                    ? strings.filtersApplied(numActiveFilters)
                    : undefined
            }
            buttonLabel={strings.filter}
            buttonDisabled={
                !unfilteredPendingOrders.length && !sortedClosedOrders.length
            }
            buttonOnClick={() => setIsFilterDrawerOpen(true)}
        />
    );

    if (initialLoading) {
        return (
            <>
                {header}
                <div className={styles.spinnerContainer}>
                    <Spinner />
                </div>
            </>
        );
    }

    return (
        <>
            {header}
            <div className={styles.sectionsContainer}>
                <div className={cn(styles.section, styles.currentOrderSection)}>
                    <div className={styles.sectionName}>
                        {strings.currentOrder}
                    </div>
                    <div
                        className={cn(styles.orderRow, styles.currentOrderRow)}>
                        <div className={styles.orderNum}>
                            <div className={styles.orderLink}>
                                {strings.orderNum}
                                {posCurrentOrder.orderNumber}
                            </div>
                        </div>

                        <div className={styles.infoRow}>
                            <div className={styles.total}>
                                {Lib.currency.centsToDollarString(
                                    subtotalCents,
                                )}
                            </div>
                            <OutlineButton
                                className={styles.cancelCurrentOrder}
                                mode="red"
                                label={strings.clearOrder}
                                disabled={
                                    cancelling ||
                                    !posCurrentOrder.id ||
                                    currentOrderIsEmpty
                                }
                                onClick={() =>
                                    setCancelOrderId(posCurrentOrder.id)
                                }
                            />
                        </div>
                    </div>
                </div>
                <div
                    className={cn(styles.section, styles.pendingOrdersSection)}>
                    <div
                        className={cn(
                            styles.sectionName,
                            styles.pendingSectionName,
                        )}>
                        {strings.pending}
                    </div>
                    {filteredPendingOrders.length === 0 ? (
                        <div className={styles.noOrders}>
                            {strings.noOrders}
                        </div>
                    ) : null}
                    {filteredPendingOrders.map((o) => (
                        <div
                            key={o.id}
                            className={cn(
                                styles.orderRow,
                                styles.pendingOrderRow,
                            )}>
                            <div className={styles.orderNumContainer}>
                                <div className={styles.orderNum}>
                                    <span>
                                        {`${strings.orderNum} ${o.orderNumber}`}
                                    </span>
                                </div>
                                <div className={styles.stationName}>
                                    {orderDisplayUtil.getStationNameText(
                                        o.stationMode,
                                    ) ??
                                        orderDisplayUtil.getOrderSourceText(
                                            o.source,
                                            o.type,
                                        )}
                                </div>
                                <div className={styles.orderPlacementTime}>
                                    {Lib.dates.format(o.orderDate, "MM/DD/YY")}
                                    {" : "}
                                    {Lib.dates.format(o.orderDate, "hh:mm:ssa")}
                                </div>
                            </div>
                            <div className={styles.infoRow}>
                                <div className={styles.total}>
                                    {Lib.currency.centsToDollarString(
                                        o.totalCents,
                                    )}
                                </div>
                                <div className={styles.buttonsRow}>
                                    <OutlineButton
                                        className={styles.viewDetails}
                                        onClick={() => setOrderDetails(o)}
                                        label={strings.viewDetails}
                                    />
                                    <OutlineButton
                                        className={styles.cancelPendingButton}
                                        label={strings.cancelOrder}
                                        mode="red"
                                        onClick={() => setCancelOrderId(o.id)}
                                        disabled={cancelling}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.closedSection}>
                    <div
                        className={cn(
                            styles.sectionName,
                            styles.closedSectionName,
                        )}>
                        {strings.closed}
                    </div>
                    {filteredAndSortedClosedOrders.length === 0 ? (
                        <div className={styles.noOrders}>
                            {strings.noOrders}
                        </div>
                    ) : null}
                    {filteredAndSortedClosedOrders.map((o) => (
                        <div
                            key={o.id}
                            className={cn(styles.orderRow, styles.closedRow)}>
                            <div className={styles.orderNumContainer}>
                                <div
                                    className={cn(
                                        styles.orderNum,
                                        styles.greyedText,
                                    )}>
                                    <span>
                                        {`${strings.orderNum} ${o.orderNumber}`}
                                    </span>
                                </div>
                                <div
                                    className={cn(
                                        styles.stationName,
                                        styles.greyedText,
                                    )}>
                                    {orderDisplayUtil.getStationNameText(
                                        o.stationMode,
                                    ) ??
                                        orderDisplayUtil.getOrderSourceText(
                                            o.source,
                                            o.type,
                                        )}
                                </div>
                                <div
                                    className={cn(
                                        styles.orderPlacementTime,
                                        styles.greyedText,
                                    )}>
                                    {Lib.dates.format(o.orderDate, "MM/DD/YY")}
                                    {" : "}
                                    {Lib.dates.format(o.orderDate, "hh:mm:ssa")}
                                </div>
                            </div>
                            <div
                                className={cn(
                                    styles.infoRow,
                                    styles.closedInfoRow,
                                )}>
                                <div className={styles.total}>
                                    {Lib.currency.centsToDollarString(
                                        o.totalCents,
                                    )}
                                </div>
                                <div className={styles.fulfilled}>
                                    {o.status === "paid"
                                        ? strings.fulfilled
                                        : null}
                                    {o.status === "cancelled"
                                        ? strings.cancelled
                                        : null}
                                </div>
                                <OutlineButton
                                    className={styles.viewDetails}
                                    label={strings.viewDetails}
                                    onClick={() => setOrderDetails(o)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {orderDetails ? (
                <OrderDetailsDrawer
                    order={orderDetails}
                    onClose={() => setOrderDetails(null)}
                    showPrintButton={true}
                />
            ) : null}
            {isFilterDrawerOpen ? (
                <OrderFilterDrawer
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    onClose={() => setIsFilterDrawerOpen(false)}
                />
            ) : null}
            {errorMessage || initialErrorMessage ? (
                <ErrorModal
                    errorMessage={errorMessage || initialErrorMessage}
                    onClose={() =>
                        errorMessage
                            ? setErrorMessage("")
                            : closeInitialErrorMessage()
                    }
                    showITInfo
                />
            ) : null}
            {cancelOrderId !== null ? (
                <TextModal
                    onClose={() => setCancelOrderId(null)}
                    title={strings.confirm}
                    content={
                        cancelOrderId === posCurrentOrder.id
                            ? strings.confirmClearText
                            : strings.confirmCancelText
                    }
                    cancelLabel={strings.goBack}
                    onSubmit={() =>
                        cancelOrderId === posCurrentOrder.id
                            ? onCancelCurrentOrder()
                            : onCancelPendingOrder()
                    }
                    submitLabel={
                        cancelOrderId === posCurrentOrder.id
                            ? strings.submitClear
                            : strings.submitCancel
                    }
                    submitLoading={cancelling}
                    submitMode="red"
                />
            ) : null}
        </>
    );
}
