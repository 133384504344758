import blueTriangleDown from "../../images/blue-triangle-down.svg";
import cn from "classnames";
import React from "react";
import styles from "./DateDropdown.module.css";
import {isToday} from "date-fns";
import {Lib} from "habit-core";

const strings = {
    today: "Today",
};

type Props = {
    className?: string;
    title: string;
    options: Date[];
    onChange: (i: number) => void;
    value: number;
};

export default function DateDropdown(props: Props) {
    function getDateLabel(o: Date) {
        const formattedDate = Lib.dates.format(o, "MM/DD/YY");
        return `${
            isToday(o) ? strings.today : Lib.dates.format(o, "ddd")
        } : ${formattedDate}`;
    }

    return (
        <div className={cn(props.className, styles.container)}>
            <div className={styles.title}>{props.title}</div>
            <select
                className={styles.select}
                value={props.value}
                onChange={(e) => props.onChange(parseInt(e.target.value))}>
                {props.options.map((o, i) => (
                    <option
                        key={o.toISOString()}
                        label={getDateLabel(o)}
                        value={i}
                    />
                ))}
            </select>
            <img className={styles.arrow} src={blueTriangleDown} alt="" />
        </div>
    );
}
