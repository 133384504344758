import * as orders from "../../lib/api/orders";
import * as posModels from "../../lib/api/posModels";
import AddCashAmount from "../AddCashAmount";
import cn from "classnames";
import describeNetworkError from "../../lib/describeNetworkError";
import ErrorModal from "../ErrorModal";
import logger from "../../lib/logger";
import ManagerHeader from "../ManagerHeader";
import OpenRegisterDrawer from "../OpenRegisterDrawer";
import OutlineButton from "../OutlineButton";
import React, {useState, useContext, useRef, useEffect} from "react";
import RegisterDrawerIsOpen from "../RegisterDrawerIsOpen";
import StyledKeyboard from "../StyledKeyboard";
import styles from "./ManagerPaidOuts.module.css";
import TextInput from "../TextInput";
import ToggleButton from "../ToggleButton";
import useOnChangeCashAmount from "../../lib/useOnChangeCashAmount";
import {API, Lib} from "habit-core";
import {CashierModeContext} from "../CashierModeContext";

const strings = {
    title: "Required Paid Out Entry",
    continue: "Continue",
    enterAmount: "Enter An Amount of Cash To Take From The Register",
    category: "Category :",
    reason: "Reason For Paid Out",
    reasonPlaceholder: "Enter Reason Here",
    food: "Food Paid Out",
    beverage: "Beverage Paid Out",
    paper: "Paper Paid Out",
    repair: "Repair & Maintenance",
    misc: "Miscellaneous Paid Out",
};

const CASH_AMOUNTS_CENTS = [100, 500, 1000, 2000, 5000, 10000];
const CATEGORIES: {value: posModels.PaidOutReason; label: string}[] = [
    {value: "food", label: strings.food},
    {value: "beverage", label: strings.beverage},
    {value: "paper", label: strings.paper},
    {value: "repair", label: strings.repair},
    {value: "miscellaneous", label: strings.misc},
];

export default function ManagerPaidOuts() {
    const {cashierMode} = useContext(CashierModeContext);
    const [cashAmountCents, setCashAmountCents] =
        useState<API.models.USDCents | null>(null);
    const [displayedCashAmount, setDisplayedCashAmount] = useState("");
    const [category, setCategory] = useState<posModels.PaidOutReason | null>(
        null,
    );
    const [reason, setReason] = useState("");
    const [isPromptOpen, setIsPromptOpen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    /* keyboard */
    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true);
    const [focusedInput, setFocusedInput] = useState<"cash" | "reason">("cash");
    const cashAmountRef = useRef<HTMLInputElement>(null);
    const reasonRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!keyboardCollapsed) {
            if (
                cashAmountRef.current &&
                document.activeElement === cashAmountRef.current
            ) {
                cashAmountRef.current?.scrollIntoView();
            } else if (
                reasonRef.current &&
                document.activeElement === reasonRef.current
            ) {
                reasonRef.current?.scrollIntoView();
            }
        }
    }, [keyboardCollapsed]);

    useEffect(() => {
        const floatVal = parseFloat(displayedCashAmount);
        if (
            isNaN(floatVal) ||
            cashAmountCents !== Lib.currency.dollarsFloatToCents(floatVal)
        ) {
            setDisplayedCashAmount(
                cashAmountCents
                    ? Lib.currency.centsToDollarString(cashAmountCents)
                    : "",
            );
        }
    }, [cashAmountCents]);

    function openDrawer() {
        if (cashierMode.type !== "manager") {
            logger.warn(
                "attempting to access paid outs when not in manager mode",
            );
            return;
        }

        if (loading || !cashAmountCents || !category) {
            return;
        }

        setLoading(true);
        orders
            .paidOut(cashierMode.managerId, cashAmountCents, category, reason)
            .then(() => {
                // TODO: #74 - actually open drawer
                setIsDrawerOpen(true);
                setLoading(false);
                setCashAmountCents(null);
                setCategory(null);
                setReason("");
            })
            .catch((err) => {
                logger.warn(err);
                setLoading(false);
                setErrorMessage(describeNetworkError(err).join("\n"));
            });
    }

    const onChangeCashAmount = useOnChangeCashAmount(
        setDisplayedCashAmount,
        setCashAmountCents,
    );

    const header = <ManagerHeader label={strings.title} />;

    if (isDrawerOpen) {
        return (
            <>
                {header}
                <RegisterDrawerIsOpen />
            </>
        );
    }

    return (
        <>
            <div className={styles.container}>
                {header}
                <div className={styles.content}>
                    <div className={styles.section1}>
                        <AddCashAmount
                            label={strings.enterAmount}
                            optionsCents={CASH_AMOUNTS_CENTS}
                            cashAmountCents={cashAmountCents}
                            setCashAmountCents={setCashAmountCents}
                            displayedCashAmount={displayedCashAmount}
                            onFocus={() => {
                                setFocusedInput("cash");
                                setKeyboardCollapsed(false);
                            }}
                            ref={cashAmountRef}
                        />
                    </div>
                    <div className={styles.section2}>
                        <div className={styles.text}>{strings.category}</div>
                        <div className={styles.categories}>
                            {CATEGORIES.map((c) => (
                                <ToggleButton
                                    key={c.value}
                                    className={styles.category}
                                    label={c.label}
                                    checked={category === c.value}
                                    onChange={() =>
                                        setCategory((prev) =>
                                            prev === c.value ? null : c.value,
                                        )
                                    }
                                />
                            ))}
                        </div>
                        <div className={styles.text}>{strings.reason}</div>
                        <TextInput
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            onFocus={() => {
                                setKeyboardCollapsed(false);
                                setFocusedInput("reason");
                            }}
                            placeholder={strings.reasonPlaceholder}
                            ref={reasonRef}
                            inputMode="none"
                        />
                    </div>
                </div>
                <div className={styles.footer}>
                    <OutlineButton
                        className={styles.footerButton}
                        label={strings.continue}
                        disabled={!cashAmountCents || !category}
                        onClick={() => setIsPromptOpen(true)}
                    />
                </div>
                <div
                    className={cn(
                        styles.keyboardContainer,
                        keyboardCollapsed && styles.keyboardContainerCollapsed,
                    )}>
                    <StyledKeyboard
                        currentInput={
                            focusedInput === "cash"
                                ? displayedCashAmount
                                : reason
                        }
                        visible={!keyboardCollapsed}
                        setVisible={(val: boolean) =>
                            setKeyboardCollapsed(!val)
                        }
                        onChange={
                            focusedInput === "cash"
                                ? onChangeCashAmount
                                : (input: string) => setReason(input)
                        }
                        inputRefs={[cashAmountRef, reasonRef]}
                        layout={focusedInput === "cash" ? "numeric" : "default"}
                        supportsMultiple
                    />
                </div>
            </div>
            {isPromptOpen ? (
                <OpenRegisterDrawer
                    onClose={() => setIsPromptOpen(false)}
                    onOpenRegister={openDrawer}
                    loading={loading}
                />
            ) : null}
            {errorMessage ? (
                <ErrorModal
                    errorMessage={errorMessage}
                    onClose={() => setErrorMessage("")}
                    showITInfo
                />
            ) : null}
        </>
    );
}
