import * as posModels from "./posModels";
import instance from "./instance";
import {discounts as urls} from "./urls";

export function validate(
    redemptionCode: string,
    combos: posModels.ComboDiscountInfo[],
    items: posModels.ItemDiscountInfo[],
    orderId: string,
    menuId: string,
    employeeId?: string,
    reason?: string,
    existingDiscountType?: posModels.DiscountType,
): Promise<posModels.DiscountResponse> {
    return instance
        .request({
            url: urls.validateOrRedeem,
            method: "POST",
            data: {
                redeem: false,
                redemptionCode,
                orderId,
                menuId,
                combos,
                items,
                employeeId,
                reason,
                existingDiscountType,
            },
        })
        .then((response) => response.data);
}

export function redeem(
    redemptionCode: string,
    combos: posModels.ComboDiscountInfo[],
    items: posModels.ItemDiscountInfo[],
    orderId: string,
    menuId: string,
): Promise<posModels.DiscountResponse> {
    return instance
        .request({
            url: urls.validateOrRedeem,
            method: "POST",
            data: {
                redeem: true,
                redemptionCode,
                orderId,
                menuId,
                combos,
                items,
            },
        })
        .then((response) => response.data);
}

export function unredeem(redemptionCode: string): Promise<void> {
    return instance
        .request({
            url: urls.unredeem + redemptionCode,
            method: "POST",
        })
        .then((response) => response.data);
}
