import React, {useEffect, useState, createContext} from "react";
import instance from "../lib/api/instance";
import * as trainingMock from "../lib/api/trainingMock";

type UserMode = {
    type: "user";
    firstName?: string;
    lastName?: string;
};

type ManagerMode = {
    managerId: string;
    type: "manager";
    firstName: string;
    lastName: string;
};

type CashierMode = UserMode | ManagerMode;

type ModeContext = {
    cashierMode: CashierMode;
    setCashierMode: (mode: CashierMode) => void;
    isTraining: boolean;
    toggleIsTraining: (turnOn: boolean) => void;
    errorAfterPaymentsProcessed: boolean;
    setErrorAfterPaymentsProcessed: (val: boolean) => void;
};

export const CashierModeContext = createContext<ModeContext>({
    cashierMode: {
        type: "user",
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setCashierMode: () => {},
    isTraining: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    toggleIsTraining: () => {},
    errorAfterPaymentsProcessed: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setErrorAfterPaymentsProcessed: () => {},
});

export function CashierModeProvider(props: {children: React.ReactNode}) {
    const [isTraining, setIsTraining] = useState(false);
    const [cashierMode, setCashierMode] = useState<CashierMode>({
        type: "user",
    });
    const [errorAfterPaymentsProcessed, setErrorAfterPaymentsProcessed] =
        useState(false);

    useEffect(() => {
        if (!isTraining) {
            trainingMock.removeMock();
        } else {
            trainingMock.setMockResponses(instance);
        }
    }, [isTraining]);

    function toggleIsTraining(turnOn: boolean) {
        setIsTraining(turnOn);
        setCashierMode({
            type: "user",
        });
    }

    return (
        <CashierModeContext.Provider
            value={{
                cashierMode,
                setCashierMode,
                isTraining,
                toggleIsTraining,
                errorAfterPaymentsProcessed,
                setErrorAfterPaymentsProcessed,
            }}>
            {props.children}
        </CashierModeContext.Provider>
    );
}
