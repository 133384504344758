import * as routeHelpers from "../../lib/routeHelpers";
import {CashierModeContext} from "../CashierModeContext";
import ManagerPermissionForm from "../ManagerPermissionForm";
import React, {useContext, useState} from "react";
import styles from "./ManagerHome.module.css";
import TextButton from "../TextButton";
import ConfirmationModal from "../ConfirmationModal";

const strings = {
    welcome: "Welcome Back,",
    restart: "Restart POS",
    confirmationModalTitle: "Are you sure you want to restart the POS?",
    confirmLabel: "Yes, restart",
    cancelLabel: "Cancel",
};

export default function ManagerHome() {
    const {cashierMode, setCashierMode} = useContext(CashierModeContext);
    const [restartConfirmationOpen, setRestartConfirmationOpen] =
        useState(false);

    return (
        <div className={styles.container}>
            <TextButton
                label={strings.restart}
                onClick={() => setRestartConfirmationOpen(true)}
                className={styles.restartButton}
            />
            {cashierMode.type === "manager" ? (
                <div className={styles.welcome}>
                    {strings.welcome} {cashierMode.firstName}.
                </div>
            ) : (
                <ManagerPermissionForm
                    showReturnPrompt={true}
                    onSuccess={(data) =>
                        setCashierMode({
                            managerId: data.id,
                            type: "manager",
                            firstName: data.firstName,
                            lastName: data.lastName,
                        })
                    }
                />
            )}
            <ConfirmationModal
                isOpen={restartConfirmationOpen}
                title={strings.confirmationModalTitle}
                confirmLabel={strings.confirmLabel}
                cancelLabel={strings.cancelLabel}
                onPressConfirm={() => {
                    location.pathname = routeHelpers.menu();
                }}
                onPressCancel={() => {
                    setRestartConfirmationOpen(false);
                }}
            />
        </div>
    );
}
