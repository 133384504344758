import * as routeHelpers from "../../lib/routeHelpers";
import * as searchParamNames from "../../constants/searchParamNames";
import cn from "classnames";
import ComboItem from "../ComboItem";
import getInitialModSelections from "../../lib/getInitialModSelections";
import React, {useState, useEffect, useMemo} from "react";
import Drawer from "../Drawer";
import ItemEditor from "../ItemEditor";
import logger from "../../lib/logger";
import OutlineButton from "../OutlineButton";
import RetinaImage from "../RetinaImage";
import SplitDrawerContent from "../SplitDrawerContent";
import styles from "./ComboCustomization.module.css";
import {Actions, API, Lib} from "habit-core";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../lib/hooks";
import QuantityControl from "../QuantityControl";

const strings = {
    addToOrder: "Add To Order",
    updateOrder: "Update Order",
    cancel: "Cancel",
};

type Params = {
    categoryId: string;
    comboId: string;
};

type ComboItemCustomization = {
    itemId: string;
    modifierSelections: API.models.ModifierSelectionDict;
};

function ComputeAllPrepsPriceCents(
    comboModSelections: ComboItemCustomization[],
    menuItemModifiers: {
        [itemId: string]: {
            [modifierId: string]: API.models.MenuItemModifier;
        };
    },
    allPreps: {byId: {[modifierId: string]: Lib.selectors.ItemModifierData}},
) {
    const allPrepsPriceCents = comboModSelections.reduce(
        (sum, comboModSelection) => {
            let currentSumCents = 0;
            Object.keys(comboModSelection.modifierSelections).forEach(
                (modId) => {
                    const mod =
                        menuItemModifiers[comboModSelection.itemId]?.[modId];
                    // if the mod was found in the global menu, its price has already been accounted for.
                    if (!mod) {
                        const allPrepsMod = allPreps.byId[modId];
                        const allPrepModSelection =
                            allPrepsMod?.selections.find(
                                (selection) =>
                                    selection.id ===
                                    comboModSelection.modifierSelections[modId]
                                        ?.selectionId,
                            );

                        if (
                            allPrepModSelection &&
                            allPrepModSelection.priceCents
                        ) {
                            currentSumCents += allPrepModSelection.priceCents;
                        }
                    }
                },
            );
            return (sum += currentSumCents);
        },
        0,
    );

    return allPrepsPriceCents;
}

export default function ComboCustomization() {
    const navigate = useNavigate();
    const {categoryId, comboId} = useParams<Params>();
    const [searchParams] = useSearchParams();
    const ccid = searchParams.get(searchParamNames.COMBO_CUSTOMIZATION_ID);
    const menuId = useAppSelector((state) => state.currentOrder.menuId);
    useEffect(() => {
        if (!menuId || !categoryId || !comboId) {
            logger.warn(
                `invalid menu id (${menuId}), category id (${categoryId}), and/or combo id (${comboId})`,
            );
            navigate(routeHelpers.menu(), {replace: true});
        }
    }, []);

    const combo = useAppSelector((state) =>
        comboId ? state.combos.byId[comboId] : null,
    );
    useEffect(() => {
        if (!combo) {
            logger.warn(`No combo found with comboId ${comboId}`);
            navigate(routeHelpers.menu(), {replace: true});
        }
    }, []);

    const getSubstitutionItems = useMemo(
        () => Lib.selectors.createGetSubtitutionItems(true),
        [],
    );

    const itemsById = useAppSelector((state) => state.items.byId);
    const menuItems = useAppSelector((state) => state.menuItems.byMenuId);
    const menuItemModifiers = useAppSelector(
        (state) => state.menuItemModifiers.byMenuId,
    );
    const menuCombos = useAppSelector((state) => state.menuCombos.byMenuId);
    const combos = useAppSelector((state) => state.combos.byId);
    const initialCustomization = useAppSelector((state) => {
        if (!ccid) {
            return null;
        }

        return state.customizations.combos.byInternalId[ccid] ?? null;
    });

    const dispatch = useAppDispatch();
    const [quantity, setQuantity] = useState(
        initialCustomization ? initialCustomization.quantity : 1,
    );
    const onClose = () =>
        navigate(
            categoryId
                ? routeHelpers.category(categoryId)
                : routeHelpers.menu(),
        );

    let defaultItems: ComboItemCustomization[] =
        menuId && combo
            ? combo.itemIds.map((itemId) => ({
                  itemId: itemId,
                  modifierSelections: getInitialModSelections(
                      itemsById,
                      itemId,
                      menuItems,
                      menuId,
                      menuItemModifiers,
                      null,
                      true,
                  ),
              }))
            : [];

    if (initialCustomization) {
        defaultItems = useAppSelector((state) =>
            initialCustomization.itemCustomizationIds.map((icid) => {
                const itemId =
                    state.customizations.items.byInternalId[icid].itemId;
                const defaultMods = getInitialModSelections(
                    itemsById,
                    itemId,
                    menuItems,
                    menuId ?? "",
                    menuItemModifiers,
                    state.customizations.items.byInternalId[icid],
                    true,
                );
                return {
                    itemId,
                    modifierSelections: {
                        ...defaultMods,
                        ...state.customizations.items.byInternalId[icid]
                            .modifierSelections,
                    },
                };
            }),
        );
    }

    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [items, setItems] = useState(defaultItems);

    const onSave = () => {
        if (!combo) {
            logger.error("No combo when calling on save");
            return;
        }

        if (initialCustomization) {
            dispatch(
                Actions.customizationActions.modifyComboCustomization(
                    initialCustomization.customizationId,
                    quantity,
                    items,
                ),
            );
        } else {
            dispatch(
                Actions.currentOrderActions.addCombo(combo.id, quantity, items),
            );
        }

        onClose();
    };

    const itemsSubstitutionOptions = useAppSelector((state) =>
        items.map((item) =>
            getSubstitutionItems(state, comboId ?? "", menuId, item.itemId),
        ),
    );

    const baseUnitPriceCents = Lib.selectors.getComboUnitPriceCents(
        comboId ?? "",
        menuId,
        items,
        menuItems,
        menuItemModifiers,
        menuCombos,
        combos,
        false,
        false,
    );

    const allPreps = useAppSelector((state) => state.pos.allPreps);

    const allPrepsComboPriceCents = ComputeAllPrepsPriceCents(
        items,
        menuItemModifiers[menuId ?? ""],
        allPreps,
    );

    const totalPriceCents =
        (Lib.selectors.getComboUnitPriceCents(
            comboId ?? "",
            menuId,
            items,
            menuItems,
            menuItemModifiers,
            menuCombos,
            combos,
            false,
        ) +
            allPrepsComboPriceCents) *
        quantity;
    const baseUnitPriceFormatted =
        Lib.currency.centsToDollarString(baseUnitPriceCents);
    const totalPriceFormatted =
        Lib.currency.centsToDollarString(totalPriceCents);

    if (!categoryId || !comboId || !menuId || !combo) {
        return null;
    }

    const updateModSelection = (
        modifierId: string,
        selectionId: string,
        quantity: number,
        isDefault: boolean,
        isRequired: boolean,
        itemIndex?: number,
    ) => {
        setItems((prevState) => {
            let item: ComboItemCustomization | undefined;
            let tempActiveItemIndex = activeItemIndex;
            if (itemIndex === undefined) {
                item = prevState[activeItemIndex];
            } else {
                tempActiveItemIndex = itemIndex;
                item = prevState[tempActiveItemIndex];
            }

            if (!item) {
                return prevState;
            }

            if (
                tempActiveItemIndex < 0 ||
                tempActiveItemIndex > prevState.length ||
                (item.modifierSelections[modifierId]?.selectionId ===
                    selectionId &&
                    item.modifierSelections[modifierId]?.quantity === quantity)
            ) {
                return prevState;
            }

            const nextModSelections = {...item.modifierSelections};
            if (isDefault && !isRequired) {
                delete nextModSelections[modifierId];
            } else {
                nextModSelections[modifierId] = {
                    selectionId,
                    quantity,
                };
            }

            // TODO: #56 - Do we need dirty tracking?
            return [
                ...prevState.slice(0, tempActiveItemIndex),
                {
                    ...item,
                    modifierSelections: nextModSelections,
                },
                ...prevState.slice(tempActiveItemIndex + 1),
            ];
        });
    };

    const getExistingAllPrepsMods = (
        currentItemModSelections: API.models.ModifierSelectionDict,
    ) => {
        const appliedAllPrepsMods: API.models.ModifierSelectionDict = {};
        Object.keys(currentItemModSelections).forEach((modId) => {
            const allPrepsMod = allPreps.byId[modId];
            const currentItemModSelection = currentItemModSelections[modId];
            const allPrepsModSelection = allPrepsMod?.selections.find(
                (sel) => sel.id === currentItemModSelection.selectionId,
            );
            if (allPrepsMod && allPrepsModSelection) {
                appliedAllPrepsMods[modId] = currentItemModSelection;
            }
        });
        return appliedAllPrepsMods;
    };

    const onSubstitute = (replacingItemId: string) => {
        setItems((prevState) => {
            const item = prevState[activeItemIndex];
            if (
                activeItemIndex < 0 ||
                activeItemIndex > prevState.length ||
                item.itemId === replacingItemId
            ) {
                return prevState;
            }

            // TODO: #56 - dirty tracking?
            // this will get us the required mods for the substitue item
            const initialModSelections = getInitialModSelections(
                itemsById,
                replacingItemId,
                menuItems,
                menuId,
                menuItemModifiers,
                null,
                true,
            );
            // this will get any mod selections on the item that is being replaced that are valid on the substitute.
            const currentItemModSelections =
                items[activeItemIndex].modifierSelections;
            const existingValidMods =
                Lib.selectors.getExistingValidModsForSubstitute(
                    menuItemModifiers[menuId ?? ""],
                    replacingItemId,
                    currentItemModSelections,
                );
            const existingAllPrepsMods = getExistingAllPrepsMods(
                currentItemModSelections,
            );

            return [
                ...prevState.slice(0, activeItemIndex),
                {
                    itemId: replacingItemId,
                    modifierSelections: {
                        ...initialModSelections,
                        ...existingValidMods,
                        ...existingAllPrepsMods,
                    },
                },
                ...prevState.slice(activeItemIndex + 1),
            ];
        });
    };

    return (
        <Drawer
            onClose={() => navigate(routeHelpers.category(categoryId))}
            closeButtonLabel={strings.cancel}>
            <SplitDrawerContent
                className={styles.content}
                left={
                    <ItemEditor
                        itemId={items[activeItemIndex].itemId}
                        itemKey={`${items[activeItemIndex].itemId}_${activeItemIndex}`}
                        menuId={menuId}
                        modifierSelections={
                            items[activeItemIndex].modifierSelections
                        }
                        onChangeModifier={updateModSelection}
                        substitutions={
                            itemsSubstitutionOptions[activeItemIndex]
                        }
                        onSubstitute={onSubstitute}
                    />
                }
                right={
                    <>
                        <div className={styles.titleAndPrice}>
                            {combo.isolatedImages328.length > 0 && (
                                <div className={styles.imageContainer}>
                                    <RetinaImage
                                        retinaImages={combo.isolatedImages328}
                                        className={styles.image}
                                    />
                                </div>
                            )}
                            <div
                                className={cn(
                                    styles.title,
                                    combo.isolatedImages328.length > 0
                                        ? styles.titleMargin
                                        : null,
                                )}>
                                {combo.name}
                            </div>
                            <div className={styles.price}>
                                {baseUnitPriceFormatted}
                            </div>
                        </div>

                        <div className={styles.divider} />

                        <div className={styles.spacer}>
                            {items.map((x, i) => (
                                // NOTE: Using index instead of item id because box combos can contain duplicate items
                                <ComboItem
                                    key={i}
                                    itemId={x.itemId}
                                    className={styles.comboItem}
                                    active={activeItemIndex === i}
                                    name={itemsById[x.itemId].name}
                                    modifierSelections={x.modifierSelections}
                                    onChangeModifier={(
                                        modifierId: string,
                                        selectionId: string,
                                        quantity: number,
                                        isDefault: boolean,
                                        isRequired: boolean,
                                    ) =>
                                        updateModSelection(
                                            modifierId,
                                            selectionId,
                                            quantity,
                                            isDefault,
                                            isRequired,
                                            i,
                                        )
                                    }
                                    onClick={() => setActiveItemIndex(i)}
                                    images={
                                        itemsById[x.itemId].isolatedImages328
                                    }
                                />
                            ))}
                        </div>

                        <div className={styles.divider} />

                        <div className={styles.qtyAddToOrder}>
                            <QuantityControl
                                quantity={quantity}
                                setQuantity={setQuantity}
                            />
                            <OutlineButton
                                label={totalPriceFormatted}
                                label2={
                                    initialCustomization
                                        ? strings.updateOrder
                                        : strings.addToOrder
                                }
                                onClick={onSave}
                                className={styles.fullWidth}
                            />
                        </div>
                    </>
                }
            />
        </Drawer>
    );
}
