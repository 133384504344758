import * as ccEndpoints from "../../lib/api/creditCardScanner";
import creditCardRegisterIcon from "../../images/credit-card-scanner-instruction.svg";
import creditCardRegisterSuccessIcon from "../../images/credit-card-scanner-success.svg";
import creditCardRegisterFailureIcon from "../../images/credit-card-scanner-failure.svg";
import OutlineButton from "../OutlineButton";
import React, {useEffect, useState} from "react";
import styles from "./CreditCardScannerRegistration.module.css";
import {useAppSelector} from "../../lib/hooks";

const strings = {
    title: "Credit Card Machine Registration",
    configureMainPrompt: "Please Configure Card Reader",
    configureInstruction:
        "Please enter the following PIN into the attached credit card reader.",
    registerSuccessMessage: "Credit Card Reader Registered",
    registerFailureMessage:
        "Failed To Register Credit Card Reader. Please Try Again.",
    okay: "Okay",
    mockButtonLabel: "mock enter code on device",
};

function getRandom4DigitString() {
    return Math.floor(Math.random() * 10000)
        .toString()
        .padStart(4, "0");
}

const isMock = import.meta.env.REACT_APP_MOCK_API === "true" ? true : false;

type Props = {
    onSuccess: () => void;
};

export default function CreditCardScannerRegistration(props: Props) {
    const stationMode = useAppSelector((state) => state.pos.station.mode);
    const [deviceCode, setDeviceCode] = useState<string>(
        getRandom4DigitString(),
    );
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [registrationFailure, setRegistrationFailure] = useState(false);
    const [attemptToRegister, setAttemptToRegister] = useState(true);

    useEffect(() => {
        if (attemptToRegister && !isMock) {
            ccEndpoints
                .registerDevice(stationMode, deviceCode)
                .then((isSuccess) => {
                    setRegistrationSuccess(isSuccess);
                    setRegistrationFailure(!isSuccess);
                    setAttemptToRegister(false);
                });
        }
    }, [attemptToRegister, stationMode]);

    const renderConfigPrompt = () => {
        return (
            <>
                <img
                    src={creditCardRegisterIcon}
                    className={styles.promptImage}
                    alt=""
                />
                <div className={styles.mainText}>
                    {strings.configureMainPrompt}
                </div>
                <div className={styles.secondaryText}>
                    {strings.configureInstruction}
                </div>
                <div className={styles.codeText}>{deviceCode}</div>
                {isMock ? (
                    <OutlineButton
                        label={strings.mockButtonLabel}
                        onClick={() => {
                            setRegistrationSuccess(true);
                        }}
                        mode="blue"
                    />
                ) : null}
            </>
        );
    };

    const renderRegistrationSuccess = () => {
        return (
            <>
                <img
                    src={creditCardRegisterSuccessIcon}
                    className={styles.successAndFailureImage}
                    alt=""
                />
                <div className={styles.mainText}>
                    {strings.registerSuccessMessage}
                </div>
                <OutlineButton
                    label={strings.okay}
                    className={styles.okayButton}
                    mode="blue"
                    onClick={() => {
                        props.onSuccess();
                    }}
                />
            </>
        );
    };

    const renderRegistrationFailure = () => {
        return (
            <>
                <img
                    src={creditCardRegisterFailureIcon}
                    className={styles.successAndFailureImage}
                    alt=""
                />
                <div className={styles.mainText}>
                    {strings.registerFailureMessage}
                </div>
                <OutlineButton
                    label={strings.okay}
                    className={styles.okayButton}
                    mode="blue"
                    onClick={() => {
                        setDeviceCode(getRandom4DigitString());
                        setRegistrationFailure(false);
                        setRegistrationSuccess(false);
                        setAttemptToRegister(true);
                    }}
                />
            </>
        );
    };

    const renderBody = () => {
        if (!registrationSuccess && !registrationFailure) {
            return renderConfigPrompt();
        } else if (registrationSuccess) {
            return renderRegistrationSuccess();
        } else {
            return renderRegistrationFailure();
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>{strings.title}</div>
            <div className={styles.bodyContainer}>{renderBody()}</div>
        </div>
    );
}
