import cn from "classnames";
import React from "react";
import styles from "./Drawer.module.css";
import closeIcon from "../../images/close.svg";

const strings = {
    closeDrawer: "Close Drawer",
};

type Props = {
    children?: React.ReactNode;
    onClose?: () => void;
    isSmall?: boolean;
    closeButtonLabel?: string;
};

export default function Drawer(props: Props) {
    return (
        <div>
            <div
                className={cn(
                    styles.drawer,
                    props.isSmall ? styles.small : styles.large,
                )}>
                <button
                    onClick={props.onClose}
                    className={
                        props.closeButtonLabel
                            ? styles.closeButtonWithLabel
                            : styles.closeButton
                    }>
                    {props.closeButtonLabel ? (
                        <span className={styles.closeButtonLabelStyle}>
                            {props.closeButtonLabel}
                        </span>
                    ) : (
                        <img src={closeIcon} alt="close" />
                    )}
                </button>
                {props.children}
            </div>

            <div
                className={styles.shadow}
                onClick={props.onClose}
                role="button"
                aria-label={strings.closeDrawer}
            />
        </div>
    );
}
