import * as routeHelpers from "../../lib/routeHelpers";
import cn from "classnames";
import React from "react";
import styles from "./MenuCategories.module.css";
import {NavLink} from "react-router-dom";
import {CategoryRedux} from "habit-core/types/reducers/categoriesReducer";
import {connect} from "react-redux";
import {Lib} from "habit-core";
import {RootState} from "../../store";

const strings = {
    offMenu: "Off-Menu / Special",
};

type Props = {
    className?: string;
    categoriesById: Record<string, CategoryRedux>;
    categoryIds: string[];
    disabled?: boolean;
};

function MenuCategories(props: Props) {
    return (
        <div className={cn(styles.container, props.className)}>
            <div
                className={cn(
                    styles.innerContainer,
                    props.disabled && styles.innerContainerDisabled,
                )}>
                {props.categoryIds.map((id) => (
                    <NavLink
                        className={({isActive}) =>
                            cn(
                                styles.category,
                                isActive && styles.categoryActive,
                                props.disabled && styles.disabled,
                            )
                        }
                        to={routeHelpers.category(id)}
                        key={id}>
                        <div className={styles.categoryAndColor}>
                            {props.categoriesById[id].color ? (
                                <div
                                    className={styles.color}
                                    style={{
                                        backgroundColor:
                                            props.categoriesById[id].color ??
                                            undefined,
                                    }}
                                />
                            ) : (
                                <div className={styles.noColorSpacer} />
                            )}
                            <span>{props.categoriesById[id].name}</span>
                        </div>
                    </NavLink>
                ))}
            </div>
            <NavLink
                className={({isActive}) =>
                    cn(
                        styles.category,
                        styles.offMenu,
                        isActive && styles.categoryActive,
                        props.disabled && styles.disabled,
                    )
                }
                to={routeHelpers.offMenu()}>
                {strings.offMenu}
            </NavLink>
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    categoriesById: state.categories.byId,
    categoryIds: Lib.selectors.getCurrentOrderSortedCategoryIds(state),
});

const ConnectedMenuCategories = connect(mapStateToProps)(MenuCategories);
export default ConnectedMenuCategories;
