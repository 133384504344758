import blueTriangleDown from "../../images/blue-triangle-down.svg";
import cn from "classnames";
import React, {useState} from "react";
import styles from "./ManagerReportsSectionDropdown.module.css";

type Props = {
    title: string;
    children: React.ReactNode;
};

export default function ManagerReportsSectionDropdown(props: Props) {
    const [expanded, setExpanded] = useState(false);

    return (
        <div className={styles.container}>
            <button
                className={styles.button}
                onClick={() => setExpanded((prev) => !prev)}>
                <img
                    className={cn(styles.icon, !expanded && styles.iconRight)}
                    src={blueTriangleDown}
                    alt=""
                />
                <div>{props.title}</div>
            </button>
            {expanded ? props.children : null}
        </div>
    );
}
