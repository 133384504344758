import * as routeHelpers from "../../lib/routeHelpers";
import * as searchParamNames from "../../constants/searchParamNames";
import cn from "classnames";
import CustomizationList from "../CustomizationList";
import getInitialModSelections from "../../lib/getInitialModSelections";
import ItemEditor from "../ItemEditor";
import logger from "../../lib/logger";
import React, {useState, useEffect} from "react";
import Drawer from "../Drawer";
import OutlineButton from "../OutlineButton";
import RetinaImage from "../RetinaImage";
import SplitDrawerContent from "../SplitDrawerContent";
import styles from "./ItemCustomization.module.css";
import {Actions, API, Lib} from "habit-core";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../lib/hooks";
import {v4 as uuid4} from "uuid";
import QuantityControl from "../QuantityControl";
import ToggleButton from "../ToggleButton/ToggleButton";

const strings = {
    addToOrder: "Add To Order",
    updateOrder: "Update Order",
    cancel: "Cancel",
    addOnsTitle: "Suggested Add-Ons",
};

type Params = {
    categoryId: string;
    itemId: string;
};

function getUnitPriceCents(
    menuItemPriceCents: API.models.USDCents,
    modifierSelections: API.models.ModifierSelectionDict,
    menuItemModifiers: {[modifierId: string]: API.models.MenuItemModifier},
    allPreps: {byId: {[modifierId: string]: Lib.selectors.ItemModifierData}},
): API.models.USDCents {
    let perUnitPriceCents = menuItemPriceCents;
    Object.keys(modifierSelections).forEach((modId) => {
        const mod = menuItemModifiers[modId];
        if (mod) {
            const [sel] = mod.selections.filter(
                (x) => x.id === modifierSelections[modId]?.selectionId,
            );

            if (sel && sel.priceCents) {
                perUnitPriceCents +=
                    sel.priceCents * modifierSelections[modId]?.quantity;
            }
        } else {
            const allPrepsMod = allPreps.byId[modId];
            const allPrepModSelection = allPrepsMod?.selections.find(
                (selection) =>
                    selection.id === modifierSelections[modId]?.selectionId,
            );

            if (allPrepModSelection && allPrepModSelection.priceCents) {
                perUnitPriceCents +=
                    allPrepModSelection.priceCents *
                    modifierSelections[modId]?.quantity;
            }
        }
    });

    return perUnitPriceCents;
}

export default function ItemCustomization() {
    const navigate = useNavigate();
    const {categoryId, itemId} = useParams<Params>();
    const [searchParams] = useSearchParams();
    const icid = searchParams.get(searchParamNames.ITEM_CUSTOMIZATION_ID);
    const menuId = useAppSelector((state) => state.currentOrder.menuId);
    useEffect(() => {
        if (!menuId || !categoryId || !itemId) {
            logger.warn(
                `invalid menu id (${menuId}), category id (${categoryId}), and/or item id (${itemId})`,
            );
            navigate(routeHelpers.menu(), {replace: true});
        }
    }, []);

    const itemsById = useAppSelector((state) => state.items.byId);
    const item = itemId ? itemsById[itemId] : null;

    useEffect(() => {
        if (!item) {
            logger.warn(`No item found with itemId ${itemId}`);
            navigate(routeHelpers.menu(), {replace: true});
        }
    }, []);

    const menuItemsByMenuId = useAppSelector(
        (state) => state.menuItems.byMenuId,
    );
    const menuItem = menuId && item ? menuItemsByMenuId[menuId][item.id] : null;
    const menuItemModifiersByMenuId = useAppSelector(
        (state) => state.menuItemModifiers.byMenuId,
    );
    const allPrepsModifiersById = useAppSelector((state) => state.pos.allPreps);
    const menuItemModifiers =
        menuId && item ? menuItemModifiersByMenuId[menuId][item.id] : {};

    const addOns = useAppSelector(
        (state) => state.pos.addOns.byId[item?.id ?? ""],
    );
    const unfilteredAddOnsInfo = addOns?.map((addOnId) => {
        const addOnMenuItem = menuItemsByMenuId[menuId ?? ""][addOnId];
        const addOnItem = itemsById[addOnId];
        if (addOnMenuItem) {
            return {
                itemId: addOnMenuItem.itemId,
                name: addOnItem.name,
                priceCents: addOnMenuItem.priceCents,
            };
        } else {
            return null;
        }
    });
    const addOnsInfo: {
        itemId: string;
        name: string;
        priceCents: API.models.USDCents;
    }[] = unfilteredAddOnsInfo?.filter(
        (addOnInfo): addOnInfo is Exclude<typeof addOnInfo, null> =>
            addOnInfo !== null,
    );

    const [selectedAddOnIds, setSelectedAddOnIds] = useState<string[]>([]);

    function renderAddOns() {
        if ((addOnsInfo?.length ?? 0) === 0) {
            return;
        }

        return (
            <div className={styles.addOnsContainer}>
                {strings.addOnsTitle}
                <div className={styles.addOnsOptionsContainer}>
                    {addOnsInfo.map((addOnInfo, i) => (
                        <ToggleButton
                            key={"add-on-" + i}
                            className={styles.addOnsOption}
                            checked={selectedAddOnIds.includes(
                                addOnInfo.itemId,
                            )}
                            label={addOnInfo.name}
                            label2={`+ ${Lib.currency.centsToDollarString(
                                addOnInfo.priceCents,
                            )}`}
                            onChange={(checked) => {
                                if (checked) {
                                    setSelectedAddOnIds([
                                        ...selectedAddOnIds,
                                        addOnInfo.itemId,
                                    ]);
                                } else {
                                    setSelectedAddOnIds([
                                        ...selectedAddOnIds.filter(
                                            (addOnId) =>
                                                addOnId !== addOnInfo.itemId,
                                        ),
                                    ]);
                                }
                            }}
                        />
                    ))}
                </div>
            </div>
        );
    }

    const initialCustomization = useAppSelector((state) => {
        if (!icid) {
            return null;
        }

        return state.customizations.items.byInternalId[icid] ?? null;
    });

    const dispatch = useAppDispatch();
    const [quantity, setQuantity] = useState(
        initialCustomization ? initialCustomization.quantity : 1,
    );

    const onClose = () =>
        navigate(
            categoryId
                ? routeHelpers.category(categoryId)
                : routeHelpers.menu(),
        );

    const [modSelections, setModSelections] =
        useState<API.models.ModifierSelectionDict>(
            getInitialModSelections(
                itemsById,
                item?.id ?? "",
                menuItemsByMenuId,
                menuId ?? "",
                menuItemModifiersByMenuId,
                initialCustomization,
                true,
            ),
        );

    if (!categoryId || !itemId || !menuId || !item || !menuItem) {
        return null;
    }

    const updateModSelection = (
        modifierId: string,
        selectionId: string,
        quantity: number,
        isDefault: boolean,
        isRequired: boolean,
    ) => {
        setModSelections((prevState) => {
            if (
                prevState[modifierId]?.selectionId === selectionId &&
                prevState[modifierId]?.quantity === quantity
            ) {
                return prevState;
            }

            const nextModSelections = {...prevState};
            if (isDefault && !isRequired) {
                delete nextModSelections[modifierId];
            } else {
                nextModSelections[modifierId] = {selectionId, quantity};
            }

            // TODO: #56 - Do we need dirty tracking?
            return nextModSelections;
        });
    };

    const onSave = () => {
        if (selectedAddOnIds.length !== 0) {
            const itemCustomizationsToAdd = selectedAddOnIds.map((addOnId) => {
                return {
                    customizationId: uuid4(),
                    itemId: addOnId,
                    modifierSelections: {},
                    quantity: quantity,
                };
            });
            dispatch(
                Actions.currentOrderActions.addItems(
                    itemCustomizationsToAdd,
                    [],
                ),
            );
        }

        if (initialCustomization) {
            dispatch(
                Actions.customizationActions.modifyCustomization(
                    initialCustomization.customizationId,
                    quantity,
                    modSelections,
                ),
            );
            // modifying doesn't change the list of item customization ids and will not be caught by effect used to auto combo in App.tsx, attempt to auto combo here
            dispatch(Actions.currentOrderActions.autoComboOrderItems());
        } else {
            dispatch(
                Actions.currentOrderActions.addItem(
                    item.id,
                    quantity,
                    modSelections,
                ),
            );
        }
        onClose();
    };

    const baseUnitPriceCents = getUnitPriceCents(
        menuItem.priceCents,
        {},
        menuItemModifiers,
        allPrepsModifiersById,
    );
    const totalPriceCents =
        getUnitPriceCents(
            menuItem.priceCents,
            modSelections,
            menuItemModifiers,
            allPrepsModifiersById,
        ) * quantity;

    return (
        <Drawer onClose={onClose} closeButtonLabel={strings.cancel}>
            <SplitDrawerContent
                className={styles.content}
                left={
                    <ItemEditor
                        itemId={item.id}
                        itemKey={item.id}
                        menuId={menuId}
                        modifierSelections={modSelections}
                        onChangeModifier={updateModSelection}
                    />
                }
                right={
                    <>
                        <div className={styles.titleAndPrice}>
                            {item.isolatedImages328.length > 0 && (
                                <div className={styles.imageContainer}>
                                    <RetinaImage
                                        retinaImages={item.isolatedImages328}
                                        className={styles.image}
                                    />
                                </div>
                            )}
                            <div
                                className={cn(
                                    styles.title,
                                    item.isolatedImages328.length > 0
                                        ? styles.titleMargin
                                        : null,
                                )}>
                                {item.name}
                            </div>
                            <div className={styles.price}>
                                {Lib.currency.centsToDollarString(
                                    baseUnitPriceCents,
                                )}
                            </div>
                        </div>

                        <div className={styles.divider} />
                        <div className={styles.rightCustomizations}>
                            <CustomizationList
                                modifierSelections={modSelections}
                                itemId={item.id}
                                onChangeModifier={updateModSelection}
                                editable={true}
                            />

                            <div className={styles.spacer} />
                        </div>
                        <div className={styles.divider} />
                        {renderAddOns()}
                        <div className={styles.divider} />
                        <div className={styles.qtyAddToOrder}>
                            <QuantityControl
                                quantity={quantity}
                                setQuantity={setQuantity}
                            />

                            <OutlineButton
                                label={Lib.currency.centsToDollarString(
                                    totalPriceCents,
                                )}
                                label2={
                                    initialCustomization
                                        ? strings.updateOrder
                                        : strings.addToOrder
                                }
                                onClick={onSave}
                                className={styles.fullWidth}
                            />
                        </div>
                    </>
                }
            />
        </Drawer>
    );
}
