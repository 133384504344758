import * as posModels from "../../lib/api/posModels";
import * as reports from "../../lib/api/reports";
import cn from "classnames";
import ManagerReportsDrawer from "../ManagerReportsDrawer";
import ManagerReportsSectionDropdown from "../ManagerReportsSectionDropdown";
import React from "react";
import styles from "./ManagerTimeReport.module.css";
import {Lib} from "habit-core";

const strings = {
    title: "Time Period Report",
    allDay: "All Day",
    lunch: "Lunch",
    dinner: "Dinner",
    halfHour: "Half-Hour Intervals",
    netSales: "Net Sales :",
    checkAverage: "Check Average :",
    numChecks: "Check Count :",
    hereSales: "Here Sales :",
    toGoSales: "To-Go Sales :",
    driveThruSales: "Drive-Thru Sales :",
    deliverySales: "Delivery Sales :",
};

const LUNCH_TO_DINNER_TIME = "16:00";

export default function ManagerTimeReport() {
    function renderData(section: posModels.TimeReportSection) {
        return (
            <div className={styles.sectionContainer}>
                <div className={styles.leftSectionContainer}>
                    <div className={cn(styles.row, styles.netRow)}>
                        <div>{strings.netSales}</div>
                        <div>
                            {Lib.currency.centsToDollarString(
                                section.netSalesCents,
                            )}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div>{strings.checkAverage}</div>
                        <div>
                            {Lib.currency.centsToDollarString(
                                section.checkAverageCents,
                            )}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div>{strings.numChecks}</div>
                        <div>{section.numChecks}</div>
                    </div>
                    <div className={styles.row}>
                        <div>{strings.hereSales}</div>
                        <div>
                            {Lib.currency.centsToDollarString(
                                section.hereSalesCents,
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.rightSectionContainer}>
                    <div className={styles.row}>
                        <div>{strings.toGoSales}</div>
                        <div>
                            {Lib.currency.centsToDollarString(
                                section.toGoSalesCents,
                            )}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div>{strings.driveThruSales}</div>
                        <div>
                            {Lib.currency.centsToDollarString(
                                section.driveThruSalesCents,
                            )}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div>{strings.deliverySales}</div>
                        <div>
                            {Lib.currency.centsToDollarString(
                                section.deliverySalesCents,
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <ManagerReportsDrawer
            title={strings.title}
            getData={reports.getTimePeriod}>
            {(data) => (
                <>
                    <ManagerReportsSectionDropdown title={strings.allDay}>
                        {renderData(data.allDay)}
                    </ManagerReportsSectionDropdown>
                    <ManagerReportsSectionDropdown
                        title={`${strings.lunch} : ${data.openTime} - ${LUNCH_TO_DINNER_TIME}`}>
                        {renderData(data.lunch)}
                    </ManagerReportsSectionDropdown>
                    <ManagerReportsSectionDropdown
                        title={`${strings.dinner} : ${LUNCH_TO_DINNER_TIME} - ${data.closeTime}`}>
                        {renderData(data.dinner)}
                    </ManagerReportsSectionDropdown>
                    <ManagerReportsSectionDropdown title={strings.halfHour}>
                        {data.halfHour.map((d) =>
                            d.startTime && d.endTime ? (
                                <div
                                    key={d.startTime}
                                    className={styles.halfHourSection}>
                                    <div className={styles.halfHourTime}>
                                        {d.startTime} - {d.endTime}
                                    </div>
                                    <div className={styles.halfHourData}>
                                        {renderData(d)}
                                    </div>
                                </div>
                            ) : null,
                        )}
                    </ManagerReportsSectionDropdown>
                </>
            )}
        </ManagerReportsDrawer>
    );
}
