import React, {CSSProperties} from "react";
import {EntityData} from "../CustomerDisplay";
import {Lib} from "habit-core";

type Props = {
    entity: EntityData;
    isLatestAdded: boolean;
};

export default function CheckoutItemOrCombo({entity, isLatestAdded}: Props) {
    function getModifierString(m: {
        modifierName: string;
        selectionName: string;
    }) {
        return `${m.modifierName} : ${m.selectionName}`;
    }

    return (
        <div style={styles.entityRow}>
            <div
                style={{
                    ...styles.quantity,
                    ...(isLatestAdded
                        ? styles.textLatestAdded
                        : styles.textPreviouslyAdded),
                }}>
                {entity.quantity}
            </div>
            <div style={styles.entityInfoContainer}>
                <div style={styles.nameAndPriceContainer}>
                    <div
                        style={{
                            ...styles.productName,
                            ...(isLatestAdded
                                ? styles.textLatestAdded
                                : styles.textPreviouslyAdded),
                        }}>
                        {entity.name}
                    </div>
                    <div
                        style={
                            isLatestAdded
                                ? styles.textLatestAdded
                                : styles.textPreviouslyAdded
                        }>
                        {Lib.currency.centsToDollarString(entity.priceCents)}
                    </div>
                </div>
                {entity.itemModifiers
                    ? entity.itemModifiers.map((modData, i) => {
                          const modString = getModifierString(modData);
                          return (
                              <div
                                  key={entity.name + modString + i}
                                  style={styles.modifierRow}>
                                  <div style={styles.itemModifierName}>
                                      {modString}
                                  </div>
                                  {modData.priceCents &&
                                  modData.priceCents !== 0 ? (
                                      <div>{`+ ${Lib.currency.centsToDollarString(
                                          modData.priceCents * modData.quantity,
                                      )}`}</div>
                                  ) : null}
                              </div>
                          );
                      })
                    : null}
                {entity.comboItems
                    ? entity.comboItems.map((c) => (
                          <div key={c.id} style={styles.comboItemContainer}>
                              <div style={styles.textPreviouslyAdded}>
                                  {c.name}
                              </div>

                              {c.modifiers.map((modData, i) => {
                                  const modString = getModifierString(modData);
                                  return (
                                      <div
                                          key={c.name + modString + i}
                                          style={styles.modifierRow}>
                                          <div
                                              style={
                                                  styles.comboItemModifierName
                                              }>
                                              {modString}
                                          </div>
                                          {modData.priceCents &&
                                          modData.priceCents !== 0 ? (
                                              <div>{`+ ${Lib.currency.centsToDollarString(
                                                  modData.priceCents *
                                                      modData.quantity,
                                              )}`}</div>
                                          ) : null}
                                      </div>
                                  );
                              })}
                          </div>
                      ))
                    : null}
            </div>
        </div>
    );
}

const styles: Record<string, CSSProperties> = {
    entityRow: {
        display: "flex",
        width: "100%",
        marginTop: "4.44vh",
    },
    entityInfoContainer: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    nameAndPriceContainer: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
    },
    comboItemContainer: {
        paddingLeft: "0.83vw",
        display: "flex",
        flexDirection: "column",
        marginTop: "2.22vh",
    },
    modifierRow: {
        paddingLeft: "0.83vw",
        fontWeight: "400",
        fontSize: "1.25vw",
        lineHeight: "1.2",
        marginTop: "1.3vh",
        display: "flex",
        width: "calc(100% - 0.83vw)",
        justifyContent: "space-between",
    },
    quantity: {
        width: "6.25vw",
        textAlign: "left",
    },
    textLatestAdded: {
        fontSize: "2.92vw",
        fontWeight: "300",
        lineHeight: "1",
    },
    textPreviouslyAdded: {
        fontSize: "1.67vw",
        fontWeight: "600",
        lineHeight: "1",
    },
    productName: {
        maxWidth: "32.29vw",
    },
    itemModifierName: {
        maxWidth: "31.46vw",
    },
    comboItemModifierName: {
        maxWidth: "30.625vw",
    },
};
