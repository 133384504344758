import {isSameDay, isSameMonth} from "date-fns";
import * as posModels from "./api/posModels";
import {Lib} from "habit-core";

export type Filters = {
    text: string;
    dates: Date[];
    sources: (posModels.OrderSource | posModels.OrderType)[];
    stationModes: posModels.StationMode[];
};

export const EMPTY_FILTERS: Filters = {
    text: "",
    dates: [],
    sources: [],
    stationModes: [],
};

const today = new Date();
export const DEFAULT_FILTERS: Filters = {
    ...EMPTY_FILTERS,
    dates: [today],
};

export function datesMatch(date1: Date, date2: Date) {
    return isSameDay(date1, date2) && isSameMonth(date1, date2);
}

function mapOrderSourceToSourceCategory(source: posModels.OrderSource) {
    switch (source.toLowerCase()) {
        case "pos":
            return "pos";
        case "mobile":
            return "mobile";
        case "kiosk":
            return "kiosk";
        case "phone":
            return "phone";
        case "web":
            return "web";
        default:
            return "delivery";
    }
}

export function getFilteredOrders(
    orders: posModels.OrderParsed[],
    filters: Filters,
) {
    return orders.filter((o) => {
        if (
            filters.text === "" &&
            !filters.dates.length &&
            !filters.sources.length
        ) {
            return true;
        }

        let matchesText = true;
        let matchesDate = true;
        let matchesSource = true;
        let isSourcePos = false;
        let matchesStationMode = true;
        if (filters.text !== "") {
            const floatVal = parseFloat(filters.text);
            matchesText =
                o.orderNumber.toString() === filters.text ||
                (!isNaN(floatVal) &&
                    o.totalCents ===
                        Lib.currency.dollarsFloatToCents(floatVal)) ||
                (!!o.guestName &&
                    o.guestName
                        .toLowerCase()
                        .includes(filters.text.toLowerCase()));
        }
        if (filters.dates.length) {
            matchesDate = !!filters.dates.find((d) =>
                datesMatch(d, o.orderDate),
            );
        }
        if (filters.sources.length) {
            const orderSourceCategory = mapOrderSourceToSourceCategory(
                o.source,
            ) as posModels.OrderSource;
            const sourceIncludesCurbside = filters.sources.includes("curbside");
            matchesSource =
                filters.sources.includes(orderSourceCategory) ||
                (sourceIncludesCurbside && o.type === "curbside");
            if (
                !sourceIncludesCurbside &&
                matchesSource &&
                o.type === "curbside"
            ) {
                matchesSource = false;
            }
            isSourcePos = o.source === "pos";
            if (isSourcePos) {
                matchesStationMode = o.stationMode
                    ? filters.stationModes.includes(o.stationMode)
                    : false;
            }
        }

        return (
            matchesText &&
            matchesDate &&
            ((matchesSource && !isSourcePos) ||
                (matchesSource && matchesStationMode))
        );
    });
}
