import cn from "classnames";
import React from "react";
import styles from "./TextButton.module.css";

type Props = {
    className?: string;
    label: string;
    onClick?: () => void;
    disabled?: boolean;
};

export default function TextButton(props: Props) {
    return (
        <button
            type="button"
            onClick={props.onClick}
            disabled={props.disabled}
            className={cn(
                styles.button,
                props.disabled && styles.disabled,
                props.className,
            )}>
            {props.label}
        </button>
    );
}
