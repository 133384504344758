import * as posActions from "../../lib/posActions";
import * as posSelectors from "../../lib/posSelectors";
import * as routeHelpers from "../../lib/routeHelpers";
import closeIcon from "../../images/close.svg";
import cn from "classnames";
import CustomizationList from "../CustomizationList";
import Drawer from "../Drawer";
import EditQuantityButtons from "../EditQuantityButtons";
import ManagerHeader from "../ManagerHeader";
import OutlineButton from "../OutlineButton";
import React, {useContext, useState} from "react";
import styles from "./ManagerVoid.module.css";
import ToggleButton from "../ToggleButton";
import {Lib} from "habit-core";
import {useAppDispatch, useAppSelector} from "../../lib/hooks";
import {CashierModeContext} from "../CashierModeContext";
import {useNavigate} from "react-router-dom";

const strings = {
    voidedItems: "Voided Items",
    voidTotal: "Void Total :",
    includesTax: "(Includes Tax)",
    continue: "Continue",
    voidReason: "Void Reason",
    wrongCheck: "Rang Wrong Check",
    objectIn: "Object In Beverage",
    other: "Other",
    wrongItem: "Rang Wrong Item",
    tooMany: "Rang Too Many",
    changedMind: "Guest Changed Mind",
    location: "Wrong Location",
    missing: "Missing Item",
    prepared: "Prepared Incorrectly",
    noShow: "No Show",
    longTime: "Long Time Ticket",
    voidItem: "Void Item",
    quantity: "QTY",
};

const VOID_REASONS = [
    strings.wrongCheck,
    strings.objectIn,
    strings.other,
    strings.wrongItem,
    strings.tooMany,
    strings.changedMind,
    strings.location,
    strings.missing,
    strings.prepared,
    strings.noShow,
    strings.longTime,
];

export default function ManagerVoid() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedVoidReason, setSelectedVoidReason] = useState<string | null>(
        null,
    );

    const {setCashierMode} = useContext(CashierModeContext);

    const voidTotalCents = useAppSelector((state) =>
        posSelectors.getPendingVoidedTotalCents(state, false),
    );

    const combosById = useAppSelector((state) => state.combos.byId);
    const itemsById = useAppSelector((state) => state.items.byId);
    const comboCustomizationsById = useAppSelector(
        (state) => state.customizations.combos.byInternalId,
    );
    const itemCustomizationsById = useAppSelector(
        (state) => state.customizations.items.byInternalId,
    );
    const combosToVoidById = useAppSelector(
        (state) => state.pos.currentOrderVoided.combos.pending.byId,
    );
    const itemsToVoidById = useAppSelector(
        (state) => state.pos.currentOrderVoided.items.pending.byId,
    );
    const comboIds = Object.keys(combosToVoidById);
    const itemIds = Object.keys(itemsToVoidById);

    function changeQuantity(
        type: "item" | "combo",
        id: string,
        quantity: number,
    ) {
        if (type === "combo") {
            dispatch(posActions.changePendingVoidedComboQuantity(id, quantity));
        } else if (type === "item") {
            dispatch(posActions.changePendingVoidedItemQuantity(id, quantity));
        }
    }

    function handleVoid() {
        if (!selectedVoidReason) {
            return;
        }

        dispatch(posActions.voidAllPendingItems(selectedVoidReason));
        setIsDrawerOpen(false);
        setSelectedVoidReason(null);
        navigate(routeHelpers.menu());
        setCashierMode({type: "user"});
    }

    function renderVoidListItem(
        type: "combo" | "item",
        id: string,
        customizationId: string,
        name: string,
        quantity: number,
    ) {
        let maxQuantity = 1;
        let comboItems = null;
        let itemModifiers = null;
        let itemId = null;
        if (type === "combo") {
            const comboCustomization = comboCustomizationsById[customizationId];
            maxQuantity = comboCustomization.quantity;
            comboItems = comboCustomization.itemCustomizationIds.map(
                (itemCustomizationId) => {
                    const itemCustomization =
                        itemCustomizationsById[itemCustomizationId];
                    const name = itemsById[itemCustomization.itemId].name;
                    const modifiers = itemCustomization.modifierSelections;
                    return {
                        itemCustomizationId,
                        name,
                        modifiers,
                        itemId: itemCustomization.itemId,
                    };
                },
            );
        } else if (type === "item") {
            const itemCustomization = itemCustomizationsById[customizationId];
            maxQuantity = itemCustomization.quantity;
            itemModifiers = itemCustomization.modifierSelections;
            itemId = itemCustomization.itemId;
        }

        return (
            <div key={id} className={styles.voidItem}>
                <div className={styles.voidItemTopRow}>
                    <div className={styles.voidItemName}>{name}</div>
                    <div className={styles.voidItemButtonsContainer}>
                        <EditQuantityButtons
                            className={styles.voidItemQuantityControls}
                            current={quantity}
                            max={maxQuantity}
                            onChange={(newQuantity: number) =>
                                changeQuantity(type, id, newQuantity)
                            }
                        />
                        <button
                            className={styles.removeButton}
                            onClick={() => {
                                if (type === "combo") {
                                    dispatch(
                                        posActions.removePendingVoidedCombo(id),
                                    );
                                } else if (type === "item") {
                                    dispatch(
                                        posActions.removePendingVoidedItem(id),
                                    );
                                }
                            }}>
                            <img src={closeIcon} alt="" />
                        </button>
                    </div>
                </div>
                <div>
                    {itemModifiers && itemId ? (
                        <CustomizationList
                            className={styles.customizationList}
                            modifierSelections={itemModifiers}
                            itemId={itemId}
                            hidePrices={true}
                            editable={false}
                        />
                    ) : null}
                    {comboItems
                        ? comboItems.map((c, i) => (
                              <div key={c.itemCustomizationId}>
                                  <div
                                      className={cn({
                                          [styles.comboItemName]: true,
                                          [styles.comboItemTop]: i === 0,
                                      })}>
                                      {c.name}
                                  </div>
                                  <CustomizationList
                                      className={styles.customizationList}
                                      modifierSelections={c.modifiers}
                                      itemId={c.itemId}
                                      hidePrices={true}
                                      editable={false}
                                  />
                              </div>
                          ))
                        : null}
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={styles.container}>
                <ManagerHeader label={strings.voidedItems} />
                <div className={styles.itemsContainer}>
                    {comboIds.map((id) => {
                        const comboToVoid = combosToVoidById[id];
                        const comboCustomization =
                            comboCustomizationsById[
                                comboToVoid.comboCustomizationId
                            ];
                        const combo = combosById[comboCustomization.comboId];

                        return renderVoidListItem(
                            "combo",
                            id,
                            comboToVoid.comboCustomizationId,
                            combo.name,
                            comboToVoid.quantity,
                        );
                    })}
                    {itemIds.map((id) => {
                        const itemToVoid = itemsToVoidById[id];
                        const itemCustomization =
                            itemCustomizationsById[
                                itemToVoid.itemCustomizationId
                            ];
                        const item = itemsById[itemCustomization.itemId];

                        return renderVoidListItem(
                            "item",
                            id,
                            itemToVoid.itemCustomizationId,
                            item.name,
                            itemToVoid.quantity,
                        );
                    })}
                </div>
                <div className={styles.footerContainer}>
                    <div className={styles.totalContainer}>
                        <div className={styles.totalText}>
                            <div>{strings.voidTotal}</div>
                            <div>{strings.includesTax}</div>
                        </div>
                        <div className={styles.totalNumber}>
                            {Lib.currency.centsToDollarString(voidTotalCents)}
                        </div>
                    </div>
                    <div className={styles.continueContainer}>
                        <OutlineButton
                            className={styles.continueButton}
                            label={strings.continue}
                            disabled={!comboIds.length && !itemIds.length}
                            onClick={() => setIsDrawerOpen(true)}
                        />
                    </div>
                </div>
            </div>
            {isDrawerOpen ? (
                <Drawer
                    isSmall={true}
                    onClose={() => {
                        setIsDrawerOpen(false);
                        setSelectedVoidReason(null);
                    }}>
                    <div className={styles.drawer}>
                        <div className={styles.voidReasonsContainer}>
                            <div className={styles.drawerTitle}>
                                {strings.voidReason}
                            </div>
                            <div className={styles.reasonsList}>
                                {VOID_REASONS.map((r) => (
                                    <ToggleButton
                                        key={r}
                                        className={styles.reasonsListItem}
                                        label={r}
                                        checked={selectedVoidReason === r}
                                        onChange={() => {
                                            if (selectedVoidReason === r) {
                                                setSelectedVoidReason(null);
                                            } else {
                                                setSelectedVoidReason(r);
                                            }
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className={styles.drawerFooter}>
                            <OutlineButton
                                className={styles.voidItemButton}
                                label={strings.voidItem}
                                disabled={selectedVoidReason === null}
                                onClick={handleVoid}
                            />
                        </div>
                    </div>
                </Drawer>
            ) : null}
        </>
    );
}
