import {API, Constants} from "habit-core";
import {Link} from "react-router-dom";
import cn from "classnames";
import React from "react";
import styles from "./MenuProductLink.module.css";
import RetinaImage from "../RetinaImage";

type Props = {
    className?: string;
    url: string;
    name: string;
    status?: API.models.Availability;
    statusReason?: string | null;
    retinaImages?: API.models.Image[];
    numColumns?: number;
    hideImage?: boolean;
    displaySize?: "small" | "regular";
    disabled?: boolean;
};

export default function MenuProductLink({
    status = "available",
    displaySize = "regular",
    ...props
}: Props) {
    const isSmall = displaySize === "small";
    const isLongName = props.name.length > 30;

    return (
        <Link
            className={cn(
                styles.product,
                status === Constants.availability.UNAVAILABLE
                    ? styles.unavailable
                    : null,
                props.hideImage && styles.hideImage,
                props.numColumns === 3 ? styles.product3Col : null,
                props.disabled && styles.disabled,
                props.className,
            )}
            to={props.url}>
            <div
                className={cn(
                    styles.name,
                    isSmall ? styles.smallName : null,
                    props.numColumns === 3 ? styles.smallName3Col : null,
                    props.numColumns === 3 && isLongName
                        ? styles.longName
                        : null,
                )}>
                {props.name}
                {props.statusReason ? (
                    <div className={styles.statusReason}>
                        {props.statusReason}
                    </div>
                ) : null}
            </div>
            {props.hideImage ? null : (
                <div
                    className={cn(
                        styles.imageContainer,
                        isSmall ? styles.smallImageContainer : null,
                    )}>
                    {props.retinaImages && props.retinaImages.length > 0 && (
                        <RetinaImage
                            retinaImages={props.retinaImages}
                            className={cn(
                                styles.image,
                                isSmall ? styles.smallImage : null,
                            )}
                        />
                    )}
                </div>
            )}
        </Link>
    );
}
