import React, {InputHTMLAttributes, useEffect, useState} from "react";
import useOnChangeCashAmount from "../lib/useOnChangeCashAmount";
import TextInput from "./TextInput";
import {API} from "habit-core";

type Props = Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "onChange" | "value"
> & {
    onChange: (value: API.models.USDCents | null) => void;
    value: string;
};

const CurrencyInput = React.forwardRef<HTMLInputElement, Props>(
    (props, ref) => {
        const [value, setValue] = useState<string>("");

        useEffect(() => {
            if (props.value !== value) {
                setValue(props.value);
            }
        }, [props.value]);

        const onChangeCashAmount = useOnChangeCashAmount(
            setValue,
            props.onChange,
        );

        return (
            <TextInput
                {...props}
                value={value}
                onChange={(e) => {
                    const str = e.target.value;
                    const val = str.replace(/[^\d]/g, "");
                    onChangeCashAmount(val);
                }}
                ref={ref}
                inputMode="none"
            />
        );
    },
);

CurrencyInput.displayName = "CurrencyInput";
export default CurrencyInput;
