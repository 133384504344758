import {Capacitor, registerPlugin} from "@capacitor/core";
import logger from "../logger";

const REQUIRED_DPI = 160;

interface DisplayPlugin {
    getDPI: () => Promise<{dpi: number}>;
}

const display = registerPlugin<DisplayPlugin>("Display");

export const getDPI = () => {
    return display
        .getDPI()
        .then((res) => res.dpi)
        .catch((err) => {
            if (Capacitor.isNativePlatform()) {
                logger.error(err);
                return undefined;
            } else {
                return REQUIRED_DPI; // return the dpi we desire if the platform is not android
            }
        });
};
