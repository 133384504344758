import instance from "./instance";
import {users as urls} from "./urls";

export type ValidateManagerResponse = {
    id: string;
    firstName: string;
    lastName: string;
};

export function validateManagerCardNumber(
    cardNumber: string,
): Promise<ValidateManagerResponse> {
    return instance
        .request({
            url: urls.validateCardNumber,
            method: "POST",
            data: {cardNumber},
        })
        .then((response) => response.data);
}

export function validateManagerById(
    employeeId: string,
): Promise<ValidateManagerResponse> {
    return instance
        .request({
            url: urls.validateId,
            method: "POST",
            data: {employeeId},
        })
        .then((response) => response.data);
}

export function associateCard(
    employeeId: string,
    cardNumber: string,
): Promise<void> {
    return instance
        .request({
            url: urls.associateCard,
            method: "POST",
            data: {employeeId, cardNumber},
        })
        .then((response) => response.data);
}
