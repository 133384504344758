import * as posModels from "./api/posModels";
import {Lib} from "habit-core";
import {createSelector} from "reselect";
import {RootState} from "../store";

export const createGetIsEntireOrderVoided = () =>
    createSelector(
        (state: RootState) => state.pos.currentOrderVoided.combos.pending.byId,
        (state: RootState) => state.pos.currentOrderVoided.items.pending.byId,
        (state: RootState) => state.currentOrder.comboCustomizationIds,
        (state: RootState) => state.currentOrder.itemCustomizationIds,
        (state: RootState) => state.customizations.combos.byInternalId,
        (state: RootState) => state.customizations.items.byInternalId,
        (
            pendingVoidedCombosById,
            pendingVoidedItemsById,
            currentOrderComboCustomizationIds,
            currentOrderItemCustomizationIds,
            comboCustomizationsById,
            itemCustomizationsById,
        ) => {
            const pendingVoidedComboIds = Object.keys(pendingVoidedCombosById);
            for (let i = 0; i < currentOrderComboCustomizationIds.length; i++) {
                const comboCustomizationId =
                    currentOrderComboCustomizationIds[i];
                const existingPendingVoidedComboId = pendingVoidedComboIds.find(
                    (id) =>
                        pendingVoidedCombosById[id].comboCustomizationId ===
                        comboCustomizationId,
                );
                if (!existingPendingVoidedComboId) {
                    return false;
                }
                const hasDifferentQuantity =
                    pendingVoidedCombosById[existingPendingVoidedComboId]
                        .quantity !==
                    comboCustomizationsById[comboCustomizationId].quantity;
                if (hasDifferentQuantity) {
                    return false;
                }
            }

            const pendingVoidedItemIds = Object.keys(pendingVoidedItemsById);
            for (let i = 0; i < currentOrderItemCustomizationIds.length; i++) {
                const itemCustomizationId = currentOrderItemCustomizationIds[i];
                const existingPendingVoidedItemId = pendingVoidedItemIds.find(
                    (id) =>
                        pendingVoidedItemsById[id].itemCustomizationId ===
                        itemCustomizationId,
                );
                if (!existingPendingVoidedItemId) {
                    return false;
                }
                const hasDifferentQuantity =
                    pendingVoidedItemsById[existingPendingVoidedItemId]
                        .quantity !==
                    itemCustomizationsById[itemCustomizationId].quantity;
                if (hasDifferentQuantity) {
                    return false;
                }
            }

            return true;
        },
    );

export const getPendingVoidedTotalCents = createSelector(
    (state: RootState) => state,
    (state: RootState) => state.currentOrder.menuId,
    (state: RootState) => state.customizations,
    (state: RootState) => state.menuItems.byMenuId,
    (state: RootState) => state.menuItemModifiers.byMenuId,
    (state: RootState) => state.menuCombos.byMenuId,
    (state: RootState) => state.combos.byId,
    (state: RootState) => state.pos.currentOrderVoided,
    //eslint-disable-next-line @typescript-eslint/no-inferrable-types
    (_: RootState, zeroIfUnavailable: boolean = true) => zeroIfUnavailable,
    (
        state,
        menuId,
        customizations,
        menuItemsByMenuId,
        menuItemModsByMenuId,
        menuCombosByMenuId,
        combosById,
        currentOrderVoided,
        zeroIfUnavailable,
    ) => {
        if (!menuId) {
            return 0;
        }

        const voidedCombos = currentOrderVoided.combos.pending.byId;
        const voidedComboIds = Object.keys(voidedCombos);
        const voidedComboSumCents = Lib.selectors.sumComboPricesCents(
            voidedComboIds.map((id) => ({
                customizationId: voidedCombos[id].comboCustomizationId,
                quantity: voidedCombos[id].quantity,
            })),
            customizations,
            menuId,
            menuItemsByMenuId,
            menuItemModsByMenuId,
            menuCombosByMenuId,
            combosById,
            zeroIfUnavailable,
        );

        const voidedItems = currentOrderVoided.items.pending.byId;
        const voidedItemIds = Object.keys(voidedItems);
        const voidedItemSumCents = Lib.selectors.sumItemPricesCents(
            voidedItemIds.map((id) => ({
                customizationId: voidedItems[id].itemCustomizationId,
                quantity: voidedItems[id].quantity,
            })),
            customizations,
            menuId,
            menuItemsByMenuId,
            menuItemModsByMenuId,
            zeroIfUnavailable,
        );

        const voidComboInfo = voidedComboIds.map((vcid) => ({
            id: voidedCombos[vcid].comboCustomizationId,
            quantity: voidedCombos[vcid].quantity,
        }));

        const voidItemInfo = voidedItemIds.map((viid) => ({
            id: voidedItems[viid].itemCustomizationId,
            quantity: voidedItems[viid].quantity,
        }));

        const allPrepsAmountCents = getAllPrepsAmountCents(
            state,
            voidComboInfo,
            voidItemInfo,
        );

        return voidedComboSumCents + voidedItemSumCents + allPrepsAmountCents;
    },
);

export const getCurrentOrderVoidTotalCents = createSelector(
    (state: RootState) => state,
    (state: RootState) => state.currentOrder.menuId,
    (state: RootState) => state.customizations,
    (state: RootState) => state.menuItems.byMenuId,
    (state: RootState) => state.menuItemModifiers.byMenuId,
    (state: RootState) => state.menuCombos.byMenuId,
    (state: RootState) => state.combos.byId,
    (state: RootState) => state.pos.currentOrder,
    (state: RootState) => state.pos.currentOrderVoided,
    //eslint-disable-next-line @typescript-eslint/no-inferrable-types
    (_: RootState, zeroIfUnavailable: boolean = true) => zeroIfUnavailable,
    (
        state,
        menuId,
        customizations,
        menuItemsByMenuId,
        menuItemModsByMenuId,
        menuCombosByMenuId,
        combosById,
        posCurrentOrder,
        currentOrderVoided,
        zeroIfUnavailable,
    ) => {
        if (!menuId) {
            return 0;
        }

        const voidedCombosById = currentOrderVoided.combos.voided.byId;
        const voidedComboSumCents = Lib.selectors.sumComboPricesCents(
            posCurrentOrder.voidedComboIds.map((id) => ({
                customizationId: voidedCombosById[id].comboCustomizationId,
                quantity: voidedCombosById[id].quantity,
            })),
            customizations,
            menuId,
            menuItemsByMenuId,
            menuItemModsByMenuId,
            menuCombosByMenuId,
            combosById,
            zeroIfUnavailable,
        );

        const voidedItemsById = currentOrderVoided.items.voided.byId;
        const voidedItemSumCents = Lib.selectors.sumItemPricesCents(
            posCurrentOrder.voidedItemIds.map((id) => ({
                customizationId: voidedItemsById[id].itemCustomizationId,
                quantity: voidedItemsById[id].quantity,
            })),
            customizations,
            menuId,
            menuItemsByMenuId,
            menuItemModsByMenuId,
            zeroIfUnavailable,
        );

        const voidComboInfo = Object.keys(voidedCombosById).map((vcid) => ({
            id: vcid,
            quantity: voidedCombosById[vcid].quantity,
        }));

        const voidItemInfo = Object.keys(voidedItemsById).map((viid) => ({
            id: viid,
            quantity: voidedItemsById[viid].quantity,
        }));

        const allPrepsAmountCents = getAllPrepsAmountCents(
            state,
            voidComboInfo,
            voidItemInfo,
        );

        return voidedComboSumCents + voidedItemSumCents + allPrepsAmountCents;
    },
);

export const getAllPrepsAmountCents = createSelector(
    (state: RootState) => state.customizations.combos,
    (state: RootState) => state.customizations.items,
    (state: RootState) => state.pos.allPreps,
    (state: RootState) =>
        state.menuItemModifiers.byMenuId[state.currentOrder.menuId ?? ""],
    (_: RootState, comboCustomizationInfo: {id: string; quantity: number}[]) =>
        comboCustomizationInfo,
    (
        _: RootState,
        _2: {id: string; quantity: number}[],
        itemCustomizationInfo: {id: string; quantity: number}[],
    ) => itemCustomizationInfo,
    (
        comboCustomizations,
        itemCustomizations,
        allPreps,
        menuItemModifiers,
        comboInfo,
        itemInfo,
    ) => {
        if (!menuItemModifiers) {
            return 0;
        }

        let amount = 0;
        amount += comboInfo
            .map((cInfo) => cInfo.id)
            .reduce((sum, ccid) => {
                const comboCustomization =
                    comboCustomizations.byInternalId[ccid];
                let comboAmount = 0;
                comboAmount += comboCustomization.itemCustomizationIds.reduce(
                    (sum, icid) => {
                        const itemCustomization =
                            itemCustomizations.byInternalId[icid];
                        const modifiers =
                            menuItemModifiers[itemCustomization.itemId];
                        let modPriceCents = 0;
                        Object.keys(
                            itemCustomization.modifierSelections,
                        ).forEach((modId) => {
                            const modSelectionId =
                                itemCustomization.modifierSelections[modId]
                                    ?.selectionId;
                            const menuItemMod = modifiers[modId];
                            const modSelectionOnMenuItemMod =
                                !!menuItemMod?.selections.find(
                                    (sel) => sel.id === modSelectionId,
                                );

                            if (
                                !modifiers[modId] ||
                                !modSelectionOnMenuItemMod
                            ) {
                                // we need to use the all prep pricing
                                const allPrepMod = allPreps.byId[modId];
                                const allPrepModSelection =
                                    allPrepMod.selections.find(
                                        (selection) =>
                                            selection.id ===
                                            itemCustomization
                                                .modifierSelections[modId]
                                                ?.selectionId,
                                    );
                                if (allPrepModSelection) {
                                    modPriceCents +=
                                        allPrepModSelection.priceCents ?? 0;
                                }
                            }
                        });
                        return (sum +=
                            modPriceCents * itemCustomization.quantity);
                    },
                    0,
                );
                return (sum +=
                    comboAmount *
                    (comboInfo.find((cInfo) => cInfo.id === ccid)?.quantity ??
                        0));
            }, 0);

        amount += itemInfo
            .map((iInfo) => iInfo.id)
            .reduce((sum, icid) => {
                const itemCustomization = itemCustomizations.byInternalId[icid];
                const modifiers = menuItemModifiers[itemCustomization.itemId];
                let modPriceCents = 0;
                Object.keys(itemCustomization.modifierSelections).forEach(
                    (modId) => {
                        const modSelectionId =
                            itemCustomization.modifierSelections[modId]
                                ?.selectionId;
                        const menuItemMod = modifiers[modId];
                        const modSelectionOnMenuItemMod =
                            !!menuItemMod?.selections.find(
                                (sel) => sel.id === modSelectionId,
                            );
                        if (!modifiers[modId] || !modSelectionOnMenuItemMod) {
                            // we need to use the all prep pricing
                            const allPrepMod = allPreps.byId[modId];
                            const allPrepModSelection =
                                allPrepMod.selections.find(
                                    (selection) =>
                                        selection.id ===
                                        itemCustomization.modifierSelections[
                                            modId
                                        ]?.selectionId,
                                );
                            if (allPrepModSelection) {
                                modPriceCents +=
                                    allPrepModSelection.priceCents ?? 0;
                            }
                        }
                    },
                );
                return (sum +=
                    modPriceCents *
                    (itemInfo.find((iInfo) => iInfo.id === icid)?.quantity ??
                        0));
            }, 0);

        return amount;
    },
);

export const getCurrentOrderSubtotalSansVoidedCents = createSelector(
    (state: RootState) => state,
    (state: RootState) => state.pos.currentOrderVoided.items.voided.byId,
    (state: RootState) => state.pos.currentOrderVoided.combos.voided.byId,
    (state: RootState) => state.pos.currentOrder.giftCards,
    (state, voidedItemsById, voidedCombosById, giftCards) => {
        const voidCombosInfo = Object.keys(voidedCombosById).map(
            (voidComboId) => ({
                customizationId:
                    voidedCombosById[voidComboId].comboCustomizationId,
                quantity: voidedCombosById[voidComboId].quantity,
            }),
        );

        const nonVoidComboInfo = state.currentOrder.comboCustomizationIds.map(
            (ccid) => ({
                id: ccid,
                quantity:
                    (state.customizations.combos.byInternalId[ccid]?.quantity ??
                        0) -
                    (voidCombosInfo.find(
                        (vcInfo) => vcInfo.customizationId === ccid,
                    )?.quantity ?? 0),
            }),
        );

        const voidItemsInfo = Object.keys(voidedItemsById).map(
            (voidItemId) => ({
                customizationId:
                    voidedItemsById[voidItemId].itemCustomizationId,
                quantity: voidedItemsById[voidItemId].quantity,
            }),
        );

        const nonVoidItemInfo = state.currentOrder.itemCustomizationIds.map(
            (icid) => ({
                id: icid,
                quantity:
                    (state.customizations.items.byInternalId[icid]?.quantity ??
                        0) -
                    (voidItemsInfo.find(
                        (viInfo) => viInfo.customizationId === icid,
                    )?.quantity ?? 0),
            }),
        );

        const subtotalCents = Lib.selectors.getCurrentOrderSubtotalCents(
            state,
            false,
            voidCombosInfo,
            voidItemsInfo,
        );

        const allPrepsSubtotalCents = getAllPrepsAmountCents(
            state,
            nonVoidComboInfo,
            nonVoidItemInfo,
        );

        const giftCardsTotalCents =
            giftCards.addFunds.reduce(
                (sum, gc) => sum + gc.amountToAddCents,
                0,
            ) +
            giftCards.purchase.reduce(
                (sum, gc) => sum + gc.balanceCents * gc.quantity,
                0,
            );

        return subtotalCents + allPrepsSubtotalCents + giftCardsTotalCents;
    },
);

export const getCurrentOrderTaxSansVoidedCents = createSelector(
    (state: RootState) => state,
    (state: RootState) => state.pos.currentOrderVoided.items.voided.byId,
    (state: RootState) => state.pos.currentOrderVoided.combos.voided.byId,
    (_: RootState, compCardsApplied: posModels.AppliedCompCard[]) =>
        compCardsApplied,
    (state, voidedItemsById, voidedCombosById, compCardsApplied) => {
        const voidCombosInfo = Object.keys(voidedCombosById).map(
            (voidComboId) => ({
                customizationId:
                    voidedCombosById[voidComboId].comboCustomizationId,
                quantity: voidedCombosById[voidComboId].quantity,
            }),
        );

        const nonVoidComboInfo = state.currentOrder.comboCustomizationIds.map(
            (ccid) => ({
                id: ccid,
                quantity:
                    (state.customizations.combos.byInternalId[ccid]?.quantity ??
                        0) -
                    (voidCombosInfo.find(
                        (vcInfo) => vcInfo.customizationId === ccid,
                    )?.quantity ?? 0),
            }),
        );

        const voidItemsInfo = Object.keys(voidedItemsById).map(
            (voidItemId) => ({
                customizationId:
                    voidedItemsById[voidItemId].itemCustomizationId,
                quantity: voidedItemsById[voidItemId].quantity,
            }),
        );

        const nonVoidItemInfo = state.currentOrder.itemCustomizationIds.map(
            (icid) => ({
                id: icid,
                quantity:
                    (state.customizations.items.byInternalId[icid]?.quantity ??
                        0) -
                    (voidItemsInfo.find(
                        (viInfo) => viInfo.customizationId === icid,
                    )?.quantity ?? 0),
            }),
        );

        const taxCents = Lib.selectors.getCurrentOrderTaxCents(
            state,
            false,
            voidCombosInfo,
            voidItemsInfo,
        );

        const storeTaxRate = state.currentOrder.storeId
            ? state.stores.byId[state.currentOrder.storeId].taxRate ?? 0
            : 0;

        // TODO: do we need to use overrides? currently not provided.
        const allPrepsTaxCents =
            getAllPrepsAmountCents(state, nonVoidComboInfo, nonVoidItemInfo) *
            storeTaxRate;

        // we don't know what the order total is before computing the tax so we can't determine an applied amount for comp cards
        // we use the balance instead since a comp card cannot be used partially.
        const compCardsAmountCents = compCardsApplied.reduce(
            (sum, cc) => sum + cc.balance,
            0,
        );
        const compCardsTaxCents = storeTaxRate * compCardsAmountCents;

        const discountTaxCents =
            storeTaxRate * getCurrentOrderDiscountAmountCents(state);

        return Lib.currency.roundFloat(
            Math.max(
                taxCents +
                    allPrepsTaxCents -
                    discountTaxCents -
                    compCardsTaxCents,
                0,
            ),
        );
    },
);

export const getCharityRoundUpSansVoidedCents = createSelector(
    (state: RootState) => state,
    (state: RootState) => state.currentOrder.shouldRoundUp,
    (_: RootState, compCardsApplied: posModels.AppliedCompCard[]) =>
        compCardsApplied,
    (
        _: RootState,
        compCardsApplied: posModels.AppliedCompCard[],
        // eslint-disable-next-line @typescript-eslint/no-inferrable-types
        forceRoundUp: boolean = false,
    ) => forceRoundUp,
    (state, shouldRoundUp, compCardsApplied, forceRoundUp) => {
        if (!shouldRoundUp && !forceRoundUp) {
            return 0;
        }

        const subtotalCents = getCurrentOrderSubtotalSansVoidedCents(state);
        const taxCents = getCurrentOrderTaxSansVoidedCents(
            state,
            compCardsApplied,
        );
        const discountAmountCents = getCurrentOrderDiscountAmountCents(state);
        const preRoundupTotalCents = Math.max(
            subtotalCents + taxCents - discountAmountCents,
            0,
        );

        if (preRoundupTotalCents === 0 || preRoundupTotalCents % 100 === 0) {
            return 0;
        }

        return 100 - (preRoundupTotalCents % 100);
    },
);

export const getCurrentOrderTotalCents = createSelector(
    (state: RootState) => state,
    (_: RootState, compCardsApplied: posModels.AppliedCompCard[]) =>
        compCardsApplied,
    (
        _: RootState,
        compCardsApplied: posModels.AppliedCompCard[],
        // eslint-disable-next-line @typescript-eslint/no-inferrable-types
        forceCharityRoundUp: boolean = false,
    ) => forceCharityRoundUp,
    (state, compCardsApplied, forceCharityRoundUp) => {
        const total =
            getCurrentOrderSubtotalSansVoidedCents(state) +
            getCurrentOrderTaxSansVoidedCents(state, compCardsApplied) +
            getCharityRoundUpSansVoidedCents(
                state,
                compCardsApplied,
                forceCharityRoundUp,
            );
        return total;
    },
);

export const getCurrentOrderDiscountAmountCents = createSelector(
    (state: RootState) => state,
    (state: RootState) => state.pos.currentOrder.discounts,
    (state, discounts) => {
        const orderSubtotal = getCurrentOrderSubtotalSansVoidedCents(state);
        return discounts.reduce((sum, discount) => {
            if (discount.amountCents) {
                sum += discount.amountCents;
            } else if (discount.amountPercentage) {
                sum += Lib.currency.roundFloat(
                    (orderSubtotal * discount.amountPercentage) / 100.0,
                );
            }
            return sum;
        }, 0);
    },
);

export const getCurrentOrderTotalQuantity = createSelector(
    (state: RootState) => state.currentOrder.comboCustomizationIds,
    (state: RootState) => state.currentOrder.itemCustomizationIds,
    (state: RootState) => state.customizations.combos.byInternalId,
    (state: RootState) => state.customizations.items.byInternalId,
    (state: RootState) => state.currentOrder.discountId,
    (
        comboCustomizationIds,
        itemCustomizationIds,
        comboCustomizationsById,
        itemCustomizationsById,
        currentDiscountId,
    ) => {
        let totalQuantity = 0;
        totalQuantity += comboCustomizationIds.reduce(
            (sum, ccId) => sum + comboCustomizationsById[ccId].quantity,
            0,
        );
        totalQuantity += itemCustomizationIds.reduce(
            (sum, icId) => sum + itemCustomizationsById[icId].quantity,
            0,
        );
        return currentDiscountId ? totalQuantity + 1 : totalQuantity;
    },
);

export const getIsCurrentOrderEmpty = createSelector(
    (state: RootState) => state.currentOrder.itemCustomizationIds.length,
    (state: RootState) => state.currentOrder.comboCustomizationIds.length,
    (state: RootState) => state.pos.currentOrder.giftCards.addFunds.length,
    (state: RootState) => state.pos.currentOrder.giftCards.purchase.length,
    (
        numItems,
        numCombos,
        numGiftCardAddFundsEntities,
        numGiftCardPurchaseEntities,
    ) => {
        return (
            numItems === 0 &&
            numCombos === 0 &&
            numGiftCardAddFundsEntities === 0 &&
            numGiftCardPurchaseEntities === 0
        );
    },
);
