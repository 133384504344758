import classNames from "classnames";
import * as routeHelpers from "../../lib/routeHelpers";
import * as posModels from "../../lib/api/posModels";
import * as posActions from "../../lib/posActions";
import describeNetworkError from "../../lib/describeNetworkError";
import {useAppDispatch} from "../../lib/hooks";
import completeIcon from "../../images/discount-complete.svg";
import React, {useEffect, useState, useRef, useContext} from "react";
import styles from "./ManagerDiscounts.module.css";
import {useNavigate} from "react-router-dom";
import discountErrorIcon from "../../images/discount-error-icon.svg";
import ToggleButton from "../ToggleButton/ToggleButton";
import TextInput from "../TextInput/TextInput";
import OutlineButton from "../OutlineButton/OutlineButton";
import StyledKeyboard from "../StyledKeyboard/StyledKeyboard";
import {CashierModeContext} from "../CashierModeContext";
import {ParallelModeContext} from "../ParallelModeContext";

const strings = {
    customer100: "Customer 100%",
    certificateOfAcheivement: "Certificate of Achievement",
    employee20: "Employee 20%",
    employee50: "Employee 50%",
    employee100: "Employee 100%",
    student10: "Student 10%",
    applyDiscount: "Apply Discount",
    employeeIdPlaceholder: "Employee ID",
    reasonPlaceholder: "Reason",
    error: "Error",
    ok: "Ok",
    returnToOrder: "Return to Order",
    discountApplied: (name: string) => {
        return `${name} Discount Applied`;
    },
};

type ManagerDiscount = {
    name: string;
    code: posModels.ManagerDiscountCode;
    requireEmployeeId?: boolean;
    requireReason?: boolean;
};

const DISCOUNTS: ManagerDiscount[] = [
    {name: strings.customer100, code: "customer100", requireReason: true},
    {
        name: strings.certificateOfAcheivement,
        code: "certificateOfAchievement",
    },
    {name: strings.employee20, code: "employee20", requireEmployeeId: true},
    {name: strings.employee50, code: "employee50", requireEmployeeId: true},
    {name: strings.employee100, code: "employee100", requireEmployeeId: true},
    {name: strings.student10, code: "student10"},
];

export default function ManagerDiscounts() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {setCashierMode, isTraining} = useContext(CashierModeContext);
    const {isParallel} = useContext(ParallelModeContext);

    const [currentDiscount, setCurrentDiscount] = useState<ManagerDiscount>();
    const [employeeId, setEmployeeId] = useState("");
    const [reason, setReason] = useState("");
    const [loading, setLoading] = useState(false);
    const [showSuccessScreen, setShowSuccessScreen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    /* keyboard */
    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current && !keyboardCollapsed) {
            if (document.activeElement === inputRef.current) {
                inputRef?.current?.scrollIntoView();
            }
        }
    }, [keyboardCollapsed]);

    const onApplyDiscount = () => {
        if (!currentDiscount || isTraining || isParallel) {
            return;
        }

        setLoading(true);
        dispatch(
            posActions.currentOrderValidateDiscount(
                currentDiscount.code,
                employeeId,
                reason,
            ),
        )
            .then((response) => {
                dispatch(
                    posActions.currentOrderAddDiscount(
                        currentDiscount.code,
                        response.redemptionAmountCents,
                        response.redemptionAmountPercentage,
                        response.discountType,
                        currentDiscount.name,
                        employeeId,
                        reason,
                    ),
                );
            })
            .then(() => {
                setShowSuccessScreen(true);
            })
            .catch((err) => {
                setErrorMessage(describeNetworkError(err).join("\n"));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const returnToOrder = () => {
        setCashierMode({type: "user"});
        navigate(routeHelpers.menu());
    };

    function resetState() {
        setCurrentDiscount(undefined);
        setEmployeeId("");
        setReason("");
        setLoading(false);
        setErrorMessage("");
        setShowSuccessScreen(false);
    }

    function renderDiscountFailureScreen() {
        return (
            <div className={styles.errorContainer}>
                <img
                    className={styles.errorIcon}
                    src={discountErrorIcon}
                    alt=""
                />
                <div className={styles.errorHeader}>{strings.error}</div>
                <div className={styles.errorText}>{errorMessage}</div>
                <OutlineButton
                    className={styles.errorOkButton}
                    label={strings.ok}
                    loading={false}
                    onClick={resetState}
                />
            </div>
        );
    }

    function renderDiscountSuccessScreen() {
        return (
            <div className={styles.completeContainer}>
                <img className={styles.completeIcon} src={completeIcon} />

                <div className={styles.completeText}>
                    <div className={styles.completeHeader}>
                        {strings.discountApplied(currentDiscount?.name ?? "")}
                    </div>
                </div>

                <div className={styles.completeSpacer} />

                <OutlineButton
                    className={styles.completeButton}
                    label={strings.returnToOrder}
                    mode="blue"
                    onClick={returnToOrder}
                />
            </div>
        );
    }

    if (errorMessage) {
        return renderDiscountFailureScreen();
    }

    if (showSuccessScreen) {
        return renderDiscountSuccessScreen();
    }

    return (
        <div className={styles.container}>
            <div className={styles.contentContainer}>
                <div className={styles.discountContainer}>
                    {DISCOUNTS.map((l) => (
                        <ToggleButton
                            key={l.code}
                            className={styles.discount}
                            checked={currentDiscount == l}
                            onChange={(checked) => {
                                if (checked) {
                                    setCurrentDiscount(l);
                                }
                            }}
                            label={l.name}
                        />
                    ))}
                    <div className={styles.spacer} />
                </div>
                <div className={styles.bottom}>
                    {currentDiscount?.requireEmployeeId ? (
                        <div className={styles.employeeIdContainer}>
                            <TextInput
                                value={employeeId}
                                onChange={(e) => setEmployeeId(e.target.value)}
                                onFocus={() => setKeyboardCollapsed(false)}
                                placeholder={strings.employeeIdPlaceholder}
                                className={styles.employeeNumberInput}
                                required={true}
                                inputMode="none"
                                ref={inputRef}
                            />
                        </div>
                    ) : null}
                    {currentDiscount?.requireReason ? (
                        <div className={styles.employeeIdContainer}>
                            <TextInput
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                onFocus={() => setKeyboardCollapsed(false)}
                                placeholder={strings.reasonPlaceholder}
                                className={styles.employeeNumberInput}
                                required={true}
                                inputMode="none"
                                ref={inputRef}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            <div
                className={classNames(
                    styles.keyboardContainer,
                    keyboardCollapsed && styles.keyboardContainerCollapsed,
                )}>
                <StyledKeyboard
                    currentInput={
                        currentDiscount?.requireEmployeeId ? employeeId : reason
                    }
                    visible={!keyboardCollapsed}
                    setVisible={(val: boolean) => setKeyboardCollapsed(!val)}
                    onChange={(input) =>
                        currentDiscount?.requireEmployeeId
                            ? setEmployeeId(input)
                            : setReason(input)
                    }
                    onPressEnter={onApplyDiscount}
                    inputRefs={[inputRef]}
                />
            </div>
            <div className={styles.applyContainer}>
                <div />
                <OutlineButton
                    label={strings.applyDiscount}
                    disabled={
                        loading ||
                        currentDiscount == null ||
                        (currentDiscount.requireEmployeeId && !employeeId) ||
                        (currentDiscount.requireReason && !reason)
                    }
                    onClick={onApplyDiscount}
                />
            </div>
        </div>
    );
}
