import * as posActions from "../../lib/posActions";
import * as posModels from "../../lib/api/posModels";
import * as posSelectors from "../../lib/posSelectors";
import * as routeHelpers from "../../lib/routeHelpers";
import cn from "classnames";
import describeNetworkError from "../../lib/describeNetworkError";
import Drawer from "../Drawer";
import ErrorModal from "../ErrorModal";
import logger from "../../lib/logger";
import ManagerPermissionForm from "../ManagerPermissionForm";
import OrdersCurrentOrder from "./OrdersCurrentOrder";
import OrderDetailsDrawer from "../OrderDetailsDrawer";
import OrderRowItem from "./OrderRowItem";
import React, {useState, useMemo, useEffect, useContext} from "react";
import sortBy from "lodash.sortby";
import Spinner from "../Spinner";
import styles from "./CashierCheckoutOrders.module.css";
import TextModal from "../TextModal";
import useRefreshOrders from "../../lib/useRefreshOrders";
import {CashierModeContext} from "../CashierModeContext";
import {useAppDispatch, useAppSelector} from "../../lib/hooks";
import {useNavigate, useLocation} from "react-router-dom";

const strings = {
    currentOrder: "Current Order :",
    noCurrentOrder: "No Current Order",
    pendingOrders: "Pending Orders",
    noPendingOrders: "No Pending Orders",
    closedOrders: "Closed Orders",
    noClosedOrders: "No Closed Orders",
    confirm: "Confirmation",
    confirmText: "Are you sure you want to cancel this order?",
    goBack: "Go Back",
    submit: "Cancel This Order",
    confirmOpenOrder:
        "Are you sure you want to open this order? It will override any current order data.",
    openOrder: "Open This Order",
};

type Props = {
    className?: string;
};

export default function CashierCheckoutOrders(props: Props) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {cashierMode, isTraining} = useContext(CashierModeContext);

    const {initialLoading, initialErrorMessage, closeInitialErrorMessage} =
        useRefreshOrders(["pending", "paid"], ["pos"], isTraining);

    const [errorMessage, setErrorMessage] = useState("");
    const [cancelOrderId, setCancelOrderId] = useState<string | null>(null);
    const [cancelling, setCancelling] = useState(false);
    const [managerId, setManagerId] = useState(
        cashierMode.type === "manager" ? cashierMode.managerId : null,
    );
    const [orderDetails, setOrderDetails] =
        useState<posModels.OrderParsed | null>(null);
    const [openingPendingOrder, setOpeningPendingOrder] = useState(false);

    const posCurrentOrder = useAppSelector((state) => state.pos.currentOrder);

    const ordersById = useAppSelector((state) => state.pos.orders.byId);
    const pendingOrders = useMemo(() => {
        const pending = Object.keys(ordersById)
            .map((id) => ordersById[id])
            .filter(
                (o) =>
                    o.status === "pending" &&
                    o.orderNumber !== posCurrentOrder.orderNumber,
            );
        return sortBy(pending, (x) => x.orderDate);
    }, [ordersById]);

    const closedOrders = useMemo(() => {
        const closed = Object.keys(ordersById)
            .map((id) => ordersById[id])
            .filter((o) => o.status === "paid" || o.status === "cancelled");
        return sortBy(closed, (x) => x.orderDate).reverse();
    }, [ordersById]);

    const currentOrderTotalCents = useAppSelector((state) =>
        posSelectors.getCurrentOrderTotalCents(state, []),
    );

    useEffect(() => {
        if (cashierMode.type !== "manager") {
            setManagerId(null);
        }
    }, [cashierMode.type]);

    function onCancelOrder() {
        if (cancelling || !cancelOrderId || !managerId) {
            return;
        }

        const cancellingCurrentOrder = cancelOrderId === posCurrentOrder.id;
        if (cancellingCurrentOrder) {
            return dispatch(posActions.removeAllProductsFromCurrentOrder());
        } else {
            setCancelling(true);
            dispatch(posActions.cancelOrder(cancelOrderId))
                .then(() => {
                    setCancelling(false);
                    setCancelOrderId(null);
                    if (cashierMode.type !== "manager") {
                        setManagerId(null);
                    }
                    if (
                        cancellingCurrentOrder &&
                        location.pathname === routeHelpers.otherOrders()
                    ) {
                        navigate(routeHelpers.checkout());
                    }
                })
                .catch((err) => {
                    logger.warn(err);
                    setCancelling(false);
                    setCancelOrderId(null);
                    if (cashierMode.type !== "manager") {
                        setManagerId(null);
                    }
                    const description = describeNetworkError(err);
                    setErrorMessage(description.join("\n"));
                });
        }
    }

    function onOpenPendingOrder(order: posModels.OrderParsed) {
        if (openingPendingOrder) {
            return;
        }

        setOpeningPendingOrder(true);
        dispatch(posActions.openPendingOrder(order))
            .catch((err) => {
                const description = describeNetworkError(err);
                setErrorMessage(description.join("\n"));
            })
            .finally(() => {
                setOpeningPendingOrder(false);
                navigate(routeHelpers.payment());
            });
    }

    if (initialLoading) {
        return (
            <div className={cn(styles.loadingContainer, props.className)}>
                <Spinner />
            </div>
        );
    }

    return (
        <>
            <div className={props.className}>
                <div className={styles.currentSection}>
                    <div
                        className={cn(
                            styles.sectionTitle,
                            styles.currentSectionTitle,
                        )}>
                        {strings.currentOrder}
                    </div>
                    {posCurrentOrder.id &&
                    posCurrentOrder.orderNumber &&
                    posCurrentOrder.startTime ? (
                        <OrdersCurrentOrder
                            driveThruDetails={posCurrentOrder.driveThruDetails}
                            orderId={posCurrentOrder.id}
                            orderNumber={posCurrentOrder.orderNumber}
                            currentOrderTotalCents={currentOrderTotalCents}
                            startTime={posCurrentOrder.startTime}
                            onCancel={(id) => setCancelOrderId(id)}
                        />
                    ) : (
                        <div className={styles.noCurrentOrder}>
                            {strings.noCurrentOrder}
                        </div>
                    )}
                </div>
                <div className={styles.pendingSection}>
                    <div className={styles.sectionTitle}>
                        {strings.pendingOrders}
                    </div>
                    {pendingOrders.length ? (
                        pendingOrders.map((o) => (
                            <OrderRowItem
                                key={o.id}
                                order={o}
                                onOpen={() => {
                                    onOpenPendingOrder(o);
                                }}
                                onClickDetails={() => setOrderDetails(o)}
                                onCancel={() => setCancelOrderId(o.id)}
                            />
                        ))
                    ) : (
                        <div className={styles.noOrders}>
                            {strings.noPendingOrders}
                        </div>
                    )}
                </div>
                <div className={styles.closedSection}>
                    <div className={styles.sectionTitle}>
                        {strings.closedOrders}
                    </div>
                    {closedOrders.length ? (
                        closedOrders.map((o) => (
                            <OrderRowItem
                                key={o.id}
                                order={o}
                                onClickDetails={() => setOrderDetails(o)}
                            />
                        ))
                    ) : (
                        <div className={styles.noOrders}>
                            {strings.noClosedOrders}
                        </div>
                    )}
                </div>
            </div>
            {errorMessage || initialErrorMessage ? (
                <ErrorModal
                    errorMessage={errorMessage || initialErrorMessage}
                    onClose={() =>
                        errorMessage
                            ? setErrorMessage("")
                            : closeInitialErrorMessage()
                    }
                    showITInfo
                />
            ) : null}
            {cancelOrderId !== null && managerId ? (
                <TextModal
                    onClose={() => setCancelOrderId(null)}
                    title={strings.confirm}
                    content={strings.confirmText}
                    cancelLabel={strings.goBack}
                    onSubmit={onCancelOrder}
                    submitLabel={strings.submit}
                    submitLoading={cancelling}
                    submitMode="red"
                />
            ) : null}
            {cancelOrderId !== null && !managerId ? (
                <Drawer
                    isSmall={true}
                    onClose={() => {
                        setCancelOrderId(null);
                    }}>
                    <div className={styles.managerPermissionFormContainer}>
                        <ManagerPermissionForm
                            onSuccess={(data) => setManagerId(data.id)}
                        />
                    </div>
                </Drawer>
            ) : null}
            {orderDetails !== null ? (
                <OrderDetailsDrawer
                    order={orderDetails}
                    onClose={() => setOrderDetails(null)}
                />
            ) : null}
        </>
    );
}
