import cn from "classnames";
import React, {useState, useEffect, useMemo} from "react";
import styles from "./ModifierToggleRow.module.css";
import {Constants, Lib} from "habit-core";
import {useAppSelector} from "../../lib/hooks";

const strings = {
    unavailable: "Modifier Unavailable",
};

type Props = {
    className?: string;
    data: Lib.selectors.ItemModifierData;
    value?: string;
    isRequired: boolean;

    onChange?: (
        modifierId: string,
        selectionId: string,
        quantity: number,
        isDefault: boolean,
        isRequired: boolean,
    ) => void;
};

export default function ModifierToggleRow(props: Props) {
    const modStatus = useAppSelector(
        (state) => state.modifiers.byId[props.data.id]?.status ?? true,
    );

    const defaultSelection = useMemo(
        () => props.data.selections.find((x) => x.default),
        [props.data.selections],
    );

    const [value, setValue] = useState(props.value || defaultSelection?.id);
    useEffect(() => {
        setValue(
            props.value === undefined ? defaultSelection?.id : props.value,
        );
    }, [props.value]);

    const onChange = (selection: Lib.selectors.ItemModifierSelectionData) => {
        if (value === selection.id) {
            return;
        }

        if (props.onChange) {
            props.onChange(
                props.data.id,
                selection.id,
                selection.min ?? 1,
                selection.default,
                props.isRequired,
            );
        } else {
            setValue(selection.id);
        }
    };

    return (
        <div className={cn(styles.container, props.className)}>
            <div
                className={cn(
                    styles.modifierName,
                    modStatus === Constants.availability.UNAVAILABLE &&
                        styles.unavailableName,
                )}>
                {props.data.name}
                {modStatus === Constants.availability.UNAVAILABLE ? (
                    <div className={styles.unavailableText}>
                        {strings.unavailable}
                    </div>
                ) : null}
            </div>

            <div className={styles.selectionsContainer}>
                {props.data.selections.map((x) => (
                    <button
                        key={x.id}
                        className={cn(styles.selection, {
                            [styles.selectionWithPrice]: x.priceCents != 0,
                            [styles.active]: value === x.id,
                        })}
                        onClick={() => onChange(x)}>
                        <div className={styles.selectionName}>{x.name}</div>

                        {x.priceCents ? (
                            <div className={styles.price}>
                                {x.priceCents > 0 ? "+" : "-"}{" "}
                                {Lib.currency.centsToDollarString(x.priceCents)}
                            </div>
                        ) : null}
                    </button>
                ))}
            </div>
        </div>
    );
}
