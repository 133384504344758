import cn from "classnames";
import React from "react";
import styles from "./SplitInput.module.css";

type Props = React.ComponentProps<"input"> & {
    className?: string;
    value: string;
    setValue: (n: string) => void;
};

export default function SplitInput({
    className,
    value,
    setValue,
    ...rest
}: Props) {
    return (
        <input
            {...rest}
            className={cn(styles.input, className)}
            type="number"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            inputMode="none"
        />
    );
}
