import * as posModels from "../../lib/api/posModels";
import * as reports from "../../lib/api/reports";
import cn from "classnames";
import ManagerReportsDrawer from "../ManagerReportsDrawer";
import ManagerReportsSectionDropdown from "../ManagerReportsSectionDropdown";
import React from "react";
import styles from "./ManagerBalanceReport.module.css";
import {Lib} from "habit-core";

const strings = {
    title: "Balance Report",
    balanceSummary: "Balance Summary",
    totalRevenue: "Total Revenue :",
    netSales: "Net Sales :",
    serviceCharges: "Service Charges :",
    taxCollected: "Tax Collected :",
    totalDiscounts: "Total Discounts :",
    itemDiscounts: "Item Discounts :",
    otherDiscounts: "Other Discounts :",
    managerVoids: "Manager Voids :",
    errorCorrect: "Error Correct :",
    cancels: "Cancels :",
    openChecks: "Open Checks :",
    byOrderMode: "By Order Mode",
    mode: "Mode",
    numChecks: "# of Checks",
    checkAvg: "Check Avg",
    checkTotal: "Check Total",
    avgTime: "Avg. Order Time",
    delivery: "Delivery :",
    inStore: "Dine-In :",
    carryOut: "To-Go :",
    driveThru: "Drive-Thru :",
    familyGroup: "Family Group",
    discounts: "Discounts",
    paymentTypes: "Payment Types",
    transactionsTotal: "Transactions Total :",
    cashSummary: "Cash Summary",
    totalPull: "Total Pull :",
    overShort: "Over / (Short) :",
    reason: "Reason :",
    cash: "Cash",
    credit: "Credit",
    giftCard: "Gift Card",
    compCard: "Comp Card",
    unknown: "Unknown",
    noData: "No Data to Show",
};

function getOrderModeLabel(mode: string) {
    switch (mode) {
        case "delivery":
            return strings.delivery;
        case "in_store":
            return strings.inStore;
        case "carry_out":
            return strings.carryOut;
        case "drive_thru":
            return strings.driveThru;
        default:
            return "";
    }
}

const MS_IN_MIN = 60000;
function getFormattedOrderTime(timeInMs: number) {
    const minutes = Math.floor(timeInMs / MS_IN_MIN);
    const seconds = parseInt(((timeInMs % MS_IN_MIN) / 1000).toFixed(2));
    return seconds === 60
        ? `${minutes + 1}:00`
        : `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
}

export default function ManagerBalanceReport() {
    function getPaymentLabel(type: posModels.PaymentType) {
        switch (type) {
            case "cash":
                return strings.cash;
            case "credit":
                return strings.credit;
            case "gift_card":
                return strings.giftCard;
            case "comp_card":
                return strings.compCard;
            default:
                return strings.unknown;
        }
    }

    function renderNoData() {
        return (
            <div className={cn(styles.sectionContainer, styles.noData)}>
                {strings.noData}
            </div>
        );
    }

    function renderSummary(summary: posModels.BalanceSummary) {
        return (
            <div>
                <div className={cn(styles.sectionContainer, styles.flex)}>
                    <div className={styles.leftSectionContainer}>
                        <div className={cn(styles.row, styles.topRow)}>
                            <div>{strings.totalRevenue}</div>
                            <div>
                                {Lib.currency.centsToDollarString(
                                    summary.netSalesCents +
                                        summary.taxCollectedCents +
                                        summary.serviceChargesCents,
                                )}
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div>{strings.netSales}</div>
                            <div>
                                {Lib.currency.centsToDollarString(
                                    summary.netSalesCents,
                                )}
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div>{strings.serviceCharges}</div>
                            <div>
                                {Lib.currency.centsToDollarString(
                                    summary.serviceChargesCents,
                                )}
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div>{strings.taxCollected}</div>
                            <div>
                                {Lib.currency.centsToDollarString(
                                    summary.taxCollectedCents,
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightSectionContainer}>
                        <div className={cn(styles.row, styles.topRow)}>
                            <div>{strings.totalDiscounts}</div>
                            <div>
                                {Lib.currency.centsToDollarString(
                                    summary.totalDiscountsCents,
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.divider} />
                <div className={cn(styles.sectionContainer, styles.flex)}>
                    <div className={styles.leftSectionContainer}>
                        <div className={styles.row}>
                            <div className={styles.summarySecondName}>
                                {strings.managerVoids}
                            </div>
                            <div className={styles.summarySecondCount}>
                                {summary.managerVoids.numApplied}
                            </div>
                            <div className={styles.summarySecondSum}>
                                {Lib.currency.centsToDollarString(
                                    summary.managerVoids.sumCents,
                                )}
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.summarySecondName}>
                                {strings.cancels}
                            </div>
                            <div className={styles.summarySecondCount}>
                                {summary.cancels.numApplied}
                            </div>
                            <div className={styles.summarySecondSum}>
                                {Lib.currency.centsToDollarString(
                                    summary.cancels.sumCents,
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightSectionContainer}>
                        <div className={styles.row}>
                            <div className={styles.summarySecondName}>
                                {strings.openChecks}
                            </div>
                            <div className={styles.summarySecondCount}>
                                TODO
                            </div>
                            <div className={styles.summarySecondSum}>TODO</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function renderByOrderMode(orderModes: posModels.OrderModeSummary[]) {
        if (!orderModes.length) {
            return renderNoData();
        }

        return (
            <div className={styles.sectionContainer}>
                <div className={styles.row}>
                    <div
                        className={cn(
                            styles.orderRowItem,
                            styles.topOrderRowItem,
                        )}>
                        {strings.mode}
                    </div>
                    <div
                        className={cn(
                            styles.orderRowItem,
                            styles.topOrderRowItem,
                        )}>
                        {strings.numChecks}
                    </div>
                    <div
                        className={cn(
                            styles.orderRowItem,
                            styles.topOrderRowItem,
                        )}>
                        {strings.checkAvg}
                    </div>
                    <div
                        className={cn(
                            styles.orderRowItem,
                            styles.topOrderRowItem,
                        )}>
                        {strings.checkTotal}
                    </div>
                    <div
                        className={cn(
                            styles.orderRowItem,
                            styles.topOrderRowItem,
                        )}>
                        {strings.avgTime}
                    </div>
                </div>
                {orderModes.map((o) => (
                    <div key={o.mode} className={styles.row}>
                        <div className={styles.orderRowItem}>
                            {getOrderModeLabel(o.mode)}
                        </div>
                        <div className={styles.orderRowItem}>{o.numChecks}</div>
                        <div className={styles.orderRowItem}>
                            {Lib.currency.centsToDollarString(
                                o.checkAverageCents,
                            )}
                        </div>
                        <div className={styles.orderRowItem}>
                            {Lib.currency.centsToDollarString(
                                o.checkTotalCents,
                            )}
                        </div>
                        <div className={styles.orderRowItem}>
                            {o.averageOrderTimeMs
                                ? getFormattedOrderTime(o.averageOrderTimeMs)
                                : null}
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    function renderFamilyGroup(familyGroups: posModels.FamilyGroupSummary[]) {
        if (!familyGroups.length) {
            return renderNoData();
        }

        return (
            <div className={cn(styles.sectionContainer, styles.wrapSection)}>
                {familyGroups.map((f) => (
                    <div
                        key={f.name}
                        className={cn(styles.row, styles.wrapRow)}>
                        <div className={styles.wrapName}>{f.name} :</div>
                        <div className={styles.wrapCount}>{f.amount}</div>
                        <div className={styles.wrapTotal}>
                            {Lib.currency.centsToDollarString(f.subtotalCents)}
                        </div>
                    </div>
                ))}
                <div className={styles.wrapRow} />
            </div>
        );
    }

    function renderDiscounts(discounts: posModels.DiscountSummary[]) {
        if (!discounts.length) {
            return renderNoData();
        }

        return (
            <div className={cn(styles.sectionContainer, styles.wrapSection)}>
                {discounts.map((d) => (
                    <div
                        key={d.name}
                        className={cn(styles.row, styles.wrapRow)}>
                        <div className={styles.wrapName}>{d.name} :</div>
                        <div className={styles.wrapTotal}>
                            {Lib.currency.centsToDollarString(d.amountCents)}
                        </div>
                    </div>
                ))}
                <div className={styles.wrapRow} />
            </div>
        );
    }

    function renderPaymentTypes(paymentTypes: posModels.PaymentTypeSummary[]) {
        if (!paymentTypes.length) {
            return renderNoData();
        }

        let totalCount = 0;
        let totalChargedCents = 0;
        paymentTypes.forEach((p) => {
            totalCount += p.count;
            totalChargedCents += p.totalChargedCents;
        });

        return (
            <div className={cn(styles.sectionContainer, styles.wrapSection)}>
                <div className={cn(styles.row, styles.wrapRow, styles.bold)}>
                    <div className={styles.wrapName}>
                        {strings.transactionsTotal}
                    </div>
                    <div className={styles.wrapCount}>{totalCount}</div>
                    <div className={styles.wrapTotal}>
                        {Lib.currency.centsToDollarString(totalChargedCents)}
                    </div>
                </div>
                <div className={styles.wrapRow} />
                {paymentTypes.map((p) => (
                    <div
                        key={p.type}
                        className={cn(styles.row, styles.wrapRow)}>
                        <div className={styles.wrapName}>
                            {getPaymentLabel(p.type)} :
                        </div>
                        <div className={styles.wrapCount}>{p.count}</div>
                        <div className={styles.wrapTotal}>
                            {Lib.currency.centsToDollarString(
                                p.totalChargedCents,
                            )}
                        </div>
                    </div>
                ))}
                <div className={styles.wrapRow} />
            </div>
        );
    }

    function renderCashSummaries(cashSummaries: posModels.CashSummary[]) {
        if (!cashSummaries.length) {
            return renderNoData();
        }

        return (
            <div className={styles.sectionContainer}>
                {cashSummaries.map((c, i) => (
                    <div key={c.eventName} className={styles.cashSection}>
                        <div className={cn(styles.cashName, styles.smallText)}>
                            <div>{c.eventName}</div>
                            <div>
                                {Lib.dates.format(c.createDate, "HH:mma")}
                            </div>
                        </div>
                        <div
                            className={cn(
                                styles.cashInfo,
                                i === 0 ? null : styles.cashInfoDivider,
                            )}>
                            <div
                                className={cn(
                                    styles.row,
                                    styles.cashInfoNumber,
                                )}>
                                <div>{strings.totalPull}</div>
                                <div>
                                    {Lib.currency.centsToDollarString(
                                        c.totalAmountCents,
                                    )}
                                </div>
                            </div>
                            <div
                                className={cn(
                                    styles.row,
                                    styles.cashInfoNumber,
                                )}>
                                <div>{strings.overShort}</div>
                                <div>
                                    {Lib.currency.centsToDollarString(
                                        c.overAmountCents,
                                    )}
                                </div>
                            </div>
                            {c.reason ? (
                                <>
                                    <div
                                        className={cn(
                                            styles.cashReasonHeader,
                                            styles.smallText,
                                        )}>
                                        {strings.reason}
                                    </div>
                                    <div className={styles.row}>{c.reason}</div>
                                </>
                            ) : null}
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <>
            <ManagerReportsDrawer
                title={strings.title}
                getData={reports.getBalance}>
                {(data) => (
                    <>
                        <ManagerReportsSectionDropdown
                            title={strings.balanceSummary}>
                            {renderSummary(data.summary)}
                        </ManagerReportsSectionDropdown>
                        <ManagerReportsSectionDropdown
                            title={strings.byOrderMode}>
                            {renderByOrderMode(data.orderModes)}
                        </ManagerReportsSectionDropdown>
                        <ManagerReportsSectionDropdown
                            title={strings.familyGroup}>
                            {renderFamilyGroup(data.familyGroups)}
                        </ManagerReportsSectionDropdown>
                        <ManagerReportsSectionDropdown
                            title={strings.discounts}>
                            {renderDiscounts(data.discounts)}
                        </ManagerReportsSectionDropdown>
                        <ManagerReportsSectionDropdown
                            title={strings.paymentTypes}>
                            {renderPaymentTypes(data.paymentTypes)}
                        </ManagerReportsSectionDropdown>
                        <ManagerReportsSectionDropdown
                            title={strings.cashSummary}>
                            {renderCashSummaries(data.cashSummaries)}
                        </ManagerReportsSectionDropdown>
                    </>
                )}
            </ManagerReportsDrawer>
        </>
    );
}
