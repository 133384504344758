import * as posModels from "./posModels";
import instance from "./instance";
import {menus as urls} from "./urls";

type AllPrepsResponse = {
    preps: posModels.AllPrepsModifier[];
};

export function getAllPreps(storeId: string): Promise<AllPrepsResponse> {
    return instance
        .request({
            url: urls.allPreps(storeId),
            method: "GET",
        })
        .then((response) => {
            return response.data;
        });
}

type ItemsAddOnsResponse = {
    items: posModels.ItemAddOnsInfo[];
};

export function getItemsAddOnsInfo(): Promise<ItemsAddOnsResponse> {
    return instance
        .request({
            url: urls.addOns,
            method: "GET",
        })
        .then((response) => {
            return response.data;
        });
}

export function getGlobalMenuLastUpdateDate(): Promise<Date> {
    return instance
        .request({
            url: urls.globalMenuLastUpdateDate,
            method: "GET",
        })
        .then((response) => {
            return new Date(response.data.lastMenuUpdateDate);
        });
}

export function getStoreMenuLastUpdateDate(storeId: string): Promise<Date> {
    return instance
        .request({
            url: urls.storeMenuLastUpdateDate(storeId),
            method: "GET",
        })
        .then((response) => {
            return new Date(response.data.lastMenuUpdateDate);
        });
}
