import * as deviceIdStorage from "../../lib/deviceIdStorage";
import * as displayPlugin from "../../lib/DisplayPlugin/DisplayPlugin";
import * as posActions from "../../lib/posActions";
import * as posMenuEndpoints from "../../lib/api/menus";
import * as posModels from "../../lib/api/posModels";
import * as posSelectors from "../../lib/posSelectors";
import * as posSettings from "../../lib/api/settings";
import * as posStoreEndpoints from "../../lib/api/stores";
import * as Sentry from "@sentry/capacitor";
import * as stationModeStorage from "../../lib/stationModeStorage";
import CashierCheckout from "../CashierCheckout";
import describeNetworkError from "../../lib/describeNetworkError";
import ErrorModal from "../ErrorModal";
import instance from "../../lib/api/instance";
import logger from "../../lib/logger";
import React, {
    useEffect,
    useCallback,
    useContext,
    useState,
    useRef,
} from "react";
import Spinner from "../Spinner";
import styles from "./App.module.css";
import {API, Actions, Constants} from "habit-core";
import {CashierModeContext} from "../CashierModeContext";
import {Outlet} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../lib/hooks";
import {ParallelModeContext} from "../ParallelModeContext";
import {RefreshContext} from "../RefreshContext";

const REQUIRED_DPI = 160;

const strings = {
    genericErrorTitle: "Whoops!",
    genericErrorMessage: "Something went wrong. Please try again.",
    retry: "Retry",
    discountRemoved:
        "The applied discount(s) could not be validated and have been removed.",
    dpiErrorModalTitle: "Display Configuration Warning",
    dpiDetectedWarningMessage: (detectedDpi: number) =>
        `This device's display configuration is incorrect and may result in some visual issues. The POS can still be used, but please contact IT. \n Issue: DPI is set to ${detectedDpi}. It should be set to ${REQUIRED_DPI}.`,
    dpiUndetectedWarningMessage:
        "This device's display configuration could not be read & may have some issues. The POS can still be used but please contact IT.",
    dismiss: "Dismiss",
};

// TODO: #188 - Think about how we want to load this onto pos config
const requiresDriveThruDetails = true;

export type ParallelModeContext = {
    isParallelMode: boolean;
};

export default function App() {
    const [loading, setLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [dpiWarningMessage, setDpiWarningMessage] = useState("");
    const dispatch = useAppDispatch();

    const currentOrder = useAppSelector((state) => state.currentOrder);
    const posCurrentOrder = useAppSelector((state) => state.pos.currentOrder);
    const subtotalCents = useAppSelector((state) =>
        posSelectors.getCurrentOrderSubtotalSansVoidedCents(state),
    );
    const stationMode = useAppSelector((state) => state.pos.station.mode);
    const deviceId = useAppSelector((state) => state.pos.deviceId);
    const currentOrderStoreId = useAppSelector(
        (state) => state.currentOrder.storeId,
    );

    useEffect(() => {
        instance.defaults.headers.common["X-POS-Station-Mode"] = stationMode;
        if (deviceId) {
            instance.defaults.headers.common["X-POS-Device-Id"] = deviceId;
        }
    }, [stationMode, deviceId]);

    const {
        globalMenuLastUpdateDateRef,
        storeMenuLastUpdateDateRef,
        storeSettingsLastUpdateDateRef,
    } = useContext(RefreshContext);

    const {isTraining} = useContext(CashierModeContext);
    const {toggleIsParallel} = useContext(ParallelModeContext);

    const isAfterInitialLoad = useRef(false);
    const initialLoad = () => {
        setLoading(true);
        Promise.all([
            deviceIdStorage.getStoredDeviceId(),
            stationModeStorage.getStoredStationMode(),
            stationModeStorage.getStoredLaneAssignment(),
        ])
            .then(
                ([storedDeviceId, storedStationMode, storedLaneAssignment]) => {
                    return Promise.all([
                        posSettings.get(storedDeviceId),
                        storedDeviceId,
                        storedStationMode,
                        storedLaneAssignment,
                        posStoreEndpoints.getStoreSettings(),
                        displayPlugin.getDPI(),
                    ]);
                },
            )
            .then(
                ([
                    settings,
                    storedDeviceId,
                    storedStationMode,
                    storedLaneAssignment,
                    storeSettings,
                    dpi,
                ]) => {
                    if (dpi === undefined) {
                        Sentry.captureMessage(
                            "Unable to detect the device DPI",
                        );
                        setDpiWarningMessage(
                            strings.dpiUndetectedWarningMessage,
                        );
                    } else if (dpi !== REQUIRED_DPI) {
                        setDpiWarningMessage(
                            strings.dpiDetectedWarningMessage(dpi),
                        );
                        Sentry.captureMessage(
                            `The device has a dpi of ${dpi} while we require ${REQUIRED_DPI}.`,
                        );
                    }

                    // use stored station mode & fallback on default station mode returned in settings
                    const stationMode: posModels.StationMode = storedStationMode
                        ? (storedStationMode as posModels.StationMode)
                        : settings.defaultStationMode;
                    const laneAssignment: posModels.LaneAssignment | null =
                        storedLaneAssignment
                            ? (storedLaneAssignment as posModels.LaneAssignment)
                            : null;

                    let initialOrderType: API.models.OrderType = "in_store";
                    if (
                        stationMode === "drive_thru_order_taker" ||
                        stationMode === "drive_thru_order_fulfillment" ||
                        stationMode === "line_buster"
                    ) {
                        initialOrderType = "drive_thru";
                    }

                    const storeId = storeSettings.storeId;
                    // set the device id
                    dispatch(posActions.setDeviceId(settings.deviceId));
                    if (storedDeviceId !== settings.deviceId) {
                        // should only happen the first time the app loads on a tablet.
                        deviceIdStorage.setStoredDeviceId(settings.deviceId);
                    }

                    // initialize the station
                    dispatch(
                        posActions.initializeStation(
                            stationMode,
                            requiresDriveThruDetails,
                            laneAssignment,
                        ),
                    );
                    toggleIsParallel(storeSettings.isParallelMode);

                    // initialize charity round up settings
                    const charitySettings = storeSettings.charitySettings;
                    dispatch(
                        posActions.initializeCharityRoundUpSettingsImmediate(
                            charitySettings.isEnabled,
                            charitySettings.charityName,
                            charitySettings.showMinimal,
                        ),
                    );

                    // initialize store address and tax rate.
                    const storeInfo = {
                        id: storeId,
                        taxRate: storeSettings.taxRate,
                        address: storeSettings.address,
                        established: "n/a",
                        name: "n/a",
                        status: Constants.storeStatus.ONLINE,
                        storeNumber: 0,
                        maxOrderCost: 500,
                        maxOrderCostCents: 50000,
                        supportsCurbside: false,
                        supportsCharlane: false,
                        curbsideAutoCheckIn: {enabled: false},
                        supportsDineIn: true,
                        hours: {
                            driveThru: [],
                            inStore: [],
                            modified: {
                                driveThru: [],
                                inStore: [],
                            },
                        },
                        images: [],
                        supportsQrToGo: false,
                        supportsQrDineIn: false,
                        isItemComboUpsellEnabled: false,
                        supportsPickupLane: false,
                        maxGiftCardCashOutCents:
                            storeSettings.maxGiftCardCashOutCents,
                        isCondimentUpsellEnabled: false,
                        fundraiserSupportEnabled: false,
                    };
                    dispatch(Actions.storeActions.setStores([storeInfo]));

                    return Promise.all([
                        stationMode,
                        posMenuEndpoints.getGlobalMenuLastUpdateDate(),
                        posMenuEndpoints.getStoreMenuLastUpdateDate(storeId),
                        posStoreEndpoints.getStoreSettingsLastUpdateDate(),
                        dispatch(Actions.menuActions.loadGlobalMenu()),
                        dispatch(
                            Actions.currentOrderActions.selectStore(
                                storeId,
                                initialOrderType,
                                false,
                            ),
                        ),
                        dispatch(posActions.initializePosAllPreps(storeId)),
                        dispatch(posActions.initializePosAddOns()),
                    ]);
                },
            )
            .then(
                ([
                    stationMode,
                    globalMenuLastUpdateDate,
                    storeMenuLastUpdateDate,
                    storeSettingsLastUpdateDate,
                ]) => {
                    globalMenuLastUpdateDateRef.current =
                        globalMenuLastUpdateDate;
                    storeMenuLastUpdateDateRef.current =
                        storeMenuLastUpdateDate;
                    storeSettingsLastUpdateDateRef.current =
                        storeSettingsLastUpdateDate;
                    return Promise.all([stationMode]);
                },
            )
            .then(([stationMode]) => {
                if (stationMode !== "drive_thru_order_fulfillment") {
                    return dispatch(
                        posActions.reserveCurrentOrderId(isTraining),
                    );
                }
            })
            .then(() => {
                setLoading(false);
                isAfterInitialLoad.current = true;
            })
            .catch((err) => {
                logger.warn(err);
                setErrorMessage(describeNetworkError(err).join("\n"));
            });
    };

    /* fire once on app startup */
    useEffect(() => {
        initialLoad();
    }, []);

    const reserverOrderId = useCallback(() => {
        // initial load reserves an order id if the station mode is not drive_thru_order_fulfillment
        if (
            !isAfterInitialLoad.current ||
            stationMode === "drive_thru_order_fulfillment"
        ) {
            return;
        }

        setLoading(true);
        dispatch(posActions.reserveCurrentOrderId(isTraining))
            .then(() => {
                setLoading(false);
            })
            .catch((err) => {
                logger.warn(err);
                setErrorMessage(describeNetworkError(err).join("\n"));
            });
    }, [isTraining]);

    /* should fire every time isTraining changes */
    useEffect(() => {
        reserverOrderId();
    }, [reserverOrderId]);

    // prevent a new inactivity refresh interval from being created in case currentStoreId changes
    // (it shouldn't once it changes from null to a string, provides extra safeguard)
    const inactivityRefreshCreated = useRef<boolean>(false);
    let inactivityId = 0;
    useEffect(() => {
        const setInactiveInterval = () => {
            inactivityId = window.setInterval(
                () => {
                    Promise.all([
                        posMenuEndpoints.getGlobalMenuLastUpdateDate(),
                        posMenuEndpoints.getStoreMenuLastUpdateDate(
                            currentOrderStoreId ?? "",
                        ),
                        posStoreEndpoints.getStoreSettingsLastUpdateDate(),
                    ])
                        .then(
                            async ([
                                _globalMenuLastUpdateDate,
                                _storeMenuLastUpdateDate,
                                _storeSettingsLastUpdateDate,
                            ]) => {
                                if (
                                    globalMenuLastUpdateDateRef.current ===
                                        null ||
                                    globalMenuLastUpdateDateRef.current <
                                        _globalMenuLastUpdateDate
                                ) {
                                    await dispatch(
                                        Actions.menuActions.loadGlobalMenu(),
                                    );
                                    await dispatch(
                                        posActions.initializePosAddOns(),
                                    );
                                    globalMenuLastUpdateDateRef.current =
                                        _globalMenuLastUpdateDate;
                                }
                                if (
                                    storeMenuLastUpdateDateRef.current ===
                                        null ||
                                    storeMenuLastUpdateDateRef.current <
                                        _storeMenuLastUpdateDate
                                ) {
                                    await dispatch(
                                        posActions.refreshStoreMenu(
                                            currentOrderStoreId ?? "",
                                            currentOrder.orderType ??
                                                "in_store", // this shouldn't be null
                                        ),
                                    );
                                    await dispatch(
                                        posActions.initializePosAllPreps(
                                            currentOrderStoreId ?? "",
                                        ),
                                    );
                                    storeMenuLastUpdateDateRef.current =
                                        _storeMenuLastUpdateDate;
                                }
                                if (
                                    storeSettingsLastUpdateDateRef.current ===
                                        null ||
                                    storeSettingsLastUpdateDateRef.current <
                                        _storeSettingsLastUpdateDate
                                ) {
                                    const storeSettings =
                                        await posStoreEndpoints.getStoreSettings();
                                    const charitySettings =
                                        storeSettings.charitySettings;
                                    await dispatch(
                                        posActions.initializeCharityRoundUpSettingsImmediate(
                                            charitySettings.isEnabled,
                                            charitySettings.charityName,
                                            charitySettings.showMinimal,
                                        ),
                                    );

                                    // initialize store address and tax rate.
                                    const storeInfo = {
                                        id: storeSettings.storeId,
                                        taxRate: storeSettings.taxRate,
                                        address: storeSettings.address,
                                        established: "n/a",
                                        name: "n/a",
                                        status: Constants.storeStatus.ONLINE,
                                        storeNumber: 0,
                                        maxOrderCost: 500,
                                        maxOrderCostCents: 50000,
                                        supportsCurbside: false,
                                        supportsCharlane: false,
                                        curbsideAutoCheckIn: {enabled: false},
                                        supportsDineIn: true,
                                        hours: {
                                            driveThru: [],
                                            inStore: [],
                                            modified: {
                                                driveThru: [],
                                                inStore: [],
                                            },
                                        },
                                        images: [],
                                        supportsQrToGo: false,
                                        supportsQrDineIn: false,
                                        isItemComboUpsellEnabled: false,
                                        supportsPickupLane: false,
                                        maxGiftCardCashOutCents:
                                            storeSettings.maxGiftCardCashOutCents,
                                        isCondimentUpsellEnabled: false,
                                        fundraiserSupportEnabled: false,
                                    };
                                    await dispatch(
                                        Actions.storeActions.setStores([
                                            storeInfo,
                                        ]),
                                    );
                                    storeSettingsLastUpdateDateRef.current =
                                        _storeSettingsLastUpdateDate;
                                }
                            },
                        )
                        .catch((err) => {
                            logger.warn(err);
                        });
                },
                import.meta.env.REACT_APP_INACTIVITY_MINUTES !== undefined
                    ? parseInt(import.meta.env.REACT_APP_INACTIVITY_MINUTES) *
                          60000
                    : 300000,
            );
        };
        const resetInterval = () => {
            clearInterval(inactivityId);
            setInactiveInterval();
        };

        const events = ["mousedown", "scroll", "keydown"];

        if (
            currentOrderStoreId &&
            currentOrder.orderType &&
            !inactivityRefreshCreated.current
        ) {
            events.forEach((e) => window.addEventListener(e, resetInterval));
            setInactiveInterval();
            inactivityRefreshCreated.current = true;
        }

        return () => {
            events.forEach((e) => {
                window.removeEventListener(e, resetInterval);
                clearInterval(inactivityId);
            });
            inactivityRefreshCreated.current = false;
        };
    }, [currentOrderStoreId, currentOrder.orderType, inactivityRefreshCreated]);

    useEffect(() => {
        const hasItemsAndOrCombos =
            currentOrder.itemCustomizationIds.length ||
            currentOrder.comboCustomizationIds.length;
        const hasGiftCards =
            posCurrentOrder.giftCards.addFunds.length ||
            posCurrentOrder.giftCards.purchase.length;

        if (
            !posCurrentOrder.startTime &&
            (hasItemsAndOrCombos || hasGiftCards)
        ) {
            // if order hasn't started yet and user just added an item/combo
            dispatch(posActions.setCurrentOrderStartTime(new Date()));
        } else if (
            posCurrentOrder.startTime &&
            !hasItemsAndOrCombos &&
            !hasGiftCards
        ) {
            // reset start time if remove only item/combo in order
            dispatch(posActions.setCurrentOrderStartTime(null));
        }
    }, [
        posCurrentOrder.startTime,
        currentOrder.itemCustomizationIds,
        currentOrder.comboCustomizationIds,
        posCurrentOrder.giftCards,
    ]);

    /* attempt to auto combo when the item customization ids in the current order changes */
    useEffect(() => {
        if (currentOrder.itemCustomizationIds.length >= 2) {
            dispatch(Actions.currentOrderActions.autoComboOrderItems());
        }
    }, [currentOrder.itemCustomizationIds]);

    const [discountErrorMessage, setDiscountErrorMessage] = useState("");
    // revalidate discounts when subtotal changes
    useEffect(() => {
        posCurrentOrder.discounts.forEach((d) => {
            dispatch(
                posActions.currentOrderValidateDiscount(
                    d.discountCode,
                    d.employeeId,
                    undefined,
                    true,
                ),
            )
                .then((result) => {
                    dispatch(
                        posActions.currentOrderRemoveDiscount(d.discountCode),
                    );
                    dispatch(
                        posActions.currentOrderAddDiscount(
                            d.discountCode,
                            result.redemptionAmountCents,
                            result.redemptionAmountPercentage,
                            result.discountType,
                        ),
                    );
                })
                .catch(() => {
                    setDiscountErrorMessage(strings.discountRemoved);
                    dispatch(
                        posActions.currentOrderRemoveDiscount(d.discountCode),
                    );
                });
        });
    }, [subtotalCents]);

    const onRetry = () => {
        setErrorMessage("");
        // if not true, initial load failed at some step, retry
        if (!isAfterInitialLoad.current) {
            initialLoad();
        } else {
            reserverOrderId();
        }
    };

    if (errorMessage) {
        return (
            <div>
                <ErrorModal
                    onClose={onRetry}
                    errorMessage={errorMessage}
                    closeButtonLabel={strings.retry}
                    title={strings.genericErrorTitle}
                />
            </div>
        );
    }

    if (dpiWarningMessage) {
        return (
            <div>
                <ErrorModal
                    onClose={() => setDpiWarningMessage("")}
                    errorMessage={dpiWarningMessage}
                    closeButtonLabel={strings.dismiss}
                    title={strings.dpiErrorModalTitle}
                    showITInfo
                />
            </div>
        );
    }

    if (loading) {
        return (
            <div className={styles.loading}>
                <Spinner />
            </div>
        );
    }

    return (
        <div className={styles.app}>
            <CashierCheckout className={styles.cashierCheckout} />
            <Outlet />
            <div className={styles.gitVersion}>
                {import.meta.env.REACT_APP_GIT_REVISION}
                {import.meta.env.DEV ? "-dev" : null}
            </div>
            {discountErrorMessage ? (
                <ErrorModal
                    onClose={() => setDiscountErrorMessage("")}
                    errorMessage={discountErrorMessage}
                />
            ) : null}
        </div>
    );
}
