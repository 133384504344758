import * as paymentMethods from "../../lib/api/paymentMethods";
import * as posModels from "../../lib/api/posModels";
import * as routeHelpers from "../../lib/routeHelpers";
import cn from "classnames";
import describeNetworkError from "../../lib/describeNetworkError";
import ErrorModal from "../ErrorModal";
import GiftCardsDrawer from "../GiftCardsDrawer";
import logger from "../../lib/logger";
import OutlineButton from "../OutlineButton";
import React, {useState, useEffect, FormEvent, useRef, useContext} from "react";
import Spinner from "../Spinner";
import StyledKeyboard from "../StyledKeyboard";
import styles from "./GiftCardsCashOut.module.css";
import TextInputWithResetButton from "../TextInputWithResetButton";
import {API, Lib} from "habit-core";
import {useExternalInput} from "../../lib/api/useExternalInput";
import {useNavigate, useLocation} from "react-router-dom";
import {openCashDrawer} from "../../lib/CashDrawer/CashDrawerPlugin";
import {CashierModeContext} from "../CashierModeContext";

const strings = {
    step0Instructions: "Please swipe the guest's card",
    balance: (cardType?: posModels.CardType) =>
        `${cardType === "comp" ? "Comp" : "Gift"} Card Balance Is `,
    step1Instructions: "Hand Guest Their Money",
    invalidText: "Unable To Cash-Out Gift Card",
    invalidCompCardText: "Comp Cards Can't Be Cashed Out",
    cancel: "Cancel Cash-Out",
    complete: "Complete Cash-Out",
    enterCardNumber: "Enter Card Number",
    submit: "Submit",
    manualInstruction:
        "If Unable To Swipe Card, Enter Card Number Manually Below",
    cashDrawerError:
        "An unexpected error occurred when establishing a connection with the cash drawer.",
    cashDrawerErrorWithMessage: (errMessage: string) =>
        `There was an error related to the cash drawer: ${errMessage}`,
};

type GiftCardsCashOutState = {
    balanceCents?: API.models.USDCents;
    cardNumber?: string;
};

const GIFT_CARD_LENGTH = 16;
const SHOW_SWIPE_BUTTON = import.meta.env.REACT_APP_MOCK_API === "true";

export default function GiftCardsCashOut() {
    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as GiftCardsCashOutState | undefined;
    const {isTraining} = useContext(CashierModeContext);

    const [step, setStep] = useState(
        locationState?.balanceCents !== undefined && locationState?.cardNumber
            ? 1
            : 0,
    );
    const [balanceCents, setBalanceCents] = useState<API.models.USDCents>(
        locationState?.balanceCents ?? 0,
    );
    const [cardNumber, setCardNumber] = useState(
        locationState?.cardNumber ?? "",
    );
    const [cardType, setCardType] = useState<posModels.CardType | undefined>(
        undefined,
    );
    const [ineligibleReason, setIneligibleReason] = useState("");
    const [manualCardNumber, setManualCardNumber] = useState("");
    const onChangeManualCardNumber = (input: string) => {
        setManualCardNumber(input);
    };
    const inputRef = useRef<HTMLInputElement>(null);
    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true);
    const {externalInput, clearExternalInput, cardReadError} = useExternalInput(
        step === 0 && keyboardCollapsed,
    );
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<{
        type: "warning" | "info";
        title?: string;
        message: string;
    } | null>(null);

    function getBalance(gcNumber: string) {
        if (loading) {
            return;
        }

        setLoading(true);
        paymentMethods
            .getGiftCardBalance(gcNumber)
            .then((data) => {
                setCardNumber(gcNumber);
                setCardType(data.cardType);
                setBalanceCents(data.balanceCents);
                const eligible =
                    data.eligibleForCashOut &&
                    data.cardType === "gift" &&
                    data.balanceCents !== 0;
                setStep(eligible ? 1 : -1);
                if (!eligible) {
                    setIneligibleReason(
                        data.cardType === "comp"
                            ? strings.invalidCompCardText
                            : strings.invalidText,
                    );
                }
            })
            .catch((err) => {
                logger.warn(err);
                let errorType: "warning" | "info" = "warning";
                if (err.response.status === 400) {
                    errorType = "info";
                }
                setError({
                    type: errorType,
                    message: describeNetworkError(err).join("\n"),
                });
                clearExternalInput();
            })
            .finally(() => setLoading(false));
    }

    function onCancel() {
        clearExternalInput();
        setBalanceCents(0);
        setCardNumber("");
        setStep(0);
    }

    function onComplete() {
        if (loading) {
            return;
        }

        setLoading(true);
        openCashDrawer(isTraining)
            .then(() => {
                return paymentMethods.cashOutGiftCard(cardNumber);
            })
            .then(() => {
                navigate(routeHelpers.giftCards());
            })
            .catch((err) => {
                logger.warn(err);
                let errorMessage = "";
                if (!err.config) {
                    // we know that this is not an AxiosError
                    errorMessage = err.message
                        ? strings.cashDrawerErrorWithMessage(err.message)
                        : strings.cashDrawerError;
                } else {
                    errorMessage = describeNetworkError(err).join("\n");
                }
                let errorType: "warning" | "info" = "warning";
                if (err.response.status === 400) {
                    errorType = "info";
                }
                setError({
                    type: errorType,
                    message: errorMessage,
                });
                setLoading(false);
            });
    }

    useEffect(() => {
        if (externalInput.length === GIFT_CARD_LENGTH && !cardReadError) {
            getBalance(externalInput);
        }
    }, [externalInput, cardReadError]);

    useEffect(() => {
        if (cardReadError) {
            setError(cardReadError);
        }
    }, [cardReadError]);

    function onSubmitGiftCardNumberForm(e: FormEvent) {
        e.preventDefault();
        getBalance(manualCardNumber);
    }

    function manualEntryContent() {
        return (
            <>
                <div className={styles.manualInstruction}>
                    {strings.manualInstruction}
                </div>
                <form
                    className={styles.inputContainer}
                    onSubmit={onSubmitGiftCardNumberForm}>
                    <TextInputWithResetButton
                        ref={inputRef}
                        value={manualCardNumber}
                        setValue={(value: string) => {
                            setManualCardNumber(value);
                            setError(null);
                        }}
                        onFocus={() => setKeyboardCollapsed(false)}
                        placeholder={strings.enterCardNumber}
                        required={true}
                    />
                    <OutlineButton
                        className={styles.submitButton}
                        label={strings.submit}
                        type="submit"
                        disabled={manualCardNumber.length !== GIFT_CARD_LENGTH}
                        loading={loading}
                    />
                </form>
            </>
        );
    }

    return (
        <>
            <GiftCardsDrawer
                balanceCents={balanceCents}
                left={
                    <>
                        {step === 0 ? (
                            <div className={styles.leftContainer}>
                                <div className={styles.step0}>
                                    {strings.step0Instructions}
                                </div>
                                {loading ? (
                                    <div className={styles.step0Spinner}>
                                        <Spinner />
                                    </div>
                                ) : null}
                                {SHOW_SWIPE_BUTTON ? (
                                    <button
                                        style={{
                                            position: "absolute",
                                            top: "75%",
                                        }}
                                        onClick={() =>
                                            getBalance("1234123412341234")
                                        }>
                                        mock swipe
                                    </button>
                                ) : (
                                    manualEntryContent()
                                )}
                                <div
                                    className={cn(
                                        styles.keyboardContainer,
                                        keyboardCollapsed &&
                                            styles.keyboardContainerCollapsed,
                                    )}>
                                    <StyledKeyboard
                                        currentInput={manualCardNumber}
                                        visible={!keyboardCollapsed}
                                        setVisible={(val: boolean) =>
                                            setKeyboardCollapsed(!val)
                                        }
                                        onChange={onChangeManualCardNumber}
                                        onPressEnter={() => () => {
                                            if (
                                                manualCardNumber.length !==
                                                GIFT_CARD_LENGTH
                                            ) {
                                                return;
                                            }
                                            getBalance(manualCardNumber);
                                        }}
                                        inputRefs={[inputRef]}
                                        layout="numeric"
                                    />
                                </div>
                            </div>
                        ) : null}
                        {step === 1 ? (
                            <div
                                className={cn(
                                    styles.leftContainer,
                                    styles.step1,
                                )}>
                                <div className={styles.step1Text}>
                                    <div className={styles.step1MainText}>
                                        {strings.balance(cardType)}
                                        {Lib.currency.centsToDollarString(
                                            balanceCents,
                                        )}
                                    </div>
                                    <div className={styles.step1SubText}>
                                        {strings.step1Instructions}
                                    </div>
                                </div>
                                <div className={styles.step1Buttons}>
                                    <OutlineButton
                                        mode="blue"
                                        className={styles.step1Button}
                                        labelClassName={styles.step1ButtonLabel}
                                        label={strings.cancel}
                                        onClick={onCancel}
                                        disabled={loading}
                                    />
                                    <OutlineButton
                                        mode="blue"
                                        className={styles.step1Button}
                                        labelClassName={styles.step1ButtonLabel}
                                        label={strings.complete}
                                        onClick={onComplete}
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        ) : null}
                        {step === -1 ? (
                            <div
                                className={cn(
                                    styles.leftContainer,
                                    styles.invalid,
                                )}>
                                <div className={styles.invalidText}>
                                    {strings.balance(cardType)}
                                    {Lib.currency.centsToDollarString(
                                        balanceCents,
                                    )}
                                </div>
                                <div className={styles.invalidSubText}>
                                    {ineligibleReason}
                                </div>
                            </div>
                        ) : null}
                    </>
                }
            />
            {error ? (
                <ErrorModal
                    title={error.title}
                    errorMessage={error.message}
                    onClose={() => {
                        setError(null);
                        clearExternalInput();
                    }}
                    backgroundColor={error.type === "info" ? "grey" : "red"}
                    showITInfo={error.type === "warning"}
                />
            ) : null}
        </>
    );
}
