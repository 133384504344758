import * as posModels from "../../lib/api/posModels";
import Modal from "../Modal";
import React from "react";
import styles from "./HealthCheckModal.module.css";
import OutlineButton from "../OutlineButton";

const strings = {
    title: "Health Check Results",
    success: "Success",
    errored: "Errored",
    printer: "Printer",
    cardReader: "Card Reader",
    qsr: "QSR",
    punchh: "Punchh Connection",
    mobileApi: "Habit Digital API",
    accountImport: "Tracks Account Import",
    tracks: "Tracks Connection",
    tracksDiscount: "Tracks Discount",
    tracksIdentity: "Tracks Identity",
    tracksPurchase: "Tracks Purchase",
    tracksMenuPatch: "Tracks Menu",
    sentry: "Sentry Reporting",
    sso: "Yum SSO",
    cognito: "AWS Cognito",
    cloudWatch: "AWS Cloudwatch",
    dismiss: "Dismiss",
};

type Props = {
    healthCheckData: posModels.ExhaustiveHealthCheckResponse;
    onClose: () => void;
};

export function HealthCheckModal({healthCheckData, onClose}: Props) {
    const getHealthCheckContent = (
        name: string,
        status: posModels.HealthCheckStatus,
    ) => {
        return (
            <div className={styles.statusLine}>
                <span
                    className={
                        status.error !== null ? styles.errorStatus : undefined
                    }>{`${name} - ${
                    status.error === null ? strings.success : strings.errored
                }`}</span>
                {status.error !== null ? (
                    <span>{` - Error Details: ${status.error}`}</span>
                ) : null}
            </div>
        );
    };

    const getHealthCheckContentWithCategory = (
        category: string,
        name: string,
        status: posModels.HealthCheckStatus,
        key: number,
    ) => {
        return (
            <div key={key} className={styles.statusLine}>
                <span
                    className={
                        status.error !== null ? styles.errorStatus : undefined
                    }>
                    {`${category} - ${name} - ${
                        status.error === null
                            ? strings.success
                            : strings.errored
                    }`}
                </span>
                {status.error !== null ? (
                    <span>{` - Error Details: ${status.error}`}</span>
                ) : null}
            </div>
        );
    };

    return (
        <Modal className={styles.modal} onClose={onClose}>
            <div className={styles.contentContainer}>
                <div className={styles.title}>{strings.title}</div>
                <div className={styles.content}>
                    {healthCheckData.printers.map((printerStatus, i) => {
                        return getHealthCheckContentWithCategory(
                            strings.printer,
                            printerStatus.name,
                            printerStatus.status,
                            i,
                        );
                    })}
                    {healthCheckData.cardReaders.map((cardReaderStatus, i) => {
                        return getHealthCheckContentWithCategory(
                            strings.cardReader,
                            cardReaderStatus.name,
                            cardReaderStatus.status,
                            i,
                        );
                    })}
                    {healthCheckData.qsr.map((qsrStatus, i) => {
                        return getHealthCheckContentWithCategory(
                            strings.qsr,
                            qsrStatus.name,
                            qsrStatus.status,
                            i,
                        );
                    })}{" "}
                    {getHealthCheckContent(
                        strings.punchh,
                        healthCheckData.punchh,
                    )}
                    {getHealthCheckContent(
                        strings.mobileApi,
                        healthCheckData.mobileApi,
                    )}
                    {getHealthCheckContent(
                        strings.accountImport,
                        healthCheckData.accountImport,
                    )}
                    {getHealthCheckContent(
                        strings.tracks,
                        healthCheckData.tracks,
                    )}
                    {getHealthCheckContent(
                        strings.tracksIdentity,
                        healthCheckData.tracksIdentity,
                    )}
                    {getHealthCheckContent(
                        strings.tracksDiscount,
                        healthCheckData.tracksDiscount,
                    )}
                    {getHealthCheckContent(
                        strings.tracksPurchase,
                        healthCheckData.tracksPurchase,
                    )}
                    {getHealthCheckContent(
                        strings.tracksMenuPatch,
                        healthCheckData.tracksMenuPatch,
                    )}
                    {getHealthCheckContent(
                        strings.sentry,
                        healthCheckData.sentry,
                    )}
                    {getHealthCheckContent(strings.sso, healthCheckData.sso)}
                    {getHealthCheckContent(
                        strings.cognito,
                        healthCheckData.cognito,
                    )}
                    {healthCheckData.cloudwatch.map((cloudwatchStatus, i) => {
                        return getHealthCheckContentWithCategory(
                            strings.cloudWatch,
                            cloudwatchStatus.name,
                            cloudwatchStatus.status,
                            i,
                        );
                    })}
                </div>

                <OutlineButton
                    className={styles.closeButton}
                    label={strings.dismiss}
                    onClick={onClose}
                />
            </div>
        </Modal>
    );
}
