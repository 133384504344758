import React from "react";
import styles from "./PreferenceButtons.module.css";

type Props = {
    className?: string;
    options: {id: string; label: string}[];
    order: string[];
    setOrder: React.Dispatch<React.SetStateAction<string[]>>;
};

export default function PreferenceButtons(props: Props) {
    return (
        <div className={props.className}>
            {props.options.map((o) => (
                <button
                    key={o.id}
                    className={styles.button}
                    onClick={() =>
                        props.setOrder((prev) =>
                            prev.includes(o.id)
                                ? prev.filter((id) => id !== o.id)
                                : [...prev, o.id],
                        )
                    }>
                    <div className={styles.label}>{o.label}</div>
                    <div className={styles.order}>
                        {props.order.findIndex((id) => id === o.id) !== -1
                            ? props.order.findIndex((id) => id === o.id) + 1
                            : null}
                    </div>
                </button>
            ))}
        </div>
    );
}
