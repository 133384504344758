import cn from "classnames";
import React, {forwardRef, InputHTMLAttributes} from "react";
import styles from "./TextInput.module.css";

type Props = InputHTMLAttributes<HTMLInputElement>;

function TextInput(props: Props, ref: React.ForwardedRef<HTMLInputElement>) {
    return (
        <input
            type="text"
            {...props}
            ref={ref}
            className={cn(styles.input, props.className)}
        />
    );
}

export default forwardRef<HTMLInputElement, Props>(TextInput);
