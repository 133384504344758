import {useEffect, useState} from "react";

const MINUTE_IN_MS = 60000;

// returns time in HH:MM format
export default function useCurrentTime() {
    const [time, setTime] = useState("");

    useEffect(() => {
        function updateTime() {
            const currentDate = new Date();
            setTime(
                currentDate.toLocaleTimeString(["en-US"], {
                    hour: "2-digit",
                    minute: "2-digit",
                }),
            );
        }

        const updateTimeInterval = setInterval(updateTime, MINUTE_IN_MS);
        updateTime();

        return () => {
            clearInterval(updateTimeInterval);
        };
    }, []);

    return {time};
}
