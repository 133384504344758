import * as routeHelpers from "../lib/routeHelpers";
import * as posPaymentMethods from "../lib/api/paymentMethods";
import describeNetworkError from "../lib/describeNetworkError";
import ErrorModal from "./ErrorModal";
import logger from "../lib/logger";
import OpenRegisterDrawer from "./OpenRegisterDrawer";
import React, {useState, useContext} from "react";
import {CashierModeContext} from "./CashierModeContext";
import {useNavigate} from "react-router-dom";
import {openCashDrawer} from "../lib/CashDrawer/CashDrawerPlugin";

const strings = {
    cashDrawerError:
        "An unexpected error occurred when establishing a connection with the cash drawer.",
    cashDrawerErrorWithMessage: (errMessage: string) =>
        `There was an error related to the cash drawer: ${errMessage}`,
};

export default function ManagerNoSale() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const {cashierMode, setCashierMode, isTraining} =
        useContext(CashierModeContext);

    function onClose() {
        navigate(routeHelpers.managerMenu());
    }

    function onOpenRegister() {
        if (isLoading) {
            return;
        }

        if (cashierMode.type !== "manager") {
            logger.warn(
                "Accessing manager no sale screen when not in manager mode",
            );
            return;
        }

        setIsLoading(true);
        posPaymentMethods
            .noSale(cashierMode.managerId)
            .then(() => openCashDrawer(isTraining))
            .then(() => {
                setIsLoading(false);
                navigate(routeHelpers.menu());
                setCashierMode({type: "user"});
            })
            .catch((err) => {
                if (!err.config) {
                    // not a axios error, produced by cash drawer call.
                    if (err.message) {
                        setErrorMessage(
                            strings.cashDrawerErrorWithMessage(err.message),
                        );
                    } else {
                        setErrorMessage(strings.cashDrawerError);
                    }
                } else {
                    setErrorMessage(describeNetworkError(err).join("\n"));
                }
                setIsLoading(false);
            });
    }

    return (
        <>
            <OpenRegisterDrawer
                onClose={onClose}
                onOpenRegister={onOpenRegister}
                loading={isLoading}
            />
            {errorMessage ? (
                <ErrorModal
                    onClose={() => setErrorMessage("")}
                    errorMessage={errorMessage}
                    showITInfo
                />
            ) : null}
        </>
    );
}
