import React from "react";
import styles from "./QuantityControl.module.css";
import cn from "classnames";
import minusIcon from "../../images/minus.svg";
import plusIcon from "../../images/plus.svg";
import minusGreyIcon from "../../images/minus-grey.svg";
import plusGreyIcon from "../../images/plus-grey.svg";

const MIN_QUANTITY = 1;
const MAX_QUANTITY = 99;

type Props = {
    quantity: number;
    setQuantity: (value: number) => void;
    min?: number;
    max?: number;
    variant?: "standard" | "outlined"; // used for various control layouts
};

export default function QuantityControl({
    quantity,
    setQuantity,
    min = MIN_QUANTITY,
    max = MAX_QUANTITY,
    variant = "standard",
}: Props) {
    return (
        <div className={cn(styles.container, styles[variant])}>
            <button
                className={cn(styles.quantityButton, styles[variant])}
                disabled={quantity <= min}
                onClick={() => setQuantity(quantity - 1)}>
                <img
                    className={styles.minusIcon}
                    src={quantity <= min ? minusGreyIcon : minusIcon}
                    alt=""
                />
            </button>
            <label className={cn(styles.quantityDisplay, styles[variant])}>
                {quantity}
            </label>
            <button
                disabled={quantity >= max}
                className={cn(styles.quantityButton, styles[variant])}
                onClick={() => setQuantity(quantity + 1)}>
                <img src={quantity >= max ? plusGreyIcon : plusIcon} alt="" />
            </button>
        </div>
    );
}
