import cn from "classnames";
import React from "react";
import Spinner from "../Spinner";
import styles from "./OutlineButton.module.css";

type Props = {
    children?: React.ReactNode;
    className?: string;
    disabled: boolean;
    label?: string;
    labelClassName?: string;
    label2?: string;
    mode: "blue" | "solid_blue" | "white" | "red";
    onClick?: () => void;
    type: "button" | "submit" | "reset";
    loading: boolean;
};

OutlineButton.defaultProps = {
    disabled: false,
    mode: "white",
    type: "button",
    loading: false,
};

const modeToClassName = {
    blue: styles.blue,
    solid_blue: styles.solidBlue,
    white: styles.white,
    red: styles.red,
};

export default function OutlineButton(props: Props) {
    function renderContent() {
        if (props.children) {
            return (
                <>
                    {props.children}
                    {props.loading ? (
                        <div className={styles.spinner}>
                            <Spinner isSmall={true} />
                        </div>
                    ) : null}
                </>
            );
        }
        return (
            <>
                <div className={cn(styles.label, props.labelClassName)}>
                    {props.label}
                </div>
                {props.label2 ? (
                    <div className={styles.label}>{props.label2}</div>
                ) : null}
                {props.loading ? (
                    <div className={styles.spinner}>
                        <Spinner isSmall={true} />
                    </div>
                ) : null}
            </>
        );
    }

    return (
        <button
            className={cn(
                styles.button,
                modeToClassName[props.mode],
                props.className,
                props.label && props.label2 ? styles.buttonTwoLabels : null,
                props.disabled || props.loading ? styles.disabled : null,
                props.loading ? styles.loading : null,
            )}
            disabled={props.disabled || props.loading}
            onClick={
                props.disabled || props.loading ? undefined : props.onClick
            }
            type={props.type}>
            {renderContent()}
        </button>
    );
}
