import React, {createContext, useState} from "react";

type ParallelModeContext = {
    isParallel: boolean;
    toggleIsParallel: (turnOn: boolean) => void;
};

export const ParallelModeContext = createContext<ParallelModeContext>({
    isParallel: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    toggleIsParallel: () => {},
});

export function ParallelModeProvider(props: {children: React.ReactNode}) {
    const [isParallel, setIsParallel] = useState(false);

    function toggleIsParallel(turnOn: boolean) {
        setIsParallel(turnOn);
    }

    return (
        <ParallelModeContext.Provider
            value={{
                isParallel,
                toggleIsParallel,
            }}>
            {props.children}
        </ParallelModeContext.Provider>
    );
}
