import * as posModels from "./posModels";
import instance from "./instance";
import {settings as urls} from "./urls";

// if the storedDeviceId is not null, the backend echoes it back.
export function get(
    storedDeviceId: string | null,
): Promise<posModels.Settings> {
    return instance
        .request({
            url: urls.get,
            method: "GET",
            params: {deviceId: storedDeviceId},
        })
        .then((response) => response.data);
}
